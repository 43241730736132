

export default class Address {
    constructor() {

    }

    public addressId: number = 0;
    public address: string;
    public town:string;
    public county:string;
    public postCode: string;
    public primaryAddress: boolean;

    public primaryContactId: number;
    public primaryContactName: string;
    public primaryJobTitle: string;
    public primaryMobile: string;
    public primaryLandline: string;
    public primaryEmail: string;

    public secondaryContactId: number;
    public secondaryContactName: string;
    public secondaryJobTitle: string;
    public secondaryMobile: string;
    public secondaryLandline: string;
    public secondaryEmail: string;

    public inUse: Boolean;
}

