import React from 'react';
import { Accordion,Card,Button } from 'react-bootstrap';
import postal from 'postal';



interface IProps {
    url:string,
    count:number,
    text:string,
    history:any,
    icon:any
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

const HomePageCard =(props:IProps) => {

    const redirectUrl = async() => {
        props.history.push(props.url);
    }
    
    return (
        <>

            <div className="card widget-user">
                <div className="card-body" style={{padding:"5px", height:"115px"}}>
                    <div style={{paddingLeft:"15px"}}>
                        <i className={`${props.icon} float-right`} style={{paddingRight:"10px", fontSize:"34px"}}></i>
                        <h3 className="text-primary normal-font-weight" data-plugin="counterup" style={{cursor: "pointer", fontSize:"42px", paddingTop:"10px"}} onClick={() => redirectUrl()} >{props.count}</h3>
                        <h5 className="font-10 normal-font-weight" style={{fontSize:"16px",cursor: "pointer", paddingTop:"15px"}} onClick={() => props.history.push(props.url)}>{props.text}</h5>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomePageCard;