import React from 'react';

interface IProps {
    value:number,
    color:string
}

const Pill =(props:IProps) => {
    return (
        <span style={{width:"25px"}} className={`badge badge-${props.color}`}>{props.value}</span>
    )
}

export default Pill;