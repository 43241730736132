import React, {useEffect, useState} from 'react';
import { Modal } from 'react-bootstrap';

import Course from '../../models/Course';
import Class from '../../models/Class';
import ClassWrapper from '../../models/ClassWrapper';
import ClassAttendenceBadge from '../../components/badges/ClassAttendenceBadge';

import UserService from "../../services/userService";



import axios from 'axios';

interface IProps {
    show:boolean;
    isMobile:boolean;
    hide:Function;
    course:Course;
}

const ClassListModal = (props:IProps) => {
    const [classes, setClasses] = useState(new Array<Class>());
    const userService = new UserService();

    const loadClasses = async() => {
        const response = await axios.get<ClassWrapper>(`/api/classes/GetByFilter?studentId=${userService.getUser().entityId}&courseId=${props.course.courseId}`);
        setClasses(response.data.classes);
    }

    useEffect(() => {
        loadClasses();
    },[props.show])
    
    
    return (
        <>
         <Modal show={props.show}  dialogClassName={(!props.isMobile) ? "modal-750px" : "modal-350px"}  onHide={()=>props.hide()}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.course.subject} {props.course.courseLevel}  - {classes.length} Classes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {classes && classes.map(c => (
                        <div className="chat-card">
                            <div className="card-header-mobile" >
                                <div className="row" >
                                    <div className="col-6">
                                        <h4 className="header-title m-t-0 m-b-30" style={{textTransform:"uppercase",color:"grey"}}>{c.dayWeek} {c.classDateString}  </h4>
                                    </div>
                                    <div className="col-6">
                                        <div style={{color:"#495057", textAlign:"right"}}>
                                            <h4 className="header-title m-t-0 m-b-30" style={{textTransform:"uppercase",color:"grey"}}>{c.classDatesCombined}  </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body-mobile">
                                <div>{c.course}</div>
                                <div>{c.subject} {c.level}</div>
                                <div>{c.definedAddress} </div>
                                <div>{c.teacher} </div>
                                <div><ClassAttendenceBadge statusId={c.classStudentStatusId} onClick={()=>{}} /></div>
                            </div>
                        </div>
                    ))}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ClassListModal;