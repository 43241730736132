import React, {useEffect, useState} from "react";
import { Route } from "react-router";
import Layout from "./components/Layout";

import ClassGrid from "./components/classes/ClassGrid";

import LoginPage from "./pages/LoginPage";
import SignUp from "./pages/SignUp";
import ForgotPassword from "./pages/ForgotPassword";


import StudentsGrid from "./components/students/StudentsGrid";
import StudentsEdit from "./components/students/StudentsEdit";

import TeachersGrid from './components/teachers/TeachersGrid';
import TeachersEdit from './components/teachers/TeachersEdit';
import TeachersPayments from './components/teachers/TeachersPayments';

import CoursesGrid from './components/courses/CoursesGrid';
import RoomsGrid from './components/rooms/RoomsGrid';
import RoomsEdit from './components/rooms/RoomsEdit';

import CompaniesGrid from './components/companies/CompaniesGrid';
import CompaniesForm from './components/companies/CompaniesForm';
import CoursesForm from './components/courses/CoursesForm';
import {SnackbarProvider} from 'react-snackbar-toast'

import StudentMain from './students/components/StudentMain';
import TeachersMain from './teachers/components/TeachersMain';
import axios from 'axios';
import PrivateRoute from './routing/PrivateRoute';

import CompanyAdminHomePage from './homePages/CompanyAdminHomePage';
import TeacherHomePage from './homePages/TeacherHomePage';
import StudentHomePage from './homePages/StudentHomePage';
import BlankHomePage from './homePages/BlankHomePage';
import ProfilePage from './pages/ProfilePage';


import ClassDetails from './teachers/components/ClassDetails';


import UserService from './services/userService';
import ReportsPage from './pages/ReportsPage';

/* Infra */

import CheckVersion from "./components/infrastructure/CheckVersion";

/* Mobile */

import ClassesMobile from './teachers/components/ClassesMobile';
import CoursesMobile from './teachers/components/CoursesMobile';

import PaymentsMobile from './teachers/components/PaymentsMobile';

import MarkClassesMobile from './teachers/components/MarkClassesMobile';
import ProfileMobile from './teachers/components/ProfileMobile';
import StudentsMobile from './teachers/components/StudentsMobile';


import StudentsClassesMobile from './students/components/ClassesMobile';
import StudentsCoursesMobile from './students/components/CoursesMobile';

import Chat from './students/components/Chat';

import PrintPaymentReport from './components/reports/PrintPaymentReport';
import PrintStudentAttendence from "./components/reports/PrintStudentAttendence";

import NotesPage from "./pages/NotesPage";

import jwt_decode from "jwt-decode";
import { isMobile } from 'react-device-detect';

import "react-datepicker/dist/react-datepicker.css";



const userService = new UserService();

const getUserHomePage = () => {
  if (userService.getPrimaryRole().toUpperCase() === "TEACHER") {
    return TeacherHomePage;
  } else if (userService.getPrimaryRole().toUpperCase() === "STUDENT"){
    return StudentHomePage;
  } else if (userService.getPrimaryRole().toUpperCase() === "COMPANYADMIN"){
    return CompanyAdminHomePage;
  } else {
    return BlankHomePage;
  }
}
export const browserRouterRef = React.createRef();


export default () => {
  const [versionUpdated, setVersionUpdated] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);


  const updateVersionUpdated = () =>{
    if(!versionUpdated)
      setVersionUpdated(true);
  } 

  window['areFormsDirty'] = false;


  axios.interceptors.request.use((config) => {
      setShowSpinner(true);

      let token;
      const userString = localStorage.getItem('user');

      let user;
      if (userString && userString !== "") {
           user = JSON.parse(userString);
      }

      token = (user) ? user.token : "";



      if(token && token!=="" && window.location.pathname != "/login" ){
        let decoded:any = jwt_decode(token);
        if (decoded.exp < (new Date().getTime() + 1) / 1000) {
          document.location.href="/login"
        }
      }
      

      config.headers.Authorization = `Bearer ${token}`
      return config;
  }, (error) => {
    setShowSpinner(false);
    return Promise.reject(error);
  });


  axios.interceptors.response.use((config) => {
      setShowSpinner(false);

      return config;
  }, (error) => {
    setShowSpinner(false);
    return Promise.reject(error);
  });

  


    return (
      /*https://dev.to/mychal/protected-routes-with-react-function-components-dh#:~:text=Protected%20routes%20allow%20us%20to,components%20along%20with%20react%2Drouter.*/
     /*TODO: These need to be locked down with roles*/
      <>

      <div className="spinner-modal" style={{ visibility: showSpinner == true ? 'visible': 'hidden', paddingTop:"100px"}} >
          <div style={{textAlign:"center", verticalAlign:"middle"}}>
            <div className="spinner-border text-muted" style={{top:"50%", left:"50%"}}></div>
          </div>
      </div>

      <div >
        <SnackbarProvider>
          <Layout history={browserRouterRef}>
          <CheckVersion updateVersionUpdated={updateVersionUpdated} />
            {!versionUpdated && <>
              <PrivateRoute exact path="/" component={getUserHomePage()} />
              <PrivateRoute exact path="/Memberships" component={StudentsGrid} />
              <PrivateRoute exact path="/studentsform" component={StudentsEdit} />
              <PrivateRoute exact path="/studentsform/:id" component={StudentsEdit} />
              <PrivateRoute exact path="/Classes" component={ClassGrid} />
              <PrivateRoute exact path="/teachers" component={TeachersGrid} />
              <PrivateRoute exact path="/teachersform" component={TeachersEdit} />
              <PrivateRoute exact path="/teachersform/:id" component={TeachersEdit} />

              <PrivateRoute exact path="/TeachersPayments" component={TeachersPayments} />
              

              <PrivateRoute exact path="/students" component={StudentsGrid} />
              <PrivateRoute exact path="/courses" component={CoursesGrid}  />
              <PrivateRoute exact path="/rooms" component={RoomsGrid} />
              <PrivateRoute exact path="/roomsForm" component={RoomsEdit} />
              <PrivateRoute exact path="/roomsForm/:id" component={RoomsEdit} />
              <PrivateRoute exact path="/companies" component={CompaniesGrid} />
              <PrivateRoute exact path="/companiesform" component={CompaniesForm} />
              <PrivateRoute exact path="/companiesform/:id" component={CompaniesForm} />
              <PrivateRoute exact path="/coursesform/" component={CoursesForm} />
              <PrivateRoute exact path="/coursesform/:id" component={CoursesForm} />
              <PrivateRoute exact path="/profile" component={ProfilePage} />

              <PrivateRoute exact path="/Reports" component={ReportsPage} />

              
              <PrivateRoute exact path="/students/main" component={StudentMain} />
              <PrivateRoute exact path="/teachers/main" component={TeachersMain} />


              <PrivateRoute exact path="/teachers/class" component={ClassDetails} />
              <PrivateRoute exact path="/teachers/MarkClassesMobile" component={MarkClassesMobile} />
              <PrivateRoute exact path="/teachers/PaymentsMobile" component={PaymentsMobile} />
              <PrivateRoute exact path="/teachers/ProfileMobile" component={ProfileMobile} />
              <PrivateRoute exact path="/teachers/StudentsMobile" component={StudentsMobile} />

              <PrivateRoute exact path="/teachers/CoursesMobile" component={CoursesMobile} />
              <PrivateRoute exact path="/teachers/classesmobile" component={ClassesMobile} />
              <PrivateRoute exact path="/teachers/classesmobile/:id" component={ClassesMobile} />

              <PrivateRoute exact path="/students/classesmobile" component={StudentsClassesMobile} />
              <PrivateRoute exact path="/students/coursesmobile" component={StudentsCoursesMobile} />
              <PrivateRoute exact path="/students/chat" component={Chat} />

              <PrivateRoute exact path="/reports/printpaymentreport" component={PrintPaymentReport} />
              <PrivateRoute exact path="/reports/printstudentattendence" component={PrintStudentAttendence} />

              <PrivateRoute exact path="/notes" component={NotesPage} />
              
            
            </>}

            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/signup" component={SignUp} />
            <Route exact path="/forgotpassword" component={ForgotPassword} />
            
            
          </Layout>
        </SnackbarProvider>
      </div>


      
    </>
  )
}
