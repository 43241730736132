import React from 'react';
import StudentsTab from "./StudentsTabs";

interface IProps {
    history: any,
    match: any
}

const StudentsEdit =(props:IProps) => {
    return (
        <>
          <StudentsTab id={props.match && props.match.params.id} history={props.history}/>
        </>
    )
}

export default StudentsEdit;