export default class Avatar {
    constructor() {

    }

    public avatarId: number = 0;
    public imageName:string;
    public gender: number;
    public avatarImage: string;
}



