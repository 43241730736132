import React, {useState, useEffect} from 'react';
import InputTextBoxDuel from '../components/common/InputTextBoxDuel';
import ChangePasswordRequestVM from '../models/ChangePasswordRequestVM';
import ChangeUserDetailsRequestVM from '../models/ChangeUserDetailsRequestVM';
import UserService from '../services/userService';
import useSnackbar from 'react-snackbar-toast';
import Switch from "react-switch";
import GDPRVM from '../models/GDPRVM';

import axios from 'axios';

interface IProps {}

const ProfilePage = (props: IProps) => {
    const { addToast } = useSnackbar();

    const open = (option:any, message:string) => {
        addToast(message, option);
    };


    const userService = new UserService();
    const [passwordRequest, setPasswordRequest] = useState(new ChangePasswordRequestVM());
    const [passwordValidationErrors, setPasswordValidationErrors] = useState(new Array<string>());
    const [currentProfile, setCurrentProfile] = useState(new ChangeUserDetailsRequestVM());
    const [profileValidationErrors, setProfileValidationErrors] = useState(new Array<string>());

    //GDPR
    const [CREChecked, setCREChecked]   = useState(false);
    const [CPPDChecked, setCPPDChecked] = useState(false);
    const [CPMDChecked, setCPMDChecked] = useState(false);
    const [CPSDChecked, setCPSDChecked] = useState(false);

    const [GDPRChecked, setGDPRChecked] = useState(new Array<string>());


    const [GDPRConsent, setGDPRConsent] = useState(new Array<GDPRVM>());
    

    useEffect(() => {
        populateCurrentProfile();
        populateGDPR();
    }, []);

    const populateGDPR = async() => {
        const response = await axios.get<Array<GDPRVM>>('/api/users/GetGDPRConsentByUser');
        setGDPRConsent(response.data);
    }

    const populateCurrentProfile = () => {
        const user = userService.getUser();
        let changeUserDetailsRequestVM = new ChangeUserDetailsRequestVM();

        changeUserDetailsRequestVM.FirstName = user.firstName;
        changeUserDetailsRequestVM.LastName = user.lastName;
        changeUserDetailsRequestVM.Email = user.username;

        setCurrentProfile(changeUserDetailsRequestVM);
    }


    const updatePasswords = (e:any) => {
        setPasswordRequest({ ...passwordRequest, [e.target.name]: e.target.value, UserName: userService.getUserName() });
    }

    const updateProfileField = (e:any) => {
        setCurrentProfile({ ...currentProfile, [e.target.name]: e.target.value, UserName: userService.getUserName() });
    }

    const hasError = (field: string) => {
        let errorsFound = passwordValidationErrors.find(e =>  e === field );
        if (errorsFound && errorsFound.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    const hasProfileError = (field: string) => {
        let errorsFound = profileValidationErrors.find(e =>  e === field );
        if (errorsFound && errorsFound.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    const isPasswordFormValid = (): boolean => {
        let errors = new Array<string>();
        let isValid = true;

        if (!passwordRequest.OldPassword || passwordRequest.OldPassword === "") {
            errors.push("OldPassword");
            isValid = false;
        }
        if (!passwordRequest.NewPassword || passwordRequest.NewPassword === "") {
            errors.push("NewPassword");
            isValid = false;
        }
        if (!passwordRequest.ConfirmPassword || passwordRequest.ConfirmPassword === "") {
            errors.push("ConfirmPassword");
            isValid = false;
        }

        setPasswordValidationErrors(errors);

        return isValid;
    }

    const isProfileFormValid = () => {
        let errors = new Array<string>();
        let isValid = true;

        if (!currentProfile.FirstName || currentProfile.FirstName === "") {
            errors.push("FirstName");
            isValid = false;
        }

        if (!currentProfile.LastName || currentProfile.LastName === "") {
            errors.push("LastName");
            isValid = false;
        }

        if (!currentProfile.Email || currentProfile.Email === "") {
            errors.push("Email");
            isValid = false;
        }

        setProfileValidationErrors(errors);

        return isValid;
    }

    const updatePassword = async () => {
        let isValid = isPasswordFormValid(); 

        if (isValid) {
            const response = await axios.post('/api/users/UpdatePassword', passwordRequest);
            
            if(response.data){
                const responseIsValid = response.data.isValid
                if (!responseIsValid) {
                    open({ className: "customToast", type: "error" }, "Could not update password, incorrect details supplied");
                } else {
                    open({ className: "customToast", type: "success" }, "Password updated");
                }
            }
        }
    }

    const UpdateProfile = async () => {
        const isValid = isProfileFormValid();

        if(isValid){
            const response = await axios.post('/api/users/UpdateProfile', currentProfile);
            open({ className: "customToast", type: "success" }, "Profile Updated");

        }
    }

    const switcherChanged = async(gpprCode:string) => {
        let copy = [ ...GDPRConsent ]
        let GDPRItem = copy.find(g => { return g.code === gpprCode });

        if (GDPRItem) {
            GDPRItem.consent = !GDPRItem.consent;
            var foundIndex = copy.findIndex(g => g.code === gpprCode );
            copy[foundIndex] = GDPRItem;

            setGDPRConsent(copy);

            await axios.get(`/api/users/UpdateGDPR?GDPRCode=${gpprCode}&value=${GDPRItem.consent}`);
        }


    }
    
    return (
        <>
            <div className="row">
                <div className="w-550">
                    <div className="row">
                        <div className="col">

                            <div className="card" style={{height:"397px", width:"500px"}}>
                                <div className="card-body">
                                    <div className="dropdown float-right">
                                        <a href="#" className="dropdown-toggle card-drop" data-toggle="dropdown"
                                            aria-expanded="false">
                                            <i className="zmdi zmdi-more-vert"></i>
                                        </a>
                                        <ul className="dropdown-menu dropdown-menu-right">
                                            <li><a href="#" className="dropdown-item">Action</a></li>
                                            <li><a href="#" className="dropdown-item">Another action</a></li>
                                            <li><a href="#" className="dropdown-item">Something else here</a></li>
                                            <li className="dropdown-divider"></li>
                                            <li><a href="#" className="dropdown-item">Separated link</a></li>
                                        </ul>
                                    </div>

                                    <h4 className="header-title m-t-0 m-b-30">Your Information</h4>
                                    
                                    <div className="row">
                                        <div className="col-6">
                                            <InputTextBoxDuel mode="E" value={currentProfile && currentProfile.FirstName} errorMessage="Please enter your First Name" name="FirstName" isError={hasProfileError("FirstName")} label="First Name" onChange={(e) => updateProfileField(e)}  />
                                        </div>
                                        <div className="col-6">
                                            <InputTextBoxDuel mode="E" value={currentProfile && currentProfile.LastName}  errorMessage="Please enter your Last Name" name="LastName" isError={hasProfileError("LastName")} label="Last Name" onChange={(e) => updateProfileField(e)}  />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-10">
                                            <InputTextBoxDuel mode="E" value={currentProfile && currentProfile.Email} errorMessage="Please enter your Email" name="Email" isError={hasProfileError("Email")} label="Email"  width={435} onChange={(e) => updateProfileField(e)} />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group text-right m-b-0">
                                            <div style={{ paddingRight: "25px", paddingTop: "100px" }}><button className="btn btn-primary-pink" style={{ width: "175px" }} onClick={()=>UpdateProfile()}>Update Details</button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card" style={{height:"397px", width:"500px"}}>
                        <div className="card-body">
                            <div className="dropdown float-right">
                                <a href="#" className="dropdown-toggle card-drop" data-toggle="dropdown"
                                    aria-expanded="false">
                                    <i className="zmdi zmdi-more-vert"></i>
                                </a>
                                <ul className="dropdown-menu dropdown-menu-right">
                                    <li><a href="#" className="dropdown-item">Action</a></li>
                                    <li><a href="#" className="dropdown-item">Another action</a></li>
                                    <li><a href="#" className="dropdown-item">Something else here</a></li>
                                    <li className="dropdown-divider"></li>
                                    <li><a href="#" className="dropdown-item">Separated link</a></li>
                                </ul>
                            </div>

                            <h4 className="header-title m-t-0 m-b-30">Update Password</h4>
                            
                            <div className="row">
                                <div className="col-10">
                                    <InputTextBoxDuel mode="E"  label="Current Password" isPassword={true} isError={hasError("OldPassword")} errorMessage="Please enter Current Password" name="OldPassword" value={passwordRequest.OldPassword} width={435} onChange={(e) => updatePasswords(e)} />
                                </div>
                                <div className="col-10">
                                    <InputTextBoxDuel mode="E" label="New Password" isPassword={true} isError={hasError("NewPassword")} errorMessage="Please enter a New Password" name="NewPassword" value={passwordRequest.NewPassword} width={435} onChange={(e) => updatePasswords(e)} />
                                </div>
                                <div className="col-10">
                                    <InputTextBoxDuel mode="E" label="Confirm Password" isPassword={true} isError={hasError("ConfirmPassword")} errorMessage="Please enter a Confirm Password" name="ConfirmPassword" value={passwordRequest.ConfirmPassword} width={435} onChange={(e) => updatePasswords(e)}  />
                                </div>
                                <div className="col-12">
                                    <div className="form-group text-right m-b-0">
                                        <div style={{paddingRight:"25px",paddingTop:"25px"}}><button className="btn btn-primary-pink" style={{width:"175px"}} onClick={()=>updatePassword()}>Update Password</button></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                
            </div>
            <div className="row">
                <div className="w-550">
                    <div className="row">

                        <div className="col">
                            <div className="card" style={{height:"397px", width:"500px"}}>
                                <div className="card-body">
                                    <div className="dropdown float-right">
                                        <a href="#" className="dropdown-toggle card-drop" data-toggle="dropdown"
                                            aria-expanded="false">
                                            <i className="zmdi zmdi-more-vert"></i>
                                        </a>
                                        <ul className="dropdown-menu dropdown-menu-right">
                                            <li><a href="#" className="dropdown-item">Action</a></li>
                                            <li><a href="#" className="dropdown-item">Another action</a></li>
                                            <li><a href="#" className="dropdown-item">Something else here</a></li>
                                            <li className="dropdown-divider"></li>
                                            <li><a href="#" className="dropdown-item">Separated link</a></li>
                                        </ul>
                                    </div>

                                    <h4 className="header-title m-t-0 m-b-30">Data Consent Settings</h4>
                                    
                                    <div className="row">
                                        
                                        <div className="card" style={{width:"750px"}} >
                                        <div className="row" style={{paddingBottom:"10px"}}>
                                            <div className="col">
                                                <div className="card-title pink" style={{paddingLeft:"10px",paddingTop:"10px"}}>Consent</div>
                                            </div>
                                        </div>
                                        <div className="row">
                                                <div className="col">
                                                {GDPRConsent.map(g => (
                                                    <div className="row" style={{ paddingBottom: "12px", paddingLeft: "12px" }}>
                                                        <div className="col-10">{g.name}:</div>
                                                        <div className="col-2"> <Switch height={25} onChange={() => switcherChanged(g.code)} checked={g.consent} /> </div>
                                                    </div>
                                                ))}


                                               
                                            </div>
                                        </div>
                                    </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default ProfilePage;