import React,{useEffect, useState} from 'react';
import Room from '../../models/Room';
import axios from "axios";

import ActionButton from '../buttons/ActionButton';


interface IProps {
    history:any
}

const RoomsGrid = (props:IProps) => {
    const [rooms, setRooms] = useState<Array<Room>>(new Array<Room>());

    const getRooms = () => {
        let roomsData = new Array<Room>();

        let room1:Room = new Room();
        room1.roomId = 1;
        room1.name = "Pedlaer";
        roomsData.push(room1);

        let room2:Room = new Room();
        room2.roomId = 1;
        room2.name = "Charing X";
        roomsData.push(room2);

        return roomsData;
    }

   

    useEffect(() => {
        // Create an scoped async function in the hook
        async function getData() {
            var response = await axios.get<Array<Room>>(`/api/rooms/getall`);
            setRooms(response.data);
        }
        getData();
      }, []);
     
    return(
        <div >
            <div className="card">
                <div className="card-body">
                    <div className="page-title-box" style={{paddingBottom:"25px"}}>
                        <div className="page-title-btn btn-group float-right">
                            <ActionButton text="ADD ROOM" onClick={()=> props.history.push('/roomsForm')} />
                        </div>
                        <h4 className="page-title">Rooms</h4>
                    </div>
                    


                    <table className="table table table-hover m-0" style={{backgroundColor:"white"}}>
                        <thead>
                            <tr>
                                <th>Room Name</th>
                                <th>Company</th>
                                <th>Capacity</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rooms.map(r=>(
                                <tr onClick={() => props.history.push(`/roomsForm/${r.roomId}`) }>
                                    <td>{r.name}</td>
                                    <td>{r.company}</td>
                                    <td>{r.capacity}</td>
                                </tr>
                            ))}

                            {!rooms || rooms.length===0 && <tr><td colSpan={3} align="center">No rooms currently</td></tr>}
                            
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        );
}

export default RoomsGrid;