import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { isMobile } from "react-device-detect";

import BottomNav from '../../teachers/BottomNav';
import Course from '../../models/Course';
import ClassWrapper from '../../models/ClassWrapper';
import Class from '../../models/Class';

import Student from '../../models/Student';
import UserService from '../../services/userService';
import ClassStatusBadge from '../../components/badges/ClassStatusBadge';

import WaveBox from "../../components/common/WaveBox";
import CourseStatusBadge from '../../components/badges/CourseStatusBadge';

import ActionButton from '../../components/buttons/ActionButton';
import CancelButton from '../../components/buttons/CancelButton';

import ClassStatusVM from '../../models/ClassStatusVM';

import TimeRemainingBadge from '../../components/badges/TimeRemainingBadge';

import ClassList from './common/ClassList';
import RescheduleClass from './common/RescheduleClass';
import moment from 'moment';




interface IProps {
    history:any
}

const CoursesMobile = (props:IProps) => {
    let userService = new UserService();

    const [courses, setCourses] = useState(new Array<Course>());
    const [classes, setClasses] = useState(new Array<Class>());
    const [students, setStudents] = useState(new Array<Student>());

    const [remainingClasses, setRemainingClasses] = useState(new Array<Class>());

    const [showClassesModal, setShowClassesModal] = useState(false);
    const [showUsersModal, setShowUsersModal] = useState(false);
    const [currentCourse, setCurrentCourse] = useState(new Course());
    const [currentClass, setCurrentClass] = useState(new Class());

    const [classStatues, setClassStatues] = useState(new Array<ClassStatusVM>());
    const [currentCourseStudents, setCurrentCourseStudents] = useState(new Array<Student>());

    const [showClassesRemainingModal, setShowClassesRemainingModal] = useState(false);

    const [rescheduleDate, setrescheduleDate] = useState(new Date());
    const [rescheduleTime, setrescheduleTime] = useState("");

    const [modalMode, setModalMode] = useState("C");
    const [modalRemainingMode, setModalRemainingMode] = useState("R");

    const [isLoaded, setIsLoaded] = useState(false);

    const [showMoveToEnd, setShowMoveToEnd] = useState(false);
    

    useEffect(() => {
        loadCourses();
        window.scrollTo(0, 0);
    }, []);

    const loadCourses = async() => {
        const results = await axios.get<Array<Course>>(`api/courses/GetAll?teacherId=${userService.getUser().roles[0].entityId}&excludeDraft=true&excludeArchived=true`); 
        setCourses(results.data);
        setIsLoaded(true);
    }

    const showClassIcon_OnClick = async(c:Course) => {
        //props.history.push(`/teachers/classesmobile/${c.courseId}`);
        setShowClassesModal(true);

        const results = await axios.get<ClassWrapper>(`api/classes/GetByFilter?teacherId=${userService.getUser().roles[0].entityId}&courseId=${c.courseId}`); 

        setClasses(results.data.classes);
        setCurrentCourse(c);
    }

    const showUserIcon_OnClick = async(c:Course) => {

        const result = await axios.get<Array<Student>>(`api/students/GetStudentsByCourseId?id=${c.courseId}`);
        setStudents(result.data);
        setCurrentCourse(c);
        
        setShowUsersModal(true);
    }

    //TODO: Move to helper class
    const getCourseMinsUsed = (c:Course) => {
        let result:number = (c.classLengthTemplate * c.duration) - c.totalClassLength;

        let toReturn: any;
        if (result < 0) {
            toReturn = <span style={{ color: "red" }}>{result}</span>;
        } else {
            toReturn = <span >{result}</span>;
        }

        return toReturn;
    }

    const hideClassesModal =() => {
        setShowClassesModal(!showClassesModal);
    }

    const hideUsersModal = () => {
        setShowUsersModal(!showUsersModal);
    }

    //TODO: Duplicated function from MarkClassesModal - Create component
    const loadStudentForClass = async (classId:number) => {
        if(classId){
            let response = await axios.get(`/api/students/GetStudentsByClassId?id=${classId}`);
            setCurrentCourseStudents(response.data);
        }
    }

    const classStatusClicked = (c:Class) => {
        if(c.seriesStatusId === 1 ||c.seriesStatusId === 2 || c.seriesStatusId === 3) {
            setCurrentClass(c);
            loadStudentForClass(c.classId);

            setModalMode("M");
        }
    }

    const WaveBoxHandler = (record, item) => {
        const studentId = record.studentId;
        const classId   = currentClass.classId;

        let statusId = 0;
        if(item==="ABSENT") {
            statusId = 5;
        } else if (item==="ATTENDED") {
            statusId = 2;
        }

        let classStatusToAdd = new ClassStatusVM();

        classStatusToAdd.classId = classId;
        classStatusToAdd.studentId = studentId;
        classStatusToAdd.statusId = statusId;

        setClassStatues(oldArray => [...oldArray, classStatusToAdd]);

        //Update local array

        let data = [...currentCourseStudents];
        let studentIndex = data.findIndex(d =>  d.studentId === studentId);


        data[studentIndex].status = statusId;

        setCurrentCourseStudents(data);

        //axios.get(`/api/classes/UpdateClassAttendence?studentId=${studentId}&classId=${classId}&statusId=${statusId}`);
    }

    const getStatusFromId = (statusId: number) => {
        if (statusId === 1) {
            return "PENDING";
        } else if (statusId === 2){
            return "ATTENDED";
        } else if (statusId === 3){
            return "Late cancellation";
        }  else if (statusId === 4){
            return "Cancelled in advance";
        } else if (statusId === 5){
            return "ABSENT";
        }
    }

    const isClassMarkingComplete = ():boolean => {
        let isMarkedComplete:boolean = true;

        currentCourseStudents.map(c => {
            if(c.status === 1 || c.status === 0){
                isMarkedComplete = false;
            }
        });

        return isMarkedComplete;
    }

    const saveMarkings = async() => {
        await axios.post(`/api/classes/UpdateClassAttendenceMultiple`, classStatues);
        
        let markingComplete:boolean = isClassMarkingComplete();
        //props.updateGridClassStatus(props.classId,  markingComplete);
    }

    const saveMarkingButton_OnClick =() => {
        //Need to update the model to avoid time sync issues

        saveMarkings();
        setModalMode("C");

        const isMarkingComplete:boolean = isClassMarkingComplete()

        let newSeriesStatus = (isMarkingComplete) ? 3 : 2;

        let copyClasses = [...classes];
        let classFound = copyClasses.find(c=>c.classId === currentClass.classId); 
        
        if(classFound){
            classFound.seriesStatusId = newSeriesStatus;
        }

        setClasses(copyClasses);

        loadCourses();
    }


    const hideClassesRemainingModal = () => {
        setShowClassesRemainingModal(false);
        setModalRemainingMode("R");
    }

    const ShowClassesRemainingModal = async(course:Course) => {
        const results = await axios.get<ClassWrapper>(`/api/classes/GetByFilter?teacherId=${userService.getUser().roles[0].entityId}&showRemainingOnly=true&courseId=${course.courseId}`);
        setRemainingClasses(results.data.classes);
        setShowClassesRemainingModal(true);
        setCurrentCourse(course);
    }

    const classEditClicked = (c:Class) => {
        if(c?.classDateString){
            var dd = moment(c?.classDateString, "DD-MMM-YYYY");

            setrescheduleDate(new Date(dd.toString()));
        }

        setModalMode("E");
        setCurrentClass(c);
    }

    const timeChange = (e:any) =>{
        setrescheduleTime(e);
    }

    const rescheduleDateChange = (e:any) => {
        setrescheduleDate(e);

    }

    const updateClassDetails = async() => {
        let classId:number = 0;

        if(currentClass){
            classId = currentClass?.classId;
        }
        let newDateString = (rescheduleDate.getDate()).toString() + "/" + ((rescheduleDate.getMonth() + 1).toString()+ "/" + rescheduleDate.getFullYear().toString())

        await axios.get(`/api/classes/UpdateClassDate?classId=${classId}&classDate=${newDateString} ${rescheduleTime}&classLength=${currentClass.classLength}`);

        setModalMode("C");

        const results = await axios.get<ClassWrapper>(`api/classes/GetByFilter?teacherId=${userService.getUser().roles[0].entityId}&courseId=${currentCourse.courseId}`); 

        setClasses(results.data.classes);

        await loadCourses();

        let resultsCourse = await axios.get<Array<Course>>(`api/courses/GetAll?teacherId=${userService.getUser().roles[0].entityId}&excludeDraft=true&excludeArchived=true`); 
        if(currentCourse && currentCourse.courseId){
            let course = resultsCourse.data.find(c => c.courseId === currentCourse.courseId);
            if(course) {
                setCurrentCourse(course);
            }
        }

        
    } 

    const updateRemainingClassDetails = async() => {
        let classId:number = 0;
        let courseId:number = 0;

        if(currentClass){
            classId = currentClass?.classId;
        }
        

        let newDateString = (rescheduleDate.getDate()).toString() + "/" + ((rescheduleDate.getMonth() + 1).toString()+ "/" + rescheduleDate.getFullYear().toString())

        await axios.get(`/api/classes/UpdateClassDate?classId=${classId}&classDate=${newDateString} ${rescheduleTime}&classLength=${currentClass.classLength}`);

        setModalMode("C");

        const results = await axios.get<ClassWrapper>(`/api/classes/GetByFilter?teacherId=${userService.getUser().roles[0].entityId}&showRemainingOnly=true`);
        setRemainingClasses(results.data.classes);

    } 

    const modalRemainingEdit_Click = (c:Class) => {
        setCurrentClass(c);

        if(c?.classDateString){
            var dd = moment(c?.classDateString, "DD-MMM-YYYY");

            setrescheduleDate(new Date(dd.toString()));
        }

        

        setModalRemainingMode('E');
    }

    const moveClassToEnd = async () => {
        await axios.get(`/api/classes/UpdateClassDateToLast?classId=${currentClass.classId}`);
        setModalMode('C');
        setShowMoveToEnd(false);

        const results = await axios.get<ClassWrapper>(`api/classes/GetByFilter?teacherId=${userService.getUser().roles[0].entityId}&courseId=${currentCourse.courseId}`); 

        setClasses(results.data.classes);


        await loadCourses();

        let resultsCourse = await axios.get<Array<Course>>(`api/courses/GetAll?teacherId=${userService.getUser().roles[0].entityId}&excludeDraft=true&excludeArchived=true`); 
        if(currentCourse && currentCourse.courseId){
            let course = resultsCourse.data.find(c => c.courseId === currentCourse.courseId);
            if(course) {
                setCurrentCourse(course);
            }
        }
    }


    return (
    <>
        {
            courses.map(c => (
                <div className="card" key={c.courseId} >
    
                    <div className="card-body-mobile">
                        <h4 className="header-title m-t-0 m-b-30" style={{textTransform:"uppercase",color:"grey"}}> {c.title}  {(c.description !=="") ? <span style={{textTransform:"none", fontWeight:"normal"}}><br/>{c.description}</span> : ''}   </h4>

                        <div>{c.subject} {c.courseLevel}</div>
                        <div>{c.company}</div>
                        <div>{c.definedAddress}</div>
                        <div>Start date: {c.startDateString} </div>
                        <div>{c.totalClasses} classes x {c.classLengthTemplate} mins</div>
                        <div>{c.duration - c.takenPlace} classes remaining </div>

                        

                        <div className="row" >
                            <div className="col" style={{paddingTop:"5px"}}>    
                                <div >
                                    <TimeRemainingBadge timeInMins={c.minsRemaining} fontSize={"13"} />
                                </div>
                            </div>
                            {<div className="col" style={{textAlign:"right",cursor:"pointer"}}>
                                {(c.hasUnMarkedClasses) ? <i className="ti-face-sad pink" style={{fontSize:"20px", paddingTop:"8px", paddingRight:"15px"}} onClick={() => showClassIcon_OnClick(c) } title={"Course has unmarked classes"}></i> : ''}
                                <i className="ti-calendar pink" style={{fontSize: "20px", paddingRight:"15px"}}  onClick={() => showClassIcon_OnClick(c) }></i>   
                                <i className="ti-user pink" style={{fontSize: "20px"}} onClick={()=> showUserIcon_OnClick(c)  }></i>   
                            </div>
                            }
                        </div>
                    </div>
                </div>
            ))}

            {isLoaded === true && courses.length === 0 &&
                <div style={{textAlign:"center", paddingTop:"15px"}}>No courses currently </div>
            }

            <Modal show={showUsersModal}  dialogClassName={(!isMobile) ? "modal-750px" : "modal-350px"}  onHide={()=> hideUsersModal()}>
                <Modal.Header closeButton style={{paddingBottom:"0px"}}>
                    <Modal.Title>
                        <div>Course</div>
                        <h5>{currentCourse.title}</h5>
                        </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{paddingTop:"0px"}}>
                    <>
                    
                    <div className="table-responsive" style={{height:"400px"}}>
                        <table className="table">
                            <tr>
                                <th>Student</th>
                                <th>Company</th>
                            </tr>
                            {students.map(s => (
                            <tr>
                                <td>{s.firstName + ' ' + s.lastName}</td>
                                <td>{s.company}</td>
                            </tr>           
                            ))}
                        </table>
                    </div>
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <CancelButton onClick={() => hideUsersModal()} text="CLOSE"  />
                </Modal.Footer>
            </Modal>




            <Modal show={showClassesModal}  dialogClassName={(!isMobile) ? "modal-750px" : "modal-350px"}  onHide={()=> hideClassesModal()}>
                <Modal.Header closeButton style={{paddingBottom:"0px"}}>
                    <Modal.Title>
                        <div>Course</div>
                        <h5>{currentCourse.title}</h5>
                        {modalMode === "C" && <TimeRemainingBadge timeInMins={currentCourse.minsRemaining} fontSize={"18"} />}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{paddingTop:"0px" }}>
                    
                    {modalMode === "C" && <div className="table-responsive" id="NewScroller" style={{height:"400px"}}>
                        <ClassList classes={classes} classStatusClicked={classStatusClicked} classEditClicked={classEditClicked} />
                    </div>}

                    {modalMode == "E" && 
                        <>
                            <RescheduleClass classDateString={currentClass.classDatesCombined}  classLength={currentClass.classLength} rescheduleDate={rescheduleDate} rescheduleTime={currentClass.classTime} time_onChange={timeChange} rescheduleDateChange={rescheduleDateChange}  />
                        </>
                    }

                    {(modalMode === "CM") && 
                        <div style={{paddingTop:"15px"}}>Are you sure you wish to move this class to the end of the course?</div>
                    }

                    {modalMode === "M" && 
                        <div>
                            
                            <table className="table  mb-0 table-hover">
                            <thead>
                                <tr>
                                    <th style={{width:(!isMobile) ? "65%" : "50%"}}>Name</th>
                                    <th>{(currentClass.seriesStatusId === 1)  ? "Company" : "Status"}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentCourseStudents && currentCourseStudents.map(c =>(
                                <tr >
                                    <td  style={{padding:"8px"}}>{c.firstName} {c.lastName}</td>
                                    <td style={{padding:"8px"}}>

                                        {(currentClass.seriesStatusId !== 1)  &&<WaveBox floatLeft={true} onClick={(item) => WaveBoxHandler(c, item)} size="s" allowEmpty={true} selected={getStatusFromId(c.status)} items={['ATTENDED', 'ABSENT']} />}
                                        {(currentClass.seriesStatusId === 1)  && <>{(c.company && c.company !== "") ? c.company : "Private" }</>}
                                    </td>
                                </tr>
                                ))}
                                
                            </tbody>
                        </table>

                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <div className="mr-auto">
                        {!showMoveToEnd && modalMode === "E" && <ActionButton onClick={() => {setModalMode("CM")}} text="MOVE TO END"/>}
                    </div>

                    {modalMode === "E" && <ActionButton onClick={() => { updateClassDetails()}} text="SAVE"  />}
                    {modalMode === "M" && currentClass.seriesStatusId !== 1 && <ActionButton onClick={() => {saveMarkingButton_OnClick()}} text="SAVE"  />}
                    {modalMode === "C" && <CancelButton onClick={() => hideClassesModal()} text="CLOSE"  />}
                    {modalMode === "E" && <CancelButton onClick={() => setModalMode("C")} text="CANCEL"  />}
                    {modalMode === "M" && <CancelButton onClick={() => {setModalMode("C");showClassIcon_OnClick(currentCourse)}} text="CLOSE"  />}

                    {!showMoveToEnd && modalMode === "CM" && <>
                        <ActionButton onClick={() => {setShowMoveToEnd(true);moveClassToEnd();}} text="CONFIRM"/>
                        <CancelButton onClick={() => setModalMode("C")} text="CANCEL"  />
                    </>
                    }

                </Modal.Footer>
            </Modal>


            <Modal show={showClassesRemainingModal}  dialogClassName={(!isMobile) ? "modal-750px" : "modal-350px"}  onHide={()=> hideClassesRemainingModal()}>
                <Modal.Header closeButton style={{paddingBottom:"0px"}}>
                    <Modal.Title><h5>{currentCourse.title}</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body style={{paddingTop:"0px" }}>
                    <>
                        <br/>
                        {modalRemainingMode === "R" && <div className="table-responsive" style={{height:"400px"}}>
                            {remainingClasses.map(c => (
                                <div className="card" style={{border:"1px solid #c0c0c0"}}>
                                    <div className="card-header-mobile" >
                                        <div className="row" >
                                            <div className="col-6">
                                                <h4 className="header-title m-t-0 m-b-30" style={{textTransform:"uppercase",color:"grey"}}>{c.dayWeek} {c.classDateString}  </h4>
                                            </div>
                                            <div className="col-6">
                                                <div style={{color:"#495057", textAlign:"right"}}>
                                                    <h4 className="header-title m-t-0 m-b-30" style={{textTransform:"uppercase",color:"grey"}}>{c.classDatesCombined}  </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body-mobile">
                                        <div >{c.course} </div>
                                        <div >{c.subject} {c.level}</div>
                                        <div >{c.address} </div>
                                        <div >{c.classLength} mins </div>
                                        <div className="row" > 
                                            <div className="col" style={{paddingTop:"5px"}}>{<ClassStatusBadge statusId={c.seriesStatusId} onClick={() => {} } />}</div>
                                            {c.seriesStatusId !== 6 && c.seriesStatusId !== 3 && <div className="col" style={{textAlign:"right",cursor:"pointer"}} onClick={() => modalRemainingEdit_Click(c) }>
                                                <i className="ti-pencil pink" style={{fontSize: "20px"}}></i>   
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            ))}

                            {!remainingClasses || remainingClasses.length===0 &&
                                <div >No classes remaining</div>
                            }

                        </div>}

                        {modalRemainingMode === "E" && 
                            <RescheduleClass classDateString={currentClass.classDatesCombined} classLength={currentClass.classLength} rescheduleDate={rescheduleDate} rescheduleTime={currentClass.classTime} time_onChange={timeChange} rescheduleDateChange={rescheduleDateChange}  />
                        }
                    </>

                </Modal.Body>
                <Modal.Footer>
                    {modalRemainingMode === "E" && <ActionButton onClick={() => { updateRemainingClassDetails();setModalRemainingMode("R")}} text="SAVE"  />}
                    {modalRemainingMode==="R" && <CancelButton onClick={() => hideClassesRemainingModal()} text="CLOSE" />}
                    {modalRemainingMode==="E" && <CancelButton onClick={() => setModalRemainingMode("R")} text="CANCEL" />}
                </Modal.Footer>
            </Modal>



        <BottomNav history={props.history} currentTab={2} />
    </>
    );    
}

export default CoursesMobile;