import React from "react";
import HeaderBar from './HeaderBar';
import SideBar from './SideBar';
import PrintPaymentReport from "./reports/PrintPaymentReport";
import PrintStudentAttendence from "./reports/PrintStudentAttendence";
import UserService from '../services/userService';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

const userService = new UserService();

const getPadding = (normalPadding:number) => {
  let paddingToReturn:number = normalPadding;

  if(isMobile){
    paddingToReturn = 0;
  }

  return paddingToReturn;
}

const getIdFromQuery = (paramName:string) => {
  let paramValue:string = "";
  const urlParams = new URLSearchParams(window.location.search);

  let x = urlParams.get(paramName);

  if(x){
    paramValue = x.toString();
  }

  
  return paramValue; 
}



export default props => (
  <div id="wrapper">

    {window.location.pathname.indexOf('printpaymentreport') === 9 && <PrintPaymentReport isTeacher={getIdFromQuery('isTeacher') === 'true'} teacherId={parseInt(getIdFromQuery('teacherId'))} periodEndDate={getIdFromQuery('periodEndDate')} grandTotal={parseFloat(getIdFromQuery('grandTotal'))} id={parseInt(getIdFromQuery('id'))} />}

    {window.location.pathname.indexOf('printstudentattendence') === 9 && <PrintStudentAttendence id={parseInt(getIdFromQuery('id'))} />}
    
    
    {window.location.pathname.toUpperCase() != "/FORGOTPASSWORD" && window.location.pathname.toUpperCase() != "/SIGNUP" && window.location.pathname != "/login" && window.location.pathname.indexOf('printpaymentreport')!==9 && window.location.pathname.indexOf('printstudentattendence')!==9 && <HeaderBar  />}
    {window.location.pathname.toUpperCase() != "/FORGOTPASSWORD" && window.location.pathname.toUpperCase() != "/SIGNUP" && window.location.pathname != "/login" && window.location.pathname.indexOf('printpaymentreport')!==9 && window.location.pathname.indexOf('printstudentattendence')!==9 && <><br/><br/><br/></>}
    {window.location.pathname.toUpperCase() != "/FORGOTPASSWORD" && window.location.pathname.toUpperCase() != "/SIGNUP" && window.location.pathname != "/login"  && window.location.pathname.indexOf('printpaymentreport')!==9 && window.location.pathname.indexOf('printstudentattendence')!==9 && <SideBar />}


    {window.location.pathname.indexOf('printpaymentreport')!==9  && window.location.pathname.indexOf('printstudentattendence')!==9 && <div  className={`${(window.location.pathname != "/login" && window.location.pathname.toUpperCase() != "/SIGNUP" && window.location.pathname.toUpperCase() != "/FORGOTPASSWORD") ? 'content-page' : ''}`} >
      <div className="content"> 
        <div className="container-fluid" style={{paddingLeft:`${getPadding(12)}px`,paddingRight:`${getPadding(12)}px`}}>
          { (window.location.pathname != "/login" && window.location.pathname.toUpperCase() != "/SIGNUP" && window.location.pathname.toUpperCase() != "/FORGOTPASSWORD")  && <div style={{ paddingLeft: `${getPadding(15)}px`, paddingRight: `${getPadding(15)}px`, paddingTop: "25px" }}>{props.children}</div>}
          {(window.location.pathname === "/login" || window.location.pathname.toUpperCase() === "/SIGNUP"  || window.location.pathname.toUpperCase() === "/FORGOTPASSWORD") && <div style={{ paddingLeft: `${getPadding(15)}px`, paddingRight: `${getPadding(15)}px` }}>{props.children}</div>}
        </div>
      </div> 
    </div>}
  </div>

);
