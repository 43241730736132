import React, {useEffect, useState} from 'react';

import BottomNav from '../BottomNav';
import Class from '../../models/Class';
import ClassWrapper from '../../models/ClassWrapper';
import Student from '../../models/Student';
import UserService from '../../services/userService';
import ClassStatusBadge from '../../components/badges/ClassStatusBadge';

import MarkClassesModal from '../../modals/MarkClassesModal';

import WaveBox from "../../components/common/WaveBoxMobile";

import axios from 'axios';
import { setSourceMapRange } from 'typescript';
import { isMobile } from 'react-device-detect';

interface IProps {
    history:any,
    match:any,
    location:any
}

const ClassesMobile = (props:IProps) => {
    const [classes, setClasses] = useState(new Array<Class>());
    const [currentClassId, setCurrentClassId] = useState(0);
    const [currentClass, setCurrentClass] = useState(new Class());
    const [showMarkClasses, setShowMarkClasses] = useState(false);

    const [dateMultiplier, setDateMultiplier] = useState(0);
    const [currentFilter, setCurrentFilter] = useState("WEEK");
    const [datesSelected, setDatesSelected] = useState("");
    const [modelMode, setModelMode] = useState("M");


    const [isLoaded, setIsLoaded] = useState(false);

    let userService = new UserService();

    const loadData = async() => {

        let url:string = "";
        var paymentHeaderId = getQueryStringParam("paymentHeaderId");

        if(paymentHeaderId && paymentHeaderId !==""){
            url = `api/classes/GetByFilter?teacherId=${userService.getUser().roles[0].entityId}&paymentHeaderId=${paymentHeaderId}`;
        }

        let filter:string;
        let preText:string = "";

        if(currentFilter === "MONTH") {
            filter = "M"
            preText = "Classes on";
        } else if (currentFilter === "DAY"){
            filter = "D"
            preText = "Classes on";
        } else if (currentFilter === "WEEK"){
            filter = "W"
            preText = "Classes in week";
        } else {
             filter = currentFilter;
        }

       
        if(url === ""){
            if(props.match.params.id){
                url = `api/classes/GetByFilter?courseId=${props.match.params.id}&ExcludePaid=true`;
            } else {
                url = `api/classes/GetByFilter?teacherId=${userService.getUser().roles[0].entityId}&ExcludePaid=true&timePeriod=${filter}&ExcludePaid=true&dateMultiplier=${dateMultiplier}`
            }
        }
        
        const results = await axios.get<ClassWrapper>(url); 
        setClasses(results.data.classes);
        setDatesSelected(preText + " " + results.data.datesSelected);
        setIsLoaded(true);
    }

    const getQueryStringParam = (param:string) => {
        const query = new URLSearchParams(props.location.search);
        const token = query.get(param);
        if(token){
            return token;
        } else {
            return "";
        }
    }

    const currentClass_OnClick = (classClicked:Class) => {
        setModelMode("M");
        setCurrentClass(classClicked);
        setShowMarkClasses(!showMarkClasses);
    }

    const reschedule_OnClick = (classClicked:Class) => {
        setModelMode("R");
        setCurrentClass(classClicked);
        setShowMarkClasses(!showMarkClasses);
    }
    

    const changeTab = async(e, dtMulti:number=dateMultiplier) => {
        let newPeriod:string = "";
        let preText:string = "";


        switch(e) {
            case "DAY":
                newPeriod = "D";
                preText = "Classes on";
                break;
            case "WEEK":
                preText = "Classes in week";
                newPeriod = "W";
                break;
            case "MONTH":
                preText = "Classes in";
                newPeriod = "M";
                break;
            default:
          }

          setCurrentFilter(e);
         

          const results = await axios.get<ClassWrapper>(`api/classes/GetByFilter?teacherId=${userService.getUser().roles[0].entityId}&timePeriod=${newPeriod}&ExcludePaid=true&dateMultiplier=${dtMulti}`); 
          setClasses(results.data.classes);

          setDatesSelected(preText + " " + results.data.datesSelected);
    }

    const updateGridClassStatus = () => {
        loadData();
    }

    const incrementDateModifier = async () => {
        await setDateMultiplier(dateMultiplier + 1);
        changeTab(currentFilter, dateMultiplier + 1);
    }

    const decrementDateModifier = async() => {
        await setDateMultiplier(dateMultiplier - 1);
        changeTab(currentFilter, dateMultiplier - 1);
    }

    const resetMulti = () => {
        setDateMultiplier(0);
    }

    useEffect(() => {
        loadData();
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {isMobile && 
                <div className="row" style={{paddingBottom:"10px", paddingRight:"15px"}}>
                    <div className="col-10">
                        <WaveBox  allowEmpty={false}  items ={['DAY', 'WEEK', 'MONTH']} size={'mm'} onClick={(e) =>  {resetMulti();changeTab(e,0)}} selected="WEEK"/>
                    </div>
                    <div className="col-2" style={{padding:"0px"}}>
                        <table>
                            <tr>
                                <td style={{width:"100px"}}><i className="fas fa-angle-double-left fa-lg" style={{paddingTop:"8px",paddingRight:"8px", fontSize:"20px"}} onClick={() => decrementDateModifier()}></i></td>
                                <td><i className="fas fa-angle-double-right fa-lg" style={{paddingTop:"8px",fontSize:"20px"}} onClick={() => incrementDateModifier()}></i></td>
                            </tr>
                        </table>
                    </div>
                </div>
            }

            {!isMobile && 
                <div className="row" style={{paddingBottom:"10px"}}>
                    <div className="col-8"></div>

                    <div className="col-3">
                        <WaveBox  allowEmpty={false}  items ={['DAY', 'WEEK', 'MONTH']} size={'mm'} onClick={(e) =>  {resetMulti();changeTab(e,0)}} selected="WEEK"/>
                    </div>
                    <div className="col-1" style={{padding:"0px",textAlign:"right"}} >
                        <div>
                            <span style={{paddingRight:"10px"}}><i className="fas fa-angle-double-left fa-lg" style={{paddingTop:"8px", fontSize:"20px"}} onClick={() => decrementDateModifier()}></i></span>
                            <span style={{paddingRight:"15px"}}><i className="fas fa-angle-double-right fa-lg" style={{ paddingTop:"8px",fontSize:"20px"}} onClick={() => incrementDateModifier()}></i></span>
                        </div>
                    </div>
                </div>
            }




            <div className="row"  style={{paddingTop:"10px"}}>
                <div className="col-12" style={{textAlign:"center"}}>
                    <h4 className="header-title m-t-0 m-b-30">{datesSelected} </h4>
                </div>
            </div>

            {
            //props.history.push('/teachers/MarkClassesMobile')
            classes.map(c => (
                <div className="card">
                    <div className="card-header-mobile" >
                        <div className="row" >
                            <div className="col-6">
                                <h4 className="header-title m-t-0 m-b-30" style={{textTransform:"uppercase",color:"grey"}}>{c.dayWeek} {c.classDateString}  </h4>
                            </div>
                            <div className="col-6">
                                <div style={{color:"#495057", textAlign:"right"}}>
                                     <h4 className="header-title m-t-0 m-b-30" style={{textTransform:"uppercase",color:"grey"}}>{c.classDatesCombined}  </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body-mobile">
                    <div>{c.course}</div>
                    {c.courseDescription !== "" && <div>{c.courseDescription}</div>}
                    <div>{c.subject} {c.level}</div>
                    {c.company !== "" && <div>{c.company}</div>}
                    <div>{c.definedAddress}</div>
                        <div className="row" >
                            <div className="col" style={{paddingTop:"5px"}}  onClick={() => currentClass_OnClick(c) }>
                                <table cellPadding={0} cellSpacing="0"  >
                                    <tr>
                                        <td> {<ClassStatusBadge statusId={c.seriesStatusId} onClick={() => {} } />}</td>
                                        <td valign="middle"> 
                                            <div style={{height:"2px"}}></div>
                                            <div style={{paddingTop:"5px"}}>
                                                <i className="ti-angle-double-right"></i>
                                            </div>
                                        </td>
                                    </tr>
                                    </table>
                            </div>
                            {c.seriesStatusId !== 6 && c.seriesStatusId !== 3 && <div className="col" style={{textAlign:"right",cursor:"pointer"}} onClick={() => reschedule_OnClick(c) }>
                                <i className="ti-pencil pink" style={{fontSize: "20px"}}></i>   
                            </div>}
                        </div>
                    </div>
                </div>
            ))}

            {isLoaded == true && classes.length === 0 &&
                <div style={{textAlign:"center", paddingTop:"15px"}}>No classes in this period</div>
            }

            <BottomNav history={props.history} currentTab={1} />

            <MarkClassesModal currentClass={currentClass} classId={currentClass.classId} show={showMarkClasses} students={new Array<Student>()} hide={()=>{setShowMarkClasses(false)}} updateGridClassStatus={ updateGridClassStatus } mode={modelMode}/>
        </>
    )
}

export default ClassesMobile;