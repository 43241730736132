import React, {useState, useEffect} from 'react';
import classnames from 'classnames';
import Calandar from '../../components/common/Calandar';
import UserService from '../../services/userService';
import TeachersClasses from '../../components/teachers/TeachersClasses';

import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';

interface IProps {
  history:any;
}

const TeacherMain = (props:IProps) => {
  const [activeTab, setActiveTab] = useState('3');
 
  
  useEffect(() => {
    alert(userService.isUserLoggedIn());
  }, []);
  //userService.getUser().userId

  let userService = new UserService();

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }
  return(
      <div>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '3' })}
            onClick={() => { toggle('3'); }}
          >
            Courses
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '4' })}
            onClick={() => { toggle('4'); }}
          >
            Calendar
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '5' })}
            onClick={() => { toggle('5'); }}
          >
            Documents
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '6' })}
            onClick={() => { toggle('6'); }}
          >
            Tests
          </NavLink>
        </NavItem>
      </Nav>
      
      <TabContent activeTab={activeTab}>
      
        
        <TabPane tabId="3">
          <Row >
            <Col sm="12" >
            <TeachersClasses id={userService.getEntityIdForRole("Teacher")} history={props.history} />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="4">
          <Row>
            <Col sm="12">
               <Calandar type="T" id={userService.getEntityIdForRole("Teacher")} history={props.history} />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="5">
          <Row>
            <Col sm="12">
                  <div>Documents</div>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="6">
          <Row>
            <Col sm="12">
              <div>Testing</div>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  )
}

export default TeacherMain;