import React from 'react';

interface IProps {
    status:boolean,
    onClick:Function
}

const ActiveBadgeStatus =(props:IProps) => {
    return (
        <>
            <a style={{cursor:"pointer"}} onClick={() => props.onClick()}>{(props.status) ? <span className="badge badge-course-status-active">Active</span> : <span className="badge badge-grey">Inactive</span>}</a>
        </>
    )
}

export default ActiveBadgeStatus;
