import React, {useState, useEffect} from 'react';

import InputTextBoxDuel from '../common/InputTextBoxDuel';
import InputTextArea2 from '../common/InputTextArea2';

import Axios from 'axios';
import Company from "../../models/Company"
import Address from '../../models/Address';

interface IProps {
    companyId:number;
    updateCompany:Function;
    primaryAddress:boolean;
    mode:string;
    addressId:number;
}

const AddCompany = (props:IProps) => {
        const [validationError, setValidationError] = useState(new Array<string>());
        const [company, setCompany] = useState<Company>(new Company());

        

        const setCompanyName = (name:string) => {
            setCompany({...company,name:name});
            props.updateCompany({...company,name:name});
        }
        const setCompanyCode = (name: string) => {
            setCompany({ ...company, code: name });
            props.updateCompany({ ...company, code: name });
        }
        const setaddress = (name:string) => {
            setCompany({...company,address:name});
            props.updateCompany({...company,address:name});
        }
        const setTown = (name:string) => {
            setCompany({...company,town:name});
            props.updateCompany({...company,town:name});
        }
        const setPostcode = (name:string) => {
            setCompany({...company,postCode:name});
            props.updateCompany({...company,postCode:name});
        }
        const setPrimaryContactName = (name:string) => {
            setCompany({...company,primaryContactName:name});
            props.updateCompany({...company,primaryContactName:name});
        }
        const setPrimaryJobTitle = (name:string) => {
            setCompany({...company,primaryJobTitle:name});
            props.updateCompany({...company,primaryJobTitle:name});
        }
        const setPrimaryMobile = (name:string) => {
            setCompany({...company,primaryMobile:name});
            props.updateCompany({...company,primaryMobile:name});
        }
        const setPrimaryLandline = (name:string) => {
            setCompany({...company,primaryLandline:name});
            props.updateCompany({...company,primaryLandline:name});
        }
        const setPrimaryEmail = (name:string) => {
            setCompany({...company,primaryEmail:name});
            props.updateCompany({...company,primaryEmail:name});
        }
        const setSecondaryContactName = (name:string) => {
            setCompany({...company,secondaryContactName:name});
            props.updateCompany({...company,secondaryContactName:name});
        }
        const setSecondaryJobTitle = (name:string) => {
            setCompany({...company,secondaryJobTitle:name});
            props.updateCompany({...company,secondaryJobTitle:name});
        }
        const setSecondaryMobile = (name:string) => {
            setCompany({...company,secondaryMobile:name});
            props.updateCompany({...company,secondaryMobile:name});
        }
        const setSecondaryLandline = (name:string) => {
            setCompany({...company,secondaryLandline:name});
            props.updateCompany({...company,secondaryLandline:name});
        }
        const setSecondaryEmail = (name:string) => {
            setCompany({...company,secondaryEmail:name});
            props.updateCompany({...company,secondaryEmail:name});
        }
        const isFieldValid = (fieldName:string):boolean =>{
            let error = validationError.find(s => s ==fieldName);
            if(error){
                return true;
            } else {
                return false;
            }
        }

        

        const getAddressData = async() => {
            const response = await Axios.get<Address>(`/api/companies/GetAddressById?addressId=${props.addressId}`);




            let company = new Company();

            company.address = response.data.address;
            company.postCode = response.data.postCode;
            company.primaryAddress = props.primaryAddress;

            company.primaryContactId = response.data.primaryContactId;
            company.primaryContactName = response.data.primaryContactName;
            company.primaryJobTitle = response.data.primaryJobTitle;

            company.secondaryContactId = response.data.secondaryContactId;
            company.secondaryContactName = response.data.secondaryContactName;
            company.secondaryJobTitle= response.data.secondaryJobTitle;



            setCompany(company);
            props.updateCompany(company);

            //Once we have the the address data all we need to do is update the address fields

        }
    

        useEffect(() => {
            async function getData() {
                let response = await  Axios.get<Company>(`api/companies/getbyid?id=${props.companyId}&addressId=${props.addressId}`);
                setCompany(response.data);
            }
            if(props.companyId && props.mode==="E") getData();

            if(props.companyId && props.mode==="A"){
                let company = new Company();
                company.companyId = props.companyId;
                company.primaryAddress = props.primaryAddress;

                setCompany(company);


                if(props.addressId && props.addressId !==0){
                    getAddressData();
                }

                props.updateCompany({ ...company, companyId: props.companyId, primaryAddress: props.primaryAddress });
            }

            if(!props.companyId && props.mode==="A"){
                let company = new Company();
                company.companyId = props.companyId;
                company.primaryAddress = props.primaryAddress;

                setCompany(company);

            }

          }, []);

    return (
        <>
            <div>
                


                <div style={{paddingLeft:"85px", paddingTop:"30px"}}>
                    
                    {props.primaryAddress &&
                        <>
                            <div className="form-group"> 
                                <InputTextBoxDuel label="Name"labelWidth={175} width={275} mode="E" isError={isFieldValid("companyName")} errorMessage="Please enter a company name" value={company && company.name} onChange={(e:any)=> setCompanyName(e.target.value)}  />
                            </div>

                            <div className="form-group">
                                <InputTextBoxDuel label="Code" labelWidth={175} width={75} mode="E" isError={isFieldValid("code")} errorMessage="Please enter a code" value={company && company.code} onChange={(e: any) => setCompanyCode(e.target.value)} />
                            </div>
                        </>
                    }



                    <InputTextArea2 label="Address" labelWidth={175} name="address" errorMessage="Please enter an address" isError={isFieldValid("address")} value={company && company.address} mode={'E'} onChange={(e) => setaddress(e.target.value)} width={380} />


                    <div className="form-group">
                        <InputTextBoxDuel label="Town" labelWidth={175} width={275} mode="E" isError={isFieldValid("town")} errorMessage="Please enter an town"  value={company && company.town} onChange={(e:any)=> setTown(e.target.value)}  />
                    </div>

                    <div className="form-group">
                        <InputTextBoxDuel label="Post code" labelWidth={175} width={275}  mode="E" isError={isFieldValid("postcode")} errorMessage="Please enter an post code"  value={company && company.postCode} onChange={(e:any)=> setPostcode(e.target.value)} />
                    </div>


                    <br/>


                    <div className="form-group">
                        <InputTextBoxDuel label="Primary contact" labelWidth={175} width={275}  mode="E" isError={isFieldValid("PrimaryContactName")} errorMessage="Please enter an contact name"  value={company && company.primaryContactName} onChange={(e:any)=> setPrimaryContactName(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <InputTextBoxDuel label="Job title" labelWidth={175} width={275}  mode="E" isError={isFieldValid("PrimaryJobTitle")} errorMessage="Please enter an job title"  value={company && company.primaryJobTitle} onChange={(e:any)=> setPrimaryJobTitle(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <InputTextBoxDuel label="Email" labelWidth={175} width={275}  mode="E" isError={isFieldValid("PrimaryEmail")} errorMessage="Please enter an email"  value={company && company.primaryEmail} onChange={(e:any)=> setPrimaryEmail(e.target.value)} />
                    </div>

                    <div className="form-group">
                        <InputTextBoxDuel label="Mobile" labelWidth={175} width={275}  mode="E" isError={isFieldValid("PrimaryMobile")} errorMessage="Please enter an mobile number"  value={company && company.primaryMobile} onChange={(e:any)=> setPrimaryMobile(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <InputTextBoxDuel label="Landline" labelWidth={175} width={275}  mode="E" isError={isFieldValid("PrimaryLandline")} errorMessage="Please enter an landline"  value={company && company.primaryLandline} onChange={(e:any)=> setPrimaryLandline(e.target.value)} />
                    </div>

                    <br />
                    <div className="form-group">
                        <InputTextBoxDuel label="Secondary contact" labelWidth={175} width={275}  mode="E" isError={isFieldValid("SecondaryContactName")} errorMessage="Please enter an contact name"  value={company && company.secondaryContactName} onChange={(e:any)=> setSecondaryContactName(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <InputTextBoxDuel label="Job title" labelWidth={175} width={275}  mode="E" isError={isFieldValid("SecondaryJobTitle")} errorMessage="Please enter an job title"  value={company && company.secondaryJobTitle} onChange={(e:any)=> setSecondaryJobTitle(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <InputTextBoxDuel label="Email" labelWidth={175} width={275}  mode="E" isError={isFieldValid("SecondaryEmail")} errorMessage="Please enter an email"  value={company && company.secondaryEmail} onChange={(e:any)=> setSecondaryEmail(e.target.value)} />
                    </div>

                    <div className="form-group">
                        <InputTextBoxDuel label="Mobile" labelWidth={175} width={275}  mode="E" isError={isFieldValid("SecondaryMobile")} errorMessage="Please enter an mobile number"  value={company && company.secondaryMobile} onChange={(e:any)=> setSecondaryMobile(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <InputTextBoxDuel label="Landline" labelWidth={175} width={275}  mode="E" isError={isFieldValid("SecondaryLandline")} errorMessage="Please enter an landline"  value={company && company.secondaryLandline} onChange={(e:any)=> setSecondaryLandline(e.target.value)} />
                    </div>
                    <br />
                    
                </div>
            </div>
        </>
    );
}

export default AddCompany;