import React from "react";

interface Iprops {
    onClick:Function,
    text:string;
}

const CancelButton = (props:Iprops) => {
    return (
        <button className="btn btn-white waves-effect waves-light btn-sm" style={{fontSize:"12px"}} onClick={()=>props.onClick()}>
            {props.text}
        </button>
    )
}

CancelButton.defaultProps = {    
    text: "CANCEL"
  }

export default CancelButton;


