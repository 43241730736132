import React, {useState} from 'react';
import Student from '../../models/Student';

import axios from 'axios';
import { idText } from 'typescript';

interface IProps {
    changeWizardStage:Function;
    currentStudent:Student
}


const Form2Register = (props:IProps) => {
    const [passwordError, setPasswordError] = useState(false);
    const [showDate, setShowDate] = useState(true);
    const [password, setPassword] = useState("");
    const [confirmCassword, setConfirmCassword] = useState("");
    
    const [errorText, setErrorText] = useState("");
    

    const updatePassword = (e:any) => {
        setPassword(e.target.value)
    }
    const updateConfirmPassword = (e:any) => {
        setConfirmCassword(e.target.value)
    }

    const registerUser = async() => {
        await axios.get(`/api/users/RegisterUser?id=${props.currentStudent.studentId}&type=1&password=${password}`);
    }

    const formSubmitted = (e:any) => {
        e.preventDefault();

        if(password !== confirmCassword){
            setErrorText("Passwords do not match");
            setPasswordError(true);
            return;
        }

        if(password === ""){
            setErrorText("Password is empty");
            setPasswordError(true);

            return;
        }
        
        if(password.length < 8){
            setErrorText("Passwords must be 8 or more characters");
            setPasswordError(true);

            return;
        }

        registerUser();

        props.changeWizardStage(3);
        setPasswordError(false);
    }

    return(
            <>
                <div className="">
                    <form onSubmit={(e:any)=>formSubmitted(e)}>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-8 col-lg-6 col-xl-5">
                                    <div className="text-center pb-1">
                                        <a href="index.html">
                                            <span><img src="assets/images/logo.png" alt="" height="18" /></span>
                                        </a>
                                    </div>
                                    <div className="card mt-4">
                                        <div className="card-body p-4">
                                            <div className="form-group mb-3">
                                                <strong>First name:</strong> {props.currentStudent.firstName}
                                            </div>
                                            <div className="form-group mb-3">
                                                <strong>Last name:</strong> {props.currentStudent.lastName}
                                            </div>
                                            <div className="form-group mb-3">
                                                <strong>Email:</strong> {props.currentStudent.email}
                                            </div>

                                            <div className="card-body p-4">
                                                <div className="form-group mb-3">
                                                    <input className={`form-control ${passwordError ? "parsley-error" : ""} `}  type="text" value={password} placeholder="password" onChange={(e:any)=>{updatePassword(e)}} />
                                                </div>
                                                <div className="form-group mb-3">
                                                    <input className={`form-control ${passwordError ? "parsley-error" : ""}  `}  type="text" value={confirmCassword} placeholder="confirm password" onChange={(e:any)=>{updateConfirmPassword(e)}} />
                                                </div>
                                            </div>

                                            <div className="form-group mb-4">
                                                <button className="btn btn-primary-pink btn-bordred btn-block waves-effect waves-light text-uppercase">REGISTER</button>
                                            </div>

                                            <div className="form-group mb-3">
                                                {errorText}
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </form>
                </div>
            </>
        );
}

export default Form2Register;