import React, {useEffect, useState} from 'react';
import BottomNav from './BottomNav';
import Course from '../../models/Course';
import UserService from "../../services/userService";
import ClassListModal from './ClassListModal';
import {
    isMobile
} from "react-device-detect";


import axios from 'axios';


interface IProps {
    history:any;
}


const CoursesMobile = (props:IProps) => {
    const [courses, setCourses] = useState(new Array<Course>());
    const [showModal, setShowModal] = useState(false);
    const [currentCourse, setCurrentCourse] = useState(new Course());
 
    const userService = new UserService();


    useEffect(() => {
        loadClasses();
    }, []);

    const hide = () => {
        setShowModal(false);
    }

    const loadClasses = async (viewStatus:string="All") => {
        const response = await axios.get<Array<Course>>(`/api/courses/GetCoursesByStudentId?studentId=${userService.getUser().entityId}`);
        setCourses(response.data); 
    }; 

    const showClassList = (c:Course) => {
        setCurrentCourse(c);
        setShowModal(true);
    }

    return (
        <div>

            {courses.map(c => (
                <div className="card" key={c.courseId} >
                    <div className="card-header-mobile" >
                        <div className="row">
                            <div className="col-12">
                                <h4 className="header-title m-t-0 m-b-30" style={{textTransform:"uppercase",color:"grey"}}>COURSE </h4>
                            </div>
                        </div>
                    </div>

                    <div className="card-body-mobile" >
                        <div>{c.subject} {c.courseLevel}</div>
                        <div>{c.company}</div>
                        <div>{c.definedAddress}</div>
                        <div>Start date: {c.startDateString} </div>
                        <div>{c.classes.length} classes</div>

                        <div className="row" >
                            <div className="col" style={{paddingTop:"5px"}}>    
                                <div>
                                    {c.classes && c.classes.length > 0 && c.classes[0].teacher}
                                </div>
                            </div>
                            {<div className="col" style={{textAlign:"right",cursor:"pointer"}}>
                                <i className="ti-calendar pink" style={{fontSize: "20px", paddingRight:"15px"}}  onClick={() => {showClassList(c)} }></i>   
                                <i className="ti-user pink" style={{fontSize: "20px"}} onClick={()=> {}  }></i>   
                            </div>
                            }
                        </div>
                    </div>
                </div>
            ))}

            <ClassListModal course={currentCourse} show={showModal} hide={hide} isMobile={isMobile}  />

            <BottomNav  history={props.history} currentTab={2} />
        </div>
    );
}

export default CoursesMobile;
