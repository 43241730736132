import React, {useRef} from 'react';
import DatePicker, {registerLocale} from "react-datepicker";
import eu from "date-fns/locale/en-GB";

registerLocale('eu', eu);


interface IProps {
    label: string,
    placeHolder?: string
    value:string
    mode: string
    onChange?: Function,
    isError?: boolean,
    errorMessage?: string,
    width?:number
}



const InputDatePickerDual = (props: IProps) => {
    const convertDate = () => {
        let dateParts = props.value.split('/');
        let newDate = new Date(parseInt(dateParts[2]), parseInt(dateParts[1]) - 1, parseInt(dateParts[0]))

        return newDate;
    }

    const getBaseCss = () => {
        if(props.width && props.width < 100 ){
            return 'form-control-datepicker-smaller';
        } else {
            return 'form-control-datepicker';
        }
    }

    const startRef = useRef<DatePicker>();

    return (
        <div className="form-group row">
            <div style={{ width: "125px",paddingTop:(props.mode==="E")  ? "6px" : "5px",textAlign:"right", paddingRight:"30px"}}>
                <label >{props.label}</label>
            </div>
            <div >
                <div className="input-group" style={{paddingTop:(props.mode==="E") ? "0px" : "5px"}}>
                    {(props.mode === "E") ? <><DatePicker calendarStartDay={1} locale="eu" ref={startRef} className={`form-control-datepicker-smaller ${props.isError ? "parsley-error" : ""}` }  dateFormat="dd/MM/yyyy" selected={props.value && convertDate()} onChange={date => props.onChange && props.onChange(date)} style={{ color: 'red' }} />
                        <div className="input-group-append" onClick={() => startRef.current.setOpen(true)}>
                            <span className="input-group-text  b-0 text-white btn-primary-pink"><i className="ti-calendar"></i></span>
                    </div>  </> : <div>{props.value && props.value}</div> }
                </div>
                {(props.isError===true) && <div style={{color:"#e96154"}}>{props.errorMessage}</div>}
            </div>
        </div>
    )
}

export default InputDatePickerDual;