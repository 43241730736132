import React from 'react';

import Class from '../../../models/Class';
import ClassStatusBadge from '../../../components/badges/ClassStatusBadge';
import Pill from '../../../components/common/Pill';

const paymentTablePadding = {
    paddingTop:"7px",
    paddingBottom:"7px",
    paddingLeft:"5px",
    paddingRight:"5px",
}

const paymentTablePaddingClass = {
    paddingTop:"7px",
    paddingBottom:"7px",
    paddingLeft:"5px",
    paddingRight:"5px",
    width:"37%"
}

const paymentTablePaddingPill = {
    paddingTop:"7px",
    paddingBottom:"7px",
    paddingLeft:"5px",
    paddingRight:"5px",
    width:"20%"
}

interface IProps{
    classes:Array<Class>;
    classStatusClicked:Function;
    classEditClicked:Function;
}

const ClassList = (props:IProps) => {
    return (
        <>
            
            <table  style={{paddingLeft:"1px",paddingRight:"1px", width:"98%"}}>
                <tr>
                    <td style={paymentTablePaddingPill}></td>
                    <td style={paymentTablePaddingClass} ><strong>Class</strong></td>
                    <td style={paymentTablePadding}><strong>Status</strong></td>
                    <td></td>
                </tr>
                {props.classes.map((c,i) => (
                    <tr key={c.classId}>
                        <td style={paymentTablePadding}><div style={{paddingTop:"7px"}}><Pill value={i+1} color="pink"/></div></td>
                        <td style={paymentTablePadding}>{c.classDateString}<br/> {c.classDatesCombined.replace(' ','').replace(' ','')}</td>
                        <td style={paymentTablePadding}>
                            <div style={{paddingTop:"7px"}}>
                                <table cellPadding={0} cellSpacing="0"   >
                                    <tr>
                                        <td> {<ClassStatusBadge statusId={c.seriesStatusId} onClick={() => props.classStatusClicked(c) } />}</td>
                                        <td valign="middle"> 
                                            <div style={{height:"2px"}}></div>
                                            {c.seriesStatusId !== 6 && 
                                                <div style={{paddingTop:"5px"}}>
                                                    <i className="ti-angle-double-right"></i>
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                </table>
                                
                            </div>
                        </td>
                        <td>
                            
                            {c.seriesStatusId !== 6 && <div  style={{float: "right",paddingTop:"6px", cursor: "pointer"}}><i className="ti-pencil pink" style={{float: "right",fontSize: "20px"}} onClick={()=> props.classEditClicked(c)} ></i></div>}
                        </td>
                    </tr>
                ))}
            </table>
        </>
    );
}

export default ClassList;
