import React, { Component } from "react";
import TagSelector from '../common/TagSelector';
import DatePicker from "react-datepicker";
import RoomsTabs from './RoomsTabs';

const subjects = ['French','Spanish','German','Russian','Japanese'];


interface IProps {
  history: any,
  match: any
}

export default class RoomsEdit extends Component<IProps,null> {
  render() {
    return (
      
        

          <RoomsTabs id={this.props.match && this.props.match.params.id} history={this.props.history} />

          
       
     
          
    );
  }
}
