import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import TeachersFormMOCK from './TeachersFormMOCK'; 
import BasicCalendar from '../common/Calandar';
import Schedule from '../common/Schedule';
import TeachersClasses from '../teachers/TeachersClasses';
import DocumentList from '../common/DocumentList';
import TeachersPayments from '../teachers/TeachersPayments';

import NoteForm from '../common/NotesForm';

interface IProps {
  id:number;
  history:any;
}

const TeachersTabs = (props:IProps) => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  return (
    <div style={{maxWidth:"1500px"}}>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => { toggle('1'); }}
          >
            Details
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => { toggle('2'); }}
          >
            Courses
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '3' })}
            onClick={() => { toggle('3'); }}
          >
            Calendar
          </NavLink>
        </NavItem>
       
        

        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '6' })}
            onClick={() => { toggle('6'); }}
          >
            Documents
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '7' })}
            onClick={() => { toggle('7'); }}
          >
            Notes
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '8' })}
            onClick={() => { toggle('8'); }}
          >
            Payments
          </NavLink>
        </NavItem>

      </Nav>
      
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12">
              <TeachersFormMOCK id={props.id}/>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col sm="12">
              <div className="card" ><div style={{padding:"15px", minHeight:"845px"}}><TeachersClasses id={props.id} history={props.history} /></div></div>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="3">
          <Row>
            <Col sm="12">
              <div className="card">
                <div style={{padding:"15px",minHeight:"845px"}}>
                  <BasicCalendar id={props.id} history={props.history} />
                  <div style={{height:"15px"}}></div>
                  
                </div>
              </div>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="6">
          <Row>
            <Col sm="12">
              
              <div className="card"><div style={{padding:"15px", minHeight:"845px"}}><DocumentList id={-1} /></div></div>
            </Col>
          </Row>
        </TabPane>
        

        <TabPane tabId="7">
          <Row>
            <Col sm="12">
              <div className="card"><div style={{padding:"15px", minHeight:"845px"}}><NoteForm id={props.id} type={2} /></div></div>
            </Col>
          </Row>
        </TabPane>

        <TabPane tabId="8">
          <Row>
            <Col sm="12">
              <div className="card"><div style={{padding:"15px", minHeight:"845px"}}><TeachersPayments id={props.id} /></div></div>
            </Col>
          </Row>
        </TabPane>

      </TabContent>
    </div>
  );
}

export default TeachersTabs;