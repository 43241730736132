import React, {useState, useEffect} from 'react';

const ClassDetails = () => {
    return (
        <>
            Hello - im here to mark classes
        </>
    );
}

export default ClassDetails;