import React, {useEffect,useState} from "react";
import axios from "axios";
import { Redirect } from 'react-router-dom';

interface IProps {
    history:any
}

const LoginPage = (props:IProps) => {
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [isError, setIsError] = useState(false);

    const parseJwt = (token) => {
        var base64Payload = token.split('.')[1];
        var payload = Buffer.from(base64Payload, 'base64');
    }

    const loginUser = async() => {
        let token;
        try {
            token = await axios.post('/api/users/authenticate', { Username: userName, password: password });
            localStorage.setItem('user', JSON.stringify(token.data));
            props.history.push('/');
        } catch (error) {
            setIsError(true);
            return;
        }

            
        //const response = await axios.get('/api/users/getall');

    }

    const formSubmitted = (e:any) => {
        e.preventDefault();
        loginUser();
    }

    return (<>
                <div className="">
                    <form onSubmit={(e:any)=>formSubmitted(e)}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-8 col-lg-6 col-xl-5">
                                <div className="text-center pb-1">
                                    <a href="index.html">
                                        <span><img src="assets/images/logo.png" alt="" height="18" /></span>
                                    </a>
                                </div>
                                <div className="card mt-4">

                                    <div className="card-body p-4">
                                        <div className="text-center mb-4">
                                            <h3 style={{fontFamily:"century gothic"}}> <strong>we<span style={{color:"#fe6271"}}>Course</span></strong></h3>
                                        </div>
                                        

                                        <div className="form-group mb-3">
                                            <input className={`form-control ${isError ? "parsley-error" : ""}`}  type="text" placeholder="Username" value={userName} onChange={e => setUserName(e.target.value)}/>
                                        </div>

                                        <div className="form-group mb-3">
                                            <input className={`form-control ${isError ? "parsley-error" : ""}`} type="password"  placeholder="Password" onChange={e => setPassword(e.target.value)}/>
                                        </div>

                                        {isError && 
                                            <div className="form-group mb-3">
                                                <div>Username or password is incorrect</div>
                                            </div>
                                        }

                                        

                                        <div className="form-group mb-4">
                                            <button className="btn btn-primary-pink btn-bordred btn-block waves-effect waves-light text-uppercase" onClick={()=> loginUser()}>Log In</button>
                                        </div>

                                        {/*<div className="row">
                                            <div className="col-6">
                                                <div style={{cursor:"pointer"}} onClick={()=> props.history.push('/forgotpassword')}><i  className="fa fa-lock m-r-5"></i> Forgot password</div>
                                            </div>
                                            <div className="col-6 text-right">
                                                <div onClick={()=> props.history.push('/signup')} style={{cursor:"pointer"}}><i  className="fa fa-lock m-r-5"></i> Student sign up</div>
                                            </div>
                                        </div>*/}

                                        
                                    </div>
                                </div>

                               
                            </div> 
                        </div>
                    </div>
                    </form>
                </div>
        </>
    )
}

export default LoginPage;