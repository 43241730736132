import React from 'react';

interface IProps {
    color:string;
    text:string;
}

const SubjectBadge = (props:IProps) => {
    return (
        <span className={`badge badge-${props.color}`}><span style={{color:"#000000"}}>{props.text}</span></span>
    );
}

export default SubjectBadge;