export default class Company {
    constructor( ){
    }

    public companyId: number;
    public name: string;
    public code: string;
    public addressId: number;
    public address: string;
    public town: string;
    public postCode: string;
    public primaryAddress: boolean;

    public primaryContactId: number;
    public primaryContactName: string;
    public primaryJobTitle: string;
    public primaryMobile: string;
    public primaryLandline: string;
    public primaryEmail: string;

    public secondaryContactId: number;
    public secondaryContactName: string;
    public secondaryJobTitle: string;
    public secondaryMobile: string;
    public secondaryLandline: string;
    public secondaryEmail: string;
    public inUse:boolean;
}

