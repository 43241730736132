import React, {useEffect, useState} from 'react';
import UserService from '../../services/userService';
const AvatarBadge = () => 
{
    const userService = new UserService();

    const getAvatar =() => {
        let user = userService.getUser();

        if(user && user.avatarImage){
            return <img src={`/assets/images/${user.avatarImage.replace("{size}","md")}`} className="rounded-circle" />;
        }
        else  {
            return <img src="/assets/images/monsterprofiles/Profile-monster-12.jpg" className="rounded-circle"></img>
        }
    }

    return (
        <>{getAvatar()}</>
    );
}

export default AvatarBadge;
