import React from 'react';
import DatePicker from "react-datepicker";

interface IProps {
    label:string,
    placeHolder?:string,
    onChange?:Function
    value:string
}

const InputDatePicker = (props:IProps) => {
    const convertDate = () => {
       
        let dateParts = props.value && props.value.split('/');
        let newDate = new Date(parseInt(dateParts[2]), parseInt(dateParts[1]) - 1, parseInt(dateParts[0]))

        return newDate;
    }
    return(
        <div className="form-group row">
            <div style={{width:"155px"}}>
                <label >{props.label}:</label>
            </div>
            <div >
                <div className="input-group">
                    <DatePicker className="form-control" dateFormat="dd/MM/yyyy" selected={props.value && convertDate()} onChange={date => props.onChange && props.onChange(date)} style={{color:'red'}}  />   
                    <div className="input-group-append" >
                    <span className="input-group-text bg-primary-pink b-0 text-white"><i className="ti-calendar"></i></span>
                  </div>  
            </div>
            </div>
        </div>
    )
}

export default InputDatePicker;