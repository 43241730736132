import React, {useState,useEffect} from 'react';
import axios from 'axios';
import ActionButton from '../buttons/ActionButton';
import Course from '../../models/Course';
import Subject from '../../models/Subject';
import StudentCountMinMaxVM from '../../models/StudentCountMinMaxVM';
import Progress from '../common/Progress';
import Pill from '../common/Pill';

import Teacher from '../../models/Teacher';
import Company from '../../models/Company';
import Student from '../../models/Student';

import SubjectBadge from '../badges/SubjectBadge';

import {confirm} from 'react-bootstrap-confirmation';
import DatePicker, {registerLocale}  from "react-datepicker";

import CourseStatusBadge from '../badges/CourseStatusBadge';


import { Modal } from 'react-bootstrap';
import postal from 'postal';

import CancelButton from '../buttons/CancelButton';

import eu from "date-fns/locale/en-GB";

registerLocale('eu', eu);

interface IProps {
    history:any,
    location:any
}

class SearchObj {
        public title:string;
        public subjectId:number = -1;
        public statusId:number = -1;
        public startDate:string = "";
        public endDate:string= "";
        public classesRemaining:number = -1;
        public searchClassesRemaining:string = "";;
        public showClassCountErrors: string = "";;
        public expiredClasses:string = "";;
        public teacherId: number = -1;
        public companyId:  number =-1; 
}

const CoursesGrid  = (props:IProps) => {

    const [teachers, setTeachers] = useState(Array<Teacher>());
    const [companies, setCompanies] = useState(Array<Company>());
    const [showStudentModal, setShowStudentModal] = useState(false);
    const [studentType, setStudentType] = useState('');
    
    const sidebarChannel = postal.channel("SideBar");


    /* NEW FUNCTIONS */
    const iniPage = () => {
        let searchObj = new SearchObj();

        searchObj.title = "";
        searchObj.subjectId = -1;
        searchObj.statusId = getStatusInitalValue("statusId");
        searchObj.classesRemaining = getStatusInitalValue("remaining");
        searchObj.showClassCountErrors = getQueryStringParam("showClassCountErrors");
        searchObj.expiredClasses =  getQueryStringParam("expiredClasses");
        searchObj.teacherId = getStatusInitalValue("teacherid");
        searchObj.companyId = getStatusInitalValue("companyId");


        newSearch(searchObj);
    }

    const newSearch = async(searchObj:SearchObj) => {
        if(showOnlyActiveAndCompleted) {
            searchObj.statusId = -1;
        }

        var response = await axios.get<Array<Course>>(`/api/courses/getall?cb=${Date.now() + Math.random()}&title=${searchObj.title}&subjectId=${searchObj.subjectId}&statusId=${searchObj.statusId}&startDate=${searchObj.startDate}&endDate=${searchObj.endDate}&classesRemaining=${searchObj.classesRemaining}&showClassCountErrors=${searchObj.showClassCountErrors}&expiredClasses=${searchObj.expiredClasses}&teacherId=${searchObj.teacherId}&companyId=${searchObj.companyId}&showOnlyActiveAndCompleted=${showOnlyActiveAndCompleted}`);
        setData(response.data);

    }

    /* END OF */





    const getQueryStringParam = (param:string) => {
        const newUrl = window.location.href.split('?')[1];
        const query = new URLSearchParams(newUrl);
        const token = query.get(param);

        
        if(token){
            return token;
        } else {
            return "";
        }
    }
    const getStatusInitalValue = (param:string) => {
        let statusId = getQueryStringParam(param);
        if(statusId === "" || isNaN(statusId  as any)){
            return -1
        } else {
            return parseInt(statusId as any);
        }
    }

    const [data, setData] = useState(Array<Course>());
    const [currentSelectedCourse, setCurrentSelectedCourse] = useState(new Course());
    const [searchTitle, setSearchTitle] = useState("");
    const [searchSubject, setSearchSubject] = useState(-1);
    const [searchStatus, setSearchStatus]   = useState(getStatusInitalValue("statusId"));
    const [searchStartDate, setSearchStartDate] = useState("");
    const [searchEndDate, setSearchEndDate] = useState("");
    const [searchClassesRemaining, setSearchClassesRemaining] = useState(getStatusInitalValue("remaining"));
    const [staticSubjects, setStaticSubjects] = useState(new Array<Subject>());
    const [showClassCountErrors, setShowClassCountErrors] = useState(getQueryStringParam("showClassCountErrors"));
    const [showExpiredClasses, setShowExpiredClasses] = useState(getQueryStringParam("expiredClasses"));
    const [currentTeacherId, setCurrentTeacherId] = useState(getStatusInitalValue("teacherId"));
    const [currentCompanyId, setCurrentCompanyId] = useState(getStatusInitalValue("companyId"));
    const [studentsOnCourse, setStudentsOnCourse] = useState(new Array<Student>());
    const [isLoaded, setIsLoaded] = useState(false);

    const [showOnlyActiveAndCompleted, setShowOnlyActiveAndCompleted] = useState(getQueryStringParam("showOnlyActiveAndCompleted"));

    const deleteCourse = async(courseId:number) => {
        const result = await confirm('Are you sure you wish to remove this course?');
        if(result){
            await axios.get(`/api/courses/delete?id=${courseId}`);
            fetchCourses();
        }
    }
    
    


    async function fetchCourses() {
        var response = await axios.get<Array<Course>>(`/api/courses/getall?cb=${Date.now() + Math.random()}`);
        setData(response.data);
    }  
    
    useEffect(() => {
        loadStaticData();
        sidebarChannel.publish("changeUrl", "courses");

        iniPage();
        
        const sidebarChannelReq = postal.channel("SideBar");
        let sc = sidebarChannelReq.subscribe("pulseCourses", (newUrl:any) => {
            iniPage();
            resetSearch();
        });
        //Clean up on unmount
        return () => {
            sc.unsubscribe();
        }
    }, []);

    //TODO: Remove this duplication
    useEffect(() => {
        if(searchTitle || searchTitle === ""){
            if(isLoaded) searchCourses();
        }
    }, [searchTitle]);

    useEffect(() => {
        if(searchSubject) {
            if(isLoaded) searchCourses();
        }
    }, [searchSubject]);
    
    useEffect(() => {
        if(searchStatus) {
            if(isLoaded) searchCourses();
        }
    }, [searchStatus]);
    
    useEffect(() => {
        if(searchClassesRemaining) {
            if(isLoaded) searchCourses();
        }
    }, [searchClassesRemaining]);
    
    useEffect(() => {
        if(searchStartDate) {
            if(isLoaded) searchCourses();
        }
    }, [searchStartDate]);

    useEffect(() => {
        if(searchEndDate) {
            if(isLoaded) searchCourses();
        }
    }, [searchEndDate]);

    useEffect(() => {
        if(currentTeacherId) {
            if(isLoaded) searchCourses();
        }
    }, [currentTeacherId]);
    
    useEffect(() => {
        if(currentCompanyId) {
            if(isLoaded) searchCourses();
        }
    }, [currentCompanyId]);

    

 

    const searchCourses = async() => {
        var response = await axios.get<Array<Course>>(`/api/courses/getall?cb=${Date.now() + Math.random()}&title=${searchTitle}&subjectId=${searchSubject}&statusId=${searchStatus}&startDate=${searchStartDate}&endDate=${searchEndDate}&classesRemaining=${searchClassesRemaining}&teacherId=${currentTeacherId}&companyId=${currentCompanyId}&showOnlyActiveAndCompleted=${showOnlyActiveAndCompleted}`);
        setData(response.data);
    }

    

    const titleChangeHandler = (e: any) => {
        setIsLoaded(true); 

        const text = e.target.value;

        setSearchTitle(text);
    }
    
    const loadStaticData = async() => {
        var response = await axios.get<Array<Subject>>(`/api/subjects/GetAll`);
        setStaticSubjects(response.data);

        let teacherResponse = await axios.get<Array<Teacher>>(`/api/teachers/GetAll?status=1`);
        setTeachers(teacherResponse.data);

        let companyResponse = await axios.get<Array<Company>>(`/api/companies/GetAll`);
        setCompanies(companyResponse.data);
        
    }

    const subjectChange = async(e:any) => {
        setIsLoaded(true); 

        let subjectId:number;

        const value = parseInt(e.target.options[e.target.selectedIndex].value);

        if(value !== -1) {
            subjectId = value;
        } else {
            subjectId = -1;
        }

        setSearchSubject(subjectId);
    }

    const statusChange = (e:any) => {
        setIsLoaded(true); 

        const value = parseInt(e.target.options[e.target.selectedIndex].value);


        setSearchStatus(value);
    }

    const changeStartDateHandler = (date:any) => {
        setIsLoaded(true); 

        let newDate = (date.getDate()).toString() + "/" + ((date.getMonth() + 1).toString() + "/" + date.getFullYear().toString());

        setSearchStartDate(newDate);
    }

    const changeEndDateHandler = (date:any) => {
        setIsLoaded(true); 

        let newDate = (date.getDate()).toString() + "/" + ((date.getMonth() + 1).toString() + "/" + date.getFullYear().toString());

        setSearchEndDate(newDate);
    }

    const classesRemainingChange = (e:any) => {
        setIsLoaded(true); 

        setSearchClassesRemaining(e.target.value);
    }

    const resetSearch = () => {
        setSearchTitle("");
        setSearchSubject(-1);
        setSearchStatus(2);
        setSearchClassesRemaining(-1);
        setSearchStartDate("");
        setSearchEndDate("");
        setShowExpiredClasses("false");
        setCurrentTeacherId(-1);
        setCurrentCompanyId(-1);
    }

    const teacher_Selected = (e) => {
        setIsLoaded(true); 

        setCurrentTeacherId(e.target.value);
    } 

    const company_Selected = (e) => {
        setIsLoaded(true); 

        setCurrentCompanyId(e.target.value);
    } 

    const showStudents_click = async(courseId:number) => {
        const response = await axios.get(`/api/students/GetStudentsByCourseId?id=${courseId}`);
        
        setStudentsOnCourse(response.data);
        setShowStudentModal(true);
    }

    const row_click = (courseId:number) => {
        props.history.push(`CoursesForm/${courseId}`)
    }

    const getStatusSpanFromId = (statusId: number) => {
        if (statusId === 1) {
            return <span className="badge badge-student-course-status-enrolled">Enrolled</span>;
        } else if (statusId === 2){
            return <span className="badge badge-student-course-status-enquired">Enquired</span>;
        } else if (statusId === 3){
            return <span className="badge badge-student-course-status-waiting">Waiting</span>;
        }
    }

    const getCourseTitle = () => {
        if(getQueryStringParam("expiredClasses")=="true"){
            return "Courses expiring / expired";
        }
        if(getStatusInitalValue("remaining") === 3) {
            return "Courses ending / ended";
        }
        if(getStatusInitalValue("statusId") === 1) {
            return "Draft courses";
        }

        if(getQueryStringParam("showClassCountErrors")=="True"){
            return "Courses with time discrepancies";
        }

        return "Courses";
    }

    return  (
        <>
            <div>
                {getQueryStringParam("showOnlyActiveAndCompleted") !=='True' && getQueryStringParam("showClassCountErrors") !=='True' &&  getQueryStringParam("expiredClasses") !=='true' && <div className="card">
                    <div className="card-body">
                        
                        
                        <div >
                            <div className="row mb-0" >
                                <div className="col-2"  >
                                    <div  style={{paddingBottom:"10px",paddingLeft:"10px"}}>
                                        <div >Course Name</div>
                                        <input className="form-control" onChange={titleChangeHandler } value={searchTitle} />
                                    </div>
                                </div> 
                                
                                <div className="col-1"  >
                                    <div  style={{paddingBottom:"10px"}}>
                                        <div>Subject</div>
                                        <select className="form-control" onChange={subjectChange} value={searchSubject}>
                                            <option value={-1} >All</option>
                                            {staticSubjects.map(s => (
                                                <option value={s.subjectId}>{s.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>  

                                <div className="col-1"  >
                                    <div  style={{paddingBottom:"10px"}}>
                                        <div>Status</div>
                                        <select className="form-control" onChange={statusChange} value={searchStatus}>
                                            <option value={-1}>All</option>
                                            <option value={1}>Draft</option>
                                            <option value={2}>Active</option>
                                            <option value={3}>Completed</option>
                                            <option value={4}>Archived</option>
                                        </select>
                                    </div>
                                </div>  

                                <div className="col-2"  >
                                    <div  >
                                        <div>Classes remaining</div>
                                        <select className="form-control" onChange={classesRemainingChange} value={searchClassesRemaining}>
                                            <option value={-1}>All</option>
                                            <option value={10}>10 or less</option>
                                            <option value={8}>8 or less</option>
                                            <option value={5}>5 or less</option>
                                            <option value={3}>3 or less</option>
                                            <option>0</option>
                                        </select>
                                    </div>
                                </div>  

                                <div className="col-1"  >
                                            <div>Start date</div>
                                            <DatePicker calendarStartDay={1} locale="eu" style={{width:"65px"}} className={`form-control-datepicker-medium` }  dateFormat="dd/MM/yyyy" onChange={date => changeStartDateHandler(date)} value={searchStartDate} />
                                </div>  

                                <div className="col-1"  >
                                        <div>End date</div>
                                        <DatePicker calendarStartDay={1} locale="eu" style={{width:"65px"}} className={`form-control-datepicker-medium` }  dateFormat="dd/MM/yyyy" onChange={date => changeEndDateHandler(date)} value={searchEndDate}  />
                                </div>


                                <div className="col-2"  >
                                    <div  >
                                        <div>Teacher</div>
                                        <select className="form-control" onChange={(e:any) => {teacher_Selected(e)}} >
                                            <option value={-1} >All</option>
                                            {teachers.map(t => (
                                                <option value={t.teacherId} selected={t.teacherId === parseInt(currentTeacherId.toString()) } >{t.firstName + " " + t.lastName}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>  

                                <div className="col-2" style={{paddingRight:"20px"}}  >
                                    <div  >
                                        <div>Company</div>
                                        <select className="form-control" onChange={(e:any) => {company_Selected(e)}} >
                                            <option value={-1} >All</option>
                                            {companies.map(c => (
                                                <option value={c.companyId} selected={c.companyId === currentCompanyId } >{c.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>  

                                
                            </div>
                        </div>
                    




                </div>
            </div>}
                
                <div className="card">
                    <div className="card-body">

                    <div className="page-title-box" >
                        <div className="page-title-btn btn-group float-right">
                            {getQueryStringParam("showClassCountErrors") !=='True' &&  getQueryStringParam("expiredClasses") !=='true' && <ActionButton onClick={()=>props.history.push('/coursesForm')} text="ADD COURSE" />}
                        </div>
                        <h4 className="page-title" style={{lineHeight:"0px", paddingTop:"10px",paddingBottom:"45px"}}> {getCourseTitle()} - {data.length} found</h4>
                    </div>

                

                <table className="table table table-hover m-0  " style={{backgroundColor:"white"}}>
                <thead>
                    <tr>
                        <th></th>
                        <th >Course name</th>
                        <th style={{width:"145px"}}>Teacher</th>
                        <th style={{width:"85px"}}>Subject</th>
                        <th style={{width:"110px",textAlign:"center"}} align="center">Start date</th>
                        <th style={{width:"110px",textAlign:"center"}} align="center">End date</th>
                        <th style={{width:"110px",textAlign:"center"}} >Expiring date</th>
                        <th style={{width:"104px",textAlign:"center"}}>Number of classes</th>
                        <th style={{width:"250px"}}>Progress</th>
                        <th style={{width:"85px",textAlign:"center"}}>Status</th>
                        <th style={{width:"8px",textAlign:"center", padding:"2px"}}></th>
                        <th style={{width:"8px",textAlign:"center", padding:"2px"}}></th>
                        <th style={{width:"85px",textAlign: "right"}}>Students enrolled</th>
                        <th style={{width:"85px",textAlign: "right"}}>Students enquired</th>
                        <th style={{width:"85px",textAlign: "right"}}>Students waiting</th>
                       
                    </tr>
                </thead>
                    <tbody>
                            {data.map((c,i) => (
                                <tr>
                                    <td onClick={()=> row_click(c.courseId)} ><Pill value={i+1} color="pink"/></td>
                                    <td onClick={()=> row_click(c.courseId)} >{<>{c.title} <div style={{fontSize:"12px"}}>{c.description}</div></>}</td>
                                    <td onClick={()=> row_click(c.courseId)} >{c.teacher}</td>
                                    <td onClick={()=> row_click(c.courseId)} >
                                        <SubjectBadge color={c.subjectColor} text={c.subject + ' ' + c.courseLevel} />
                                    </td>
                                    <td onClick={()=> row_click(c.courseId)}  align="center">{c.startDateString}</td>
                                    <td onClick={()=> row_click(c.courseId)}  align="center">{c.endDateString}</td>
                                    <td onClick={()=> row_click(c.courseId)} align="center">{c.expiringDateString}</td>
                                    <td onClick={()=> row_click(c.courseId)} ><>{c.totalClasses} classes<div style={{fontSize:"12px"}}>{c.classLengthTemplate} mins</div></></td>
                                    <td onClick={()=> row_click(c.courseId)} ><Progress  complete={c.percentageComplete} itemAmmount={c.takenPlace} itemsTotal={c.totalClasses} /></td>
                                    <td onClick={()=> row_click(c.courseId)}  align="center">  
                                        <CourseStatusBadge allowClick={false} statusId={c.courseStatusId} onClick={()=>{}} />     {/*<CourseStatusBadge statusId={c.courseStatusId} onClick={()=>alert("Click")} ></CourseStatusBadge>*/}
                                    </td>
                                    <td  onClick={()=> row_click(c.courseId)} style={{paddingLeft:"2px",paddingRight:"0px",paddingTop:"14px",paddingBottom:"2px"}} >
                                        {(c.hasClone) ? <i className="ti-star pink" style={{fontSize:"24px"}} title={"Course has been cloned"}></i> : " "}
                                    </td>
                                    <td>
                                        {c.hasUnMarkedClasses ? <div style={{paddingTop:"5px"}}><strong><i className="ti-face-sad pink" style={{fontSize:"19px", paddingTop:"8px"}} title={"Course has unmarked classes"}></i></strong></div> : " "}
                                    </td>
                                    <td style={{textAlign: "right", cursor:"pointer"}} onClick={() => showStudents_click(c.courseId) }>{c.enrolledStudents}</td>
                                    <td style={{textAlign: "right", cursor:"pointer"}} onClick={() => showStudents_click(c.courseId) }>{c.enquiredStudents}</td>
                                    <td style={{textAlign: "right", cursor:"pointer"}} onClick={() => showStudents_click(c.courseId) }>{c.waitingStudents}</td>
                                </tr>
                            ))}
                            {!data || data.length===0 && <tr><td colSpan={15} align="center">No courses found</td></tr>}
                    </tbody>
                </table>
            </div>
            </div>
        </div>


        <Modal show={showStudentModal} onHide={()=> setShowStudentModal(false)}  dialogClassName="modal-750px">
            <Modal.Header closeButton>
            <Modal.Title>Students on course</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{height:"450px"}}>
                <table className="table">
                    <tr>
                        <td><strong>First name</strong></td>
                        <td><strong>Last name</strong></td>
                        <td colSpan={2}><strong>Email</strong></td>
                    </tr>

                    {studentsOnCourse.map(s => (
                        <tr>
                            <td>{s.firstName}</td>
                            <td>{s.lastName}</td>
                            <td>{s.email}</td>
                            <td>{getStatusSpanFromId(s.statusId)}</td>
                        </tr>
                    ))}

                </table>
            </Modal.Body>
            <Modal.Footer>
                <CancelButton text="CLOSE" onClick={()=> setShowStudentModal(false)} />
            </Modal.Footer>
        </Modal>
    </>
    );
}

export default CoursesGrid;