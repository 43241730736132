import React, {useEffect,useState} from 'react';
import axios from 'axios';

import StudentAttendenceVM from '../../models/StudentAttendenceVM';

interface IProps {
  courseId:number;
}

const StudentAttendenceAgri = (props:IProps) => {
    
    const [studentAttendence, setStudentAttendence] = useState(new Array<StudentAttendenceVM>());

    const getData = async() => {
        const result = await axios.get<Array<StudentAttendenceVM>>(`/api/reports/GetStudentCourseAttedenceAgri?CourseId=${props.courseId}`);

        setStudentAttendence(result.data);
    }

    useEffect(() => {
        getData();

    }, []);
  return (
    <div>
      {studentAttendence && studentAttendence.length>0 && <table className='attendenceTable' cellPadding={3} cellSpacing={3}>
        <tr >
          <td style={{width:"242px"}} ></td>
          <td style={{width:"125px"}} align="right"><strong>Attended</strong></td>
          <td style={{width:"125px"}} align="right"><strong>Absent</strong></td>
          <td style={{width:"125px"}} align="right"><strong>Pending</strong></td>
          <td style={{width:"125px"}} align="right"><strong>Not enrolled</strong></td>
        </tr>

        {studentAttendence.map(sa => (
          <tr >
            <td>{sa.student}</td>
            <td align="right">{sa.attended}</td>
            <td align="right">{sa.absent}</td>
            <td align="right">{sa.pending}</td>
            <td align="right">{sa.missing}</td>
          </tr>
        ))
        }
      </table>}

      {!studentAttendence || studentAttendence.length === 0 && <div>No records found</div>}
    </div>
  )
}
export default StudentAttendenceAgri;