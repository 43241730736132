import React from 'react';
import NumberFormat from 'react-number-format';

interface IProps {
    value:number;
}

const roundup = (num,dec) => {
    dec= dec || 0;
    var  s=String(num);
    if(num%1)s= s.replace(/5$/, '6');
    return Number((+s).toFixed(dec));
 }

const CurrencyFormater = (props:IProps) => {
    return (
        <NumberFormat value={props.value ? props.value.toFixed(2) : 0} displayType={'text'} thousandSeparator={true} prefix={'£'} />
    );
}

export default CurrencyFormater;