import React, {useState, useEffect} from 'react';
import PaymentReport from '../../models/PaymentReport';
import TeacherPaymentVM from '../../models/TeacherPaymentVM';
import PaymentTable from './PaymentTable';
import Confirmation from '../../modals/Confirmation';
import PaymentPdfTemplate from '../common/PaymentPdfTemplate'
import SettlementReportVM from '../../models/SettlementReportVM';
import CheckBoxRound from '../common/CheckBoxRound';
import CurrencyFormater from '../common/CurrencyFormater';


import Class from '../../models/Class';

import axios from 'axios';
import postal from 'postal';


interface IProps {
    refresher:number;
    tabClickTimes:number;
}

let shouldIPrint:boolean = false;

const SettlementReport = (props:IProps) => {
    const [paymentReports, setPaymentReports] = useState(new Array<PaymentReport>());
    const [raisedPayments, setRaisedPayments] = useState(new Array<TeacherPaymentVM>());
    const [grandTotal, setGrandTotal] = useState(0);
    const [showConfirmationWindow, setShowConfirmationWindow] = useState(false);
    const [currentPaymentReport,setCurrentPaymentReport ] = useState(new PaymentReport());
    const sidebarChannel = postal.channel("SettlementReport");
    const [isLoaded, setIsLoaded] = useState(false);
    const [yearsToSelect, setYearsToSelect] = useState(new Array<string>());
    const [currentYear, setCurrentYear] = useState<string>(new Date().getFullYear().toString());
    const [currentClasses, setCurrentClasses] = useState(new Array<Class>());
    const [compactTable, setCompactTable] = useState(false);
    const [paymentTotalHours, setPaymentTotalHours] = useState(0);

    const [currentPeriodEndDate, setCurrentPeriodEndDate] = useState("");


    
    const loadPaymentReports = async(dateToShow:string) => {
        const response = await axios.get<Array<PaymentReport>>(`api/reports/GetPaymentHeadersByYear?year=${dateToShow}`);
        setPaymentReports(response.data);
        
        if(response.data.length>0){
            showPayment(response.data[0]);
        } else {
            setRaisedPayments([]);
        }
    }

    const totalCoursePayment = (teacherPayments:Array<TeacherPaymentVM>) => {

        teacherPayments.map(tp=>{
            let coursePaymentTotal:number = 0;

            
            tp.paymentItems.map(pi=>{

                if(pi.courseId === 1049){
                    const i = 1;
                }
    
                let courseItems = tp.paymentItems.filter(p => p.courseId == pi.courseId);
                let sumCourseTotal:number = 0; 

                courseItems.map(i => {
                    sumCourseTotal += (i.classLength / 60) * i.ratePerHour;
                    pi.coursePaymentTotal = roundup(sumCourseTotal,2);
                })
            });
        });

        return teacherPayments;
    }

    const roundup = (num,dec) => {

        return Math.round((num + Number.EPSILON) * 100) / 100;


        /*dec= dec || 0;
        var  s=String(num);
        if(num%1)s= s.replace(/5$/, '6');
        return Number((+s).toFixed(dec));
        */
     }
    

    const convertClassesFrom = (teacherPayments:Array<TeacherPaymentVM>):Array<Class> => {
        let classesToConvert:Array<Class> = new Array<Class>();

        teacherPayments.map(tp=>{
            let coursePaymentTotal:number = 0;
            
            
            tp.paymentItems.map(pi=>{
                let courseItems = tp.paymentItems.filter(p => p.courseId == pi.courseId);
                let sumCourseTotal:number = 0; 

               
                courseItems.map(i => {
                   
                    sumCourseTotal += (i.classLength / 60) * i.ratePerHour;
                })

                let classToA:Class = new Class();
                classToA.classLength = pi.classLength;
                classToA.course = pi.title;
                classToA.teacherRate = pi.ratePerHour
                classToA.coursePaymentTotal = roundup(sumCourseTotal,2);
                classToA.classDateString = pi.classSeperateDateString;
                classToA.classDatesCombined = pi.classSeperateTimeString;
                classToA.subject = pi.subject;
                classToA.teacher = tp.teacher;

                classesToConvert.push(classToA);
            });
        });

        return classesToConvert;

    }

    const showPayment = async(paymentReport:PaymentReport) => {
        const response = await axios.get<Array<TeacherPaymentVM>>(`api/reports/GetRaisedPaymentReport?paymentHeaderId=${paymentReport.paymentHeaderId}`);
        
        let teacherPayments:Array<TeacherPaymentVM> = response.data;

        teacherPayments = totalCoursePayment(teacherPayments);
        
        setRaisedPayments(teacherPayments);
        setCurrentPaymentReport(paymentReport);

        let classesToAdd:Array<Class> = convertClassesFrom(teacherPayments);
        setCurrentClasses(classesToAdd);

        setCurrentPeriodEndDate(paymentReport.periodEndDateString);
    }

    const deletePayment = async () => {
        await axios.get(`api/reports/DeletePayments?paymentHeaderId=${currentPaymentReport.paymentHeaderId}`);
        loadPaymentReports(currentYear);
        setShowConfirmationWindow(false);
        loadPaymentReports(currentYear);
        setRaisedPayments([]);
        sidebarChannel.publish("SettlementReport");
    }

    const yearSelectedChanged = async (e:any) => {
        setCurrentYear(e.target.value);
        await loadPaymentReports(e.target.value);
    }

    const loadYearsToSelect = () => {
        const yearsToAdd = new Array<string>();

        yearsToAdd.push('2023');
        yearsToAdd.push('2022');
        yearsToAdd.push('2021');
        yearsToAdd.push('2020');
        yearsToAdd.push('2019');
        yearsToAdd.push('2018');
        yearsToAdd.push('2017');

        setYearsToSelect(yearsToAdd);
    }


    useEffect(() => {
        loadPaymentReports(currentYear);
        loadYearsToSelect();
    }, [props.refresher]);

    useEffect(() => {
        if(paymentReports && paymentReports.length>0){
            showPayment(paymentReports[0]);
        }

    }, [props.tabClickTimes]);

    useEffect(() => {
        if(paymentReports && paymentReports.length>0 && !isLoaded){
            showPayment(paymentReports[0]);
            setIsLoaded(true);
        }
    }, [paymentReports]);

    useEffect(() => {
        if(currentPaymentReport.paymentHeaderId){
            if(shouldIPrint) {
                window.open(`/reports/printpaymentreport?id=${currentPaymentReport.paymentHeaderId}&grandTotal=${grandTotal}&periodEndDate=${currentPaymentReport.periodEndDateString}`, "_blank", "menubar=no,location=no,height=1200,width=1200");
            }
            shouldIPrint = false;
        }
    }, [currentPaymentReport]);

    const openPrintWindow = async (paymentReport:PaymentReport) => {
        //await printAndShowPayment(paymentReport);
        if(paymentReport.paymentHeaderId == currentPaymentReport.paymentHeaderId){
            window.open(`/reports/printpaymentreport?id=${currentPaymentReport.paymentHeaderId}&grandTotal=${grandTotal}&periodEndDate=${currentPaymentReport.periodEndDateString}`, "_blank", "menubar=no,location=no,height=1200,width=1200");
        }
        shouldIPrint = true;
    }

    return (
        <>
            <div >
                <div className="row">
                    <div className="col-9">
                        <div className="card" style={{minHeight:"1000px"}}>
                            <div className="card-body" >

                                <div className="dropdown float-right" style={{height:"650px"}}>
                                    <a href="#" className="dropdown-toggle card-drop" data-toggle="dropdown"
                                        aria-expanded="false">
                                        <i className="zmdi zmdi-more-vert"></i>
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-right">
                                        <li><a href="#" className="dropdown-item">Action</a></li>
                                        <li><a href="#" className="dropdown-item">Another action</a></li>
                                        <li><a href="#" className="dropdown-item">Something else here</a></li>
                                        <li className="dropdown-divider"></li>
                                        <li><a href="#" className="dropdown-item">Separated link</a></li>
                                    </ul>
                                </div>


                                <h4 style={{paddingBottom:"8px",paddingTop:"11px"}} className="header-title m-t-0 m-b-30" >
                                   
                                    <div className="page-title-btn btn-group float-right"  style={{padding:"2px", textAlign:"center"}} >
                                        <div style={{paddingTop:"9px", paddingRight:"5px", fontSize:"14px", fontWeight:5}}>Only payment list</div>
                                        <CheckBoxRound checked={compactTable} onChange={()=>{setCompactTable(!compactTable)}} />
                                    </div>
                                   
                                    <div style={{fontSize:"22px",paddingBottom:"14px"}}>SETTLEMENT REPORT {currentPeriodEndDate!=="" && "-"} {currentPeriodEndDate}</div> 


                                    <div className="header-title m-t-0 m-b-30" style={{fontSize:"17px"}}>TOTAL HOURS {(paymentTotalHours/60).toFixed(2)}</div>
                                    <div className="header-title m-t-0 m-b-30" style={{fontSize:"17px"}}>TOTAL AMOUNT <CurrencyFormater value={grandTotal} />  </div>


                                   

                                </h4>
                                <div style={{paddingBottom:"18px"}}></div>

                                <PaymentTable compactTable={compactTable} teacherPayments={raisedPayments} getPaymentTotalHours={(totalHours:number)=>{setPaymentTotalHours(totalHours)}} getPaymentGrandTotal={(paymentTotal:number)=>{
                                    setGrandTotal(paymentTotal);
                                }}  />
                            </div>
                        </div>
                    </div>

                    <div className="col-3">

                        <div className="card">
                            <div className="card-body">
                                
                                <div className="row" style={{paddingBottom:"10px"}}>
                                    <div className="col">
                                        <h4 style={{ paddingLeft: "10px",paddingTop: "8px" }} className="header-title m-t-0 m-b-30">Settlement&nbsp;Reports </h4>
                                    </div>
                                    <div className="col">
                                        <select onChange={yearSelectedChanged } value={currentYear} style={{height:"35px"}} className="form-control">
                                            {yearsToSelect.map(y => (
                                                <option value={y}>{y}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
   
                                <table className="table">
                                    <tr>
                                        <th>Period end date</th>
                                        <th>Amount paid</th>
                                        <th>Actions</th>
                                    </tr>
                                    {paymentReports && paymentReports.map(r => (
                                        <tr onClick={()=> showPayment(r)} style={{cursor:"pointer", backgroundColor:(r.paymentHeaderId== currentPaymentReport.paymentHeaderId) ? "#efefef" : "#FFFFFF"}}>
                                            <td>{r.periodEndDateString}</td>
                                            <td align="right"><CurrencyFormater value={r.amountTotal} /></td>
                                            <td>
                                                <>
                                                    <div  style={{float: "right",paddingLeft:"8px",paddingTop:"2px", cursor: "pointer"}}><i className="ti-trash pink" style={{fontSize: "20px"}} onClick={() => { setShowConfirmationWindow(true)  }}></i></div>
                                                    <div style={{float: "right",paddingTop:"3px"}} onClick={() => openPrintWindow(r)}><i style={{fontSize:"18px"}} className="far fa-file-pdf pink"></i></div>
                                                </>
                                            </td>
                                        </tr>
                                    ))
                                    }

                                    {!paymentReports || paymentReports.length === 0 && <tr><td colSpan={3} align="center">No settlement reports currently</td></tr> }
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Confirmation showModal={showConfirmationWindow} hideModal={() => { setShowConfirmationWindow(false)} } confirmButton_Click={()=>{ deletePayment()}  } confirmText={'DELETE'} title={'Delete confirmation'} bodyText={'Are you sure wish to remove this payment?'} />

            <PaymentPdfTemplate isTeacherReport={false} classes={currentClasses} paymentTotal={grandTotal} periodEndDateString={currentPaymentReport.periodEndDateString} />
        </>
    );
}

export default SettlementReport;