import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { Accordion,Card,Button,Modal } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment';


import WaveBox from "../components/common/WaveBox";
import Student from '../models/Student';
import Class from '../models/Class';
import Course from '../models/Course';

import ClassStatusVM from '../models/ClassStatusVM';
import ActionButton from '../components/buttons/ActionButton';
import CancelButton from '../components/buttons/CancelButton';

import RescheduleClass from "../teachers/components/common/RescheduleClass";

import {
    isMobile
  } from "react-device-detect";


interface IProps {
    classId:number;
    show:boolean;
    students:Array<Student>;
    hide:Function,
    updateGridClassStatus:Function,
    currentClass?: Class,
    mode?:string
    currentCourse?: Course,
    refreshMarkedClasses?: number
}



const MarkClassesModal =  (props:IProps) =>  {
    const [currentCourseStudents, setCurrentCourseStudents] = useState(new Array<Student>());
    const [classStatues, setClassStatues] = useState(new Array<ClassStatusVM>());
    const [rescheduleDateString, setrescheduleDateString] = useState(props.currentClass?.classDateString);
    const [rescheduleDate, setrescheduleDate] = useState(new Date());
    const [rescheduleTime, setRescheduleTime] = useState("");
    const [classLength, setClassLength] = useState(0);

    const [showMoveToEnd, setShowMoveToEnd] = useState(false);

    const [seriesStatusId, setSeriesStatusId] = useState(0);

    const [currentCourse, setCurrentCourse] = useState(new Course());
        

    useEffect(() => {
       
        if(props.classId !== 0){
            loadStudentForClass(props.classId);

            loadClassData(props.classId);

            if(props.currentClass?.classDateString){
                var dd = moment(props.currentClass?.classDateString, "DD-MMM-YYYY");

                setrescheduleDate(new Date(dd.toString()));
            }

            //Set time
            let time:string = "";

            if(props.currentClass?.classDateString){
                time = props.currentClass?.classTime;
                setRescheduleTime(time);
            }

            if(props.currentClass?.classLength){
                let classLengthToUpdate:number = props.currentClass?.classLength;
                setClassLength(classLengthToUpdate);
            }


        }
    }, [props.classId,props.refreshMarkedClasses]);

    const loadClassData = async(classId:number) => {
        if(classId){
            var date = new Date(); // some mock date
            var milliseconds = date.getTime(); 

            let response = await axios.get(`/api/classes/GetById?id=${classId}&cachebuster=${milliseconds}`);

            setSeriesStatusId(response.data.seriesStatusId);
        }
    }

    const loadStudentForClass = async (classId:number) => {
        if(classId) {
            var date = new Date(); // some mock date
            var milliseconds = date.getTime(); 
            // This will return you the number of milliseconds
            // elapsed from January 1, 1970 
            // if your date is less than that date, the value will be negative
            
            //alert(milliseconds);

            let response = await axios.get(`/api/students/GetStudentsByClassId?id=${classId}&cachebuster=${milliseconds}`);
            setCurrentCourseStudents(response.data);

            response = await axios.get<Course>(`/api/courses/GetCourseByClassId?id=${classId}&cachebuster=${milliseconds}`);

            setCurrentCourse(response.data);
        }

    }
    
    
    const WaveBoxHandler = (record, item) => {
        const studentId = record.studentId;
        const classId   = props.classId;

        let statusId = 0;
        if(item==="ABSENT") {
            statusId = 5;
        } else if (item==="ATTENDED") {
            statusId = 2;
        }

        let classStatusToAdd = new ClassStatusVM();

        classStatusToAdd.classId = classId;
        classStatusToAdd.studentId = studentId;
        classStatusToAdd.statusId = statusId;

        setClassStatues(oldArray => [...oldArray, classStatusToAdd]);

        //Update local array

        let data = [...currentCourseStudents];
        let studentIndex = data.findIndex(d =>  d.studentId === studentId);


        data[studentIndex].status = statusId;

        setCurrentCourseStudents(data);

        //axios.get(`/api/classes/UpdateClassAttendence?studentId=${studentId}&classId=${classId}&statusId=${statusId}`);
    }

    
    const getStatusFromId = (statusId: number) => {
        if (statusId === 1) {
            return "PENDING";
        } else if (statusId === 2){
            return "ATTENDED";
        } else if (statusId === 3){
            return "Late cancellation";
        }  else if (statusId === 4){
            return "Cancelled in advance";
        } else if (statusId === 5){
            return "ABSENT";
        }
    }

    const isClassMarkingComplete = ():boolean => {
        let isMarkedComplete:boolean = true;

        currentCourseStudents.map(c => {
            if(c.status === 1 || c.status === 0){
                isMarkedComplete = false;
            }
        });

        return isMarkedComplete;
    }

    const saveMarkings = async() => {
        await axios.post(`/api/classes/UpdateClassAttendenceMultiple`, classStatues);
        props.hide();
        
        let markingComplete:boolean = isClassMarkingComplete();
        props.updateGridClassStatus(props.classId,  markingComplete);
    }

    const moveClassToEnd = async() => {
        await axios.get(`/api/classes/UpdateClassDateToLast?classId=${props.classId}`);
        props.updateGridClassStatus();
        props.hide();
        setShowMoveToEnd(false);
    }

    const isStateScehduled = () => {
        return props.currentClass && props.currentClass.seriesStatusId === 1;
    }

    const rescheduleDateChange = (date:any) => {
        moment.locale('en-gb');                  
        let newDateString = (date.getDate()).toString() + "/" + ((date.getMonth() + 1).toString()+ "/" + date.getFullYear().toString())
        var a = moment(newDateString, 'DD/MM/YYYY');


        setrescheduleDateString(newDateString);
        setrescheduleDate(a.toDate());
    }

    

    
    const updateClassDate = async() => {
        let classId:number = 0;

        if(props.currentClass){
            classId = props.currentClass?.classId;
        }

        
        let newDateString = (rescheduleDate.getDate()).toString() + "/" + ((rescheduleDate.getMonth() + 1).toString()+ "/" + rescheduleDate.getFullYear().toString())


        await axios.get(`/api/classes/UpdateClassDate?classId=${classId}&classDate=${newDateString} ${rescheduleTime}&classLength=${classLength}`);
        
        props.updateGridClassStatus();
        props.hide();

    }

    const time_onChange = (e) => {
        setRescheduleTime(e);
    }

    const newClassLength_OnChange = (e:any) => {
        if(!isNaN(e.target.value)){
            let classLengthToUpdate = parseInt(e.target.value);
            setClassLength(classLengthToUpdate);
        }
        if(e.target.value === "" || !e.target.value){
            setClassLength(0);
        }
    } 

    const GetModalTitle = () => {
        if (props.mode === "M" && props.currentClass?.seriesStatusId === 1){
            return "Students"
        } 
        if (props.mode === "M") {
            return "Mark attendance" 
        } else { 
            return "Reschedule class"
        }
    }
    
    return (/*onHide={() => setShowStudents(false)} */
        <>
           
           <Modal show={props.show}  dialogClassName={(!isMobile) ? "modal-750px" : "modal-350px"}  onHide={()=>props.hide()} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{GetModalTitle()} 
                    
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(!isMobile && props.mode === "M" ) && 
                        <table className="table  mb-0 table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">First Name</th>
                                    <th scope="col">Last Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col" style={{width:"45px"}}>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentCourseStudents && currentCourseStudents.map(c =>(
                                <tr >
                                    <td  style={{padding:"8px"}} key={c.studentId}>{c.firstName} </td>
                                    <td>{c.lastName} </td>
                                    <td>{c.email} </td>
                                    <td>
                                        {(seriesStatusId !== 1 && seriesStatusId !== 6)  && <><WaveBox onClick={(item) => WaveBoxHandler(c, item)} size="s" allowEmpty={true} selected={getStatusFromId(c.status)} items={['ATTENDED', 'ABSENT']} /></>}
                                        {(seriesStatusId === 1 || seriesStatusId === 6)  && <>{getStatusFromId(c.status)}</>}
                                    </td>
                                </tr>
                                ))}
                                {!currentCourseStudents || currentCourseStudents.length === 0 &&
                                    <tr>
                                        <td colSpan={4} align='center'>No students in class</td>
                                    </tr>
                                } 
                            </tbody>
                        </table> 
                    }

                    {(isMobile && props.mode === "M") && 
                        <table className="table  mb-0 table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col" style={{width:"45px"}}>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentCourseStudents && currentCourseStudents.map(c =>(
                                <tr >
                                    <td  style={{padding:"8px"}}>{c.firstName} {c.lastName}</td>
                                    <td>
                                        {(seriesStatusId !== 1)  &&<WaveBox onClick={(item) => WaveBoxHandler(c, item)} size="s" allowEmpty={true} selected={getStatusFromId(c.status)} items={['ATTENDED', 'ABSENT']} />}
                                        {(seriesStatusId === 1)  && <>{getStatusFromId(c.status)}</>}
                                    </td>
                                </tr>
                                ))}
                                
                            </tbody>
                        </table>
                    }

                    {(props.mode !== "M" && !showMoveToEnd) && 
                        <>
                            <RescheduleClass classDateString={props.currentClass?.classDatesCombined} time_onChange={time_onChange} classLength={classLength}  rescheduleDateChange={rescheduleDateChange} rescheduleTime={props.currentClass?.classTime} rescheduleDate={rescheduleDate}  />
                        </>
                    }

                    {(props.mode !== "M" && showMoveToEnd) && 
                        <div>Are you sure you wish to move this class to the end of the course?</div>
                    }

                </Modal.Body>
                {(seriesStatusId !== 6 && !showMoveToEnd) ? <Modal.Footer>
                    {props.mode != "M" && 
                        <div className="mr-auto"><ActionButton onClick={() => setShowMoveToEnd(true)} text="MOVE TO END"></ActionButton></div>}
                        <ActionButton onClick={() => (props.mode && props.mode==="R") ?  updateClassDate() :saveMarkings() } text="SAVE" />
                        <CancelButton onClick={() => props.hide()}  />
                        </Modal.Footer> : <div style={{height:"45px"}}>&nbsp;</div>
                    }

                {showMoveToEnd && 
                    <Modal.Footer>
                        <ActionButton onClick={() => moveClassToEnd() } text="CONFIRM" />
                        <CancelButton onClick={() => {setShowMoveToEnd(false)}}  />
                    </Modal.Footer>
                }

            </Modal>
        </>
    );
}

export default MarkClassesModal;