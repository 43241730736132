import React, {useEffect} from 'react'
import StudentAttendence from '../reports/StudentAttendence';
import StudentAttendenceAgri from '../reports/StudentAttendenceAgri';
import StudentAttendenceHeader from '../reports/StudentAttendenceHeader';


interface IProps {
  id:number
}

const PrintStudentAttendence = (props:IProps) => {
  useEffect(() => {
      let body = document.getElementById('printbody');
      if(body){
          body.className='whiteBackgroundOveride';
      }

      setTimeout(function () {
        window.print();
      }, 2000);
  }, []);
  
  return (
    <div className='whiteFullBackground vendorListHeading' style={{paddingLeft:"25px",paddingTop:"15px"}}>

     
      <StudentAttendenceHeader courseId={props.id} />
      <h4>Student attendance</h4>
      <StudentAttendence courseId={props.id} rowMaxNumber={5} showScroll={false} />  <br/>
      <h4>Total student class attendence</h4>
      <StudentAttendenceAgri courseId={props.id} />

    </div>
  )
}

export default PrintStudentAttendence