import React, { PureComponent } from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

import ClassMonthlyCountVM from '../models/ClassMonthlyCountVM';
import axios from 'axios';


const data = [{"monthShortName":"Aug","classCount1":0,"classCount2":0},{"monthShortName":"Sep","classCount1":0,"classCount2":0},{"monthShortName":"Oct","classCount1":0,"classCount2":0},{"monthShortName":"Nov","classCount1":0,"classCount2":0},{"monthShortName":"Dec","classCount1":0,"classCount2":0},{"monthShortName":"Jan","classCount1":0,"classCount2":3},{"monthShortName":"Feb","classCount1":0,"classCount2":2},{"monthShortName":"Mar","classCount1":0,"classCount2":0},{"monthShortName":"Apr","classCount1":3,"classCount2":0},{"monthShortName":"May","classCount1":3,"classCount2":0},{"monthShortName":"Jun","classCount1":0,"classCount2":0},{"monthShortName":"Jul","classCount1":0,"classCount2":22}]

interface IState {
  data:any
}
interface IProps {
  data:any
}

export default class Example extends PureComponent<IProps,IState> {
  static jsfiddleUrl = 'https://jsfiddle.net/alidingling/xqjtetw0/';

  constructor(props){
    super(props);
    this.state = {
      data:[]
    };
  }

  
  render() {
    return (
      <>
      <LineChart
        width={465}
        height={300}
        data={this.props.data}
        margin={{
          top: 5, right: 30, left: 20, bottom: 5,
        }}
      >
        
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="classCount1" stroke="#45b0e2" activeDot={{ r: 8 }} name="Last Year" />
        <Line type="monotone" dataKey="classCount2" stroke="#fe6271" name="This Year" />
      </LineChart>
      </>
    );
  }
}
