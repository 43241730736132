export default class Room {
    constructor(){
       
    }

    public roomId:number
    public name:string
    public capacity:number
    public companyId:number
    public company:string
    public description:string
}