import React, {useEffect, useState} from 'react';
import BottomNav from './BottomNav';
import ClassWrapper from '../../models/ClassWrapper';

import UserService from "../../services/userService";
import ClassStatusBadge from '../../components/badges/ClassStatusBadge';
import ClassAttendenceBadge from '../../components/badges/ClassAttendenceBadge';

import axios from 'axios';

interface IProps {
    history:any;
}

const ClassesMobile = (props:IProps) => {
    const [courses, setCourses] = useState(new ClassWrapper());
    const userService = new UserService();


    useEffect(() => {
        loadClasses();
    }, []);

    const loadClasses = async (viewStatus:string="All") => {
        const response = await axios.get<ClassWrapper>(`/api/classes/GetByFilter?studentId=${userService.getUser().entityId}`);
        setCourses(response.data);     
    }; 

    return (
        <>
            {courses.classes && courses.classes.map(c => (
                <div className="chat-card">
                    
                    <div className="card-body-mobile" >
                        <div className="pink" style={{textTransform:"uppercase"}}><strong>C L A S S</strong></div>

                        <div className="row" >
                            <div className="col-6">
                                <div style={{textTransform:"uppercase",color:"grey", fontFamily:"Karla", fontSize:"16px"}}><strong>{c.dayWeek} {c.classDateString}</strong>  </div>
                                
                            </div>
                            <div className="col-6">
                                <div style={{color:"#495057", textAlign:"right"}}>
                                     <div  style={{textTransform:"uppercase",color:"grey",fontFamily:"Karla", fontSize:"16px"}}><strong>{c.classDatesCombined}</strong>  </div>
                                </div>
                            </div>
                        </div>

                        <div>{c.subject} {c.level}</div>
                        <div>{c.definedAddress}</div>
                        <div>{c.teacher}</div>
                        <div><ClassAttendenceBadge statusId={c.classStudentStatusId} onClick={()=>{}} /> </div>
                       
                    </div>
                </div>
            ))}


            <BottomNav  history={props.history} currentTab={1} />
        </>
    );
}

export default ClassesMobile;