import React, { Component, Fragment } from 'react';
import axios from 'axios';
import Student from '../../models/Student';
import TickCross from '../common/TickCross';
import Pagination from 'react-bootstrap/Pagination';
import Company from '../../models/Company';

import ActionButton from '../buttons/ActionButton';
import SubjectBadge from '../badges/SubjectBadge';

import Pill from '../common/Pill';


const paginationConfig = {
    recordsPerPage:100
}

interface IState {
    records:any,
    recordsPaginated:any,
    currentPage:number,
    numberOfPages:number,
    searchText:string,
    searchCompany:number,
    searchStatus:number,
    staticCompanies:Array<Company>
}

interface IProps {
    history:any
}

class DataTable extends Component<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            records:[],
            recordsPaginated:[],
            currentPage:1,
            numberOfPages:0,
            searchText:"",
            searchCompany:-1,
            searchStatus:-1,
            staticCompanies:[]
        }
    }

    convertSubjectsToPills = (currentCourses:string) => {
        let courseArray = currentCourses.split(',');

        let pills = courseArray.map(f => (
            <>
                <SubjectBadge color={f.split('|')[1]} text={f.split('|')[0]} />
                &nbsp;
            </>
        ));
        return pills;
    }
    
    async componentWillMount(){
        this.searchStudents("");
        this.loadStaticData();
    }

    loadStaticData = async() => {
        var response = await axios.get<Array<Company>>(`/api/companies/getall`);
        this.setState({"staticCompanies": response.data});
    }

    searchStudents = async(name:string, companyId:number=-1, statusId:number=-1) => {
        var url:string = `/api/students/getall?name=${name}`;
        if(!companyId || companyId !== -1){
            url += `&companyId=${companyId}`;
        }
        if(!statusId || statusId !== -1){
            url += `&statusId=${statusId}`;
        }

        var response = await axios.get<Array<Student>>(url);
        let students = new Array<Student>();

        for(var i=0; i < paginationConfig.recordsPerPage; i++) {
            if(response.data[i]) {
                students.push(response.data[i]);
            }
        }

        //Calculate number of pages
        let numberOfPages = Math.ceil(response.data.length / paginationConfig.recordsPerPage);

        this.setState({numberOfPages});
        this.setState({ records: response.data });
        this.setState({ recordsPaginated: students });
    }

    repaginate = (pageNub:number) => {
        let students = new Array<Student>();

        let offset = ((pageNub-1) * paginationConfig.recordsPerPage);

        for(var i= offset; i < offset + paginationConfig.recordsPerPage; i++) {
            if(this.state.records[i]){
                students.push(this.state.records[i]);
            }
        }

        this.setState({ recordsPaginated: students })
    }


    editRecord(record) {
        this.props.history.push('/studentsForm');
    }
 
    deleteRecord(record) {
        console.log("Delete Record", record);
    }
    rowSelect(record){
        console.log(record)

    }

    selectRow(studentId:number){
        this.props.history.push(`/studentsForm/${studentId}`);
    }

    handleClick = (e) => {
        const clickValue = e.target.offsetParent.getAttribute("data-page")
        ? e.target.offsetParent.getAttribute("data-page")
        : e.target.getAttribute("data-page");

        this.repaginate(parseInt(clickValue));

        this.setState({ currentPage: parseInt(clickValue) })
    }

    renderPaginationItem = () => {
        let output = new Array<any>();
        for(var i=1; i < this.state.numberOfPages + 1; i++) {
            if(i != this.state.currentPage){
                output.push(<Pagination.Item key={i} data-page={i}> {i} </Pagination.Item>)
            
            } else {
                output.push(<Pagination.Item key={i} data-page={i} active> {i} </Pagination.Item>)
            }
        }
        return output;
    }

    searchText_Onchange = (e:any) => {
        this.setState({'searchText': e.target.value})
        this.searchStudents(e.target.value,this.state.searchCompany, this.state.searchStatus);
    }

    searchCompany_onChange = (e:any) => {
        this.searchStudents(this.state.searchText, parseInt(e.target.value),this.state.searchStatus);
        this.setState({"searchCompany": parseInt(e.target.value)})
    }

    searchStatus_onChange = (e:any) => {
        this.searchStudents(this.state.searchText, this.state.searchCompany, parseInt(e.target.value));
        this.setState({"searchStatus": parseInt(e.target.value)})
    }
 
    render() {
        return (
            <div>
                <div>
                    <div className="card">
                        <div className="card-body">
                            <div>

                            
                                <div>

                                <div className="row mb-0 ">
                                    <div className="col-7"  ></div>


                                    <div className="col-2"  >
                                        <div  style={{paddingBottom:"10px",paddingLeft:"10px"}}>
                                        <div style={{fontWeight: 600}}>Student name</div>
                                            <input className="form-control"  value={this.state.searchText} onChange={this.searchText_Onchange}/>
                                        </div>
                                    </div> 

                                    <div className="col-2"  >
                                        <div  style={{paddingBottom:"10px"}}>
                                            <div style={{fontWeight: 600}}>Company</div>
                                            <select className="form-control" name="searchCompany"  onChange={this.searchCompany_onChange}>
                                                <option value="-1">All</option>
                                                {this.state.staticCompanies.map(c => (
                                                    <option value={c.companyId}>{c.name}</option>
                                                ))}
                                            </select>    
                                        </div>
                                    </div>  


                                    <div className="col-1"  >
                                        <div  style={{paddingBottom:"10px"}}>
                                            <div style={{fontWeight: 600}}>Status</div>
                                            <select className="form-control" name="searchCompany"  onChange={this.searchStatus_onChange} >
                                                <option value="-1">All</option>
                                                <option value="1">Active</option>
                                                <option value="0">Inactive</option>
                                            </select>    
                                        </div>
                                    </div>  


                                </div> 


                                </div> 
                            </div> 
                        </div>
                    </div>
                </div>
                
                <div className="card" >
                    <div className="card-body">
                        <div className="page-title-box" >
                            <div className="page-title-btn btn-group float-right">
                                <ActionButton onClick={()=>this.props.history.push('/studentsForm')} text="ADD STUDENT" />
                            </div>
                            <h4 className="page-title">Students - {this.state.records.length} found</h4>
                        </div>

                        <table className="table table table-hover m-0" style={{backgroundColor:"white"}}>
                            <thead>
                                <tr>
                                    <th style={{width:"75px"}}></th>
                                    <th>First name</th>
                                    <th>Last name</th>
                                    <th>Mobile</th>
                                    <th>Email</th>
                                    <th>Company</th>
                                    <th>Current courses</th>
                                    <th><div style={{textAlign: "left" }}> Status</div></th>
                                    <th style={{width:"130px"}}>Online access</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.recordsPaginated.map((s,i) => (
                                        <tr onClick={()=> this.selectRow(s.studentId)}>
                                            <td valign="top"><Pill value={i+1} color="pink" /></td>

                                            <td>{s.firstName}</td>
                                            <td>{s.lastName}</td>
                                            <td>{s.mobile}</td>
                                            <td>{s.email}</td>
                                            <td>{s.company}</td>
                                            <td>{this.convertSubjectsToPills(s.currentCourses)}</td>
                                            <td><div style={{textAlign: "left" }}> {(!s.currentCourses || s.currentCourses === "") ?<span className="badge badge-primary bg-grey">Inactive</span> : <span className="badge badge-primary bg-green">Active</span>}  </div></td>
                                            <td style={{textAlign: "center"}}><TickCross checked={s.hasLogin} /></td>
                                        </tr>
                                 ))
                                }


                                {!this.state.recordsPaginated || this.state.recordsPaginated.length === 0 && 
                                   <tr><td colSpan={13} align="center">No students currently</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

                <div style={{width:"1500px"}}>
                    {(this.state.records.length > paginationConfig.recordsPerPage) && 
                        <Pagination onClick={this.handleClick} style={{float:"right", paddingTop:"8px"}}>
                            <Pagination.First data-page={1} />
                            <Pagination.Prev data-page={(this.state.currentPage !== 1 ) ?  this.state.currentPage - 1 :  1 }   />
                                {this.renderPaginationItem().map(pag=>(
                                    pag
                                ))}
                            <Pagination.Next  data-page={(this.state.currentPage < this.state.numberOfPages ) ?  this.state.currentPage + 1 :  this.state.numberOfPages} />
                            <Pagination.Last data-page={this.state.numberOfPages}  />
                        </Pagination>
                    }
                </div>
            </div>
        )
    }
}
 
export default DataTable;