import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import StudentProfileMOCK from '../students/StudentProfileMOCK';
import StudentsClasses from '../students/StudentsClasses';
import StudentsNotes from '../common/NotesForm';

import Calendar from '../common/Calandar';
import Documents from '../common/Documents';

import DocumentList from '../common/DocumentList';

interface IProps {
    id:number,
    history:any
}

const StudentsTabs = (props: IProps) => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  return (
    <div style={{width:"1500px"}}>
      <Nav tabs>
      <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => { toggle('1'); }}
          >
            Profile
          </NavLink>
        </NavItem>

        
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '4' })}
            onClick={() => { toggle('4'); }}
          >
            Calendar
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '6' })}
            onClick={() => { toggle('6'); }}
          >
            Documents
          </NavLink>
        </NavItem>

        
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '7' })}
            onClick={() => { toggle('7'); }}
          >
            Notes
          </NavLink>
        </NavItem>
      </Nav>
      
      <TabContent activeTab={activeTab}>
      <TabPane tabId="1">
          <Row>
            <Col sm="12">
              <StudentProfileMOCK id={props.id}/>
            </Col>
          </Row>
        </TabPane>
        
        <TabPane tabId="4">
          <Row>
            <Col sm="12">
              <div className="card"><div style={{padding:"15px"}}><Calendar  id={props.id} type="S" history={props.history} /></div></div>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="5">
          <Row>
            <Col sm="12">
              
              <Documents />
            </Col>
          </Row>
        </TabPane>
        


        <TabPane tabId="6">
          <Row>
            <Col sm="12">
              
              <div className="card"><div style={{padding:"15px", minHeight:"764px"}}><DocumentList id={props.id} /></div></div>
            </Col>
          </Row>
        </TabPane>

        <TabPane tabId="7">
          <Row>
            <Col sm="12">
              <div className="card"><div style={{padding:"15px",minHeight:"764px"}}><StudentsNotes id={props.id} type={1} /></div></div>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
}

export default StudentsTabs;