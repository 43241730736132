
export default class CountStatsVM {
    constructor() {

    }

    public subjectCountYtd: number = 0;
    public classCountYtd: number = 0;
}

