export default class ReactSelectOption {
    constructor() {

    }

    public label: string = "";
    public value: number = 0;
    public other:string = "";
}

