import React, {useEffect, useState} from 'react';
import Example from './ChartTest';
import PieChartExample from './PieChartExample';
import BarChartExample from './BarChartExample';
import axios from 'axios';

import Class from '../models/Class';
import SubjectPercentageVM from '../models/SubjectPercentageVM';
import TeachersUnmarkedClassCountVM from '../models/TeachersUnmarkedClassCountVM';
import CountStatsVM from '../models/CountStatsVM';
import Student from '../models/Student';

import ClassMonthlyCountVM from '../models/ClassMonthlyCountVM';
import StudentMonthlyCountVM from '../models/StudentMonthlyCountVM';
import HighLevelStatsVM from '../models/HighLevelStatsVM';


import CountUp from 'react-countup'; 
import MarkClassesModal from '../modals/MarkClassesModal';
import CheckBoxRound from '../components/common/CheckBoxRound';

import Pill from '../components/common/Pill';

import HomePageCard from '../components/common/HomePageCard';
import Home from '../components/Home';
import ClassStatusBadge from '../components/badges/ClassStatusBadge';
import SubjectBadge from '../components/badges/SubjectBadge';

import FormService from '../services/formService';
import Nationality from '../models/Nationality';

import postal from 'postal';



interface IProps {
    history:any
}


const CompanyAdminHomePage = (props:IProps) => {
    const [thisWeeksClasses, setThisWeeksClasses] = useState(new Array<Class>());
    const [subjectPercentages, setSubjectPercentages] = useState(new Array<SubjectPercentageVM>());
    const [teachersUnmarkedClassCount, setTeachersUnmarkedClassCount] = useState(new Array<TeachersUnmarkedClassCountVM>());
    const [classMonthlyCount, setClassMonthlyCount] = useState(new Array<ClassMonthlyCountVM>());
    const [countStats, setCountStats] = useState(new CountStatsVM());

    const [studentMonthlyCount, setStudentMonthlyCount] = useState(new Array<StudentMonthlyCountVM>());
    

    const [thisYearTotal, setThisYearTotal] = useState(0);
    const [lastYearTotal, setLastYearTotal] = useState(0);

    const [thisYearStudentTotal, setThisYearStudentTotal] = useState(0);
    const [lastYearStudentTotal, setLastYearStudentTotal] = useState(0);

    const [showMarkClassesModal, setShowMarkClassesModal] = useState(false);

    const [currentCourseStudents, setCurrentCourseStudents] = useState(new Array<Student>());
    const [currentSelectedClass, setCurrentSelectedClass] = useState(0);
    
    const [highLevelStats, setHighLevelStats] = useState(new HighLevelStatsVM());

    const [currentSelectedClassFilter, setCurrentSelectedClassFilter] = useState("D");

    const sidebarChannel = postal.channel("SideBar");


    const loadStaticData = async() => {
        const response = await axios.get<Array<Nationality>>("/api/StaticData/GetAllNationalities");
        FormService.setNationalityList(response.data);
    }

    

    const loadClassesForPeriod = async(period:string) => {
        const results = await axios.get<Array<Class>>(`/api/reports/GetClassesThisWeek?period=${period}`); 
        setThisWeeksClasses(results.data);
    }
    
    const loadThisWeeksClasses = async()=> {
        const resultTeachersUnmarkedClassCount = await axios.get<Array<TeachersUnmarkedClassCountVM>>(`/api/reports/GetTeachersUnmarkedClassCount`); 
        setTeachersUnmarkedClassCount(resultTeachersUnmarkedClassCount.data);

        loadClassesForPeriod(currentSelectedClassFilter);

        const resultsSubjectPercentages = await axios.get<Array<SubjectPercentageVM>>(`/api/reports/GetSubjectPercentages`); 
        setSubjectPercentages(resultsSubjectPercentages.data);


        const resultCountStats = await axios.get<CountStatsVM>(`/api/reports/GetCountStats`); 

        const responseClassMonthCount   = await axios.get<Array<ClassMonthlyCountVM>>(`/api/reports/GetClassMonthlyCount`);
        const responseHighLevelStats    = await axios.get<Array<HighLevelStatsVM>>(`/api/reports/GetHighLevelStats`);
        setHighLevelStats(responseHighLevelStats.data[0]);

        //alert(JSON.stringify(responseHighLevelStats.data[0]))

        //Calculate totals
        let thisYearTotalCount:number = 0;
        let lastYearTotalCount:number = 0;

        responseClassMonthCount.data && responseClassMonthCount.data.forEach(d=>{
            thisYearTotalCount += d.classCount2;
            lastYearTotalCount += d.classCount1;
        });

        let data = Array<any>();
        let i = 0;
        responseClassMonthCount.data && responseClassMonthCount.data.map(d => {
            if(i>4) data.push({name:d.monthShortName,classCount1:d.classCount1,classCount2:d.classCount2})
            i++;
        });


        setThisYearTotal(thisYearTotalCount);
        setLastYearTotal(lastYearTotalCount);
        setClassMonthlyCount(data);
        setCountStats(resultCountStats.data[0]);

        const responseStudentMonthlyCount = await axios.get<Array<StudentMonthlyCountVM>>(`/api/reports/GetStudentMonthlyCount`);

        let data2 = Array<any>();
        let j = 0;
        responseStudentMonthlyCount.data && responseStudentMonthlyCount.data.map(d => {
          if(j>4) data2.push({name:d.monthShortName,studentCount1:d.studentCount1,studentCount2:d.studentCount2})
          j++;
        });

        setStudentMonthlyCount(data2);

        //Calculate totals
        let thisYearStudentCount:number = 0;
        let lastYearStudentCount:number = 0;

        j=0;
        responseStudentMonthlyCount.data && responseStudentMonthlyCount.data.forEach(d=>{
            if(j>4){
                thisYearStudentCount += d.studentCount2;
                lastYearStudentCount += d.studentCount1;
            }

            j++;
        });

        setLastYearStudentTotal(lastYearStudentCount);
        setThisYearStudentTotal(thisYearStudentCount);
    }

    const ShowStudentForm = async(classId:number) => {
        if(classId){

            var response = await axios.get<Array<Student>>(`/api/students/GetStudentsByClassId?id=${classId}`);
            setCurrentCourseStudents(response.data);

            setShowMarkClassesModal(true);
            setCurrentSelectedClass(classId);
        }
    }


    //TODO: Move all these status look ups into a class of there own
  

    const classMarkedUpdate = () => {
        loadThisWeeksClasses();
    }

    const changeClassesShown = (period:string) => {
        setCurrentSelectedClassFilter(period);
        loadClassesForPeriod(period);
    }

    const getPeriodSelectorText = (period:string) => {
        if(period == "D") {
            return "Today";
        }
        if(period == "W") {
            return "This Week";
        }
        if(period == "M") {
            return "This Month";
        }
    }

    const redirectToCourses =(courseId:number) =>{
        props.history.push(`CoursesForm/${courseId}`);
        sidebarChannel.publish("changeUrl", "courses");
    }

    const redirectToTeachers =(teacherId:number) =>{
        props.history.push(`teachersForm/${teacherId}`)
        sidebarChannel.publish("changeUrl", "teachers");
    }


    useEffect(() => {
        loadThisWeeksClasses();
        window.scrollTo(0, 0);
        sidebarChannel.publish("changeUrl", "");
        loadStaticData();
    }, []);

    return (<>
            <div>
                <div className="row">
                    <div className="col-xl-4">
                        <div className="card" style={{height:"390px"}}>
                            <div className="card-body">
                                <div className="dropdown float-right">
                                    <a href="#" className="dropdown-toggle card-drop" data-toggle="dropdown"
                                        aria-expanded="false">
                                        <i className="zmdi zmdi-more-vert"></i>
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-right">
                                        <li><a href="#" className="dropdown-item">Action</a></li>
                                        <li><a href="#" className="dropdown-item">Another action</a></li>
                                        <li><a href="#" className="dropdown-item">Something else here</a></li>
                                        <li className="dropdown-divider"></li>
                                        <li><a href="#" className="dropdown-item">Separated link</a></li>
                                    </ul>
                                </div>

                                <h4 className="header-title" style={{paddingBottom:"5px"}}>Teachers With Unmarked Classes</h4>
                                {!teachersUnmarkedClassCount || teachersUnmarkedClassCount.length === 0 && <div style={{paddingTop:"15px"}}>All teachers up to date</div>}

                                <div id="NewScroller" className="table-responsive"  style={{height:"325px"}}>
                                    {teachersUnmarkedClassCount.map(t => (
                                        <div > 
                                            <div className="row" style={{width:"98%"}}  >
                                                <div className="col-xl-1" style={{minWidth:"40px"}} >
                                                    <div className="inbox-item-img">{(t.avatar) ? <img src={`assets/images/${t.avatar.replace('{size}','sm')}`}  alt="" /> : <img src="assets/images/profile-sm-default.jpg"  alt="" />}</div>
                                                </div>
                                                <div className="col-xl-6"  style={{minWidth:"185px"}}>
                                                    <div style={{paddingLeft:"14px"}} className="notify-details">
                                                        <strong style={{cursor: "pointer"}} onClick={() => props.history.push(`/classes?teacherId=${t.teacherId}&statusId=2`)}>{t.teacher} </strong><br/>
                                                        <Pill  value={t.unMarkedClasses} color="danger"  />
                                                        <span onClick={() => props.history.push(`/classes?teacherId=${t.teacherId}&statusId=2`)} className="mb-0 user-msg text-truncate" style={{cursor: "pointer",paddingLeft:"3px",paddingTop:"13px",fontSize:"12px"}}>Classes Unmarked</span>
                                                    </div>
                                                </div>
                                            </div><br/>
                                        </div>
                                    ))}
                                </div>

                                
                            </div>
                        </div>
                    </div>
                
                
                
                        <div className="col-xl-8">
                            <div className="card">
                                <div className="card-body">
                                    <div className="dropdown float-right">
                                        <a href="#" className="dropdown-toggle card-drop" data-toggle="dropdown"
                                            aria-expanded="false">
                                            <i className="zmdi zmdi-more-vert"></i>
                                        </a>
                                        <ul className="dropdown-menu dropdown-menu-right">
                                            <li><a href="#" className="dropdown-item">Action</a></li>
                                            <li><a href="#" className="dropdown-item">Another action</a></li>
                                            <li><a href="#" className="dropdown-item">Something else here</a></li>
                                            <li className="dropdown-divider"></li>
                                            <li><a href="#" className="dropdown-item">Separated link</a></li>
                                        </ul>
                                    </div>
                                    <span className="float-right">
                                        <table cellPadding={0} cellSpacing={0}>
                                            <tr>
                                                <td>Today</td>
                                                <td  align="center" >
                                                    <div style={{paddingLeft:"10px"}}><CheckBoxRound checked={(currentSelectedClassFilter==="D") ? true : false} onChange={() => changeClassesShown('D')} /></div> 
                                                </td>  
                                                <td style={{width:"24px"}}>&nbsp;</td>
                                                <td>Week</td>
                                                <td style={{padding:"2px"}} align="center" >
                                                    <div style={{paddingLeft:"10px"}}><CheckBoxRound checked={(currentSelectedClassFilter==="W") ? true : false} onChange={() => changeClassesShown('W')} /> </div>
                                                </td>  
                                                <td style={{width:"24px"}}>&nbsp;</td>
                                                <td>Month</td>
                                                <td style={{padding:"2px"}} align="center" >
                                                    <div style={{paddingLeft:"10px"}}><CheckBoxRound checked={(currentSelectedClassFilter==="M") ? true : false} onChange={() => changeClassesShown('M')} /> </div>
                                                </td>  
                                            </tr>
                                        </table>
                                        
                                    </span>
                                    <h4 className="header-title">Classes {getPeriodSelectorText(currentSelectedClassFilter)}</h4>

                                    <div id="NewScroller" className="table-responsive" style={{height:"310px", paddingTop:"10px"}}>
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th></th>
                                                <th>Start Date</th>
                                                <th style={{width:"300px"}}>Course</th>
                                                <th>Subject</th>
                                                <th>Teacher</th>
                                                <th>Status</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                {thisWeeksClasses.map((c,i) => (
                                                    <tr style={{height:"40px"}}>
                                                        <td><Pill value={i+1} color="pink" />  </td>
                                                        <td>{c.classDateString}</td>
                                                        <td style={{cursor:"pointer"}}  onClick={()=>  redirectToCourses(c.courseId)} >{c.course} </td>
                                                        <td>
                                                            <SubjectBadge color={c.subjectColor} text={c.subject + ' ' + c.level} />
                                                        </td>
                                                        <td style={{cursor:"pointer"}} onClick={() => redirectToTeachers(c.teacherId) }>{c.teacher}</td>
                                                        <td>
                                                            <ClassStatusBadge statusId={c.seriesStatusId}  onClick={() => ShowStudentForm(c.classId)} />
                                                        </td>
                                                    </tr>
                                                ))}

                                                {!thisWeeksClasses || thisWeeksClasses.length === 0 &&
                                                <tr>
                                                    <td colSpan={8} align="center">No classes this week</td>
                                                </tr>
                                        
                                                }

                                                <tr style={{height:"25px"}}>
                                                
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="row">

                        <div className="col-xl col-md-6">
                            <HomePageCard url="/courses?expiredClasses=true&statusId=2" count={highLevelStats.expiredTotal} text="COURSES EXPIRING / EXPIRED" history={props.history} icon={"mdi mdi-alarm-multiple  "}   />
                        </div>
    
                        <div className="col-xl col-md-6">
                            <HomePageCard url="/courses?remaining=3&statusId=-1&showOnlyActiveAndCompleted=True" count={highLevelStats.lessThanThreeClassesRemaining} text="COURSES ENDING / ENDED" history={props.history}   icon={"mdi mdi-calendar-alert"}  />
                        </div>

                        <div className="col-xl col-md-6">
                            <HomePageCard url="/classes?statusId=2" count={highLevelStats.unmarkedClassTotal} text="UNMARKED CLASSES" history={props.history} icon={"mdi mdi-account-alert"}   />
                        </div>
    
                        <div className="col-xl col-md-6">
                            <HomePageCard url="/courses?showClassCountErrors=True" count={highLevelStats.classCountErrorTotal} text="COURSES WITH TIME DISCREPANCIES" history={props.history} icon={"mdi mdi-circle-slice-7"}   />
                        </div>

                        <div className="col-xl col-md-6">
                            <HomePageCard url="/courses?statusId=1" count={highLevelStats.draftCourseTotal} text="DRAFT COURSES" history={props.history}  icon={"mdi mdi-progress-alert"}  />
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-xl-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="dropdown float-right">
                                        <a href="#" className="dropdown-toggle card-drop" data-toggle="dropdown"
                                            aria-expanded="false">
                                            <i className="zmdi zmdi-more-vert"></i>
                                        </a>
                                        <ul className="dropdown-menu dropdown-menu-right">
                                            <li><a href="#" className="dropdown-item">Action</a></li>
                                            <li><a href="#" className="dropdown-item">Another action</a></li>
                                            <li><a href="#" className="dropdown-item">Something else here</a></li>
                                            <li className="dropdown-divider"></li>
                                            <li><a href="#" className="dropdown-item">Separated link</a></li>
                                        </ul>
                                    </div>

                                    <h4 className="header-title">Students Joining per Month</h4>
                                    
                                    <div className="row text-center m-t-30">
                                        <div className="col-2"></div>
                                        <div className="col-3">
                                            <h3 data-plugin="counterup"><CountUp end={lastYearStudentTotal} className="countUp" /></h3>
                                            <p className="text-muted">Total Last Year</p>
                                        </div>
                                        <div className="col-2">&nbsp;</div>
                                        <div className="col-3">
                                            <h3 data-plugin="counterup"><CountUp end={thisYearStudentTotal} className="countUp" /></h3>
                                            <p className="text-muted">Total This Year</p>
                                        </div>
                                        <div className="col-1"></div>
                                    </div>
                                    <div className="row align-items-center justify-content-center" > <BarChartExample data={studentMonthlyCount} /></div>
                                </div>
                                <div style={{height:"24px"}}>&nbsp;</div>
                            </div>
                        </div>

                        <div className="col-xl-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="dropdown float-right">
                                        <a href="#" className="dropdown-toggle card-drop" data-toggle="dropdown"
                                            aria-expanded="false">
                                            <i className="zmdi zmdi-more-vert"></i>
                                        </a>
                                        <ul className="dropdown-menu dropdown-menu-right">
                                            <li><a href="#" className="dropdown-item">Action</a></li>
                                            <li><a href="#" className="dropdown-item">Another action</a></li>
                                            <li><a href="#" className="dropdown-item">Something else here</a></li>
                                            <li className="dropdown-divider"></li>
                                            <li><a href="#" className="dropdown-item">Separated link</a></li>
                                        </ul>
                                    </div>

                                    <h4 className="header-title">Subjects Breakdown (YTD)</h4>
                                    
                                    <div className="row text-center m-t-30">
                                        <div className="col-2"></div>
                                        <div className="col-3">
                                            <h3 data-plugin="counterup"><CountUp end={countStats.classCountYtd} className="countUp" /></h3>
                                            <p className="text-muted">Classes</p>
                                        </div>
                                        <div className="col-2">&nbsp;</div>
                                        <div className="col-3">
                                            <h3 data-plugin="counterup"><CountUp end={countStats.subjectCountYtd} className="countUp" /></h3>
                                            <p className="text-muted">Subjects</p>
                                        </div>
                                        <div className="col-1"></div>
                                    </div>
                                    
                                
                                    
                                    <div className="row align-items-center justify-content-center" ><PieChartExample data={subjectPercentages} /></div>
                                    <div style={{height:"24px"}}>&nbsp;</div>

                                </div>
                            </div>
                        </div>


                        <div className="col-xl-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="dropdown float-right">
                                        <a href="#" className="dropdown-toggle card-drop" data-toggle="dropdown"
                                            aria-expanded="false">
                                            <i className="zmdi zmdi-more-vert"></i>
                                        </a>
                                        <ul className="dropdown-menu dropdown-menu-right">
                                            <li><a href="#" className="dropdown-item">Action</a></li>
                                            <li><a href="#" className="dropdown-item">Another action</a></li>
                                            <li><a href="#" className="dropdown-item">Something else here</a></li>
                                            <li className="dropdown-divider"></li>
                                            <li><a href="#" className="dropdown-item">Separated link</a></li>
                                        </ul>
                                    </div>

                                    <h4 className="header-title">Classes Taking Place by Month</h4>
                                    
                                    <div className="row text-center m-t-30">
                                        <div className="col-2"></div>
                                        <div className="col-3">
                                            <h3 data-plugin="counterup"><CountUp end={lastYearTotal} className="countUp" /></h3>
                                            <p className="text-muted">Last Year</p>
                                        </div>
                                        <div className="col-2">&nbsp;</div>
                                        <div className="col-3">
                                            <h3 data-plugin="counterup"><CountUp end={thisYearTotal} className="countUp" /></h3>
                                            <p className="text-muted">This Year</p>
                                        </div>
                                        <div className="col-1"></div>
                                    </div>

                                    <div className="row align-items-center justify-content-center" ><Example data={classMonthlyCount} /></div>
                                    <div style={{height:"24px"}}>&nbsp;</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <MarkClassesModal hide={() => setShowMarkClassesModal(false)} classId={currentSelectedClass} show={showMarkClassesModal} students={currentCourseStudents} updateGridClassStatus={classMarkedUpdate} mode="M" />

            </>
        )
}

export default CompanyAdminHomePage;