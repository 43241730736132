import React, {useState, useEffect} from 'react';
import BottomNav from '../../teachers/BottomNav';
import axios from 'axios';
import UserService from '../../services/userService';

import ActionButton from '../../components/buttons/ActionButton';
import CancelButton from '../../components/buttons/CancelButton';

import { isMobile } from "react-device-detect";


import { Modal } from 'react-bootstrap';


import SettlementReportVM from '../../models/SettlementReportVM'; 
import Class from '../../models/Class'; 
import ClassWrapper from '../../models/ClassWrapper'; 

import PaymentPdfTemplate from '../../components/common/PaymentPdfTemplate';


import CurrencyFormater from '../../components/common/CurrencyFormater';

import _ from 'underscore';

import __ from 'lodash';

import { jsPDF } from "jspdf";



interface IProps {
    history:any,
    isComponent?:boolean;
}


const PaymentsMobile = (props:IProps) => {
    const [payments, setPayments] = useState(new Array<SettlementReportVM>());
    const [showPaymentsModal, setShowPaymentsModal] = useState(false);
    const [classes, setClasses] = useState(new Array<Class>());
    const [paymentTotal, setPamentTotal] = useState(0);
    const [currentPayment, setCurrentPayment ] = useState(new SettlementReportVM() )

    const loadPayments = async() => {
        let response = await axios.get<Array<SettlementReportVM>>(`/api/reports/GetSettlementReports?teacherId=${userService.getUser().roles[0].entityId}`);

        setPayments(response.data);
    }

    const userService = new UserService();

    

    const transitionToClasses = async(payment:SettlementReportVM) => {
        let url = `api/classes/GetByFilter?teacherId=${userService.getUser().roles[0].entityId}&statusId=6&paymentHeaderId=${payment.paymentHeaderId}`
        const classResults = await axios.get<ClassWrapper>(url); 

        let sortedClasses = _.sortBy(classResults.data.classes,'course');

        

        setClasses(sortedClasses);
        setShowPaymentsModal(true);
        calculatePaymentTotal(sortedClasses);
        setCurrentPayment(payment)
    }

    const calculatePaymentTotal = (classResults:Array<Class>) => {
        let paymentRunningTotal:number = 0;

        classResults  && classResults.map(c => {
            let toadd:number = __.divide(c.classLength,60) * +(c.teacherRate.toFixed(2));

            toadd = +(toadd.toFixed(2));
            
            paymentRunningTotal += toadd;

        });

        setPamentTotal(paymentRunningTotal);
    }

    const hide = () => {
        setShowPaymentsModal(!showPaymentsModal);
    }

    useEffect(() => {
        loadPayments();
        window.scrollTo(0, 0);
    }, []);

    let storedCourseName = "";

    const getCourseNameFromArray = (i:number) => {
        return classes[i].course;
    }

    const getCourseNameFromArrayMotion = (i:number) => {
        if(i+1 < classes.length){
            return classes[i+1].course;
        } else {
            return "";
        }
    }

    const getCoursePayementFromArrayMotion = (i:number) => {
        if(i+1 < classes.length){
            return classes[i+1].coursePaymentTotal;
        } else {
            return 0;
        }
    }

    const openPrintWindow = () => {
        window.open(`/reports/printpaymentreport?id=${currentPayment.paymentHeaderId}&teacherId=${userService.getUser().roles[0].entityId}&grandTotal=${paymentTotal}&periodEndDate=${currentPayment.periodEndDateString}&isTeacher=true`, "_blank", "menubar=no,location=no,height=1200,width=1200");
    }


    const printIt = () => {
        const doc = new jsPDF('p', 'pt', 'a4');
      
        doc.setFont("helvetica");
        doc.setFontSize(5);
      
        let ll = document.getElementById('my-table');
        
        if(ll){

            ll.style.visibility = 'visible';
        
            let yy = Object.assign(ll);
            var cln = ll.cloneNode(true);
            
            ll.style.visibility = 'hidden';

            // Header
            // https://codepen.io/kuznetsovvn/pen/vYLVGqG

            const pageCount = doc.internal.pages.length;
            
            doc.html(cln as any, {
                margin:[25,5,65,0],
               
                callback: function (doc) {
                    for(let i = 1; i <= pageCount; i++) {
                        //doc.setPage(2);
                        //doc.text("Jimbob", 40, 15);
                        
                    }
                            
                    doc.save(`Payment_Report_${currentPayment.periodEndDateString.split(" ").join("")}.pdf`)
                },
            })
        }
        
      }

    return (
        <>
            { 
            <div className="card"  style={{width:"100%",paddingTop:"4px"}}  >
                <div className="card-body" style={(!isMobile) ?  {height:"650px"} : {} }>
                    <h4 className="header-title m-t-0 m-b-30" style={(!isMobile) ? {paddingBottom:"25px"} : {}}>Settlement reports  </h4>
                    
                    {(payments && payments.length >0) && 
                        <div id="NewScroller" className="table-responsive" style={(!isMobile) ?  {height:"550px"} : {} }>
                            <table className="table table-hover">
                                <tr>
                                    <td ><strong>Date</strong></td>
                                    <td align="right" ><strong>Amount</strong></td>
                                    <td ></td>
                                </tr>
                                {payments.map(p => (
                                    <>
                                        

                                        <tr  onClick={() => transitionToClasses(p)} key={p.paymentHeaderId} >
                                            <td>{p.periodEndDateString}</td>
                                            <td align="right" >  <CurrencyFormater value={p.payment} /></td>
                                            <td align="center">
                                                <i className="ti-eye pink" style={{fontSize:"18px",cursor:"pointer"}} ></i>
                                            </td>
                                        </tr>
                                    </>
                                ))}
                                
                            </table>
                        </div>
                    }

                    {(!payments || payments.length === 0) &&
                        <div>
                            No settlement reports currently
                        </div>
                    }


                    

                </div>
            </div>

            }

            {!props.isComponent && <BottomNav history={props.history} currentTab={-1} />}


            <Modal show={showPaymentsModal}  dialogClassName={(!isMobile) ? "modal-750px" : "modal-350px"}  onHide={()=> hide()}>
                <Modal.Header closeButton style={{height:"85px", paddingBottom:"0px"}}>
                    <Modal.Title>Settlement report <br/>
                        <div style={{fontSize:"18px"}}>Period end date {currentPayment.periodEndDateString}</div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ paddingBottom:"0px",  paddingTop:"0px"}}>
                <div id="NewScroller" className="table-responsive" style={{height:"400px"}}>
                   
                    {!isMobile && 
                        <table className="table">
                            <tr>
                                <td style={{width:"125px"}}><strong>Class date</strong></td>
                                <td style={{width:"125px"}}><strong>Subject</strong></td>
                                <td style={{width:"125px"}}><strong>Start time</strong></td>
                                <td><strong>Duration</strong></td>
                                <td align="right" ><strong>Hourly rate</strong></td>
                                <td align="right"><strong>Class total</strong></td>
                            </tr>

                            {classes.map((c,i) => {
                                return (
                                <>

                                    {i===0 && <tr>
                                        <td colSpan={5}><strong>{getCourseNameFromArray(i)  }</strong></td>
                                        <td align="right"><strong><CurrencyFormater value={c.coursePaymentTotal} /></strong></td>
                                    </tr>}

                                    <tr key={c.classId}> 
                                        <td>{c.classDateString}</td>
                                        <td>{c.subject}</td>
                                        <td>{c.classDatesCombined}</td>
                                        <td>{c.classLength} mins</td>
                                        <td align="right"><CurrencyFormater value={c.teacherRate} /></td>
                                        <td align="right"><CurrencyFormater value={(c.classLength/60) * c.teacherRate} />   </td>
                                    </tr>
                                    {(c.course !==  getCourseNameFromArrayMotion(i)) && 
                                        <tr>
                                            <td colSpan={5}><strong>{getCourseNameFromArrayMotion(i)  }</strong></td>
                                            <td align="right">
                                                <strong>{getCoursePayementFromArrayMotion(i) !== 0 && <CurrencyFormater value={getCoursePayementFromArrayMotion(i)} />}</strong>
                                            </td>
                                        </tr>   
                                    }
                                </>
                                )
                            })}

                            <tr>
                                <td ></td>
                                <td ></td>
                                <td ></td>
                                <td ></td>
                                <td><strong>Total&nbsp;Payment</strong></td>
                                <td align="right"><strong><CurrencyFormater value={paymentTotal} /></strong></td>
                            </tr>
                        </table>
                    }


                    {isMobile && 
                        <table className="table">
                            <tr>
                                <td ><strong>Class date</strong></td>
                                <td ><strong>Class time</strong></td>
                                <td ><strong>Total</strong></td>
                            </tr>

                            {classes.map((c,i) => {
                                return (
                                <>

                                    {i===0 && <tr>
                                        <td colSpan={2}><strong>{getCourseNameFromArray(i)  }</strong></td>
                                        <td align="right"><strong><CurrencyFormater value={c.coursePaymentTotal} /></strong></td>
                                    </tr>}

                                    <tr key={c.classId}> 
                                        <td  style={{ whiteSpace: 'nowrap' }}>{c.classDateString} </td>
                                        <td  style={{ whiteSpace: 'nowrap' }}>{c.classDatesCombined}</td>
                                        <td align="right"><CurrencyFormater value={(c.classLength/60) * c.teacherRate} /></td>
                                    </tr>
                                    {(c.course !==  getCourseNameFromArrayMotion(i)) && 
                                        <tr>
                                            <td colSpan={2}><strong>{getCourseNameFromArrayMotion(i)  }</strong></td>
                                            <td align="right">
                                                <strong>{getCoursePayementFromArrayMotion(i) !== 0 && <CurrencyFormater value={getCoursePayementFromArrayMotion(i)} />}</strong>
                                            </td>
                                        </tr>   
                                    }
                                </>
                                )
                            })}

                            <tr>
                                <td></td>
                                <td><strong>Total payment</strong></td>
                                <td align="right" ><strong><CurrencyFormater value={paymentTotal} /></strong></td>
                            </tr>
                        </table>
                    }



                </div>
                    


                </Modal.Body>
                <Modal.Footer>
                    <CancelButton onClick={() => setShowPaymentsModal(false)} text="CLOSE"  />
                    {!isMobile && <ActionButton onClick={() => openPrintWindow()} text="PRINT"  />}
                </Modal.Footer>
            </Modal>

            <PaymentPdfTemplate isTeacherReport={true} classes={classes} paymentTotal={paymentTotal} periodEndDateString={currentPayment.periodEndDateString} />


            
        </>
    );
}

export default PaymentsMobile;