import React from 'react'

interface IProps {
    timeInMins:number;
    fontSize?:string
}

const getTimeRemaining = (result:string) => {
    let returnResult = '';

    if(result && result !==""){
        return 'Time remaining ' + result;
    } else {
        return <><span style={{color:"white"}}>Course completed</span></>;
    }
}

const getPillCss = (result:string) => {
    if(result && result !== ""){
        return "badge badge-yellow-highlight";
    } else {
        return "badge badge-class-status-marked";
    }
}

const TimeRemainingBadge = (props:IProps) => {
    
    let timeInMs = props.timeInMins * 60000;
    var result = "";
    var hours = Math.floor(timeInMs/(1000*60*60));  
    var mins = Math.floor(timeInMs/(1000*60))%60;

    if(hours > 0) {
        result = hours + "h";
    }
    if(mins > 0) {
        if(hours > 0){
            result += " ";    
        }
        result += mins + "m";
    }

    let fontSize:string = "10.8px"
    if(props.fontSize){
        fontSize = props.fontSize.toString() + "px";
    }

    return (
        <div>
            <span className={getPillCss(result)}><span style={{color: "rgb(0, 0, 0)",fontSize:fontSize  }}>{getTimeRemaining(result)} </span></span>
        </div>
    );
}

export default TimeRemainingBadge;