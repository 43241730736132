import React,{useEffect, useState} from 'react';
import axios from 'axios';
import ReactDatatable from 'react-datatable-ext';
import Company from '../../models/Company';

import Axios from 'axios';

import ActionButton from '../buttons/ActionButton';
import Confirmation from '../../modals/Confirmation';

import Pill from '../common/Pill';



interface IProps {
    history:any
}

const CompaniesGrid = (props:IProps) => {
    const [companies, setCompanies] = useState<Array<Company>>(new Array<Company>());
    const [currentCompany, setCurrentCompany] = useState<Company>(new Company());
    const [showDelConfirm, setShowDelConfirm] = useState(false);

    const deleteCompany = async(c: Company) => {
        setCurrentCompany(c);
        setShowDelConfirm(true);
    }
   
    async function fetchData() {
        var response = await axios.get<Array<Company>>(`/api/companies/getall?cb=${Date.now() + Math.random()}`);
        setCompanies(response.data);
    }  

    const confirmButton_Click = async() => {
        await Axios.get(`/api/companies/delete?id=${currentCompany.companyId}`)
        fetchData();
        setShowDelConfirm(false);
    }

    useEffect(() => {
        fetchData();
    }, []);
     
    return(
        <>
            <div>
                <div className="card">
                    <div className="card-body">
                        <div className="page-title-box" style={{paddingBottom:"25px"}}>
                            <div className="page-title-btn btn-group float-right">
                                <ActionButton text="ADD COMPANY" onClick={()=> props.history.push('/companiesform')} />
                            </div>
                            <h4 className="page-title">Companies - {companies.length} found </h4>
                        </div>

                            
                        <table className="table table table-hover m-0 " style={{backgroundColor:"white"}}>
                                <thead>
                                    <tr>
                                        <th style={{width:"75px"}}></th>
                                        <th>Name</th>
                                        <th>Address</th>
                                        <th>Town</th>
                                        <th>Postcode</th>
                                        <th style={{width:"25px"}}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {companies.map((c,i)=>(
                                    <tr>
                                        <td valign="top"><Pill value={i+1} color="pink" /></td>
                                        <td onClick={() => props.history.push(`companiesForm/${c.companyId}`) }>{c.name}</td>
                                        <td onClick={() => props.history.push(`companiesForm/${c.companyId}`) }>{c.address}</td>
                                        <td onClick={() => props.history.push(`companiesForm/${c.companyId}`) }>{c.town}</td>
                                        <td onClick={() => props.history.push(`companiesForm/${c.companyId}`) }>{c.postCode}</td>
                                        <td>

                                            {!c.inUse && <div  style={{float: "right" ,paddingTop:"6px", cursor: "pointer"}}><i className="ti-trash pink" style={{float: "right",fontSize: "20px"}} onClick={()=> deleteCompany(c) } ></i></div>}
                                        
                                        </td>
                                    </tr>
                                ))}

                                {!companies || companies.length === 0 && <tr><td colSpan={4} align="center">No companies currently</td></tr> } 
                                        
                                </tbody>
                            </table>

                    </div>
                </div>
            </div>
            <Confirmation showModal={showDelConfirm} hideModal={() => setShowDelConfirm(false)} confirmButton_Click={confirmButton_Click}  title="Confirm deletion" bodyText="Are you sure you wish to delete this company?" confirmText="YES"  />
        </>
        );
}

export default CompaniesGrid;