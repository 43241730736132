import React from "react";

interface Iprops {
    onClick:Function,
    text:string,
    width?:number,
    color?:string

}

const ActionButton = (props:Iprops) => {

    const getColor = ():string => {
        switch (props.color?.toUpperCase()) {
            case "PURPLE":
                return 'btn-primary-purple';
                break;
            default:
                return 'btn-primary';
        }
    }
    
    const getIcon = (text:string) => {
        switch(text) {
            case "ADD":
            case "ADD COURSE":
            case "ADD NOTE":
            case "ADD RATE":
            case "ADD STUDENT":
            case "ADD STUDENTS":
            case "ADD TEACHER":
            case "ADD COMPANY":
            case "ADD ROOM":
            case "CREATE CLASSES":
                return <i className="fas fa-plus mr-1"></i>
            break;
            case "ATTENDANCE REPORT":
            case "RAISE SETTLEMENT REPORT": 
                return <i className="fas fa-list" style={{paddingRight:"5px"}}></i>                
            break;
            
            default:
          }
    }

    return (
        <button className={`btn ${getColor()} waves-effect waves-light btn-sm`} style={{minWidth:"25px" ,fontSize:"12px", fontWeight:"bold", width:`${props.width}px`}} onClick={()=>props.onClick()}>
            {getIcon(props.text)}
            {props.text}
        </button>
    )
}

export default ActionButton;


