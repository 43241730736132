import React, { useState, useEffect} from 'react';

interface IProps {
    checked:boolean;
    onChange:Function
}

const CheckBoxRound = (props:IProps) => {
    return (
        <>
            <div style={{paddingTop:"5px", paddingLeft:"8px"}}  className="checkbox checkbox-circle checkbox-success" onClick={(r)=> props.onChange(r)}>
                <input id="checkbox7" type="checkbox"  checked={(props.checked) ? true : false}  />
                <label></label>
            </div>
        </>    
    )
}

export default CheckBoxRound;