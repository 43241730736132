import React from 'react';

import ClassAttendenceBadge from '../../components/badges/ClassAttendenceBadge';
import Class from '../../models/Class';

interface IProps {
    class:Class;
}

const ClassCard = (props:IProps) => {
    return (<>
    
        <div className="chat-card">
            
            <div className="card-body-mobile" >
                <div className="pink" style={{textTransform:"uppercase"}}><strong>C L A S S</strong></div>

                <div className="row" >
                    <div className="col-6">
                        <div style={{textTransform:"uppercase",color:"grey", fontFamily:"Karla", fontSize:"16px"}}><strong>{props.class.dayWeek} {props.class.classDateString}</strong>  </div>
                        
                    </div>
                    <div className="col-6">
                        <div style={{color:"#495057", textAlign:"right"}}>
                                <div  style={{textTransform:"uppercase",color:"grey",fontFamily:"Karla", fontSize:"16px"}}><strong>{props.class.classDatesCombined}</strong>  </div>
                        </div>
                    </div>
                </div>

                <div>{props.class.subject} {props.class.level}</div>
                <div>{props.class.definedAddress}</div>
                <div>{props.class.teacher}</div>
                <div><ClassAttendenceBadge statusId={props.class.classStudentStatusId} onClick={()=>{}} /> </div>
                
            </div>
        </div>
    </>)
}

export default ClassCard;