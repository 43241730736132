import React, { Component } from "react";
import TagSelector from '../common/TagSelector';
import DatePicker from "react-datepicker";
import TeachersTabs from './TeachersTabs';

const subjects = ['French','Spanish','German','Russian','Japanese'];

interface IProps {
  history: any,
  match: any
}

export default class TeachersEdit extends Component<IProps, null> {
  componentDidMount(){

  }
  
  render() {
    return (
      <>
        <TeachersTabs id={(this.props.match.params.id ? this.props.match.params.id : 0)} history={this.props.history} />
      </>
    );
  }
}
