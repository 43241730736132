import React from 'react';


interface IProps {
    changeWizardStage:Function;
    history:any,
}

const Form3Confirmation = (props:IProps) => {
    return (
    <>
        
        <div className="">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6 col-xl-5">
                        <div className="text-center pb-1">
                            <a href="index.html">
                                <span><img src="assets/images/logo.png" alt="" height="18" /></span>
                            </a>
                        </div>
                        <div className="card mt-4">
                            <div className="card-body p-4">
                                <div className="form-group mb-3">
                                    You have successfully registered, click below to log in
                                </div>
                                

                                <div className="form-group mb-4">
                                    <button className="btn btn-primary-pink btn-bordred btn-block waves-effect waves-light text-uppercase" onClick={()=>{ props.history.push('/login') }} >FINISH</button>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    </>);
}

export default Form3Confirmation;