import React, { useState, useEffect, Component } from "react";
import { Button,Modal } from 'react-bootstrap';

import {isMobile} from "react-device-detect";


import ActionButton from '../components/buttons/ActionButton';
import CancelButton from '../components/buttons/CancelButton';


interface IProps {
    showModal:boolean,
    hideModal:Function,
    confirmButton_Click:Function,
    title:string,
    bodyText:string,
    confirmText?:string
}

const Confirmation =  (props:IProps) =>  {
    return (
        <>
            <Modal show={props.showModal} onHide={()=> props.hideModal()} dialogClassName={(!isMobile) ? "modal-MMMpx" : "modal-350px"} centered >
                <Modal.Body>
                    <div style={{textAlign:"center", paddingBottom:"20px",paddingTop:"20px"}}><i className="ti-help-alt" style={{color:"#fe6271", fontSize:"72px",paddingTop:"15px"}}></i></div>

                    <div style={{textAlign:"center",fontSize:"27px", fontWeight:"bold", paddingBottom:"8px", margin:"0px"}} className="header-title m-t-0 m-b-30">{props.title}</div>

                    <div style={{textAlign:"center",fontSize:"22px",paddingBottom:"22px"}} className="header-title m-t-0 m-b-30">
                        {props.bodyText}
                    </div>

                    <div style={{textAlign:"right"}}>
                        <ActionButton onClick={() => props.confirmButton_Click()} text={(props.confirmText) ? props.confirmText : "SAVE"} />&nbsp;
                        <CancelButton onClick={() => props.hideModal()}  />                      
                    </div>
                </Modal.Body>
              
            </Modal>
        </>
    );
}

export default Confirmation;

