import React, { useState, useEffect, useRef, Component } from "react";
import axios from 'axios';
import { Modal } from 'react-bootstrap';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Wyiswyg from "./WysiwygEditor";
import Note from '../../models/Note';
import useSnackbar from 'react-snackbar-toast';

import ActionButton from '../buttons/ActionButton';
import CancelButton from '../buttons/CancelButton';

import Confirmation from  '../../modals/Confirmation';


interface IProps {
    id:number;
    type:number;
}

const StudentsNotes = (props:IProps) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const {addToast} = useSnackbar();

    const openToast = (message, option:any) => {
      addToast(message, option);  
    };
  
    const [notes, setNotes] = useState(new Array<Note>());
    const [currentNote, setCurrentNote] = useState(new Note());
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showAddNoteModal, setShowAddNoteModal] = useState(false);
    const [currentHtml, setCurrentHtml] = useState("");
    const [currentSelectedNoteId, setCurrentSelectedNoteId] = useState(0);

    const loadNotes = async() => {
        var response = await axios.get<Array<Note>>(`/api/notes/GetNotesByTeacherId?entityTypeId=${props.type}&entityId=${props.id}`);
        setNotes(response.data);
    }


    const saveNote = async() => {
        let saveNote = new Note();
        
        saveNote.entityId = parseInt(props.id.toString());
        saveNote.noteId = currentNote.noteId;
        saveNote.entityTypeId = props.type;
        saveNote.text = (currentHtml!="") ? currentHtml : currentNote.text;
        saveNote.title = currentNote.title;
        
        let updatedNote = await (await axios.post<Note>(`/api/notes/savenote`, saveNote)).data;

        let newArr = [...notes];
        newArr.push(updatedNote);

        setNotes(newArr);
    }

    const title_onChange = async (e:any) => {
        let titleText = e.target.value;
        let mainText = (currentHtml!="") ? currentHtml : currentNote.text;

        setCurrentNote({ ...currentNote, title: titleText, text:mainText});
    } 

    const clearFormat = () => {
        setCurrentNote({ ...currentNote, title: "", text:"", noteId:0});
    }

    const deleteNote = (noteId:number) => {
       setShowConfirmation(true);
       setCurrentSelectedNoteId(noteId);
    }

    const hideModal =() =>{
       setShowConfirmation(false);
    }

    const confirmButton_Click = async() => {
        const response = await axios.get<Array<Note>>(`/api/notes/deletenotebyid?id=${currentNote.noteId}`);
        setNotes(response.data);
        setShowConfirmation(false);
    }

    const changeHandlerNote = (e:any) => {
        setCurrentHtml (e.target.value);
    }

    const closeAndClearModal = () => {
        setShowAddNoteModal(false);
        setCurrentHtml("");
    }

    const delete_onClick = (n:Note) => {
        setShowConfirmation(true);
        setCurrentNote(n);
    }

    useEffect(() => {
        loadNotes();
    }, []);

    return (
        <>
            <div className="page-title-box" >
                <div className="page-title-btn btn-group float-right" style={{paddingRight:"15px"}}>
                    <ActionButton onClick={() => setShowAddNoteModal(true)} text="ADD NOTE" />
                </div>
                <h4 className="page-title" style={{lineHeight:"0px", paddingTop:"10px",paddingBottom:"45px"}}>  </h4>
            </div>

            <div className="col" style={{paddingTop:"15px"}}>
                <div className="row">
                    <div className="col-12">
                        <div id="NewScroller" className="table-responsive" style={{height:"750px"}}>
                            <table className="table" style={{width:"99%"}}>
                                <thead>
                                    <tr>
                                        <th style={{width:"100px"}}>User</th>
                                        <th style={{width:"185px"}}>Date</th>
                                        <th >Note</th>
                                        {props.type === 3 && <th className="text-right">Delete</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {notes.map(n => (
                                        <tr>
                                            <td>{n.userFullName}</td>
                                            <td>{n.dateString}</td>
                                            <td  style={{whiteSpace: "pre-wrap"}}>{n.text}</td>
                                            {props.type === 3 && <td valign='middle'><i className="ti-trash pink" style={{float: "right", fontSize: "20px", cursor:"pointer"}} onClick={()=> {delete_onClick(n)}}></i></td>}
                                        </tr>
                                    ))}

                                    {!notes || notes.length === 0 &&  <tr>
                                        <td colSpan={4} align="center">No notes currently</td>
                                    </tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <Confirmation title={'Delete confirmation'} bodyText={'Are you sure you want to delete this note?'} showModal={showConfirmation} hideModal={hideModal} confirmButton_Click={confirmButton_Click} confirmText="YES" />

            <Modal onEntered={()=>{ textAreaRef.current?.focus() }} show={showAddNoteModal} onHide={() => setShowAddNoteModal(false)} dialogClassName="modal-YYYpx" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add note</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{height:"450px"}}> 
                    <textarea  className="form-control" ref={textAreaRef} style={{height:"400px"}} value={currentHtml} onChange={(e:any) => changeHandlerNote(e)}></textarea>
                </Modal.Body>
                <Modal.Footer>
                    <ActionButton onClick={() => {saveNote();closeAndClearModal()}} text="SAVE"/>
                    <CancelButton onClick={() => {setShowAddNoteModal(false)}} text={'CLOSE'} />
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default StudentsNotes;