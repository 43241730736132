import React, {useEffect,useState, useRef} from "react";
import axios from 'axios';
import TeachersMostAttendingStudentsVM from '../models/TeachersMostAttendingStudentsVM';
import Class from '../models/Class';
import ClassWrapper from '../models/ClassWrapper';
import Student from '../models/Student';

import UserService from '../services/userService';

import User from '../models/User';
import BottomNavigation from '../teachers/BottomNav';
import BottomNav from '../teachers/BottomNav';

import ClassStatusBadge from '../components/badges/ClassStatusBadge';


import TeacherPaymentVM from '../models/TeacherPaymentVM';
import MarkClassesModal from '../modals/MarkClassesModal';

import PaymentsMobile from '../teachers/components/PaymentsMobile';
import CheckVersion from "../components/infrastructure/CheckVersion";

import ClassesCardDeskTop from "../teachers/components/ClassesCardDeskTop";
import ClassesCardMobile from "../teachers/components/ClassesCardMobile";


import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
  } from "react-device-detect";

interface IProps {
    history:any
}

const TeacherHomePage = (props:IProps) => {
    const [thisWeeksClasses, setThisWeeksClasses] = useState(new Array<Class>());

    const [mostAttendedStudents, setMostAttendedStudents] = useState(new Array<TeachersMostAttendingStudentsVM>());
    const [teacherPayments, setTeacherPayments] = useState(new Array<TeacherPaymentVM>());
    const [totalOwed, setTotalOwed] = useState(0.00);
    const [classes, setClasses] = useState(new Array<Class>());

    const [currentClass, setCurrentClass] = useState(new Class());
    const [showMarkClasses, setShowMarkClasses] = useState(false);

    const [paymentTotal, setPaymentTotal] = useState(0.00);

    const [modelMode, setModelMode] = useState("M");
    
    const [isLoaded, setIsLoaded] = useState(false);

    const [refreshMarkedClasses, setRefreshMarkedClasses] = useState(1);

    let pageLoaded:boolean = false;;


    const loadData = async() => {

       

        let url = `api/classes/GetByFilter?teacherId=${userService.getUser().roles[0].entityId}&statusId=2`
        const classResults = await axios.get<ClassWrapper>(url); 
        setClasses(classResults.data.classes);

        const results = await axios.get<ClassWrapper>(`/api/classes/GetByFilter?timePeriod=W&subjectId=&teacherId=${userService.getUser().roles[0].entityId}&levelId=-1&statusId=-1&title=&excludePaid=True`); 
        setThisWeeksClasses(results.data.classes);

    
        pageLoaded=true;
        setIsLoaded(true);
    }

    const userService = new UserService();

    const openMarkingModal = (classToSet:Class) => {
        setCurrentClass(classToSet);
        setShowMarkClasses(true);
        setModelMode('M');

        setRefreshMarkedClasses(refreshMarkedClasses+1);
    }

    const refreshHomeScreen = () => {
        loadData();
        loadPaymentTotal();        
    } 

    useEffect(() => {
        loadData();
        window.scrollTo(0, 0);
    }, []);


    const loadPaymentTotal = async() => {
        let responseTP = await axios.get<Array<TeacherPaymentVM>>(`/api/reports/GetTeacherPaymentReportAuto?teacherIds=${userService.getUser().roles[0].entityId}`);
        setTeacherPayments(responseTP.data);


        if(responseTP.data && responseTP.data.length > 0 ){
            setTotalOwed(responseTP.data[0].paymentTotal);
        } else {
            setTotalOwed(0.00);
        }

    }

    const isInitialMount = useRef(true);

    const reschedule_OnClick = (classClicked:Class) => {
        setModelMode("R");
        setCurrentClass(classClicked);
        setShowMarkClasses(!showMarkClasses);
    }

    const status_OnClick = (classClicked:Class) => {
        setModelMode("M");
    }

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
         } else {
            loadPaymentTotal();
        }
    }, [classes]);


    return (
            <>
            
                <div className="card" >
                    <div className="card-body">

                        <div className="row">
                            <div className="col-4"><i className="fas fa-piggy-bank fa-5x"></i></div>
                            <div className="col-8" style={{textAlign:"right"}}>
                                <div className="pink" style={{fontSize:"38px", marginBottom:"-5px"}}><strong>£{(totalOwed).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')  }</strong></div>
                                <span style={{paddingRight:"5px"}}>earnings this month</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    {!isMobile && <div className="col-xl-4">
                        {classes && classes.length > 0 && <div className="card">
                            <div className="card-body" style={{paddingRight: "5px"}}>
                                <div className="dropdown float-right">
                                    <a href="#" className="dropdown-toggle card-drop" data-toggle="dropdown"
                                        aria-expanded="false">
                                        <i className="zmdi zmdi-more-vert"></i>
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-right">
                                        <li><a href="#" className="dropdown-item">Action</a></li>
                                        <li><a href="#" className="dropdown-item">Another action</a></li>
                                        <li><a href="#" className="dropdown-item">Something else here</a></li>
                                        <li className="dropdown-divider"></li>
                                        <li><a href="#" className="dropdown-item">Separated link</a></li>
                                    </ul>
                                </div>
                                <div className="row" style={{paddingBottom:"8px"}} >
                                    <div className="col-8">
                                        <div className="pink" style={{fontSize:"38px"}}>
                                            <h4 className="header-title m-t-0 m-b-30" style={{paddingTop:"10px"}}>Classes to mark </h4>
                                        </div>
                                    </div>
                                    <div className="col-4" style={{textAlign:"right"}}>
                                        <i className="ti-face-sad" style={{fontSize:"40px", paddingRight:"5px"}}></i>
                                    </div>
                                </div>
                                <div className="table-responsive" >
                                    <div id="NewScroller" className="table-responsive" style={(!isMobile) ?  {height:"564px", paddingTop:"10px"} : {paddingTop:"0px"}} >
                                        <ClassesCardDeskTop classes={classes} openMarkingModal={openMarkingModal} reschedule_OnClick={reschedule_OnClick} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        }

                        {isLoaded === true && classes.length === 0 && <div className="card">
                            <div className="card-body" >
                                <div className="dropdown float-right">
                                    <a href="#" className="dropdown-toggle card-drop" data-toggle="dropdown"
                                        aria-expanded="false">
                                        <i className="zmdi zmdi-more-vert"></i>
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-right">
                                        <li><a href="#" className="dropdown-item">Action</a></li>
                                        <li><a href="#" className="dropdown-item">Another action</a></li>
                                        <li><a href="#" className="dropdown-item">Something else here</a></li>
                                        <li className="dropdown-divider"></li>
                                        <li><a href="#" className="dropdown-item">Separated link</a></li>
                                    </ul>
                                </div>
                                <div style={(!isMobile) ?  {height:"618px"} : {}}>
                                    <div className="row" style={{paddingTop:"5px"}}>
                                        <div className="col-8">
                                            <div className="pink" style={{fontSize:"38px"}}>
                                                <h4 className="header-title m-t-0 m-b-30">Classes to mark </h4>
                                            </div>
                                            <span style={{paddingRight:"5px"}}>All up to date thanks!</span>

                                        </div>
                                        <div className="col-4" style={{textAlign:"right"}}>
                                            <i className="ti-face-smile" style={{fontSize:"45px"}}></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        }                   
                    </div>
                    }

                    {/* ------------------------------------------------------------------------------------------------------*/}

                    {isMobile && <div className="col-xl-4">
                        {classes && classes.length > 0 && <div className="row" style={{paddingBottom:"8px"}} >
                            <div className="col-8">
                                <div className="pink" style={{fontSize:"38px"}}>
                                    <h4 className="header-title m-t-0 m-b-30" style={{paddingTop:"10px", paddingLeft:"6px"}}>Classes to mark </h4>
                                </div>
                            </div>
                            <div className="col-4" style={{textAlign:"right"}}>
                                <i className="ti-face-sad" style={{fontSize:"40px", paddingRight:"5px"}}></i>
                            </div>
                        </div>
                        }

                        {classes && classes.length > 0 && 
                                
                            <div className="table-responsive" >
                                <div id="NewScroller" className="table-responsive" style={(!isMobile) ?  {height:"564px", paddingTop:"10px"} : {paddingTop:"0px"}} >
                                    <ClassesCardMobile classes={classes} openMarkingModal={openMarkingModal} reschedule_OnClick={reschedule_OnClick} />
                                </div>
                            </div>
                        
                        }

                        {isLoaded === true && classes.length === 0 && <div className="card">
                            <div className="card-body" >
                                <div className="dropdown float-right">
                                    <a href="#" className="dropdown-toggle card-drop" data-toggle="dropdown"
                                        aria-expanded="false">
                                        <i className="zmdi zmdi-more-vert"></i>
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-right">
                                        <li><a href="#" className="dropdown-item">Action</a></li>
                                        <li><a href="#" className="dropdown-item">Another action</a></li>
                                        <li><a href="#" className="dropdown-item">Something else here</a></li>
                                        <li className="dropdown-divider"></li>
                                        <li><a href="#" className="dropdown-item">Separated link</a></li>
                                    </ul>
                                </div>
                                <div style={(!isMobile) ?  {height:"618px"} : {}}>
                                    <div className="row" style={{paddingTop:"5px"}}>
                                        <div className="col-8">
                                            <div className="pink" style={{fontSize:"38px"}}>
                                                <h4 className="header-title m-t-0 m-b-30">Classes to mark </h4>
                                            </div>
                                            <span style={{paddingRight:"5px"}}>All up to date,  thanks {userService.getUser().firstName}!</span>

                                        </div>
                                        <div className="col-4" style={{textAlign:"right"}}>
                                            <i className="ti-face-smile" style={{fontSize:"45px"}}></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        }                   
                    </div>
                    }



                
                    <div className="col-xl-4">
                        
                        {!isMobile && isLoaded && <div className="card">
                            <div className="card-body" style={{paddingRight: "5px"}}>
                                <div className="dropdown float-right">
                                    <a href="#" className="dropdown-toggle card-drop" data-toggle="dropdown"
                                        aria-expanded="false">
                                        <i className="zmdi zmdi-more-vert"></i>
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-right">
                                        <li><a href="#" className="dropdown-item">Action</a></li>
                                        <li><a href="#" className="dropdown-item">Another action</a></li>
                                        <li><a href="#" className="dropdown-item">Something else here</a></li>
                                        <li className="dropdown-divider"></li>
                                        <li><a href="#" className="dropdown-item">Separated link</a></li>
                                    </ul>
                                </div>
                                
                                <h4 className="header-title m-t-0 m-b-30" style={{paddingTop:"8px"}}>Classes this week  </h4>


                               

                                <div className="table-responsive" style={{paddingTop:(isMobile) ? "10px" : "24px"}} >
                                    <div id="NewScroller" className="table-responsive" style={(!isMobile) ?  {height:"557px"} : {} }>
                                        <ClassesCardDeskTop classes={thisWeeksClasses} openMarkingModal={openMarkingModal} reschedule_OnClick={reschedule_OnClick}  />
                                        
                                        
                                    </div>
                                </div>
                            </div>

                        </div>}
                    </div>

                    <div className="col-xl-4" >
                        {isLoaded && <PaymentsMobile history={props.history} isComponent={true} />}
                    </div>

                </div>

                <BottomNav history={props.history} currentTab={0} />

                <MarkClassesModal currentClass={currentClass} classId={currentClass.classId} mode={modelMode} show={showMarkClasses} students={new Array<Student>()} hide={()=>{setShowMarkClasses(false)}} updateGridClassStatus={refreshHomeScreen} refreshMarkedClasses={refreshMarkedClasses} />
            </>
        );
}

export default TeacherHomePage;