import React, {useEffect, useState} from 'react';
import SchoolLogo from '../Schools/SchoolLogo';
import axios from 'axios';
import Course from '../../models/Course';
import Class from '../../models/Class';
import TimeFormatter from '../common/TimeFormatter';

import moment from 'moment';


interface IProps {
    courseId:number
}

const StudentAttendenceHeader = (props:IProps) => {
    const [course, setCourse] = useState(new Course());
    const [classes, setClasses] = useState(new Array<Class>());
    
    const getData = async() =>{
        const response = await axios.get<Course>(`/api/courses/GetById?id=${props.courseId}`);
        setCourse(response.data);

        const responseClass = await axios.get<Array<Class>>(`/api/classes/GetByCourseId?id=${props.courseId}`);
        setClasses(responseClass.data);
    }

    const formateDate = (dateToConvert:Date) => {

        //alert(moment(dateToConvert).format("DD/MM/YYYY"));

        let dateParts = moment(dateToConvert).format("DD/MM/YYYY").split('/');
        let newDate = new Date(parseInt(dateParts[2]), parseInt(dateParts[1]) - 1, parseInt(dateParts[0]))
        
        let dateConverted = moment(new Date(newDate)).format("DD MMM YYYY");


        return dateConverted.toString();
    }

    useEffect(() => {
        getData();
    }, []);

    return (
    <>
        <table style={{width:"950px",color:"grey", fontSize:"18px", paddingLeft:"105px"}}>
            <tr>
                <td><SchoolLogo /></td>
                <td align="right">
                    <div>                        
                        <header style={{color:"#c0c0c0",fontSize:"20px"}}>COURSE ATTENDANCE REPORT</header>
                        {course.company && <header style={{color:"#c0c0c0",fontSize:"20px"}}>{course.company}</header>}
                        <header style={{color:"#c0c0c0",fontSize:"20px"}}>{course.subject} {course.courseLevel}</header>
                        <header style={{color:"#c0c0c0",fontSize:"20px"}}>Start date {formateDate(course.startDate)} </header>
                        <header style={{color:"#c0c0c0",fontSize:"20px"}}>Total hours {/*course && course.totalClassLength / 60*/} <TimeFormatter value={course.totalClassLength} /></header>
                    </div>
                </td>
            </tr>
        </table>
        <br/><br/><br/>

    </>
  )
}

export default StudentAttendenceHeader