
export default class UserService  {
    public getUser = () => {
        const userString = localStorage.getItem('user');
        if(userString){
            return JSON.parse(userString);
        } else {
            return null;
        }
    }

    public clearUser = () => {
        localStorage.removeItem("user");
    }

    public hasRole(roleName:string){
        const user = this.getUser();

        if(!user){
            return false;
        }

        let roles = user.roles.filter(r => r.name===roleName);
        if(!roles){
            return false;
        }
        if(roles.length===1) {
            return true;
        } else {
            return false;
        }
    }

    public getEntityIdForRole(roleName:string){
        const user = this.getUser();

        if(!user){
            return 0;
        }

        let role = user.roles.find(r => r.name===roleName);
        if(role){
            return role.entityId;
        } else {
            return 0;
        }
    }

    public isUserLoggedIn = () => {
        const user = this.getUser();
        if(!user || user === "" || !user.token || user.token === ""){
            return false;
        } else {
            return true;
        }
    }

    public getFullName = ():string => {
        const user = this.getUser();
        if(user) {
            return user.firstName + " " + user.lastName;
        } else {
            return ""
        }
    }

    public getUserName= (): string => {
        const user = this.getUser();
        if (user) {
            return user.username;
        } else {
            return ""
        }
    }


    public getPrimaryRole = ():string => {
        const user = this.getUser();
        if(!user){
            return "";
        }
        const firstRole = user.roles[0]
        if(firstRole){
            return firstRole.name;
        } else {
            return "";
        }
    }
}


