import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import RoomsForm from './RoomsForm';
import Calandar from '../common/Calandar';

interface IProps {
  id:number,
  history:any
}

const TeachersTabs = (props:IProps) => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  return (
    <div style={{maxWidth:"1500px"}}>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => { toggle('1'); }}
          >
            Details
          </NavLink>
        </NavItem>
       
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '3' })}
            onClick={() => { toggle('3'); }}
          >
            Calendar
          </NavLink>
        </NavItem>
        
      </Nav>
      
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12">
              <div className="card" style={{paddingLeft:"30px",paddingTop:"30px"}}><RoomsForm id={props.id}/></div>
            </Col>
          </Row>
        </TabPane>
       
        <TabPane tabId="3">
          <Row>
            <Col sm="12">
              <div className="card" style={{padding:"15px"}}><Calandar type="R" id={props.id} history={props.history} /></div>
            </Col>
          </Row>
        </TabPane>
        
      </TabContent>
    </div>
  );
}

export default TeachersTabs;