import React from 'react'

interface IProps {
    label:string,
    placeHolder?:string,
    data:any,
    width:number,
    showPlease:boolean,
    changeFunc?: (e: any) => void,
    isError?: boolean,
    value?:any,
    name?: string,
    errorMessage?:string,
    pleaseSelectMessage?:string
}

const InputDropDown = (props:IProps) => {
    return (<>
        

        <div className="form-group row">
            <div style={{width:"125px",paddingTop:"6px",textAlign:"right", paddingRight:"30px"}}>
                <label >{props.label}</label>
            </div>
            <div style={{paddingTop:"0px"}}>
                
                <select  style={{ width: props.width }} onChange={props.changeFunc} name={props.name && props.name} className={`form-control ${props.isError ? "parsley-error" : ""}`}>
                    {props.showPlease && <option value="0" style={{color:"#A9A9A9"}}> {(props.pleaseSelectMessage) ? props.pleaseSelectMessage : "--- Please select ---" } </option>}
                    {props.data && props.data.map(d => <option selected={props.value == d.Value} value={d.Value}>{d.Text}</option>)}
                  </select>
                  {(props.isError===true) && <div style={{color:"#e96154"}}>{(props.errorMessage) && props.errorMessage}</div>}
            </div>
        </div>

        </>
    )
}

export default InputDropDown;