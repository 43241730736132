import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Class from '../../models/Class';
import ClassWrapper from '../../models/ClassWrapper';
import Student from '../../models/Student';
import Subject from '../../models/Subject';
import Teacher from '../../models/Teacher';
import CourseLevel from '../../models/CourseLevel';
import SubjectBadge from '../badges/SubjectBadge';
import Pill from '../common/Pill';
import ClassStatusBadge from '../badges/ClassStatusBadge';
import MarkClassesModal from '../../modals/MarkClassesModal';

import DatePicker, {registerLocale} from "react-datepicker";


import postal from 'postal';
import { time } from 'console';

import eu from "date-fns/locale/en-GB";

registerLocale('eu', eu);



interface IProps {
    history: any,
    match: any,
    location:any
  }
  const sidebarChannel = postal.channel("SideBar");

  const ClasseGrid = (props:IProps) => {

    const getQueryStringParam = (param:string) => {
        const query = new URLSearchParams(props.location.search);
        const token = query.get(param);
        if(token){
            return token;
        } else {
            return "";
        }
    }
    const getStatusInitalValue = (param:string) => {
        let statusId = getQueryStringParam(param);
        if(statusId === "" || isNaN(statusId  as any)){
            return -1
        } else {
            return parseInt(statusId as any);
        }
    }

    const [classes, setClasses] = useState(Array<Class>());
    const [staticSubjects, setStaticSubjects] = useState(Array<Subject>());
    const [timePeriod, setTimePeriod] = useState("");
    const [title, setTitle] = useState("");
    const [subject, setSubject] = useState("");
    const [teachers, setTeachers] = useState(Array<Teacher>());
    const [currentTeacherId, setCurrentTeacherId] = useState(getStatusInitalValue("teacherId"));
    const [staticLevels, setStaticLevels] = useState(Array<CourseLevel>());
    const [currentLevelId, setCurrentLevelId] = useState(-1);
    const [currentStatusId, setCurrentStatusId] = useState(getStatusInitalValue("statusId"));
    const [hasPageLoaded, setHasPageLoaded] = useState(false);
    const [showMarkClassesModal, setShowMarkClassesModal] = useState(false);
    const [currentSelectedClassId,setCurrentSelectedClassId]  = useState(0);
    const [totalStudentCount,setTotalStudentCount]  = useState(0);

    const [searchStartDate, setSearchStartDate] = useState("");
    const [searchEndDate, setSearchEndDate] = useState("");


    const resetSearch = () => {
        setTimePeriod("");
        setSubject("");
        setCurrentTeacherId(getStatusInitalValue("teacherId"));
        setCurrentLevelId(-1);
        setCurrentStatusId(getStatusInitalValue("statusId"));
        setTitle("");
    }

    const fetchClasses = async() => {
        let qs:string = "?";
        let studentCountResult:number = 0; 

        let timePeriodResult = timePeriod;

        timePeriodResult = timePeriod;

        qs += `timePeriod=${timePeriodResult}`;
        qs += `&subjectId=${subject}`;
        qs += `&teacherId=${currentTeacherId}`;
        qs += `&levelId=${currentLevelId}`;
        qs += `&statusId=${currentStatusId}`;
        qs += `&title=${title}`;
        
        qs += `&startDate=${searchStartDate}`;
        qs += `&endDate=${searchEndDate}`;

        let response = await axios.get<ClassWrapper>(`api/classes/GetByFilter${qs}`);

        response.data.classes.map(c => {
            studentCountResult += c.studentCount;
        });

        setTotalStudentCount(studentCountResult);

        setClasses(response.data.classes);
    }  

    useEffect(() => {
        fetchClasses();
        loadStaticData();
        setHasPageLoaded(true);
        sidebarChannel.publish("changeUrl", "classes");

        const sidebarChannelReq = postal.channel("SideBar");
        

        let sc = sidebarChannelReq.subscribe("pulseClasses", (newUrl:any) => {
            resetSearch();
            fetchClasses();
        });
        //Clean up on unmount event
        return () => {
            sc.unsubscribe();
        }
    }, []);

    

    useEffect(() => {
        if(hasPageLoaded){
            if(timePeriod!=='C') {
                fetchClasses();
               
            }        
        }
    }, [timePeriod]);

    useEffect(() => {
        if(hasPageLoaded){
            fetchClasses();
        }
    }, [subject]);

    useEffect(() => {
        if(hasPageLoaded){
            fetchClasses();
        }
    }, [title]);


    useEffect(() => {
        if(currentTeacherId !== -1 && hasPageLoaded){
            fetchClasses();
        }
    }, [currentTeacherId]);

    useEffect(() => {
        if(currentLevelId !== -1 && hasPageLoaded){
            fetchClasses();
        }
    }, [currentLevelId]);

    useEffect(() => {
        if(currentStatusId !== -1 && hasPageLoaded){
            fetchClasses();
        }
    }, [currentStatusId]);

    useEffect(() => {
        if(hasPageLoaded){
            if(searchStartDate !== "" && searchEndDate !== ""){
                fetchClasses();
                
            }
        }
    }, [searchStartDate]);

    useEffect(() => {
        if(hasPageLoaded){
            if(searchStartDate !== "" && searchEndDate !== ""){
                fetchClasses();
               
            }
        }
    }, [searchEndDate]);

    const getSeriesStatusId = (statusId:number) => {
        if(statusId === 0){
            return "No Students";
        }
        else if (statusId === 1) {
            return "Scheduled";
        } else if (statusId === 2){
            return "Requires marking";
        } else if (statusId === 3){
            return "Marked";
        }  
    } 

    const getSeriesStatusColor = (statusId:number) => {
        if (statusId === 2){
            return "primary";
        } else if (statusId === 1) {
            return "info";
        } else if (statusId === 3){
            return "success";
        }  else if (statusId === 0){
            return "danger";
        }   
    }
    
    const loadStaticData = async() => {
        let response = await axios.get<Array<Subject>>(`/api/subjects/GetAll`);
        setStaticSubjects(response.data);

        let teacherResponse = await axios.get<Array<Teacher>>(`/api/teachers/GetAll?status=1`);
        setTeachers(teacherResponse.data);

        let responseCourseLevel = await axios.get<Array<CourseLevel>>(`/api/courses/GetAllLevels`);
        setStaticLevels(responseCourseLevel.data);
    }

    const timePeriod_onChange = (e:any) => {
        setTimePeriod(e.target.value);
        resetTimeSearch('period');
    }

    const subject_onChange = (e:any) => {
        setSubject(e.target.value);      
    }

    const teacher_onChange = (e:any) => {
        setCurrentTeacherId(e.target.value);
    }
    
    const level_onChange = (e:any) => {
        setCurrentLevelId(e.target.value);
    }

    const status_onChange = (e:any) => {
        setCurrentStatusId(e.target.value);
    }

    const title_onChange = (e:any) => {
        setTitle(e.target.value);
    } 

    const classMarkedUpdate = () => {
        fetchClasses();
    }

    const class_Clicked = (classId:number) => {
        setCurrentSelectedClassId(classId);
        setShowMarkClassesModal(true);
    } 

    const changeStartDateHandler = (date:any) => {
        let newDate = (date.getDate()).toString() + "/" + ((date.getMonth() + 1).toString() + "/" + date.getFullYear().toString());

        setSearchStartDate(newDate);
    }

    const changeEndDateHandler = (date:any) => {
        let newDate = (date.getDate()).toString() + "/" + ((date.getMonth() + 1).toString() + "/" + date.getFullYear().toString());
        
        setSearchEndDate(newDate);
        resetTimeSearch('date');
    }


    const resetTimeSearch = (resetType:string) => {
        if(resetType === "period") {
            setSearchStartDate('');
            setSearchEndDate('');
        } else {
            setTimePeriod('C');
        }
    }

    const getSearchTitle = () => {
        
        if(getStatusInitalValue("statusId") === 2) {
            return "Unmarked classes";
        }

        return "Classes";
    }

    return (
        <>
            <div className="card"  >
                <div className="card-body">
                    {
                    <div>
                        <div className="row mb-0">

                            <div className="col-3" style={{paddingRight:"10px"}}></div>


                            <div className="col-2" style={{paddingRight:"20px"}} >
                                <div  style={{paddingBottom:"10px",paddingLeft:"10px"}}>
                                    <div>Course name</div>
                                    <input className="form-control" onChange={(e:any)=> title_onChange(e)}  />
                                </div>
                            </div> 
                            
                            <div className="col-2" style={{paddingRight:"20px"}} >
                                <div>
                                    <div>Subject</div>
                                    <select className="form-control" onChange={(e:any) => subject_onChange(e)} >
                                        <option value={-1} >All</option>
                                        {staticSubjects.map(s => (
                                            <option value={s.subjectId}>{s.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="col-1" style={{paddingRight:"20px"}}  >
                                <div>
                                    <div>Level</div>
                                    <select className="form-control" onChange={(e:any) => level_onChange(e)} >
                                        <option value={-1} >All</option>
                                        {staticLevels.map(l => (
                                            <option value={l.courseLevelId}>{l.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>  

                            <div className="col-2" style={{paddingLeft:"0px"}} >
                                <div style={{paddingBottom:"10px"}}>
                                    <div>Status</div>
                                    <select className="form-control" onChange={(e:any) => status_onChange(e)} >
                                        <option value="" selected={currentStatusId == -1}>All</option>
                                        <option value={1} selected={currentStatusId == 1}>Scheduled</option>
                                        <option value={2} selected={currentStatusId == 2}>Requires marking</option>
                                        <option value={3} selected={currentStatusId == 3}>Marked</option>
                                        <option value={6} selected={currentStatusId == 6}>Paid</option>
                                    </select>
                                </div>
                            </div>
  
                            <div className="col-2" style={{paddingRight:"20px"}} >
                                <div  >
                                    <div>Teacher</div>
                                    <select className="form-control" onChange={(e:any) => teacher_onChange(e)} >
                                        <option value={0} >All</option>
                                        {teachers.map(t => (
                                            <option value={t.teacherId} selected={t.teacherId === currentTeacherId }>{t.firstName + " " + t.lastName}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>  
                        </div>

                        <div className='row mb-0'>

                            <div className="col-8" ></div>

                            
                           
                            <div className="col-1" style={{paddingLeft:"0px"}}   >
                                <div>Start date</div>
                                <DatePicker calendarStartDay={1} locale="eu" style={{width:"65px"}} className={`form-control-datepicker-medium` }  dateFormat="dd/MM/yyyy" onChange={date => changeStartDateHandler(date)} value={searchStartDate} />
                            </div>  

                            <div className="col-1" style={{paddingLeft:"0px"}} >
                                <div>End date</div>
                                <DatePicker calendarStartDay={1} locale="eu" style={{width:"65px"}} className={`form-control-datepicker-medium` }  dateFormat="dd/MM/yyyy" onChange={date => changeEndDateHandler(date)} value={searchEndDate}  />
                            </div> 
                        
                            
                            <div className="col-2"  >
                                <div style={{paddingRight:"10px"}}>
                                    <div  style={{paddingBottom:"10px"}}>
                                        <div>Period</div>
                                        <select className="form-control" onChange={(e:any) => timePeriod_onChange(e)} >
                                            <option value="" selected={timePeriod === ''}>All</option>
                                            <option value={'D'}>Today</option>
                                            <option value={'W'}>This Week</option>
                                            <option value={'NW'}>Next Week</option>
                                            <option value={'M'}>This Month</option>
                                            <option value={'Y'}>This Year</option>
                                            <option value={'C'} selected={timePeriod === 'C'}>Custom</option>
                                        </select>
                                    </div>
                                </div>
                            </div>  
                            
                        </div>
                    </div>
                }
                </div>
            </div>

            <div className="card" >
                <div className="card-body">
                    <div className="page-title-box" >
                        <h4 className="page-title" style={{lineHeight:"0px", paddingTop:"10px",paddingBottom:"45px"}}>{getSearchTitle()} - {classes.length} found &nbsp;({totalStudentCount} students)</h4>
                    </div>
                    
                    <table className="table table table-hover m-0  " style={{backgroundColor:"white"}}>
                <thead>
                    <tr>
                        <th></th>
                        <th>Day</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Course name</th>
                        <th>Subject</th>
                        <th style={{width:"1%"}}>Level</th>
                        <th style={{textAlign:"right",width:"1%"}}>Students</th>
                        <th>Teacher</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {classes.map((c,i) => (
                        <tr>
                            <td valign="top"><Pill value={i+1} color="pink" /></td>
                            <td>{c.dayWeek}</td>
                            <td>{c.classDateString}</td>
                            <td>{c.classDatesCombined}</td>
                            <td> <div style={{cursor:"pointer"}} onClick={() => { props.history.push(`/CoursesForm/${c.courseId}`) }}>{c.course}</div></td>
                            <td>
                                <SubjectBadge color={c.subjectColor} text={c.subject} />
                            </td>
                            <td>{c.level}</td>
                            <td  style={{textAlign: "right"}}>{c.studentCount}</td>
                            <td> <div style={{cursor:"pointer"}} onClick={() => { props.history.push(`teachersForm/${c.teacherId}`) }}>  {c.teacher}</div></td>
                            <td>
                                <ClassStatusBadge statusId={c.seriesStatusId} onClick={()=> class_Clicked(c.classId)} />
                            </td>
                        </tr>
                    ))}

                    {!classes || classes.length === 0 &&
                        <tr>
                            <td colSpan={10} align="center">No classes found</td>
                        </tr>
                    }
                </tbody>
            </table>
                </div>
            </div>


            <MarkClassesModal hide={() => setShowMarkClassesModal(false)} classId={currentSelectedClassId} show={showMarkClassesModal} students={new Array<Student>()} mode="M" updateGridClassStatus={classMarkedUpdate} />


        </>
    )   
   
}

export default ClasseGrid;
