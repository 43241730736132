

import React, { useState, useEffect} from 'react';

import UserService from '../services/userService';
import AvatarBadge from './Avatar/AvatarBadge';
import ConfirmationModal from '../modals/Confirmation';

import { withRouter } from 'react-router-dom';

const HeaderBar = (props) => {
    
    const [showLogOutConf, setShowLogOutConf] = useState(false);


    const showProfile =() => {
        props.history.push('/teachers/ProfileMobile');
    }

    const showPayments = () => {
        props.history.push('/teachers/PaymentsMobile');
    }

    const logout = () => {
        setShowLogOutConf(true);
    }

    const logoutAction = () => {
        setShowLogOutConf(false);
        let userService = new UserService();
    
        userService.clearUser();
        document['location'].href = "/";  
    }
    

    return (
        <>
            <div className="navbar-custom">
                <ul className="list-unstyled topnav-menu float-right mb-0">
                    <li className="dropdown d-none d-lg-block">
                    
                       
                    </li>

                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <a className=" nav-user waves-effect waves-light">                        
                                        <span onClick={()=> showProfile()} style={{paddingTop:"5px"}}><AvatarBadge /></span>
                                    </a>
                                </td>
                                <td>
                                    <a className="nav-link right-bar-toggle waves-effect waves-light">
                                        <div style={{paddingTop:"4px", color:"white"}} onClick={()=> logout()}><i className="fas fa-sign-out-alt fa-2x"></i></div>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ul>

                <ul className="list-unstyled topnav-menu topnav-menu-left m-0">
                    <li>
                        <div style={{color:"#ffffff",paddingTop:"22px", paddingLeft:"20px", fontSize:"18px", fontFamily:"Karla", textTransform:"uppercase"}}>Succeed in Languages</div>
                    </li>
                </ul>
            </div>
            
            <ConfirmationModal  showModal={showLogOutConf} hideModal={()=>setShowLogOutConf(false)} confirmButton_Click={() => logoutAction()} title={"Are you sure?"} bodyText={"You want to log out of weCourse"} confirmText={"YES"}  />
        </>
    );
}

export default withRouter(HeaderBar);