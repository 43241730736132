import React, { useState, useEffect} from 'react';
import axios from 'axios';
import Student from '../../models/Student';
import WaveBox from '../common/WaveBox';
import {confirm} from 'react-bootstrap-confirmation';
import ReactDatatable from 'react-datatable-ext';
import Pagination from 'react-bootstrap/Pagination';
import SubjectBadge from '../badges/SubjectBadge';


const paginationConfig = {
    recordsPerPage:7
}


interface IProps {
    id:number,
    updateStudents: Function
}


const AddStudents = (props:IProps) => {

    const [interestedStudents, setInterestedStudents] = useState<Array<Student>>(new Array<Student>());
    const [enrolledStudents, setEnrolledStudents] = useState<Array<Student>>(new Array<Student>());
    const [waitingStudents, setWaitingStudents] = useState<Array<Student>>(new Array<Student>());

    const [studentDb, setStudentDb] = useState(new Array<Student>());
    const [filteredStudentDb, setFilteredStudentDb] = useState(new Array<Student>());
    const [selectedStudent, setSelectedStudent] = useState(new Array<Student>());
    const [filteredStudent, setfilteredStudent] = useState(new Array<Student>());

    const [studentsSearched, setStudentsSearched] = useState("");

    const [currentFilter, setCurrentFilter] = useState("");

    const [numberOfPages, setNumberOfPages] = useState(0);
    const [currentPage, setCurrentPage]     = useState(1);

    const [filteredStudentDbPag, setFilteredStudentDbPag] = useState(new Array<Student>());

    useEffect(() => {

        const loadStudents = async () => {
            let courseId = "";
            if (props.id) {
                courseId = props.id.toString();
            }

            var response = await axios.get<Array<Student>>(`/api/students/getall?CourseId=${courseId}`);


            setStudentDb(response.data);
            setFilteredStudentDb(response.data);


            let students = new Array<Student>();

            for(var i=0; i < paginationConfig.recordsPerPage; i++) {
                if(response.data[i]) {
                    students.push(response.data[i]);
                }
            }
    


            setFilteredStudentDbPag(students);

            let numberOfPages = Math.ceil(response.data.length / paginationConfig.recordsPerPage);
            setNumberOfPages(numberOfPages);


            if (props.id) {
                let response = await axios.get<Array<Student>>(`/api/students/GetStudentsByCourseId?id=${props.id}`);
                setfilteredStudent(response.data);
                loadLocalData(response.data);
            }
        }
        loadStudents();
    }, []);
    
    const loadLocalData = (data:Array<Student>) => {
        setSelectedStudent(data);

        let enrolled    = data.filter(d => d.statusId==1);
        let enquired    = data.filter(d => d.statusId==2);
        let waiting     = data.filter(d => d.statusId==3);


        setEnrolledStudents(enrolled);
        setInterestedStudents(enquired);
        setWaitingStudents(waiting);


        //props.updateStudents(enrolled);
    }

    const handleClose = () => {
        //setShow(!show);
    }

    const WaveBoxHandler = (record:Student, item) => {
        let studentsEmail: string = "";

        studentsEmail = record.email;
        let newEnrolledStudents: Array<Student> = enrolledStudents;
        let newInterestedStudents: Array<Student> = interestedStudents;
        let newWaitingStudents:Array<Student> = waitingStudents;

      
        let foundStudent = interestedStudents.find(s => s.email == studentsEmail);
        if (foundStudent && foundStudent != null) {
            let students = interestedStudents.filter(s => s.email != studentsEmail);
            newInterestedStudents = students; 
            setInterestedStudents(students);
        }

        foundStudent = enrolledStudents.find(s => s.email == studentsEmail);
        if (foundStudent && foundStudent != null) {
            let students = enrolledStudents.filter(s => s.email != studentsEmail);
            newEnrolledStudents = students; 
            setEnrolledStudents(students);
        }

        foundStudent = waitingStudents.find(s => s.email == studentsEmail);
        if (foundStudent && foundStudent != null) {
            let students = waitingStudents.filter(s => s.email != studentsEmail);
            newWaitingStudents = students;
            setWaitingStudents(students);
        }


        if (item == "Enrolled") {
            record.statusId = 1;
            let foundStudent = enrolledStudents.find(s => s.email == studentsEmail);



            if (foundStudent && foundStudent != null) {
                let students = enrolledStudents.filter(s => s.email != studentsEmail);
                newEnrolledStudents = students;
                setEnrolledStudents(students);
            } else {
                newEnrolledStudents = [...enrolledStudents, record]
                setEnrolledStudents(newEnrolledStudents);
            }

        } 




        

        if (item == "Enquired") {
            record.statusId = 2;
            let foundStudent = interestedStudents.find(s => s.email == studentsEmail);

            if (!foundStudent || foundStudent!=null){
                newInterestedStudents = [...interestedStudents, record];
                setInterestedStudents(newInterestedStudents);
            }
        }
        
        if (item == "Waiting") {
            record.statusId = 3;
            let foundStudent = waitingStudents.find(s => s.email == studentsEmail);

            if (!foundStudent || foundStudent == null) {
                newWaitingStudents = [...waitingStudents, record];
                setWaitingStudents(newWaitingStudents);
            }
        }
        if(item == ""){
            record.statusId = 0;
        }
        
        let updatedStudents = [...newEnrolledStudents, ...newInterestedStudents, ...newWaitingStudents ];
        props.updateStudents(updatedStudents);
    }

    const convertSubjectsToPills = (currentCourses:string) => {
        let courseArray = currentCourses.split(',');

        let pills = courseArray.map(f => (
            <>
                <SubjectBadge color={f.split('|')[1]} text={f.split('|')[0]} />&nbsp;
            </>
        ));
        return pills;
    }

    

    const getStatusFromId = (statusId: number) => {
        if (statusId === 1) {
            return "Enrolled";
        } else if (statusId === 2){
            return "Enquired";
        } else if (statusId === 3){
            return "Waiting";
        }
    }

    const getStatusSpanFromId = (statusId: number) => {
        if (statusId === 1) {
            return <span className="badge badge-success">Enrolled</span>;
        } else if (statusId === 2){
            return <span className="badge badge-primary">Enquired</span>;
        } else if (statusId === 3){
            return <span className="badge badge-info">Waiting</span>;
        }
    }

    const addStudents = () => {
        let data = [...waitingStudents, ...interestedStudents,...enrolledStudents ];

        setSelectedStudent(data);
        setfilteredStudent(data);

        props.updateStudents(data);

        //props.postCourse();

        //setShow(false);

        //props.incrementRefresh();

    }

    const filterSelectedStudents = (e: any) => {
        let students = selectedStudent;

        if (e == "Enrolled") {
            setfilteredStudent(students.filter(s => s.statusId == 1));
        } else if (e == "Enquired") {
            setfilteredStudent(students.filter(s => s.statusId == 2));
        } else if (e == "Waiting") {
            setfilteredStudent(students.filter(s => s.statusId == 3));
        } else if (e == "") {
            setfilteredStudent(students);
        }
        setCurrentFilter(e);

    }

    const filterStudents = (searchText:string) => {
        let  filteredStudents: Array<Student>;

        if (studentsSearched == "") {
            filteredStudents = studentDb;
        } else {
            filteredStudents = studentDb.filter(s => s.firstName.toLowerCase().indexOf(searchText.toLowerCase()) !=-1 || s.lastName.toLowerCase().indexOf(searchText.toLowerCase()) !=-1)
        }
        setFilteredStudentDb(filteredStudents);
        setFilteredStudentDbPag(filteredStudents);

        setStudentsSearched(searchText);

        repaginate(1,filteredStudents);
    }

    const deleteStudentFromCourse = async (studentId:number) => {
        
        const result = await confirm('Are you sure you wish to remove this student?');
        if(result){
            await axios.get(`/api/courses/DeleteStudentFromCourse?courseId=${props.id}&studentId=${studentId}`);

            let response = await axios.get<Array<Student>>(`/api/students/GetStudentsByCourseId?id=${props.id}`);
            setfilteredStudent(filteredStudent.filter(s => s.studentId != studentId));
            //props.incrementRefresh();
        }
    }

    const renderPaginationItem = () => {
        let output = new Array<any>();
        for(var i=1; i < numberOfPages + 1; i++) {
            if(i != currentPage){
                output.push(<Pagination.Item key={i} data-page={i}> {i} </Pagination.Item>)
            } else {
                output.push(<Pagination.Item key={i} data-page={i} active> {i} </Pagination.Item>)
            }
        }
        return output;
    }

    const handleClick = (e) => {
        const clickValue = e.target.offsetParent.getAttribute("data-page")
        ? e.target.offsetParent.getAttribute("data-page")
        : e.target.getAttribute("data-page");

        repaginate(parseInt(clickValue), filteredStudentDb);

        setCurrentPage(parseInt(clickValue));
    }

    const repaginate = (pageNub:number, studentsArray:Array<Student>) => {
        let students = new Array<Student>();

        let offset = ((pageNub-1) * paginationConfig.recordsPerPage);


        for(var i= offset; i < offset + paginationConfig.recordsPerPage; i++) {
            if(studentsArray[i]){
                students.push(studentsArray[i]);
            }
        }


        let numberOfPages = Math.ceil(studentsArray.length / paginationConfig.recordsPerPage);

        setNumberOfPages(numberOfPages);
        setFilteredStudentDbPag(students);
    }


    return (
        <>
            <div className="row">
                <div className="col">
                    <div className="page-title-box" style={{paddingBottom:"25px"}}>
                        <div className="page-title-btn btn-group float-right">
                            <div className="input-group">
                                <input type="search" id="example-input1-group2" name="example-input1-group2" className="form-control" value={studentsSearched} onChange={(e) => filterStudents(e.target.value)} placeholder="Search"  style={{width:"300px"}} />
                            </div>
                        </div>
                        <div>
                            <table>
                                <tr>
                                    <td style={{width:"100px"}}><strong>Enrolled</strong></td>
                                    <td>
                                    {enrolledStudents && enrolledStudents.map(s => (
                                        <><span className="badge badge-student-course-status-enrolled" style={{ padding: "5px" }}>{s.firstName + " " + s.lastName}</span>&nbsp;</>
                                    ))}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{width:"100px"}}><strong>Enquired</strong></td>
                                    <td>
                                    {interestedStudents && interestedStudents.map(s => (
                                        <><span className="badge badge-student-course-status-enquired" style={{ padding: "5px" }}>{s.firstName + " " + s.lastName}</span>&nbsp;</>
                                    ))}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{width:"100px"}}><strong>Waiting</strong></td>
                                    <td>
                                    {waitingStudents && waitingStudents.map(s => (
                                        <><span className="badge badge-student-course-status-waiting" style={{ padding: "5px" }}>{s.firstName + " " + s.lastName}</span>&nbsp;</>
                                    ))}
                                    </td>
                                </tr>
                                
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            
            <div style={{height:"465px"}}>                                
                <table className="table table table-hover m-0 tableLessPadding" style={{backgroundColor:"white", padding:"0px"}}>   
                    <thead>
                        <tr>
                            <th style={{width:"135px"}}>First name</th>
                            <th style={{width:"135px"}}>Last name</th>
                            <th style={{width:"200px"}}>Email</th>
                            <th style={{width:"215px"}}>Company</th>
                            <th style={{width:"300px"}}>Current Courses</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody >
                        {filteredStudentDbPag && filteredStudentDbPag.map(c =>(
                            <tr style={{height:"10px", padding:"0px"}}> 
                                <td>{c.firstName}  </td>
                                <td>{c.lastName}</td>
                                <td>{c.email}</td>
                                <td>{c.company}</td>
                                <td>
                                    <div > 
                                        {convertSubjectsToPills(c.currentCourses)}
                                    </div>
                                </td>
                                <td>
                                    <div style={{textAlign: "center"}}> 
                                        {!c.hasMarkedOrPaid &&  <WaveBox size="s" onClick={(item) => WaveBoxHandler(c, item)} allowEmpty={true} selected={getStatusFromId(c.statusId)} items={['Enrolled', 'Enquired', 'Waiting']} />}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div>
                
                <Pagination onClick={handleClick} style={{float:"right", paddingTop:"25px"}}>
                    <Pagination.First data-page={1} />
                    <Pagination.Prev data-page={(currentPage !== 1 ) ?  currentPage - 1 :  1 }   />
                    {renderPaginationItem().map(pag=>(
                        pag
                    ))}
                    <Pagination.Next  data-page={(currentPage < numberOfPages ) ?  currentPage + 1 :  numberOfPages} />
                    <Pagination.Last data-page={numberOfPages}  />
                </Pagination>
            </div>
        </>
    )
}

export default AddStudents;