import React from 'react'
import Class from '../../models/Class';
import ClassStatusBadge from '../../components/badges/ClassStatusBadge';

interface IProps {
    classes: Array<Class>;
    openMarkingModal: Function;
    reschedule_OnClick: Function;

}

const ClassesCardMobile = (props:IProps) => {
  return (
    <>

         {props.classes && props.classes.map(c =>(

         <div className="card">
                    <div className="card-header-mobile" >
                        <div className="row" >
                            <div className="col-6">
                                <h4 className="header-title m-t-0 m-b-30" style={{textTransform:"uppercase",color:"grey"}}>{c.dayWeek} {c.classDateString}  </h4>
                            </div>
                            <div className="col-6">
                                <div style={{color:"#495057", textAlign:"right"}}>
                                     <h4 className="header-title m-t-0 m-b-30" style={{textTransform:"uppercase",color:"grey"}}>{c.classDatesCombined}  </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body-mobile">
                    <div>{c.course}</div>
                    {c.courseDescription !== "" && <div>{c.courseDescription}</div>}
                    <div>{c.subject} {c.level}</div>
                    {c.company !== "" && <div>{c.company}</div>}
                    <div>{c.definedAddress}</div>
                        <div className="row" >
                            <div className="col" style={{paddingTop:"5px"}}  onClick={() => {props.openMarkingModal(c)} }>
                                <table cellPadding={0} cellSpacing="0"  >
                                    <tr>
                                        <td> {<ClassStatusBadge statusId={c.seriesStatusId} onClick={() => {} } />}</td>
                                        <td valign="middle"> 
                                            <div style={{height:"2px"}}></div>
                                            <div style={{paddingTop:"5px"}}>
                                                <i className="ti-angle-double-right"></i>
                                            </div>
                                        </td>
                                    </tr>
                                    </table>
                            </div>
                            {c.seriesStatusId !== 6 && c.seriesStatusId !== 3 && <div className="col" style={{textAlign:"right",cursor:"pointer"}} onClick={() => props.reschedule_OnClick(c) }>
                                <i className="ti-pencil pink" style={{fontSize: "20px"}}></i>   
                            </div>}
                        </div>
                    </div>
                </div>
            ))    
            }
    </>
    
  )
}

export default ClassesCardMobile;
