import React, {useState, useEffect, useRef} from 'react';

import Axios from 'axios';
import Company from "../../models/Company"

import AddCompany from './AddCompany';
import AddressGrid from './AddressGrid';

import ActionButton from '../buttons/ActionButton';
import CancelButton from '../buttons/CancelButton';
import { Modal } from 'react-bootstrap';

import Confirmation from '../../modals/Confirmation';

interface IProps {
    history:any,
    match:any
}

const CompaniesForm = (props:IProps) => {
    const [showAddCompanyForm, setShowAddCompanyForm] = useState(false);
    const [validationError, setValidationError] = useState(new Array<string>());
    const [company, setCompany] = useState<Company>(new Company());
    const [companyUpdated, setCompanyUpdated] = useState<Company>(new Company());
    const [addressId, setAddressId] = useState(0);
    const [mode, setMode] = useState("A");
    

    const setCompanyName = (name:string) => {
        setCompany({...company,name:name});
    }
    const setCompanyCode = (name: string) => {
        setCompany({ ...company, code: name });
    }
    

    const setaddress = (name:string) => {
        setCompany({...company,address:name});
    }
    const setTown = (name:string) => {
        setCompany({...company,town:name});
    }
    const setPostcode = (name:string) => {
        setCompany({...company,postCode:name});
    }



    const setPrimaryContactName = (name:string) => {
        setCompany({...company,primaryContactName:name});
    }
    const setPrimaryJobTitle = (name:string) => {
        setCompany({...company,primaryJobTitle:name});
    }
    const setPrimaryMobile = (name:string) => {
        setCompany({...company,primaryMobile:name});
    }
    const setPrimaryLandline = (name:string) => {
        setCompany({...company,primaryLandline:name});
    }
    const setPrimaryEmail = (name:string) => {
        setCompany({...company,primaryEmail:name});
    }


    const setSecondaryContactName = (name:string) => {
        setCompany({...company,secondaryContactName:name});
    }
    const setSecondaryJobTitle = (name:string) => {
        setCompany({...company,secondaryJobTitle:name});
    }
    const setSecondaryMobile = (name:string) => {
        setCompany({...company,secondaryMobile:name});
    }
    const setSecondaryLandline = (name:string) => {
        setCompany({...company,secondaryLandline:name});
    }
    const setSecondaryEmail = (name:string) => {
        setCompany({...company,secondaryEmail:name});
    }


    useEffect(() => {
        async function getData() {
            let response = await  Axios.get<Company>(`api/companies/getbyid?id=${props.match.params.id}`);
            setCompany(response.data)
        }
        if(props.match.params.id) {
            getData();
            setMode("E");
        } else{
            setMode("A");
        }
      }, []);

    const UpsertCompany = async() => {
        let isFormValid:boolean = true;
        let invalid = new Array<string>();


        if(companyUpdated.name === ""){
            isFormValid = false;
            invalid.push("companyName");
        }
        /*if(company.address1 === ""){
            isFormValid = false;
            invalid.push("address1");
        }
        if(company.address2 === ""){
            isFormValid = false;
            invalid.push("address2");
        }
        if(company.town === ""){
            isFormValid = false;
            invalid.push("town");
        }
        if(company.postCode === ""){
            isFormValid = false;
            invalid.push("postcode");
        }*/
        
        if(isFormValid){
            let companyToUpsert = {
                Name: companyUpdated.name,
                Code : companyUpdated.code,
                AddressId: companyUpdated.addressId,
                Address:companyUpdated.address,
                Town:companyUpdated.town,
                PostCode:companyUpdated.postCode,
                CompanyId: companyUpdated.companyId,
                Email: companyUpdated.primaryEmail,
                mobile:companyUpdated.primaryMobile,
                landline:companyUpdated.primaryLandline,
                PrimaryAddress:companyUpdated.primaryAddress,

                primaryContactId: companyUpdated.primaryContactId,
                primaryContactName:companyUpdated.primaryContactName,
                primaryJobTitle:companyUpdated.primaryJobTitle,
                primaryEmail:companyUpdated.primaryEmail,
                primaryMobile:companyUpdated.primaryMobile,
                primaryLandline: companyUpdated.primaryLandline,

                secondaryContactId: companyUpdated.secondaryContactId,
                secondaryContactName:companyUpdated.secondaryContactName,
                secondaryJobTitle:companyUpdated.secondaryJobTitle,
                secondaryEmail:companyUpdated.secondaryEmail,
                secondaryMobile:companyUpdated.secondaryMobile,
                secondaryLandline: companyUpdated.secondaryLandline
            };
    
            await Axios.post('/api/companies/upsert', companyToUpsert);
            props.history.push('/companies');
            
        } else {
            setValidationError(invalid);
        }
    }

    const isFieldValid = (fieldName:string):boolean =>{
        let error = validationError.find(s => s ==fieldName);
        if(error){
            return true;
        } else {
            return false;
        }
    }

    const updateCompany = (company:Company) => {
        setCompanyUpdated(company);
    }

    const openFormForEdit = (addressId:number) => {
        setShowAddCompanyForm(true);
        setAddressId(addressId);
    }

    return (
        <>
            <div style={{maxWidth:"1500px"}}>

                <div className="row">
                    <div className="col"></div>
                    <div className="col"></div>

                </div>

               
                <div className="row">
                    <div className="col-7">
                        <div className="card">

                            <div className="page-title-box" style={{paddingBottom:"5px",paddingTop:"10px",paddingLeft:"15px",paddingRight:"15px"}}>
                                <h4 className="header-title" style={{paddingTop:"12px",paddingBottom:"10px", paddingLeft:"30px"}}>Company</h4>
                            </div>


                            <AddCompany companyId={props.match.params.id} updateCompany={updateCompany} addressId={0} primaryAddress={true} mode={(props.match.params.id) ? "E" : "A"} />
                            <div className="form-group text-right m-b-0" style={{paddingBottom:"25px",paddingRight:"15px"}}>
                                <ActionButton onClick={() => UpsertCompany()} text="SAVE" /><span>&nbsp;</span>

                                <CancelButton onClick={()=> props.history.push(`/companies`)} />
                            </div>
                        </div>
                    </div>
                    <div className="col-5">
                        <div className="card">
                            <div className="page-title-box" style={{paddingBottom:"25px",paddingTop:"15px"}}>
                                <div className="page-title-btn btn-group float-right">
                                    <div style={{paddingRight:"8px"}}><ActionButton onClick={()=>{setAddressId(0); setMode("A"); setShowAddCompanyForm(true)}} text="ADD ADDRESS" /></div>
                                </div>
                                <h4 className="header-title" style={{paddingTop:"8px",paddingLeft:"12px"}}>Secondary addresses</h4>
                            </div>
                            <AddressGrid addressClick={openFormForEdit} companyId={props.match.params.id} />
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showAddCompanyForm} onHide={() => setShowAddCompanyForm(false)} dialogClassName="modal-750px">
                <Modal.Header closeButton>
                    <Modal.Title>Add address </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <AddCompany companyId={company.companyId} updateCompany={updateCompany} primaryAddress={false} addressId={addressId} mode={mode} />
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <ActionButton onClick={() => {  UpsertCompany() }} text="SAVE" />&nbsp;
                        <CancelButton onClick={() => { setShowAddCompanyForm(false) }}  />
                    </div>
                </Modal.Footer>
            </Modal>

            

            
        </>
    )
}

export default CompaniesForm;