import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { getDefaultSettings } from 'http2';
import Infra from '../../models/Infra';
import { isWindows } from 'react-device-detect';

interface IProps{
    updateVersionUpdated:Function;
}

const CheckVersion = (props:IProps) => {

    const [versionNumber, setVersionNumber] = useState<string>("");
    const [initialVersionNumber, SetInitialVersionNumber] = useState<string>("");
    const [showRefreshPannel, setShowRefreshPannel] = useState<boolean>(false);
    const [counter, setCounter] = useState<number>(1);

    /*
    1. Make web request to get the current version from DB

    2. Compaire with version in state.

    3. If version in state in blank then this must be the correct version
        Store the version number in state
    
    4. If different show mesaage with button to refresh
    
    5. Repoll DB
    
    */

    const getData = async() => {
        const response = await axios.get<string>(`/api/infra/GetVersionNumber`);
        let newVersionNumber:string = response.data;
        setCounter((c) => c + 1);


        if(!newVersionNumber || versionNumber === ""){
            setVersionNumber((n) => n=newVersionNumber);
 
        } else {
            if(newVersionNumber !== versionNumber){
                setShowRefreshPannel(b => b=true);
            }
        }

    }

    const loadInit = async() => {
        const response = await axios.get<string>(`/api/infra/GetVersionNumber`);
        SetInitialVersionNumber(response.data);
    }
    

    useEffect(() => {
        loadInit();

        setInterval(function(){ getData() }, 60000);
    }, []);
    

    return (
        <>
            { (initialVersionNumber!="" && versionNumber!="" ) && (initialVersionNumber !== versionNumber )  &&
                <>
                    {props.updateVersionUpdated()}
                    <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                        <h5 className="alert-heading">Application update!</h5>
                        <p>This application has been updated, please click the button below to get the latest version.</p>

                        <button type="button" className="btn btn-info waves-effect waves-light" onClick={()=>window.location.reload()}>UPDATE</button>
                    </div>
                    <br/><br/>
                </> 
            }
            
        </>
    );
}

export default CheckVersion;