import React, {useEffect, useState} from "react";
import BottomNav from '../students/components/BottomNav';
import Course from '../models/Course';
import ClassWrapper from '../models/ClassWrapper';
import Class from '../models/Class';
import ClassAttendenceBadge from '../components/badges/ClassAttendenceBadge';

import ClassCard from "../students/components/ClassCard";

import UserService from "../services/userService";

import axios from 'axios';

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
  } from "react-device-detect";

interface IProps {
    history:any
}


const StudentHomePage = (props:IProps) => {
    const [courses, setCourses] = useState(new Array<Course>());
    const [weeksClasses, setWeeksClasses] = useState(new Array<Class>());

    const userService = new UserService();

    const loadCourses = async() => {
        const response = await axios.get<Array<Course>>(`/api/courses/GetCoursesByStudentId?studentId=${userService.getUser().entityId}&onlyActive=true`);
        setCourses(response.data);
    }

    const loadThisWeeksClasses = async() => {
        const response = await axios.get<ClassWrapper>(`/api/classes/GetByFilter?studentId=${userService.getUser().entityId}&timePeriod=W`);
        setWeeksClasses(response.data.classes);
    }

    useEffect(() => {
        loadCourses();
        loadThisWeeksClasses();
    }, []);

    return (<>
        <div>
            <div className="card" >
                <div className="card-body" >

                    <div className="row">
                        <div className="col-4"><i className="fas fa-pastafarianism pink" style={{fontSize:"55px"}}></i></div>
                        <div className="col-8" style={{textAlign:"right"}}>
                            <div style={{paddingRight:"25px", fontSize:"22px", paddingTop:"15px"}}>This weeks classes</div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="row">
                <div className="col-xl-4">
                    {weeksClasses.map(c => (
                        <ClassCard class={c} />
                    ))}
                </div>
                <div className="col-xl-4">3</div>
                <div className="col-xl-4">
                    {courses.map(c => (
                        <div className="card" key={c.courseId} >
                            <div className="card-header-mobile" >
                                <div className="row" >
                                    <div className="col-12">
                                        <h4 className="header-title m-t-0 m-b-30" style={{textTransform:"uppercase",color:"grey"}}>{c.title}  </h4>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body-mobile">
                                <div>{c.subject} {c.courseLevel}</div>
                                <div>{c.company}</div>
                                <div>{c.definedAddress}</div>
                                <div>Start date: {c.startDateString} </div>
                                <div>{c.totalClasses} classes x {c.classLengthTemplate} mins</div>
                                <div>{c.duration - c.takenPlace} classes remaining </div>
                                

                                <div className="row" >
                                    <div className="col" style={{paddingTop:"5px"}}>    
                                        <div >
                                            
                                        </div>
                                    </div>
                                    {<div className="col" style={{textAlign:"right",cursor:"pointer"}}>
                                        <i className="ti-calendar pink" style={{fontSize: "20px", paddingRight:"15px"}}  onClick={() => { } }></i>   
                                        <i className="ti-user pink" style={{fontSize: "20px"}} onClick={()=> { }  }></i>   
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                
            </div>


            

            <h4>Current Courses</h4>

            


            <BottomNav  history={props.history} currentTab={0} />
        </div></>
        );
}

export default StudentHomePage;