import React, {useEffect} from 'react';
import CurrencyFormater from './CurrencyFormater';

import SchoolLogo from '../Schools/SchoolLogo';
import Class from '../../models/Class'; 


interface IProps {
    periodEndDateString:string;
    paymentTotal:number;
    classes:Array<Class>;
    isTeacherReport:boolean;
}

const PaymentPdfTemplate = (props:IProps) => {

   let count = 0;
   let pageCount = 1;

   useEffect(() => {
        let body = document.getElementById('printbody');
        if(body){
            body.className='whiteBackgroundOveride';
        }
    }, []);

   const getCourseNameFromArrayMotion = (i:number) => {
        if(i+1 < props.classes.length){
            return props.classes[i+1].course;
        } else {
            return "";
        }
    }

    const getTeacherNameFromClassMotion = (i:number) => {
        if(i+1 < props.classes.length && !props.isTeacherReport){
            return props.classes[i+1].teacher;
        } else {
            return "";
        }
    }

    const getCoursePayementFromArrayMotion = (i:number) => {
        if(i+1 < props.classes.length){
            return props.classes[i+1].coursePaymentTotal;
        } else {
            return 0;
        }
    }

    const getCourseNameFromArray = (i:number) => {
        return props.classes[i].course;
    }

    const getTeacherName = ():string => {
        if(!props.isTeacherReport){
            return "";
        }
        let teacherName = "";

        if(props.classes.length>0){
            teacherName = props.classes[0].teacher;
            if(teacherName) {
                teacherName = teacherName.toUpperCase();
             }
        }
        
        return teacherName;
    }
   


    return (
      


            <div  className='whiteFullBackground' id={`my-table`} style={{paddingLeft:"105px",paddingTop:"15px",visibility:"hidden"}}   >
                

                <table style={{width:"850px",color:"grey", fontSize:"18px", paddingLeft:"15px"}}>
                    <tr>
                        <td><SchoolLogo /></td>
                        <td align="right">
                            <div>                        
                                <header style={{color:"#c0c0c0",fontSize:"20px"}}>SETTLEMENT REPORT {getTeacherName()}</header>
                                <header style={{color:"#c0c0c0",fontSize:"20px"}}>PERIOD END DATE {props.periodEndDateString}</header>
                                <header style={{color:"#c0c0c0",fontSize:"20px"}}>TOTAL PAYMENT <CurrencyFormater value={props.paymentTotal} /></header>
                            </div>
                        </td>
                    </tr>
                </table>

                <br/><br/>

                <table  id="hiddenTable" style={{width:"850px",color:"grey", fontSize:"18px", paddingLeft:"15px"}} >
                    <tr>
                        <td style={{color:"black",paddingTop:"15px"}}>CLASS DATE</td>
                        <td style={{color:"black",paddingTop:"15px", width:"125px"}}>SUBJECT</td>
                        <td style={{color:"black",paddingTop:"15px"}}>TIME</td>
                        <td align="right" style={{color:"black",paddingTop:"15px"}}>DURATION</td>
                        <td style={{width:"25px"}}></td>
                        <td align="right" style={{color:"black",paddingTop:"15px"}}>HOURLY RATE</td>
                        <td align="right" style={{color:"black",paddingTop:"15px"}}>CLASS TOTAL</td>
                    </tr>

                    {props.classes.map((c,i) => {
                        let addBlank = false;
                        count += 1;
                        if(c.course !==  getCourseNameFromArrayMotion(i)) {
                            count = count + 3;
                        }
                        //Cut off number needs to be different if we are on the first page or not
                        let incrementor = 0;
                        if(pageCount === 1) {
                            incrementor = 30;
                        } else {
                            incrementor = 38;
                        }
                        if (count > incrementor) {
                            addBlank = false;
                            count = 0;
                            pageCount++;
                        }

                        return (
                        <>

                            {i===0 && 
                            <tr>

                                <td colSpan={6} style={{color:"black",paddingTop:"25px", borderBottom:"1px solid #c0c0c0"}}>{getTeacherNameFromClassMotion(i)} {getCourseNameFromArray(i)  }</td>
                                <td align="right" style={{color:"black",paddingTop:"25px",borderBottom:"1px solid #c0c0c0"}}><CurrencyFormater value={c.coursePaymentTotal} /></td>
                            </tr>}

                            <tr key={c.classId}> 
                                <td style={{color:"#c0c0c0"}}>{c.classDateString} </td>
                                <td style={{color:"#c0c0c0"}}>{c.subject}</td>
                                <td style={{color:"#c0c0c0"}}>{c.classDatesCombined}</td>
                                <td style={{color:"#c0c0c0"}} align="right">{c.classLength} mins</td>
                                <td style={{width:"25px"}}></td>
 
                                <td style={{color:"#c0c0c0"}} align="right"><CurrencyFormater value={c.teacherRate} /></td>
                                <td style={{color:"#c0c0c0"}} align="right"><CurrencyFormater value={(c.classLength/60) * c.teacherRate} />   </td>
                            </tr>
                            {(c.course !==  getCourseNameFromArrayMotion(i)) && 
                                <tr>
                                    <td colSpan={6} style={{color:"black",paddingTop:"25px", borderBottom:"1px solid #c0c0c0"}}> {getTeacherNameFromClassMotion(i)} {(getTeacherNameFromClassMotion(i) !=='') ? ' - ' : '' } {getCourseNameFromArrayMotion(i)  }</td>
                                    <td align="right" style={{color:"black",paddingTop:"25px", borderBottom:"1px solid #c0c0c0"}}> {getCoursePayementFromArrayMotion(i) !== 0 && <CurrencyFormater value={getCoursePayementFromArrayMotion(i)} />}</td>
                                </tr>   
                            }

                            {addBlank && 
                                <>
                                    <tr>
                                        <td style={{height:"100px"}}>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td style={{color:"black",paddingTop:"15px"}}>CLASS DATE</td>
                                        <td style={{color:"black",paddingTop:"15px", width:"125px"}}>SUBJECT</td>
                                        <td style={{color:"black",paddingTop:"15px"}}>TIME</td>
                                        <td align="right" style={{color:"black",paddingTop:"15px"}}>DURATION</td>
                                        <td style={{width:"25px"}}></td>
                                        <td align="right" style={{color:"black",paddingTop:"15px"}}>HOURLY RATE</td>
                                        <td align="right" style={{color:"black",paddingTop:"15px"}}>CLASS TOTAL</td>
                                    </tr>
                                    <tr>
                                        <td style={{height:"15px"}}>&nbsp;</td>
                                    </tr>
                                </>
                            }
                        </>
                        )
                    })}
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colSpan={3} align="right" style={{color:"black"}}>Total payment</td>
                        <td align="right" style={{color:"black"}}><CurrencyFormater value={props.paymentTotal} /></td>
                    </tr>
                   
                </table>
            </div>
    

    )
}

export default PaymentPdfTemplate;