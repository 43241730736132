import React from 'react';

interface IProps {
    statusId:number;
    onClick:Function
    allowClick:boolean;
}

const getStatusName = (statusId:number) => {
    switch(statusId) {
        case 1:
          return <span className={`badge badge-course-status-draft`}>Draft</span>;
        case 2:
            return <span className={`badge badge-course-status-active`} >Active</span>;
        case 3:
            return <span className={`badge badge-course-status-completed`}>Completed</span>;
        case 4:
            return <span className={`badge badge-course-status-archived`}>Archived</span>;
        case 5:
            return <span>Suspended</span>;
            default:
          return <span></span>;
      }
}

const CourseStatusBadge = (props:IProps) => {
    if(props.allowClick){
        return <><a style={{cursor: "pointer"}}  onClick={() => props.onClick()}>{getStatusName(props.statusId)}</a></>
    } else {
        return <>{getStatusName(props.statusId)}</>
    }
}

export default CourseStatusBadge;
