import Student from './Student';
import Class from './Class';

export default class Course {
    constructor() {
        this.students = new Array<Student>();
    }

    public courseId: number = 0;
    public parentId: number = 0;
    public uniqueIdentifier:string = "";
    public title: string
    public description: string 
    public companyId: number = 0;
    public company: string;
    public companyCode: string;
    
    public addressId: number = 0;
    public address: string;

    public definedAddress: string;

    public private: boolean= false;
    public typeId: number = 0;
    public courseType: string ;
    public courseLevelId: number = 0;
    public courseLevel: string;
    public maxNumberStudents: number = 0;
    public minNumberStudents: number = 0;
    public startDate: Date;
    public endDate: Date;
    public expiringDate: Date;
    public startDateString: string;
    public endDateString: string;
    public expiringDateString:string;
    public subjectId: number = 0;
    public subject: string;
    public subjectColor: string;

    public price: number = 0.0;

    public enrolledStudents: number;
    public enquiredStudents: number;
    public waitingStudents: number;

    public percentageComplete: number;
    public weekLength: number;
    public takenPlace: number;
    public totalClasses: number;
    public duration:number = 0;
    public totalClassLength:number = 0;

    public students: Array<Student>;
    public classes: Array<Class>;
    public isValid: boolean;

    public courseStatus: string;
    public courseStatusId: number;
    public schoolId: number;


    public sponsored:boolean;
    public virtual: boolean;

    public classesPending: boolean;
    public classesUnmarked: boolean;

    public classLengthTemplate: number = 0;
    public durationTemplate: number = 0;

    public timeStatusId: number;

    public teacher:string;
    public teacherId:number;

    public hasClone:boolean;

    public minsRemaining:number;
    public hasUnMarkedClasses:boolean;

    public markedCount:number = 0;

    
}

