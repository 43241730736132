export default class TeachersRate {
    constructor() {

    }

    public teacherRateId: number = 0;
    public teacherId: number = 0;
    public rate: number = 0;
    public description: string = "";
    public timeUnitId: number = 0;
    public inUse: boolean;
    public active: boolean;
}

