import React, {useState, useEffect} from 'react';
import CoursesTabs from './CoursesTabs';

interface IProps {
    match: any;
    history:any;
}

const CoursesForm = (props: IProps) => {

    useEffect(() => {
    }, []);

    return (
        <>
            

                    <CoursesTabs history={props.history} id={props.match.params.id && props.match.params.id} />
         
        </>
    )
}

export default CoursesForm;