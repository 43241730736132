import React, {useState, useEffect} from 'react';
import InputDropDown from '../common/InputDropDown';
import InputDatePicker from '../common/InputDatePicker';
import WaveBox from '../common/WaveBox';
import axios from 'axios';
import Course from '../../models/Course';

import "./students.css"


import { Accordion,Card,Button,Modal } from 'react-bootstrap';

const companies = [
    {value:1, Text:"Shell"},
    {value:2, Text:"BP"},
    {value:3, Text:"Total"},
]


const rooms = [
    {value:1, Text:"Pedler Room"},
    {value:2, Text:"New York"},
    {value:3, Text:"London"},
]

const rates = [
    {value:1, Text:"Day to day"},
    {value:2, Text:"Evenings"},
    {value:3, Text:"Weekend"},
]


interface IProps {
    studentId:number
}

const StudentsClasses = (props:IProps) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [currentOpenTab, setCurrentOpenTab] = useState(0);
    const [courses, setCourses] = useState(new Array<Course>());


    useEffect(() => {
       
        loadClasses();
    }, []);

    const loadClasses = async (viewStatus:string="All") => {
        const response = await axios.get<Array<Course>>(`/api/courses/GetCoursesByStudentId?studentId=${props.studentId}&viewStatus=${viewStatus}`);
        setCourses(response.data);     
    }; 
    
    const showAttendence = (attendence:boolean) => {
        return attendence ? 
        <i className="fa fa-check fa-lg green" ></i> :
        <i className="fa fa-times fa-lg red" ></i> 
    }
    const showStatus = (statusId:number) => {
        switch(statusId) {
            case(1):
              return <span className="badge badge-lightblue" >Pending</span>
              break;
            case(2):
              return <span className="badge bg-green" >Attended</span>
              break;
            case(3):
              return <span className="badge badge-primary">Late cancellation</span>
              break;
            case(4):
              return <span className="badge badge-info">Cancelled in advance</span>
              break;
            case(5):
              return <span className="badge badge-danger">Absent</span>
              break;
          }
        
    }

    const updateCurrentOpenTab = (tabId:number) => {
        if(tabId === currentOpenTab){
            tabId = 0;
        }
        setCurrentOpenTab(tabId)
    }
    return(
        <div>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                <Modal.Title>Edit Class</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col">
                            <InputDatePicker label="Date of Birth" value="" />
                            <InputDropDown label="Room" data={rooms} width={235} showPlease={true}/>
                            <InputDropDown label="Rate" data={rates} width={235}  showPlease={true}/>
                            <InputDropDown label="Company" data={companies} width={235} showPlease={true}/>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleClose}>
                    Save Changes
                </Button>
                </Modal.Footer>
            </Modal>

            <div>
                <WaveBox items ={['Current', 'Past', 'Future']} onClick={(e) => loadClasses(e)} selected="Current"/> <br/>
            </div>

            <div style={{minHeight:"400px"}}>
            
            <Accordion> 
                {courses && courses.map((c) => (
                    <Card style={{border:"1px solid #cccccc", marginBottom:"10px"}} >
                    <Card.Header className="div_hover">
                    <Accordion.Toggle as={Button} variant="link" eventKey={c.courseId.toString()} style={{borderRadius:"0.0rem",width:"100%"}}  onClick={()=>updateCurrentOpenTab(c.courseId)} >
                        <div style={{float: "left"}}>{c.title}</div>
                        <div style={{float: "right"}}>
                            <i className="ti-angle-down"></i>
                        </div>
                    </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={c.courseId.toString()}>
                        <Card.Body>
                            <div className="row">
                                <div className="col-2">&nbsp;</div>
                                <div className="col-8">
                                    <div className="form-group row">
                                    <label className="col-md-2 col-form-label">Classes</label>
                                    <div className="col-md-12">
                                        <div className="table-responsive">
                                            <table className="table mb-0 table-hover">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Date</th>
                                                        <th scope="col">Time</th>
                                                        <th scope="col">Teacher</th>
                                                        <th scope="col">Company</th>
                                                        <th scope="col" style={{textAlign: "center" }}>Attendence</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {c.classes && c.classes.map((c,i) =>(
                                                        <tr>
                                                            <td>{i + 1} </td>
                                                            <td>{c.classDateString}<div style={{fontSize:"12px"}}>{c.dayWeek}</div></td>
                                                            <td>{c.classTime} - {c.classEndTime}<br/><div style={{fontSize:"12px"}}>45 mins</div></td>
                                                            <td>{c.teacher}</td>
                                                            <td>{(c.company) ? c.company : "No company"}</td>
                                                            <td style={{textAlign: "center" }}>
                                                                {showStatus(c.classStudentStatusId)}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                </div>
                                <div className="col-2">&nbsp;</div>
                            </div>

                        </Card.Body>
                    </Accordion.Collapse>
                    </Card>
                ))}
                    
                {!courses || courses.length===0 && <div>No courses for student</div>}
                
                
            </Accordion>
            </div>

           

            

          
        </div>
    )
}

export default StudentsClasses;