import React, {useEffect, useState} from 'react';
import SettlementReport from './OutstandingPaymentReport';
import SettlementReportTabs from './PaymentTabs';


const TeacherPayments = () => {
    return (
        <>
            <SettlementReportTabs />    
        </>
    )
}

export default TeacherPayments;