import React, {useEffect, useState} from 'react';
import StudentOverride from '../../models/StudentOverride';
import CheckBoxRound from '../../components/common/CheckBoxRound';
import WaveBox from '../../components/common/WaveBox';
import TickCross from '../../components/common/TickCross';

import Student from '../../models/Student';

import axios from 'axios';

interface IProps {
    student:Student;
    courseId:number;
    UpdateStudentOveride:Function;
}

const StudentOveride = (props:IProps) => {
    let db:any
    
    const [studentOverides, setStudentOverides] = useState(new Array<StudentOverride>());
    const [studentOveridesDB, setStudentOveridesDB] = useState(new Array<StudentOverride>());

    useEffect(()=> {
        loadClassForStudent();
    },[props.student,props.courseId]);
    
    const loadClassForStudent = async() => {
        const response = await axios.get(`/api/courses/GetCourseStudentOveride?courseId=${props.courseId}&studentId=${props.student.studentId}`);
        let data = response.data;

        data.map(d => {
            d.studentId = props.student.studentId;
        })
        
        const DB = JSON.parse(JSON.stringify(data));
        
        setStudentOverides(data);
        //DB is a copy as it was selected from database, we will need to refer back to the original values
        setStudentOveridesDB(DB);
    }

    const WaveBoxHandler = (record:StudentOverride, item) => {
        //2 - Attended
        //5 - Absent

        if(item === "ATTENDED") {
            record.statusId = 2;
        } else if(item === "ABSENT"){
            record.statusId = 5;
        } else if(item === ""){
            record.statusId = 1;
            record.classStatusId = 2;
        }

        const x = [...studentOverides];
        const row = x.find(c => c.classId == record.classId);
        if(row){
            row.statusId = record.statusId;
        }

        setStudentOverides(x);
        props.UpdateStudentOveride(x);

    }

    const updateCheckBox = (s:StudentOverride) => {
        //TODO: Refactor these names
        let so = [...studentOverides];
        let sdb = [...studentOveridesDB];

        let soo = so.find(c => c.classId === s.classId);
        let orig = sdb.find(c => c.classId === s.classId);

        if(soo && orig){
            if(soo.statusId === 0){
                soo.statusId = (orig.statusId === 0) ? 1 : orig.statusId;
            } else {
                soo.statusId = 0;
            }
        }

        setStudentOverides(so);
        props.UpdateStudentOveride(so);

    }

    const getStatusFromId = (statusId: number) => {
        if (statusId === 1) {
            return "PENDING";
        } else if (statusId === 2){
            return "ATTENDED";
        } else if (statusId === 3){
            return "Late cancellation";
        }  else if (statusId === 4){
            return "Cancelled in advance";
        } else if (statusId === 5){
            return "ABSENT";
        }
    }


    return(
        <>
            <h4>{props.student.firstName + ' ' + props.student.lastName}</h4><br/>
            <div id="NewScroller" className="table-responsive"  style={{height:"550px"}}>
                {studentOverides.length !== 0 && <table style={{width:"96%"}} className="">
                    <tr>
                        <td></td>
                        <td><strong>Date</strong></td>
                        <td><strong>Time</strong></td>
                        <td align="center"><strong>Enrolled</strong></td>
                        <td></td>
                    </tr>
                    {studentOverides.map((s,i) => (
                        <tr style={{height:"50px",borderBottom:"1px solid #efefef", borderTop:"1px solid #efefef"}}>
                            <td> <span className="badge badge-pink" style={{width: "25px"}}>{i+1}</span></td>
                            <td>{s.classDateString}</td>
                            <td>{s.classDatesCombined}</td>
                            <td align="center" style={{height:"10px"}}>  
                                {(s.classStatusId !== 6) && <CheckBoxRound checked={s.statusId !== 0} onChange={() => updateCheckBox(s)} />}
                                {(s.classStatusId === 6) && <div ><TickCross checked={(s.statusId!==0) ? true : false} /></div>}
                            </td>
                            <td style={{padding:"2px", width:"185px"}} align="left">
                                {(s.classStatusId !== 6 && (s.statusId !== 0 && s.statusId !== 1) || (s.statusId !== 0 && (s.classStatusId === 2 || s.classStatusId === 3)))  &&  <div style={{paddingTop:"5px"}}><WaveBox onClick={(item) => WaveBoxHandler(s, item)} size="s" selected={getStatusFromId(s.statusId)} allowEmpty={true} items={['ATTENDED', 'ABSENT']} /></div>}
                            </td>
                        </tr>
                    ))
                    }
                
                </table>}

                {!studentOverides || studentOverides.length === 0 && <div style={{textAlign:"center"}}>No classes currently</div>}
            </div>
        </>
    ); 
}
export default StudentOveride;