import React, {useState, useEffect} from 'react';
import DatePicker, {registerLocale} from "react-datepicker";
import moment from 'moment';
import TimeSelector from '../../../components/common/TimeSelector';
import eu from "date-fns/locale/en-GB";

registerLocale('eu', eu);

const times = [
    {Text:"09:00", Value:"09:00"},
    {Text:"09:30", Value:"09:30"},
    {Text:"10:00", Value:"10:00"},
    {Text:"10:30", Value:"10:30"},
    {Text:"11:00", Value:"11:00"},
    {Text:"11:30", Value:"11:30"},
    {Text:"12:00", Value:"12:00"},
    {Text:"12:30", Value:"12:30"},
    {Text:"13:00", Value:"13:00"},
    {Text:"13:30", Value:"13:30"},
    {Text:"14:00", Value:"14:00"},
    {Text:"14:30", Value:"14:30"},
    {Text:"15:00", Value:"15:00"},
    {Text:"15:30", Value:"15:30"},
    {Text:"16:00", Value:"16:00"},
    {Text:"16:30", Value:"16:30"},
    {Text:"17:00", Value:"17:00"},
    {Text:"17:30", Value:"17:30"},
]

interface IProps {
    time_onChange:Function;
    classLength:number;
    rescheduleDateChange:Function;
    rescheduleTime?:string;
    rescheduleDate:Date;
    classDateString?:string
}

const RescheduleClass = (props:IProps) => {
    const [rescheduleTime, setRescheduleTime] = useState(props.rescheduleTime);
    const [rescheduleDate, setrescheduleDate] = useState(new Date());

    useEffect(() => {
        if(props.rescheduleTime && props.rescheduleTime != "" ){
            let newTime:string = props.rescheduleTime.split(' - ')[0];
            setRescheduleTime(props.rescheduleTime.split(' - ')[0]);
            props.time_onChange(newTime);
        }
    }, [props.rescheduleTime]);

    useEffect(() => {
        if(props.rescheduleDate  ){
            setrescheduleDate(props.rescheduleDate);
        }
    }, [props.rescheduleDate]);
    



    
    const rescheduleDateChange = (date:any) => {
        moment.locale('en-gb');                  
        let newDateString = (date.getDate()).toString() + "/" + ((date.getMonth() + 1).toString()+ "/" + date.getFullYear().toString())
        var a = moment(newDateString, 'DD/MM/YYYY');
    
    
        //setrescheduleDateString(newDateString);
        setrescheduleDate(a.toDate());
    
        props.rescheduleDateChange(date);
    }

    const time_onChange = (e:any) => {
        setRescheduleTime(e.target.value);
        props.time_onChange(e);
    } 

    const newTime_onChange = (newTime:string) => {
        setRescheduleTime(newTime);
        props.time_onChange(newTime);
    } 


    return (
        <>
            <div><strong>Class date</strong></div>
            <DatePicker calendarStartDay={1} locale="eu" autoComplete="off" className={`form-control form-control-datepicker-100` }  dateFormat="dd/MM/yyyy"  style={{width:"35px"}} name="startDateString" onChange={(e:any) => rescheduleDateChange(e)}  selected={rescheduleDate}  autocomplete="nope"  /> 
            <div style={{paddingTop:"10px"}}><strong>Class time</strong></div>

            <div style={{paddingLeft:"12px"}}><TimeSelector startTime={props.classDateString} timeUpdated={newTime_onChange}  /></div>

            <div style={{paddingTop:"10px"}}><strong>Duration</strong> : {props.classLength} mins</div>
        </>
    );
}

export default RescheduleClass;