import React, {useState} from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import OutstandingPaymentReport  from './OutstandingPaymentReport';
import SettlementReport from './SettlementReport';


const SettlementReportTabs = () => {
    const [activeTab, setActiveTab] = useState('1');
    const [refresher, setRefresher] = useState(0);
    const [settlementReporttabClickTimes, setSettlementReporttabClickTimes] = useState(0);
    const [outstandingPayClickTimes, setOutstandingPayClickTimes] = useState(0);

    const toggle = tab => {
      if(activeTab !== tab) setActiveTab(tab);
    }


    const incrementRefresh = () => {
        setRefresher(refresher+1);
    }

    const settlementReports = () => {
        setSettlementReporttabClickTimes(settlementReporttabClickTimes+1);
        toggle('1');
    }

    const outstandingPay = () => {
        setOutstandingPayClickTimes(outstandingPayClickTimes+1);
    }
  
    return (
        <div >
            <Nav tabs>
                <NavItem>
                    <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => settlementReports()}
                    >
                    Settlement reports
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => { toggle('2'); }}
                    >
                    Classes to process
                    </NavLink>
                </NavItem>
            </Nav>

            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <Row>
                    <Col sm="12">
                        <div style={{minHeight:"712px"}}>
                            <SettlementReport refresher={refresher} tabClickTimes={settlementReporttabClickTimes}  />
                        </div>
                    </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="2">
                    <Row>
                    <Col sm="12">
                        <div style={{minHeight:"712px"}}>
                            <OutstandingPaymentReport incrementRefresh={incrementRefresh} tabClickedTimes={outstandingPayClickTimes}  />
                        </div>
                    </Col>
                    </Row>
                </TabPane>
            </TabContent>
        </div>
    )
}

export default SettlementReportTabs;