import TeachersRate from "./TeachersRate";
import Subject from "./Subject";

export default class Teacher {
    constructor() {
        this.rates = new Array<TeachersRate>();
        this.subjects = new Array<Subject>();
    }

    public teacherId: number = 0;
    public firstName: string = "";
    public lastName: string = "";
    public email: string = "";
    public address1: string = "";
    public address2: string = "";
    public nationality: string = "";
    public town: string = "";
    public city: string = "";
    public postCode: string = "";
    public dateOfBirth: Date;
    public genderId: number = 0;

    public mobile: string = "";
    public landline: string = "";
    public notes: string = "";


    public dateOfBirthString: string = "";
    public rates: Array<TeachersRate> = new Array<TeachersRate>();
    public subjects: Array<Subject> = new Array<Subject>();
    public hasLogin: boolean;
    public userId: number;
    public subjectsString: string;
    public active: boolean;

    public avatarId: number;
    public nationalityId:number;
    public avatarImage: string;

}

