import React, {useEffect, useState} from 'react';

import BottomNav from '../../teachers/BottomNav';
import Class from '../../models/Class';
import Student from '../../models/Student';
import UserService from '../../services/userService';
import ClassStatusBadge from '../../components/badges/ClassStatusBadge';

import MarkClassesModal from '../../modals/MarkClassesModal';


import axios from 'axios';
import { setSourceMapRange } from 'typescript';


interface IProps {
    history:any
}

const StudentsMobile = (props:IProps) => {
    let userService = new UserService();
    const [students, setStudents] = useState(new Array<Student>());
    const [isLoaded, setIsLoaded] = useState(false);

    const loadData = async() => {
        const user = userService.getUser();
        let entityId = user.roles[0].entityId;
        let response = await axios.get(`/api/students/GetStudentsForTeacherId?teacherId=${entityId}`);
        setStudents(response.data);

        setIsLoaded(true);
    }

    useEffect(() => {
        loadData();
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {students.map(s => (
                 <div className="card" onClick={() => {} } key={s.studentId}>

                    <div className="card-header-mobile" >
                        <div className="row">
                            <div className="col-12">
                                <h4 className="header-title m-t-0 m-b-30" style={{textTransform:"uppercase",color:"grey"}}>{s.firstName + " " + s.lastName}  </h4>
                            </div>
                        </div>

                    </div>

                    <div className="card-body-mobile" >
                        <div>{(s.company) ? s.company : "Private"} </div>
                        <div>{s.email} </div>
                        {s.mobile && <div>Mobile: {s.mobile}</div>}
                        
                        {s.landline && <div>Landline: {s.landline} </div>}
                        
                        <div></div>
                    </div>
                </div>
            ))}

            {(isLoaded === true && students.length === 0) &&
                <div style={{textAlign:"center"}}>
                    You do not currently have any students
                </div>
            }

            <BottomNav history={props.history} currentTab={3} />

        </>
    )
}

export default StudentsMobile;