import React from 'react';

const Documents = () => {
    return (
        <div>
            <table className="table mb-0">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Date Modified</th>
                        <th scope="col">Type</th>
                        <th scope="col">FileSize</th>
                        <th scope="col">Created By</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td scope="col">#</td>
                        <td scope="col">Name</td>
                        <td scope="col">Date Modified</td>
                        <td scope="col">Type</td>
                        <td scope="col">FileSize</td>
                        <td scope="col">Created By</td>
                        <td><i className="fa fa-file-pdf fa-lg green" /></td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default Documents;