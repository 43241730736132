import React, {useState,useEffect} from 'react';
import { Modal } from 'react-bootstrap';

import ActionButton from '../buttons/ActionButton';
import CancelButton from '../buttons/CancelButton';

interface IProps {
    showModal:boolean,
    onConfirmed:Function,
    onHide:Function
}

const ConfirmationModal = (props:IProps) => {
    return (
        <>
            <Modal show={props.showModal} onHide={()=>props.onHide()} dialogClassName="modal-750px">
                <Modal.Header closeButton>
                <Modal.Title>Form has unsaved changes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                            <div style={{paddingTop:"8px",paddingBottom:"8px"}}>Do you wish to leave without saving?</div>
                </Modal.Body>
                <Modal.Footer>
                    <ActionButton onClick={() => props.onConfirmed(true)} text="YES" /><CancelButton onClick={()=>props.onHide()} text="NO"  />
                </Modal.Footer>
            </Modal>
        </>
    ) 
}

export default ConfirmationModal;