import React, { useState, useEffect} from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';

interface IProps {
    complete:number;
    itemsTotal:number;
    itemAmmount:number;
}

const Progress = (props:IProps) => {
    return (
        <>
        <div style={{fontSize:"14px"}}><span style={{fontWeight:"bold"}}>{props.itemsTotal-props.itemAmmount}</span> classes remaining </div>
        <ProgressBar  now={props.complete}   />
        
        </>    
    )
}

export default Progress;