import React, {useEffect,useState} from 'react';

import TeacherPaymentVM from '../../models/TeacherPaymentVM';
import Class from '../../models/Class';
import ClassWrapper from '../../models/ClassWrapper';

import PaymentPdfTemplate from '../common/PaymentPdfTemplate';

import _ from 'underscore';

import __ from 'lodash';
import axios from 'axios';

interface IProps {
    id:number,
    grandTotal:number,
    periodEndDate:string,
    teacherId:number;
    isTeacher:boolean
}
const PrintPaymentReport = (props:IProps) => {
    const [currentClasses, setCurrentClasses] = useState(new Array<Class>());
    
    const loadData = async() => {
        await showPayment(props.id);

        let ll = document.getElementById('my-table');
        
        if(ll){
            ll.style.visibility = 'visible';
        }
        window.print();
    }
    
    useEffect(() => {
        loadData();
    }, []);


    const totalCoursePayment = (teacherPayments:Array<TeacherPaymentVM>) => {

        teacherPayments.map(tp=>{
            let coursePaymentTotal:number = 0;

            
            tp.paymentItems.map(pi=>{

                if(pi.courseId === 1049){
                    const i = 1;
                }
    
                let courseItems = tp.paymentItems.filter(p => p.courseId == pi.courseId);
                let sumCourseTotal:number = 0; 

                courseItems.map(i => {
                    sumCourseTotal += (i.classLength / 60) * i.ratePerHour;
                    pi.coursePaymentTotal = roundup(sumCourseTotal,2);
                })
            });
        });

        return teacherPayments;
    }

    const showPayment = async(paymentHeaderId:number) => {
        if(!props.teacherId){
            const response = await axios.get<Array<TeacherPaymentVM>>(`api/reports/GetRaisedPaymentReport?paymentHeaderId=${paymentHeaderId}`);
            let teacherPayments:Array<TeacherPaymentVM> = response.data;
    
            teacherPayments = totalCoursePayment(teacherPayments);
            let classesToAdd:Array<Class> = convertClassesFrom(teacherPayments);
            setCurrentClasses(classesToAdd);
        } else {

            let url = `api/classes/GetByFilter?teacherId=${props.teacherId}&statusId=6&paymentHeaderId=${paymentHeaderId}`
            const classResults = await axios.get<ClassWrapper>(url); 
    
            let sortedClasses = _.sortBy(classResults.data.classes,'course');

            setCurrentClasses(sortedClasses);
        }
    }

    
    const roundup = (num,dec) => {

        return Math.round((num + Number.EPSILON) * 100) / 100;


        /*dec= dec || 0;
        var  s=String(num);
        if(num%1)s= s.replace(/5$/, '6');
        return Number((+s).toFixed(dec));
        */
     }
    const convertClassesFrom = (teacherPayments:Array<TeacherPaymentVM>):Array<Class> => {
        let classesToConvert:Array<Class> = new Array<Class>();

        teacherPayments.map(tp=>{
            let coursePaymentTotal:number = 0;
            
            
            tp.paymentItems.map(pi=>{
                let courseItems = tp.paymentItems.filter(p => p.courseId == pi.courseId);
                let sumCourseTotal:number = 0; 

               
                courseItems.map(i => {
                   
                    sumCourseTotal += (i.classLength / 60) * i.ratePerHour;
                })

                let classToA:Class = new Class();
                classToA.classLength = pi.classLength;
                classToA.course = pi.title;
                classToA.teacherRate = pi.ratePerHour
                classToA.coursePaymentTotal = roundup(sumCourseTotal,2);
                classToA.classDateString = pi.classSeperateDateString;
                classToA.classDatesCombined = pi.classSeperateTimeString;
                classToA.subject = pi.subject;
                classToA.teacher = tp.teacher;

                classesToConvert.push(classToA);
            });
        });

        return classesToConvert;

    }
    
    return <>
        <PaymentPdfTemplate isTeacherReport={props.isTeacher} classes={currentClasses} paymentTotal={props.grandTotal} periodEndDateString={props.periodEndDate} />
    </>
}

export default PrintPaymentReport;