import React from 'react'
import Class from '../../models/Class';
import ClassStatusBadge from '../../components/badges/ClassStatusBadge';

interface IProps {
    classes: Array<Class>;
    openMarkingModal: Function;
    reschedule_OnClick: Function;

}

const ClassesCardDeskTop = (props:IProps) => {
  return (
    <>
        <table style={{width:"95%"}}>
            <tbody>
                {props.classes.map((c,i) => (
                    <><tr style={{height:"40px",borderBottom:"1px solid #efefef"}}>
                        <td > 
                            <div >
                                <div className="row" >
                                    <div className="col-7">
                                        <h4 className="header-title m-t-0 m-b-30" style={{textTransform:"uppercase",color:"grey"}}>{c.dayWeek} {c.classDateString}  </h4>
                                    </div>
                                    <div className="col-5" style={{textAlign:"right", padding:"0px"}}>
                                        <div style={{color:"#495057"}}>
                                            <h4 className="header-title" style={{textTransform:"uppercase",color:"grey"}}>{c.classDatesCombined.replace(' ','').replace(' ','')}  </h4>
                                        </div>
                                    </div>
                                </div>
                            
                                <div>{c.course}</div>
                                {c.courseDescription !== "" && <div>{c.courseDescription}</div>}
                                <div>{c.subject} {c.level}</div>
                                {c.company !== "" && <div>{c.company}</div>}
                                <div>{c.definedAddress}</div>
                                <div className="row" style={{paddingBottom:"15px"}}>
                                    <div style={{paddingLeft:"10px"}}>
                                        <table cellPadding={0} cellSpacing="0" style={{width:"100px"}}  >
                                            <tr>
                                                <td onClick={()=> props.openMarkingModal(c)}> {<ClassStatusBadge statusId={c.seriesStatusId} onClick={() => {} } />}</td>
                                                <td valign="middle"> 
                                                    <div style={{height:"2px"}}></div>
                                                    <div style={{paddingTop:"5px"}}>
                                                        <i className="ti-angle-double-right"></i>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                        {c.seriesStatusId !== 6 && c.seriesStatusId !== 3 && <div className="col" style={{textAlign:"right",cursor:"pointer",padding:"0px"}} onClick={() => props.reschedule_OnClick(c) }>
                                            <i className="ti-pencil pink" style={{fontSize: "20px"}}></i>   
                                    </div>
                                }
                                </div>


                            </div>
                        </td>
                    </tr>
                        <tr style={{height:"5px"}}>
                                <td>&nbsp;</td>
                        </tr>
                        </>
                ))}

                <tr style={{height:"5px"}}>
                
                </tr>
            </tbody>
        </table>
    </>
    
  )
}

export default ClassesCardDeskTop;
