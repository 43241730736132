import React, {useEffect,useState} from 'react';
import TeacherPaymentVM from '../../models/TeacherPaymentVM';
import CurrencyFormater from '../common/CurrencyFormater';

interface IProps {
    teacherPayments:Array<TeacherPaymentVM>;
    getPaymentGrandTotal:Function,
    getPaymentTotalHours:Function,
    hideNoRecordsMessage?:boolean,
    compactTable?:boolean
}

const PaymentTable = (props:IProps) => {
    const [grandTotal, setGrandTotal] = useState(0);
    const [totalHours, setTotalHours] = useState(0);

    const teacherPaymentTotal = (teacherId:number):number => {
        const individualTeacherPayments:TeacherPaymentVM = props.teacherPayments.find(t => t.teacherId === teacherId)!;
        let teacherTotal:number = 0;

        individualTeacherPayments.paymentItems.map(p => {
            teacherTotal += p.classPaymentTotal;
        })


        return teacherTotal;
    }

    useEffect(() => {
        let grandPaymentTotal:number = 0;
        let grandHoursTotal:number = 0;
        if(props.teacherPayments.length > 0) {
            props.teacherPayments.map(t => {
                t.paymentItems.map(tt => {
                    grandPaymentTotal += tt.classPaymentTotal;
                    grandHoursTotal += tt.classLength;
                });
            });

            props.getPaymentGrandTotal(grandPaymentTotal);
            props.getPaymentTotalHours(grandHoursTotal);
            setGrandTotal(grandPaymentTotal);
            setTotalHours(grandHoursTotal);
        } else {
            props.getPaymentGrandTotal(0);
            props.getPaymentTotalHours(0);
            setGrandTotal(0);
            setTotalHours(0);
        }
    }, [props.teacherPayments]);

    

    return (
        <>
            <table cellPadding={0} cellSpacing={0} style={{width:"95%"}}   >
                {props.teacherPayments && props.teacherPayments.map((t,i) => (
                    <tbody>
                        <tr>
                            <td>
                                <table cellPadding={0} cellSpacing={0}>
                                    <tr>
                                        <td style={{width:"250px"}}><div className="pink" style={{fontSize:"16px", fontWeight:"bold", paddingBottom: (!props.compactTable) ?  "15px" : "3px"}}>  {t.teacher}  </div></td>
                                        <td><div className="pink" style={{fontSize:"16px", fontWeight:"bold", paddingBottom: (!props.compactTable) ?  "15px" : "3px"}}>   <i className="mdi mdi mdi-coin-outline" style={{fontSize:"26px",paddingLeft:"30px", paddingRight:"30px"}}></i> </div></td>
                                        <td><div className="pink" style={{fontSize:"16px", fontWeight:"bold", paddingBottom: (!props.compactTable) ?  "15px" : "3px"}}>   <CurrencyFormater value={teacherPaymentTotal(t.teacherId)} /> </div></td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        {<tr>
                            <td colSpan={4}>
                                {!props.compactTable && <table className="table" style={{border:"2px"}}>
                                    <tr>
                                        <td style={{width:"48%"}}><strong>Course</strong></td>
                                        <td style={{width:"12%"}}><strong>Class date</strong></td>
                                        <td style={{width:"10%"}} align="right"><strong>Class time</strong></td>
                                        <td style={{width:"10%"}} align="right"><strong>Duration</strong></td>
                                        <td style={{width:"10%"}} align="right"><strong>Hourly rate</strong></td>
                                        <td style={{width:"10%"}} align="right"><strong>Class total</strong></td>
                                    </tr>

                                    
                                    {!props.compactTable && t.paymentItems.map(p => (
                                        <>
                                        {(p.newRecord) && <tr><td colSpan={3}> <strong>{p.title}</strong> <div style={{fontSize:"12px"}}>{p.description}</div></td> <td align="right" colSpan={4}><strong> <CurrencyFormater value={p.coursePaymentTotal} /></strong></td></tr>}
                                        <tr>
                                            <td>{p.subject}</td>
                                            <td>{p.classSeperateDateString}</td>
                                            <td align="right">{p.classSeperateTimeString}</td>
                                            <td align="right">{p.classLength} mins</td>
                                            <td align="right">£{p.ratePerHour.toFixed(2)}</td>
                                            <td align="right">£{p.classPaymentTotal.toFixed(2)}</td>
                                        </tr>
                                        </>
                                    ))}
                                    

                                    {!props.compactTable && <tr>
                                        <td colSpan={3}></td>
                                        <td align="right" className="pink"><strong>{t.totalTimeHours} hours</strong></td>
                                        <td>&nbsp;</td>
                                        <td align="right" className='pink' >
                                            <strong><CurrencyFormater value={teacherPaymentTotal(t.teacherId)} /></strong>
                                        </td>
                                    </tr>}
                                </table>}
                            </td>
                        </tr>}

                        {!props.compactTable && <tr><td>&nbsp;</td></tr>}

                        {/*i== props.teacherPayments.length-1 && 
                            <tr>
                               <td colSpan={4}>
                                    <table className="table" style={{border:"2px"}}>
                                        <tr>
                                            <td style={{width:"50%"}}><strong>Report total</strong></td>
                                            <td style={{width:"10%"}}><div style={{color: "transparent" }}>&nbsp</div></td>
                                            <td style={{width:"10%"}} align="right"><div style={{color: "transparent" }}>&nbsp</div></td>
                                            <td style={{width:"10%"}} align="right"><strong>{(totalHours/60).toFixed(2)} hours </strong></td>
                                            <td style={{width:"10%"}} align="right"><div style={{color: "transparent" }}>&nbsp</div></td>
                                            <td style={{width:"10%"}} align="right"><strong><CurrencyFormater value={grandTotal} /></strong></td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                                    */}
                    </tbody>
                ))}

                


                {!props.teacherPayments || props.teacherPayments.length===0 &&  !props.hideNoRecordsMessage &&
                    <tr>
                        <td colSpan={5} >No records currently</td>
                    </tr>
                }
            </table>
        </>
        
    )
}

export default PaymentTable;