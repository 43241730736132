import React from 'react';

import NotesForm from '../components/common/NotesForm';

const NotesPage = () => {
    return (
        <>
            <div className='card'>
                <div className='card-body'>
                    <NotesForm id={1} type={3}  />
                </div>
            </div>
        </>
    );
}

export default NotesPage;