import React, {useState, useEffect} from 'react';

import InputTextBoxDuel from '../../components/common/InputTextBoxDuel';
import ChangePasswordRequestVM from '../../models/ChangePasswordRequestVM';
import ChangeUserDetailsRequestVM from '../../models/ChangeUserDetailsRequestVM';
import UserService from '../../services/userService';
import useSnackbar from 'react-snackbar-toast';
import Switch from "react-switch";
import GDPRVM from '../../models/GDPRVM';

import ActionButton from '../../components/buttons/ActionButton';

import axios from 'axios';
import swal from 'sweetalert';




import BottomNav from '../../teachers/BottomNav';

interface IProps {
    history:any
}

const ProfileMobile = (props:IProps) => {
    const userService = new UserService();
    const [passwordRequest, setPasswordRequest] = useState(new ChangePasswordRequestVM());
    const [passwordValidationErrors, setPasswordValidationErrors] = useState(new Array<string>());
    const [currentProfile, setCurrentProfile] = useState(new ChangeUserDetailsRequestVM());
    const [profileValidationErrors, setProfileValidationErrors] = useState(new Array<string>());

    const { addToast } = useSnackbar();

    const open = (option:any, message:string) => {
        addToast(message, option);
    };

    const hasProfileError = (field: string) => {
        let errorsFound = profileValidationErrors.find(e =>  e === field );
        if (errorsFound && errorsFound.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    const updatePasswords = (e:any) => {
        setPasswordRequest({ ...passwordRequest, [e.target.name]: e.target.value, UserName: userService.getUserName() });
    }

    const updateProfileField = (e:any) => {
        setCurrentProfile({ ...currentProfile, [e.target.name]: e.target.value, UserName: userService.getUserName() });
    }

    useEffect(() => {
        populateCurrentProfile();
        window.scrollTo(0, 0);
    }, []);

    const populateCurrentProfile = () => {
        const user = userService.getUser();
        let changeUserDetailsRequestVM = new ChangeUserDetailsRequestVM();

        changeUserDetailsRequestVM.FirstName = user.firstName;
        changeUserDetailsRequestVM.LastName = user.lastName;
        changeUserDetailsRequestVM.Email = user.username;

        setCurrentProfile(changeUserDetailsRequestVM);
    }

    const UpdateProfile = async () => {
        const isValid = isProfileFormValid();

        if(isValid){
            const response = await axios.post('/api/users/UpdateProfile', currentProfile);

            swal("Profile successfully updated","Well done!", "success", {
                timer: 3000,
                buttons:{}
            });
        }
    }

    const isPasswordFormValid = (): boolean => {
        let errors = new Array<string>();
        let isValid = true;

        if (!passwordRequest.OldPassword || passwordRequest.OldPassword === "") {
            errors.push("OldPassword");
            isValid = false;
        }
        if (!passwordRequest.NewPassword || passwordRequest.NewPassword === "") {
            errors.push("NewPassword");
            isValid = false;
        }
        if (!passwordRequest.ConfirmPassword || passwordRequest.ConfirmPassword === "") {
            errors.push("ConfirmPassword");
            isValid = false;
        }

        setPasswordValidationErrors(errors);

        return isValid;
    }

    const updatePassword = async () => {
        let isValid = isPasswordFormValid(); 

        if (isValid) {
            const response = await axios.post('/api/users/UpdatePassword', passwordRequest);
            
            if(response.data){
                const responseIsValid = response.data.isValid
                if (!responseIsValid) {
                    //open({ className: "customToast", type: "error" }, "Could not update password, incorrect details supplied");

                    swal("Incorrect password details supplied","Sorry!", "error", {
                        timer: 3000,
                        buttons:{}
                      });

                } else {
                    swal("Password successfully updated","Well done!", "success", {
                        timer: 3000,
                        buttons:{}
                    });
                }
            }
        }
    }

    const isProfileFormValid = () => {
        let errors = new Array<string>();
        let isValid = true;

        if (!currentProfile.FirstName || currentProfile.FirstName === "") {
            errors.push("FirstName");
            isValid = false;
        }

        if (!currentProfile.LastName || currentProfile.LastName === "") {
            errors.push("LastName");
            isValid = false;
        }

        if (!currentProfile.Email || currentProfile.Email === "") {
            errors.push("Email");
            isValid = false;
        }

        setProfileValidationErrors(errors);

        return isValid;
    }

    return (
        <>
            <div className="card" >
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <div>First name</div>
                            <input type="text" className="form-control" name="FirstName" value={currentProfile.FirstName}  onChange={(e) => updateProfileField(e)} />
                        </div>
                        <div className="col-12">
                            <div>Last name</div>
                            <input type="text" className="form-control" name="LastName" value={currentProfile.LastName}  onChange={(e) => updateProfileField(e)} />
                        </div>
                        <div className="col-12">
                            <div>Email</div>
                            <input type="text" className="form-control" name="Email" value={currentProfile.Email}  onChange={(e) => updateProfileField(e)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="float-right" style={{paddingTop:"10px"}}>
                                <ActionButton text="UPDATE" onClick={()=>{ UpdateProfile() }} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="card" >
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <div>Current password</div>
                            <input type="password" className="form-control" name="OldPassword" value={passwordRequest.OldPassword} width={435} onChange={(e) => updatePasswords(e)}  />
                        </div>
                        <div className="col-12">
                            <div>New password</div>
                            <input type="password" className="form-control" name="NewPassword" value={passwordRequest.NewPassword} width={435} onChange={(e) => updatePasswords(e)} />
                        </div>
                        <div className="col-12">
                            <div>Confirm password</div>
                            <input type="password" className="form-control" name="ConfirmPassword" value={passwordRequest.ConfirmPassword} width={435} onChange={(e) => updatePasswords(e)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="float-right" style={{paddingTop:"10px"}}>
                                <ActionButton text="UPDATE" onClick={()=> updatePassword() } />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <BottomNav history={props.history} currentTab={-1} />
        </>
    );
}

export default ProfileMobile;