import React from 'react';

const checkValidDateFormat = (dateofbirth: string) => {
    //alert(dateofbirth)
    if (!dateofbirth || dateofbirth === "") {
        //alert("Date is null");
        return false;
    }

    var dob = dateofbirth.split('/');
    var dd = parseInt(dob[0]);
    var mm = parseInt(dob[1]);
    var yy = parseInt(dob[2]);


    if (isNaN(dd) || isNaN(mm) || isNaN(yy) || dd == 0 || mm == 0 || yy == 0) {
        //alert("Invalid Date of Birth");
        return false;
    }
    else {
        if (dob.length != 3) {
            //alert("Please enter Date Of Birth in (dd/mm/yyyy) format");
            return false;
        }
        else {
            if ((mm == 4 || mm == 6 || mm == 9 || mm == 11) && dd > 30) {
                //alert("Please enter Day less than equal to 30");
                return false;
            }
            if ((mm == 1 || mm == 3 || mm == 5 || mm == 7 || mm == 8 || mm == 10 || mm == 12) && dd > 31) {
                //alert("Please enter Day less than equal to 31");
                return false;
            }
            if (mm == 2) {
                var lyear = false;
                if ((!(yy % 4) && yy % 100) || !(yy % 400)) {
                    lyear = true;
                }
                if ((lyear == false) && (dd >= 29)) {
                    //alert('Invalid date format in February!');
                    return false;
                }
                if ((lyear == true) && (dd > 29)) {
                    //alert('Invalid date format in February!');
                    return false;
                }
            }
            if (parseFloat(dob[1]) > 12) {
                //alert("Please enter Month less than equal to 12");
                return false;
            }
            if (dob[2].length != 4) {
                //alert("Please enter Year in Four Digit (yyyy)");
                return false;
            }

            return true;
        }
    }
}  

export default checkValidDateFormat;