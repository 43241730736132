import React, { useState, useEffect } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import ClassesGrid from './ClassesGrid';
import ClassesGridMOCK from './ClassGridMOCK';

import StudentsGrid from './StudentsGrid';

import Course from '../../models/Course';

import CoursesDetailsForm from './CoursesDetailsForm';
import Student from '../../models/Student';

import axios from 'axios';

interface IProps {
    id:number,
    history:any
}


const CoursesTabs = (props: IProps) => {
    const [activeTab, setActiveTab] = useState('4');
    const [refresh, setRefresh] = useState(0);
    const [currentCourse, setCurrentCourse] = useState(new Course());
    const [students, setStudents] = useState(new Array<Student>());
    const [classRefersher, setClassRefersher] = useState(1);

    const [studentAccess, setStudentAccess] = useState(false);


    /*useEffect(() => {
        if(props.id) {
          let updatedOptions = { ...currentCourse, courseId: props.id };
          setCurrentCourse (updatedOptions);
        }
    }, []);*/

    const toggle = tab => {
      
      if(tab === '2' && !studentAccess){
        return false;
      }
      //setRefresh(refresh+1)
        if(activeTab !== tab) {
          //postCourse();

          setActiveTab(tab);
        } 

    }

    const incrementRefresh = () => {
      setRefresh(refresh+1)
    }

    const toogleStudentTabAccess = (access:boolean) => {
      setStudentAccess(access);
    }

    const updateCourseDetails = (updatedCourse: Course) => {
        setCurrentCourse(updatedCourse);
    }

    const updateStudents = async (students: Array<Student>) => {
        setStudents(students);

        let courseToPost = currentCourse;
        courseToPost.students = students;

        //alert(JSON.stringify(courseToPost));

        let courseReturned = await axios.post<Course>('/api/courses/upsert', courseToPost);
        setCurrentCourse(courseReturned.data);
        setClassRefersher(classRefersher+1);
    }

    const saveCourse = async() => {
        let course = currentCourse;
        course.students = students;

        setRefresh(refresh+1)

        
          //postCourse();
          
          toggle('2')
         

    }

    const postCourse = async() => {
      let courseReturned = await axios.post<Course>('/api/courses/upsert', currentCourse);
      setCurrentCourse(courseReturned.data);
    }

  return (
    <div >
      
      
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
            <CoursesDetailsForm  id={props.id} updateCourseDetails={updateCourseDetails} activeTab={activeTab} setActiveTab={setActiveTab} refresh={refresh}   />
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col sm="12">
                <StudentsGrid updateStudents={updateStudents} postCourse={postCourse} id={props.id}  incrementRefresh={incrementRefresh} currentCourse={currentCourse} />
            </Col>
          </Row>
        </TabPane>
        
        <TabPane tabId="4">
          <Row>
            <Col sm="12">
            </Col>
          </Row>
        </TabPane>
              

      </TabContent>
      <ClassesGridMOCK history={props.history} id={props.id} toogleStudentTabAccess={toogleStudentTabAccess} refresh={refresh} updateCourseDetails={updateCourseDetails} students={students} />
    </div>
  );
}

export default CoursesTabs;