import React from 'react';

interface IProps {
    label:string,
    placeHolder?:string,
    width?: number,
    height?: number,
    value?: string,
    onChange?: Function;
    name?: string,
    isError?: boolean,
    labelWidth:number

}

const InputTextArea = (props:IProps) => {
    return (
  

<div className="form-group row">
    <div style={{width:(props.labelWidth) ? props.labelWidth.toString() + "px" : "125px",paddingTop:"6px",textAlign:"right", paddingRight:"30px"}}>
        <label >{props.label}</label>
    </div>
    <div>
        <textarea className={`form-control ${props.isError ? "parsley-error" : ""}`} name={props.name} value={props.value} style={{ width: props.width, height:props.height }} onChange={(e: any) => props.onChange && props.onChange(e)}></textarea>
    </div>
</div>


    )
}

export default InputTextArea;
