export default class Class {
    constructor() {

    }

    public classId:number;
    public courseId:number;
    public course:string;

    public coursePaymentTotal:number;

    public subject:string;

    public level:string;
    public levelDescription:string;
    public companyId:number
    public company:string;

    public addressId:number
    public address:string;

    public definedAddress:string;

    public room:string;
    public dayWeek:string;
    public teacher:string;
    public teacherId:number;
    public teacherRateId:number;
    public teacherRate:number;
    public classDate:Date 
    public classStartDate:Date
    public classStartEnd:Date
    public scheduleId:number;

    public classDateString: string
    public classStartDateString: string
    public classStartEndString: string
    public classDatesCombined: string

    public classTime: string
    public classEndTime: string
    
    public classLength: number

    public Time: string

    public dayOfWeek:string

    public classStudentStatusId: number;
    public classStudentStatus: number;

    public studentCount:number;

    public seriesStatusId:number;

    public courseTypeId:number;
    public courseType:number;


    public subjectColor: string;

    public classSeriesId:number;    

    public datesSelected: string;

    public courseDescription: string;

}

