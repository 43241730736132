import React,{useState,useEffect,useRef} from 'react';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import ClassesForm from './ClassesForm';
import ClassGenVM from '../../models/ClassGenVM';
import Class from '../../models/Class';
import InputTextBoxDuel from '../common/InputTextBoxDuel';

import Subject from '../../models/Subject';
import CourseType from '../../models/CourseType';
import Company from '../../models/Company';
import CourseLevel from '../../models/CourseLevel';
import Course from '../../models/Course';
import DatePicker, {registerLocale} from "react-datepicker";
import ClassesEditForm from "./ClassesEditForm";
import Student from '../../models/Student';
import Address from '../../models/Address';

import StudentAttendence from '../reports/StudentAttendence';
import StudentAttendenceAgri from '../reports/StudentAttendenceAgri';


import checkValidDateFormat from '../../services/dateService';

import AddStudents from './AddStudents';
import Pagination from 'react-bootstrap/Pagination';

import useSnackbar from 'react-snackbar-toast';

import ActionButton from '../buttons/ActionButton';
import CancelButton from '../buttons/CancelButton';
import CourseStatusBadge from '../badges/CourseStatusBadge';
import MarkClassesModal from '../../modals/MarkClassesModal';

import CourseTypes from '../../Enumerators/CourseTypes';

import ClassStatusBadge from '../badges/ClassStatusBadge';

import FormService from "../../services/formService"

import moment from 'moment';
import postal from 'postal';

import CurrencyFormater from '../common/CurrencyFormater';

import StudentOveride from './StudentOveride';
import StudentOverride from '../../models/StudentOverride';

import swal from 'sweetalert';
import { idText } from 'typescript';

import eu from "date-fns/locale/en-GB";

registerLocale('eu', eu);



interface IProps {
    id:number;
    toogleStudentTabAccess:(access:boolean) => void;
    refresh: number;
    updateCourseDetails: Function;
    students:Array<Student>;
    history:any;
}

const ClassesGridMOCK = (props:IProps) => {
    const [show, setShow] = useState(false);
    const [showAddStudents, setShowAddStudents] = useState(false);
    
    const [showLessonGenerator, setshowLessonGenerator] = useState(false);
    const [currentOpenTab, setCurrentOpenTab] = useState(0);
    const [generatorValidationErrors, setGeneratorValidationErrors] = useState(new Array<string>());
    const [subjects, setSubjects] = useState(new Array<Subject>());
    const [currentCourse, setCurrentCourse] = useState(new Course());
    const [generateOptions, setGenerateOptions] = useState(new ClassGenVM());
    const [courseLevels, setCourseLevels] = useState(new Array<CourseLevel>());
    const [courseTypes, setCourseTypes] = useState(new Array<CourseType>());
    const [companies, setCompanies] = useState(new Array<Company>());
    const [classes, setClasses] = useState(new Array<Class>());
    const [validationError, setValidationError] = useState(new Array<string>());
    const [currentClass, setCurrentClass] = useState(new ClassGenVM());
    const [series, setSeries] = useState(-1);
    const [selectedClassId, setSelectedClassId] = useState(0);
    const [showEditClass, setShowEditClass] = useState(false);
    const [teacherCost, setTeacherCost] = useState(0);
    const [coursePrice, setCoursePrice] = useState(0);
    const [profit, setProfit] = useState(0);
    const [isEdit, setIsEdit] = useState(false);


    const [showStudentDeleteConfirm, setShowStudentDeleteConfirm] = useState(false);
    const [currentStudentId, setCurrentStudentId] = useState(0);
    const [currentStudent, setCurrentStudent] = useState(new Student());


    const [showClassDeleteConfirm, setShowClassDeleteConfirm] = useState(false);
    const [selectedStudents, setSelectedStudents] = useState(new Array<Student>());
    const [tempStudentsStore, setTempStudentsStore] = useState(new Array<Student>());
    const [numberOfPages, setNumberOfPages] = useState(1);

    const [currentPage, setCurrentPage] = useState(1);
    const [currentSelectedClassId,setCurrentSelectedClassId]  = useState(0);

    const [showStatusUpdate, setShowStatusUpdate]= useState(false);
    const [currentSelectedStatus, setCurrentSelectedStatus]= useState(0);

    const [showMarkClassesModal, setShowMarkClassesModal] = useState(false);
    const [classGeneratorError,setClassGeneratorError]  = useState("");
    const [showMoveToEnd, setShowMoveToEnd] = useState(false);

    
    const [showMoveToEndButton, setShowMoveToEndButton] = useState(true);

    const [companyAddresses, setCompanyAddresses] = useState(new Array<Address>());
    const [showStudentsInClassModal, setShowStudentsInClassModal] = useState(false);    
    const [studentsInClass, setStudentsInClass] = useState(new Array<Student>());
    const [uniqueIdentifier, setUniqueIdentifier] = useState("");

    const [showStudentAttendenceReport, setShowStudentAttendenceReport] = useState(false);    

    const [showStudentOveride, setShowStudentOveride] = useState(false);    
    const [studentOverideForUpdate, setStudentOverideForUpdate] = useState(new Array<StudentOverride>());    
    

    const [refreshMarkedClasses, setRefreshMarkedClasses] = useState(0);    
    const [isClone, setIsClone] = useState(false);    

    const paginationConfig = {
        recordsPerPage:9
    }

    const {addToast} = useSnackbar();

    const courseUpdatedToast = option => {
      addToast(option.text, option);  
    };

    const sidebarChannel = postal.channel("SideBar");



    useEffect(() => {
        const loadStaticData = async () => {
            let subOptions          = new Array<any>();
            let courseTypeOptions   = new Array<any>();
            let companyOptions      = new Array<any>();
            let courseLevelOptions  = new Array<any>();

            let response = await axios.get<Array<Subject>>(`/api/subjects/getall`);
            response.data.map(c => subOptions.push(c));

            let responseCourseLevel = await axios.get<Array<CourseLevel>>(`/api/courses/GetAllLevels`);
            responseCourseLevel.data.map(c => courseLevelOptions.push(c));

            let responseCourseType = await axios.get<Array<CourseType>>(`/api/courseTypes/getall`);
            responseCourseType.data.map(c => courseTypeOptions.push(c));

            let responseCompanies = await axios.get<Array<Company>>(`/api/companies/getall`);
            responseCompanies.data.map(c => companyOptions.push(c));

            setSubjects(subOptions);
            setCourseLevels(courseLevelOptions);
            setCourseTypes(courseTypeOptions);
            setCompanies(companyOptions);



            //Load course
            if(props.id && props.id !== 0){
                await loadCourse(props.id);
            
                loadClasses(props.id);

                //Allow access to the student tab now
                props.toogleStudentTabAccess(true);

                setIsEdit(false);

            } else {
                setIsEdit(true);
                //TODO: Investigate this
                //let updatedCourse = { ...currentCourse, endDateString : "01/02/2006", courseStatusId:1};
                //await setCurrentCourse(updatedCourse);
            }


        }
        loadStaticData();
        sidebarChannel.publish("changeUrl", "courses");
    }, []);

    //TODO: Seems flacky, investigate
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    useEffect(() => {
        if(props.refresh!==0) {
            refreshData();
        } 
      }, [props.refresh]);

    useEffect(() => {
        calculateRemainingCourseMins(classes);
    }, [classes]);


    //TODO: Why are we doing this
    const refreshData = async() => {
        await sleep(500);
        loadCourse(currentCourse.courseId);
        loadClasses();
    }


    const updateIdentifer = (course:Course) => {
        let newUpdateIdentifer = generateIdentifer(course);
        setUniqueIdentifier(newUpdateIdentifer);

        let uCourse = { ...currentCourse, title:newUpdateIdentifer};
        setCurrentCourse(uCourse);
    }


    const isUndefinedString = (value:string) => {
        if(!value){
            return "";
        } else {
            return value;
        }

    }


    const generateIdentifer2 = (updatedCourse:Course) => {
        let ui = "{day}{month}{shortYear}/{totalTime}";
        
    }

    const generateIdentifer = (updatedCourse:Course) => {
        //1. Get the companay code
        let ui = "{company} - {attend} - {date}{totalTime}";

        let selectedCompany = companies.find(c => c.companyId === updatedCourse.companyId);
        if(selectedCompany && selectedCompany.code !== "PR"){
            ui = ui.replace('{company}', (selectedCompany.code) ? selectedCompany.code : "" );
        } else {
            ui = ui.replace('{company} - ', "");
        }


        if(updatedCourse.typeId === CourseTypes.Individual) {
            let firstPerson = updatedCourse.students[0];
            if(firstPerson){
                ui = ui.replace('{attend}', firstPerson.firstName + " " + firstPerson.lastName);
            } else {
                ui = ui.replace('{attend} - ','');
            }
        } else {
            let courseLevelId:number = updatedCourse.courseLevelId;
            let currentCourseLevel = courseLevels.find(f => f.courseLevelId === courseLevelId);

            ui = ui.replace('{attend} - ', isUndefinedString(updatedCourse.subject) + ' ' + isUndefinedString((currentCourseLevel) ? currentCourseLevel.name : "No level") + ' - ');
        }
        if(!updatedCourse.typeId){
            ui = ui.replace('{attend} - ','');
        }

        moment.locale('en-gb');                  
        var a = moment(updatedCourse.startDate);
        const dom       = a.date();
        let domResult:string = "";

        if(dom < 10){
            domResult = '0' + dom.toString();
        } else {
            domResult = dom.toString();
        }

        const monthName = a.format('MM')
        const year = a.format('YY')

        if(updatedCourse.duration){
            ui = ui.replace('{numClasses}',updatedCourse.duration.toString());
        } else {
            ui = ui.replace('{numClasses}','');
        }
        if(updatedCourse.classLengthTemplate){
            ui = ui.replace('{classLength}',updatedCourse.classLengthTemplate.toString());
        }
        if(a && updatedCourse.startDateString && updatedCourse.startDateString!=""){
            ui = ui.replace('{date}',  domResult + monthName + year);
        } else {
            ui = ui.replace('{date}','No date');
        }

        if(updatedCourse.duration && updatedCourse.classLengthTemplate){
            ui = ui.replace('{totalTime}','/' + (updatedCourse.duration * updatedCourse.classLengthTemplate).toString());
        } else {
            ui = ui.replace('{totalTime}','');
        }

        

        return ui;
        
    }

    const loadCourse = async(courseId:number) => {
        let id:number;

        if(courseId){
            id = courseId;
        } else {
            id = currentCourse.courseId;
        }


        let responseCourses = await axios.get<Course>(`/api/courses/GetById?id=${id}`);
        let loadedCourse = responseCourses.data;

        //paginate students

        let students = new Array<Student>();

        for(var i=0; i < paginationConfig.recordsPerPage; i++) {
            if(loadedCourse.students[i]) {
                students.push(loadedCourse.students[i]);
            }
        }

        setSelectedStudents(loadedCourse.students);

        let numberOfPagesCount = Math.ceil(loadedCourse.students.length / paginationConfig.recordsPerPage);
        loadedCourse.students = students;

        //Load address drop down
        loadCompanyAddresses(loadedCourse.companyId);

        setNumberOfPages(numberOfPagesCount);
        setCurrentCourse(loadedCourse);
    }

    const handleClose = () => {
        setShow(false);
    }

    const updateGenerateOptions = (options:ClassGenVM) => {
        let id:number;
        
        id = (currentCourse.courseId ? currentCourse.courseId : 0);
        options.courseId = parseInt(id.toString());

        

        setGenerateOptions(options);
    } 

   
    const validateOptionsForm = ():boolean => {
        let formIsValid:boolean = true;
        let validationErrors:Array<string> = new Array<string>();

       

        if(!(generateOptions as any ).teacherId){
            validationErrors.push("teacherId");
            formIsValid = false;
        }
        if(!(generateOptions as any ).teacherRateId){
            validationErrors.push("teacherRateId");
            formIsValid = false;
        }
        /*if(!(generateOptions as any ).dayWeekId){
            validationErrors.push("dayWeekId");
            formIsValid = false;
        }*/
        if(!(generateOptions as any ).time){
            validationErrors.push("time");
            formIsValid = false;
        }
        if(!(generateOptions as any ).scheduleId){
            validationErrors.push("scheduleId");
            formIsValid = false;
        }
        if(!(generateOptions as any ).startDateString){
            validationErrors.push("startDateString");
            formIsValid = false;
        }
        if(!(generateOptions as any ).numberOfClasses){
            validationErrors.push("numberOfClasses");
            formIsValid = false;
        }
        if(!(generateOptions as any ).classLength){
            validationErrors.push("classLength");
            formIsValid = false;
        }
        

        setGeneratorValidationErrors(validationErrors);

        return formIsValid;
    }

    const loadClasses = async (courseId:number=0) => {
        let id:number;
        if(courseId !== 0){
            id = courseId;
        } else {
            id = currentCourse.courseId;
        }

        if(id) {
            var response = await axios.get<Array<Class>>(`/api/classes/GetByCourseId?id=${id}`);

            let classesData = response.data;
            let teacherCostData:number = 0;
            let maxEnrolledStudentsData:number = 0;

            classesData.map(c => {
                teacherCostData += c.teacherRate;
                if(c.studentCount > maxEnrolledStudentsData){
                    maxEnrolledStudentsData = c.studentCount;
                }
            });


            setTeacherCost(teacherCostData);
            setClasses(response.data);
        }
    }


    const createClasses = async () => {
        const isFormValid:boolean = validateOptionsForm();

        let newTime = (generateOptions.classLength * generateOptions.numberOfClasses) + currentCourse.totalClassLength; 
        let timeLimit = currentCourse.classLengthTemplate * currentCourse.duration;

        if(newTime > timeLimit){
            setClassGeneratorError("Sorry, you don't have enough time left to generate this class or classes!")
            return;
        }

        generateOptions.companyId = currentCourse.companyId;

        

        if(isFormValid){
            await axios.post(`/api/classes/GenerateClasses`,generateOptions);
            await loadClasses();
            setShow(false);

            //calculateRemainingCourseMins(classes);
        } else {
            //alert("Form invalid");
        }
    }

    
    const showClassGenerator = () => {

        if( (currentCourse.totalClassLength >= currentCourse.classLengthTemplate * currentCourse.duration)) {
            //courseUpdatedToast({className: "customToast",type: "error", text:"No minutes remaining" });


            swal("No minutes remaining","Sorry!", "error", {
                timer: 3000,
                buttons:{}
              });

        } else {
            setGeneratorValidationErrors(new Array<string>());
            setShow(true);
            setClassGeneratorError("");
        }

    }

    const updateCourse = (e: any) => {
        let updatedCourse = { ...currentCourse, [e.target.name]: e.target.value };
        setCurrentCourse(updatedCourse);
        
        FormService.setAreFormsDirty(true);
    }

    const handleFocus = (event) => event.target.select();

    const updateCourseNumeric = (e: any) => {
        let newValue:number = 0;
        if (!isNaN(e.target.value) && e.target.value !== "") {
            newValue = parseInt(e.target.value)
        } 
        
        let updatedCourse = { ...currentCourse, [e.target.name]: newValue };
        let id = generateIdentifer(updatedCourse);
        updatedCourse.title = id;

        setCurrentCourse(updatedCourse);

        FormService.setAreFormsDirty(true);
    }

    const subjectChangeHandler = (e: any) => {
        const text = e.target.options[e.target.selectedIndex].text;

        let updatedCourse = { ...currentCourse, subjectId: parseInt(e.target.value), subject: text};
        let id = generateIdentifer(updatedCourse);
        updatedCourse.title = id;

        setCurrentCourse(updatedCourse);

        FormService.setAreFormsDirty(true);
    }

    const companyChangeHandler = (e: any) => {
        const text = e.target.options[e.target.selectedIndex].text;

        let updatedCourse = { ...currentCourse, companyId: parseInt(e.target.value), company: text};
        let id = generateIdentifer(updatedCourse);
        updatedCourse.title = id;

        setCurrentCourse(updatedCourse);

        /*
        New code to 
        */
        loadCompanyAddresses(e.target.value);

        FormService.setAreFormsDirty(true);
    }

    const companyAddressChangeHandler = (e: any) => {
        const text = e.target.options[e.target.selectedIndex].text;


        let updatedCourse = { ...currentCourse, addressId: parseInt(e.target.value), address: text};
        setCurrentCourse(updatedCourse);


        FormService.setAreFormsDirty(true);
    }


    const loadCompanyAddresses = async(companyId:number) => {
        const response = await axios.get<Array<Address>>(`api/companies/GetAllByCompanyId?companyId=${companyId}`);
        setCompanyAddresses(response.data);
    };


    const levelChangeHandler = (e: any) => {
        const text = e.target.options[e.target.selectedIndex].text;


        let updatedCourse = { ...currentCourse, courseLevelId: parseInt(e.target.value), courseLevel: text };
        let id = generateIdentifer(updatedCourse);
        updatedCourse.title = id;

        setCurrentCourse(updatedCourse);

        FormService.setAreFormsDirty(true);
    }

    const courseTypeChangeHandler = (e: any) => {
        const text = e.target.options[e.target.selectedIndex].text;

       
        let updatedCourse = { ...currentCourse, typeId: parseInt(e.target.value), courseType: text };
        let id = generateIdentifer(updatedCourse);
        updatedCourse.title = id;

        setCurrentCourse(updatedCourse);

        FormService.setAreFormsDirty(true);
    }

    const dateOfBirthChangeHandler = (date:any) => {
        let newDate = (date.getDate()).toString() + "/" + ((date.getMonth() + 1).toString()+ "/" + date.getFullYear().toString())


        moment.locale('en-gb');                  
        var a = moment(newDate, 'DD/MM/YYYY');

        let updatedCourse = { ...currentCourse, startDateString: newDate, startDate:a.toDate() };

        let id = generateIdentifer(updatedCourse);
        updatedCourse.title = id;



        setCurrentCourse(updatedCourse);

        FormService.setAreFormsDirty(true);
    }

    const createCourse = async() => {

        let isFormValid: boolean = true;
        let invalid = new Array<string>();

        /*
        if (!checkValidDateFormat(currentCourse.startDateString)) {
            invalid.push("startDateString");
            isFormValid = false;
        }
        if (!checkValidDateFormat(currentCourse.endDateString)) {
            invalid.push("endDateString");
            isFormValid = false;
        }*/

        if (!currentCourse.title || currentCourse.title === "") {
            invalid.push("title");
            isFormValid = false;
        }
        /*if (!currentCourse.description || currentCourse.description === "") {
            invalid.push("description");
            isFormValid = false;
        }*/


        if (currentCourse.typeId === 0) {
            invalid.push("typeId");
            isFormValid = false;
        }

        if (currentCourse.subjectId === 0) {
            invalid.push("subjectId");
            isFormValid = false;
        }

        if (currentCourse.courseLevelId === 0) {
            invalid.push("courseLevelId");
            isFormValid = false;
        }

        if (currentCourse.companyId === 0) {
            invalid.push("companyId");
            isFormValid = false;
        }
        

        if (isFormValid) {
            const newCourse = await axios.post<Course>('/api/courses/upsert', currentCourse);


            if(isClone){
                props.history.push(`/CoursesForm/${newCourse.data.courseId}`);
                loadCourse(newCourse.data.courseId);
                setIsClone(false);
                return;
            }            

            let courseToUpdate = newCourse.data;
            
            setCurrentCourse(courseToUpdate);
            setIsEdit(false);

            //Allow access to the student tab now
            props.toogleStudentTabAccess(true);
            props.updateCourseDetails(newCourse.data);
            loadClasses(newCourse.data.courseId);

            //courseUpdatedToast({className: "customToast",type: "success", text:"Course successfully updated" });

            swal("Course successfully updated","Well done!", "success", {
                timer: 3000,
                buttons:{}
              });

            FormService.setAreFormsDirty(false);
            
        }
        setValidationError(invalid);
    }

    const getSeriesStatusId = (statusId:number) => {
        if(statusId === 0){
            return "No Students";
        }
        else if (statusId === 1) {
            return "Scheduled";
        } else if (statusId === 2){
            return "Requires marking";
        } else if (statusId === 3){
            return "Marked";
        }  
    } 

    const getSeriesStatusColor = (statusId:number) => {
        if (statusId === 2){
            return "info";
        } else if (statusId === 1) {
            return "primary";
        } else if (statusId === 2){
            return "danger";
        } else if (statusId === 3){
            return "success";
        }  else if (statusId === 0){
            return "danger";
    }  
} 

    const isFieldValid = (fieldName: string): boolean => {
        let error = validationError.find(s => s == fieldName);
        if (error) {
            return true;
        } else {
            return false;
        }
    }

   

    const SaveClassChanges = async() => {
        let classData = currentClass;
        let errorTooManyMins:boolean = false;

        let updatedClass = classes.find(c => c.classId == classData.classId);
        if(updatedClass) {

            if(currentCourse.totalClassLength  + (classData.classLength -  updatedClass.classLength)  > (currentCourse.classLengthTemplate * currentCourse.duration)){
                errorTooManyMins = true;
            }
        }

        if(errorTooManyMins){
            setClassGeneratorError("Not enough minutes remaining");
            return;
        } 


        if (series === -1) {
            await axios.post(`/api/classes/updateclass`, classData);
        } else {
            classData.seriesId = series;
            await axios.post(`/api/classes/applyToSeries`, classData);
        } 

       
        let classesLoc = classes;
        let classToChange = classesLoc.find(c => c.classId == classData.classId);

        if(classToChange){
            classToChange.classLength = classData.classLength;
        }


            calculateRemainingCourseMins(classesLoc);
            setShowEditClass(false);
        

        loadClasses();
    }

    const setSeriesId = (seriesId: number) => {
        if(seriesId !== -1){
            setShowMoveToEndButton(false);
        } else {
            setShowMoveToEndButton(true);
        }
        setSeries(seriesId);
    }

    const updateClass = (classDTO:ClassGenVM) => {
        setCurrentClass(classDTO);
    }

    const classRowSelected = (classId:number) => {
        setSelectedClassId(classId);
        setShowEditClass(true);
        setSeries(-1);
        setClassGeneratorError("");
    }

    const deleteClass = async (id:number) => {
        let classesLoc = classes.filter(c => c.classId !== id);

        calculateRemainingCourseMins(classesLoc);

        await axios.get(`/api/classes/deletebyid?id=${id}`);
        loadClasses();
        //loadStudentsInCourse();
    }

    const publishToogle = () => {
        const newPublish = (currentCourse.courseStatusId === 1) ? 2 : 1;
        setCurrentCourse({ ...currentCourse, courseStatusId: newPublish });
    }

    const getCoursePrice = () => {
        let enrolledStudents:Array<Student> = new Array<Student>();
        props.students.map(s => {
            if(s.statusId){
                enrolledStudents.push(s);
            }
        });

        if(profit !== (enrolledStudents.length * currentCourse.price) - teacherCost){
            setProfit((enrolledStudents.length * currentCourse.price) - teacherCost)

        }

        return currencyFormat(enrolledStudents.length * currentCourse.price);
    }

    const currencyFormat = (num) => {
        return '£' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    const calculateProfit = () => {
        return currencyFormat(coursePrice - teacherCost); 
    }

    const getStatusSpanFromId = (statusId: number) => {
        if (statusId === 1) {
            return <span className="badge badge-student-course-status-enrolled">Enrolled</span>;
        } else if (statusId === 2){
            return <span className="badge badge-student-course-status-enquired">Enquired</span>;
        } else if (statusId === 3){
            return <span className="badge badge-student-course-status-waiting">Waiting</span>;
        }
    }

    const toogleAddStudentsModal = () => {
        setShowAddStudents(!showAddStudents);
    }

    const updateStudents = async (students: Array<Student>) => {
        setTempStudentsStore(students);
    }

    const saveStudentChanges = async () => {
        //TODO: This seems a little too complicated

        currentCourse.students = tempStudentsStore;

        let courseReturned = await axios.post<Course>('/api/courses/upsert', currentCourse);
        let students = new Array<Student>();
        let offset = ((1-1) * paginationConfig.recordsPerPage);

        for(var i= offset; i < offset + paginationConfig.recordsPerPage; i++) {
            if(courseReturned.data.students[i]){
                students.push(courseReturned.data.students[i]);
            }
        }

        currentCourse.students = students;
        setCurrentCourse(currentCourse);

        await setShowAddStudents(false);

        let numberOfPagesCount = Math.ceil(courseReturned.data.students.length / paginationConfig.recordsPerPage);

        setCurrentCourse(currentCourse);
        setSelectedStudents(courseReturned.data.students);

        setNumberOfPages(numberOfPagesCount);

        loadClasses();
        
    }

    const renderPaginationItem = () => {
        let output = new Array<any>();
        for(var i=1; i < numberOfPages + 1; i++) {
            if(i != currentPage){
                output.push(<Pagination.Item key={i} data-page={i}> {i} </Pagination.Item>)
            
            } else {
                output.push(<Pagination.Item key={i} data-page={i} active> {i} </Pagination.Item>)
            }
        }
        return output;
    }

    const handleClick = (e) => {
        const clickValue = e.target.offsetParent.getAttribute("data-page")
        ? e.target.offsetParent.getAttribute("data-page")
        : e.target.getAttribute("data-page");

        repaginate(parseInt(clickValue));

        //this.setState({ currentPage: parseInt(clickValue) })
        
        setCurrentPage(parseInt(clickValue));

    }

    const repaginate = (pageNub:number) => {
        let students = new Array<Student>();

        let offset = ((pageNub-1) * paginationConfig.recordsPerPage);

        for(var i= offset; i < offset + paginationConfig.recordsPerPage; i++) {
            if(selectedStudents[i]){
                students.push(selectedStudents[i]);
            }
        }

        currentCourse.students = students;
        setCurrentCourse(currentCourse);
    }

    const removeStudent = async(studentId:number) => {
        setShowStudentDeleteConfirm(true);
        setCurrentStudentId(studentId);
    }

    const removeStudentAction = async() => {
        await axios.get(`/api/courses/DeleteStudentFromCourse?studentId=${currentStudentId}&courseId=${props.id}`);

        loadCourse(props.id);
        loadClasses();
        setShowStudentDeleteConfirm(false);
    }

    const showDeleteConfirm = (classId:number) =>{
        setCurrentSelectedClassId(classId);
        setShowClassDeleteConfirm(true)
    }

    const deleteSeries = async (classId:number) => {
        await axios.get(`/api/classes/DeleteSeriesByClassId?id=${classId}`);
        loadClasses();
    }

    const handlecourseStatusDropDown = async(e:any) => {
        const newStatus:number = parseInt(e.target.value);

        setCurrentSelectedStatus(newStatus);
    }

    const updateCourseStatus = async() => {
        var response = await axios.get(`/api/courses/UpdateCourseStatus?courseId=${currentCourse.courseId}&courseStatusId=${currentSelectedStatus}`);
        //fetchCourses();

        setShowStatusUpdate(false);

        setCurrentCourse({ ...currentCourse, courseStatusId: currentSelectedStatus });
    } 

   

    const loadStudentsInCourse = async() => {
        const response = await axios.get<Array<Student>>(`/api/students/GetStudentsByCourseId?id=${currentCourse.courseId}`);

        setCurrentCourse({ ...currentCourse, students: response.data });
    }


    const classMarkedUpdate = () => {
        loadClasses();
        loadStudentsInCourse();
        loadCourse(currentCourse.courseId);
    }

    const showMarkingModal = (classId:number) => {
        if(currentCourse.courseStatusId !== 1){
            setRefreshMarkedClasses(refreshMarkedClasses+1);
            setCurrentSelectedClassId(classId);
            setShowMarkClassesModal(true);
        } else {
            courseUpdatedToast({className: "customToast",type: "error", text:"You can not mark classes for a draft course" });
        }
    }

    const calculateRemainingCourseMins = (classesToCalc:Array<Class>) => {
        let minCount:number = 0;
        if(classesToCalc && classesToCalc) {
            classesToCalc.map(c => {
                minCount += c.classLength;
            });
        } else {
            minCount = 0;
        }

       
        setCurrentCourse({ ...currentCourse, totalClassLength: minCount });
    }

    const getTemplatesMins = () => {
        return currentCourse.classLengthTemplate * currentCourse.duration;
    }

    const getCourseMinsUsed = () => {
        let result:number = (currentCourse.classLengthTemplate * currentCourse.duration) - currentCourse.totalClassLength
        
        let toReturn:any;
        if(result < 0) {
            toReturn = <span style={{color:"red"}}>{result}</span>;
        } else {
            toReturn = <span >{result}</span>;
        }
        
        return toReturn;
    }

    const startRef = useRef<DatePicker>();

    const moveClassToEnd = async () => {
        await axios.get(`/api/classes/UpdateClassDateToLast?classId=${selectedClassId}`);
        loadClasses();
        setShowEditClass(false);
    }

    const cloneCourse = () => {
        //reset students
        /*let studentToReset = [...currentCourse.students];

        studentToReset.map(s => {
            s.hasMarkedOrPaid = false;
        });
        */

        setClasses(new Array<Class>());
        setCurrentCourse({ ...currentCourse, courseId: 0, parentId: currentCourse.courseId, startDateString:'', courseStatusId:1  }); //, students:studentToReset

        //c.hasMarkedOrPaid

       //courseUpdatedToast({className: "customToast",type: "success", text:"Course successfully cloned" });

        swal("Course successfully cloned","Well done!", "success", {
            timer: 3000,
            buttons:{}
        });

        setIsClone(true);

    }

    const showStudentsInClass_Click = async(classId:number) => {
        const response = await axios.get<Array<Student>>(`/api/Students/GetStudentsByClassId?id=${classId}`);

        setStudentsInClass(response.data);
        setShowStudentsInClassModal(true);
    }

    const PrintStudentAttendence = () => {
        window.open(`/reports/printstudentattendence?id=${props.id}`,"_blank", "menubar=no,location=no,height=1200,width=1200");
    }

    const UpdateStudentOveride = (data:Array<StudentOverride>) => {
        setStudentOverideForUpdate(data);
    }

   const SaveStudentOveride = async() => {
       //alert(JSON.stringify(studentOverideForUpdate));
       await axios.post('/api/courses/UpdateStudentOveride', studentOverideForUpdate);
       setShowStudentOveride(false);
       loadClasses(props.id);
       loadStudentsInCourse();
   }

    return (<>
        <div>
            <div className="row">
                <div className="col">
                    <div className="card" >
                        <div>
                            <h4 className="header-title" style={{paddingTop:"12px",paddingBottom:"10px", paddingLeft:"15px"}}>Course details</h4>

                                <div className="col" style={{paddingLeft:"85px"}}>

                                    <div className="form-group row">
                                        <div style={{width:"155px",paddingTop: "6px", textAlign:"right", paddingRight:"30px"}}>
                                            <label>Name</label>
                                        </div>
                                        <div style={{paddingTop:"0px" }}>
                                            <input type="text" style={{width:"380px"}} className={`form-control ${isFieldValid("title") ? "parsley-error" : ""}`} value={currentCourse.title} name="title" onChange={(e:any) => updateCourse(e)}  />
                                        </div>
                                        <i className="mdi mdi-sync pink" style={{paddingLeft:"5px", paddingTop:"10px", cursor:"pointer"}} onClick={() => updateIdentifer(currentCourse) }></i>
                                    </div>
                                    
                                    <InputTextBoxDuel label="Description" name="description" value={currentCourse.description} labelWidth={155} mode={"E"} onChange={(e:any)=>updateCourse(e)}   width={380} />
                                    



                                    <div className="form-group row">
                                        <div style={{width:"155px",paddingTop: "6px", textAlign:"right", paddingRight:"30px"}}>
                                            <label>Subject</label>
                                        </div>
                                        <div style={{paddingTop:"0px" }}>
                                            <select id="subject" value={currentCourse.subjectId} style={{width:"380px"}} className={`form-control ${isFieldValid("subjectId") ? "parsley-error" : ""}`} onChange={(e:any)=>subjectChangeHandler(e)}>
                                                <option style={{color:"#A9A9A9"}}> -- Please select -- </option>
                                                {subjects.map(s => (
                                                    <option value={s.subjectId}>{s.name}</option>
                                                ))}
                                            </select> 
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div style={{width:"155px",paddingTop: "6px", textAlign:"right", paddingRight:"30px"}}>
                                            <label >Level</label>
                                        </div>
                                        <div style={{paddingTop:"0px" }}>
                                            <select value={currentCourse.courseLevelId} style={{width:"380px"}} className={`form-control ${isFieldValid("courseLevelId") ? "parsley-error" : ""}`} onChange={(e:any)=>levelChangeHandler(e)}>
                                                <option style={{color:"#A9A9A9"}}> -- Please select -- </option>
                                                {courseLevels.map(l => (
                                                    <option value={l.courseLevelId}>{l.name}  {(l.description && l.description!="") ? " - " + l.description : ""}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div style={{width:"155px",paddingTop: "6px", textAlign:"right", paddingRight:"30px"}}>
                                            <label>Course type</label>
                                        </div>
                                        <div style={{paddingTop:"0px" }}>
                                            <select value={currentCourse.typeId} style={{width:"380px"}} className={`form-control ${isFieldValid("typeId") ? "parsley-error" : ""}`} onChange={(e:any)=>courseTypeChangeHandler(e)}>
                                                <option style={{color:"#A9A9A9"}}> -- Please select -- </option>
                                                {courseTypes.map(s => (
                                                    <option value={s.courseTypeId}>{s.name}</option>
                                                ))}
                                            </select> 
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div style={{width:"155px",paddingTop: "6px", textAlign:"right", paddingRight:"30px"}}>
                                            <label>Company</label>
                                        </div>
                                        <div style={{paddingTop:"0px" }}>
                                            <select className={`form-control ${isFieldValid("companyId") ? "parsley-error" : ""}`} style={{width:"380px"}} value={currentCourse.companyId}  onChange={(e:any)=>companyChangeHandler(e)}>
                                            <option value={0}>{'-- Please select --'}</option>
                                                {companies.map(s => (
                                                    <option value={s.companyId}>{s.name}</option>
                                                ))}
                                            </select> 
                                        </div>
                                    </div>

                                    <InputTextBoxDuel label="Address" name="definedAddress" value={currentCourse.definedAddress} labelWidth={155} mode={"E"} onChange={(e:any)=>updateCourse(e)}   width={380} />



                                    {/*<div className="form-group row">
                                        <div style={{width:"155px",paddingTop: "6px", textAlign:"right", paddingRight:"30px"}}>
                                            <label>Company Address</label>
                                        </div>
                                        <div style={{paddingTop:"0px" }}>
                                            {companyAddresses && companyAddresses.length > 0 &&
                                                <select className="form-control" style={{width:"380px"}} value={currentCourse.addressId}  onChange={(e:any)=> companyAddressChangeHandler(e)}>
                                                        {companyAddresses.map(a => (
                                                            <option value={a.addressId}>{a.address}</option>
                                                        ))}
                                                    <option value={-1}>Zoom</option>
                                                </select>
                                            } 
                                            {(!companyAddresses || companyAddresses.length === 0) && <div style={{paddingTop:"7px"}}>Select company above</div>}
                                        </div>
                                        </div>*/}


                                    <div className="form-group row">
                                        <div style={{width:"155px",paddingTop: "6px", textAlign:"right", paddingRight:"30px"}}>
                                            <label>Start date</label>
                                        </div>
                                        <div>
                                            <div className="input-group" style={{paddingTop:"0px" }}>
                                                <>
                                                    <DatePicker calendarStartDay={1} locale="eu"  ref={startRef} autoComplete="off" className={`form-control-datepicker-250 ${isFieldValid("startDateString") ? "parsley-error" : ""}` }  dateFormat="dd/MM/yyyy" style={{width:"35px"}} name="startDateString" onChange={(e:any)=>dateOfBirthChangeHandler(e)} value={currentCourse.startDateString} autocomplete="nope"  /> 

                                                    <div className="input-group-append" onClick={() => startRef.current.setOpen(true)}>
                                                        <span className="input-group-text  b-0 text-white btn-primary-pink"><i className="ti-calendar"></i></span>
                                                    </div>
                                                </>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div style={{width:"155px",paddingTop: "6px", textAlign:"right", paddingRight:"30px"}}>
                                            <label>Number of classes</label>
                                        </div>
                                        <div style={{paddingTop:"0px" }}>
                                            <input type="text" style={{width:"112px"}} className="form-control" value={currentCourse.duration} name="duration" onChange={(e:any)=>updateCourseNumeric(e)} onClick={(e:any) => {handleFocus(e)}}  />
                                        </div>
                                        <div style={{width:"155px",paddingTop: "6px", textAlign:"right", paddingRight:"30px"}}>
                                            <label>Class length</label>
                                        </div>
                                        <div style={{paddingTop:"0px" }}>
                                        <input type="text" style={{ width: "112px" }} className="form-control" value={currentCourse.classLengthTemplate} onChange={(e: any) => updateCourseNumeric(e)} onClick={(e:any) => {handleFocus(e)}} name="classLengthTemplate"  />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div style={{width:"155px",paddingTop: "6px", textAlign:"right", paddingRight:"30px"}}>
                                            <label>Max students</label>
                                        </div>
                                        <div style={{paddingTop:"0px" }}>
                                            <input type="text" className="form-control" value={currentCourse.maxNumberStudents} name="maxNumberStudents" onChange={(e:any)=>updateCourseNumeric(e)} onClick={(e:any) => {handleFocus(e)}} style={{width:"112px"}} /> 
                                        </div>

                                        <div style={{width:"155px",paddingTop: "6px", textAlign:"right", paddingRight:"30px"}}>
                                            <label>Min students</label>
                                        </div>
                                        <div style={{paddingTop:"0px" }}>
                                            <input type="text" className="form-control" value={currentCourse.minNumberStudents} name="minNumberStudents" onChange={(e:any)=>updateCourseNumeric(e)} onClick={(e:any) => {handleFocus(e)}}  style={{width:"112px"}} /> 
                                        </div>
                                    </div>
                                    
                                    <div className="form-group row">
                                        <div style={{width:"155px",paddingTop: "6px", textAlign:"right", paddingRight:"30px"}}>
                                            <label>Price</label>
                                        </div>
                                        <div style={{paddingTop:"0px" }}>   

                                            <div className="input-group bootstrap-touchspin bootstrap-touchspin-injected" style={{width:"112px"}} >
                                                <span className="input-group-addon bootstrap-touchspin-prefix input-group-prepend">
                                                    <span className="input-group-text">£</span>
                                                </span>
                                                <input data-toggle="touchspin" type="text" data-bts-prefix="$"  className="form-control" value={currentCourse.price} name="price" onChange={(e:any)=>updateCourseNumeric(e)}  />
                                            </div>

                                        </div>
                                    </div>

                                    


                                   
                                    { (currentCourse.courseStatusId && currentCourse.courseStatusId!==0) &&

                                        <><div className="form-group row" >
                                            <div style={{width:"155px",paddingTop: "6px", textAlign:"right", paddingRight:"30px"}}>
                                                <label>Status</label>
                                            </div>
                                            <div style={{paddingTop:"7px" }}> 
                                                <CourseStatusBadge statusId={currentCourse.courseStatusId} allowClick={true}  onClick={()=>setShowStatusUpdate(true)}/>
                                            </div>
                                        </div>
                                       </>

                                        || <div style={{paddingTop:"20px"}}>&nbsp;</div>
                                    }
                                </div>

                                <div className="row mb-0">
                                    <div className="col-12" style={{paddingLeft:"11px"}}>
                                        <div  style={{ paddingRight:"15px",paddingTop:"0px", paddingBottom:"10px"}}>
                                            <span style={{paddingLeft:"15px"}}>
                                               
                                                {currentCourse.courseStatusId !==1 && currentCourse.courseId!==0 && !currentCourse.hasClone && <ActionButton onClick={() => cloneCourse()} text="CLONE" />}</span> <span>&nbsp;
                                                {currentCourse.hasClone && <i className="ti-star pink" title="Course has been cloned" style={{fontSize:"24px"}} ></i>}   
                                            </span>
                                            <span style={{float: "right", paddingBottom:(currentCourse.courseId == 0 || currentCourse.hasClone) ? "9px" : "0px"}}><ActionButton onClick={createCourse} text="SAVE" /></span>



                                        </div>
                                    </div>


                                </div>
                        </div>
                    </div>
                </div>

                <div className="col">
                    <div>
                        <div>
                            <div className="col-sm card" style={{padding:"15px",height:(currentCourse.courseId!==0) ? "631px" : "629px"}} >
                                <div>
                                    <div className="row ">
                                        <div className="col-12" style={{paddingLeft:"11px"}}>
                                            <div  style={{float: "right", paddingRight:"0px",paddingTop:"0px",paddingBottom:"15px"}}>{(currentCourse.courseId !== 0 && !isEdit) && <ActionButton onClick={()=> toogleAddStudentsModal()} text="ADD STUDENTS" /> }</div>
                                            <h4 className="header-title m-t-5 " style={{fontSize:"16px",marginBottom:"5px"}}>Students </h4>
                                        </div>    
                                    </div>

                                    
                                    <div  style={{height:"548px"}}>
                                        <table className="table  mb-0 table-hover" >
                                            <thead>
                                                <tr>
                                                    <th scope="col">First Name</th>
                                                    <th scope="col">Last Name</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col" style={{width:"25px"}}></th>
                                                    <th scope="col" style={{width:"25px"}}></th>
                                                </tr>
                                            </thead>
                                            <tbody >
                                                {currentCourse.students.map(c => (
                                                    <tr>
                                                        <td style={{ cursor: "pointer" }} onClick={() => { props.history.push(`/studentsForm/${c.studentId}`) }}>{c.firstName}  </td>
                                                        <td style={{cursor:"pointer"}} onClick={() => {props.history.push(`/studentsForm/${c.studentId}`)}}>{c.lastName}</td>
                                                        <td>{c.email}</td>
                                                        <td>{getStatusSpanFromId(c.statusId)} </td>
                                                        <td onClick={()=> {setCurrentStudent(c); setShowStudentOveride(true)}} style={{width:"25px", paddingLeft:"0px",paddingRight:"7px"}}>
                                                            {c.statusId === 1 && !isClone && <i className="ti-id-badge pink" title="Manage enrollment" style={{cursor:"pointer",fontSize:"22px"}}></i>}
                                                        </td>

                                                        <td style={{width:"25px", paddingLeft:"0px",paddingRight:"0px"}}>
                                                            {!c.hasMarkedOrPaid && !isClone && <div  style={{float: "right", paddingRight:"5px",paddingTop:"1px", cursor: "pointer"}} onClick={()=> removeStudent(c.studentId) } ><i className="ti-trash pink" title="Remove student from course" style={{float: "right",fontSize: "20px"}}></i></div>}
                                                        </td>
                                                    </tr>
                                                ))}
                                                 {!currentCourse.students || currentCourse.students.length === 0 && (
                                                    <tr>
                                                        <td colSpan={8} align="center">No students have been added</td>
                                                    </tr>    
                                                )}
                                            </tbody>
                                        </table>

                                        {(numberOfPages > 1) &&                
                                            <Pagination onClick={handleClick} style={{float:"right", paddingTop:"8px"}}>
                                                <Pagination.First data-page={1} />
                                                <Pagination.Prev data-page={(currentPage !== 1 ) ?  currentPage - 1 :  1 }   />
                                                    {renderPaginationItem().map(pag=>(
                                                        pag
                                                    ))}
                                                <Pagination.Next  data-page={(currentPage < numberOfPages ) ?  currentPage + 1 :  numberOfPages} />
                                                <Pagination.Last data-page={numberOfPages}  />
                                            </Pagination>
                                        }

                                    </div>
                                    </div>

                                </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12" style={{paddingLeft:"11px", paddingRight:"9px"}}>
                    <div className="card" >
                        <div>
                            
                            <div className="row">
                                <div className="col-12">
                                    <div  >
                                        <div>
                                            {(1===1) && 
                                            <div className="col-sm " style={{padding:"15px"}}>
                                

                                                <div className="row ">
                                                    <div className="col-12" style={{paddingLeft:"11px"}}>
                                                        {(classes.length > 0 && currentCourse.courseStatusId !==1) && <div style={{float: "left"}}><ActionButton onClick={()=> setShowStudentAttendenceReport(true)} text="ATTENDANCE REPORT" color='purple' /></div>}
                                                        
                                                        <div style={{float: "right", paddingRight:"0px",paddingTop:"0px",paddingBottom:"15px"}}>{(currentCourse.courseId !== 0 && !isEdit) && <ActionButton onClick={()=> showClassGenerator()} text="CREATE CLASSES" /> }</div>
                                                        <div  className="text-center" style={{fontSize:"16px",marginBottom:"5px"}}>
                                                            
                                                            <div className=" col-md-8  " style={{marginLeft:"32.66667%",width:"625px"}}><span style={{paddingRight:"20px"}} > <strong>COURSE DURATION: &nbsp;&nbsp; {getTemplatesMins()} mins</strong></span>  | <span style={{paddingLeft:"20px"}}><strong>TIME DISCREPANCY: &nbsp;&nbsp; {getCourseMinsUsed()} mins</strong> </span> </div>
                                                             
                                                        </div>
                                                    </div>    
                                                </div>

                                                <table className="table mb-0 table-hover" >
                                                    <thead>
                                                        <tr>
                                                            <td scope="col" style={{width:"2%"}}></td>
                                                            <td scope="col" style={{width:"2%"}}><strong>Day</strong></td>
                                                            <td scope="col"  style={{width:"9%"}}><strong>Date</strong></td>
                                                            <td scope="col"  style={{width:"9%"}}><strong>Time</strong></td>
                                                            <td scope="col"  style={{width:"6%"}}><strong>Length</strong></td>
                                                            <td scope="col"  style={{width:"14%"}}><strong>Teacher</strong></td>
                                                            <td scope="col" align="right"  style={{width:"6%"}}><strong>Rate</strong></td>
                                                            <td scope="col"  style={{width:"12%"}}><strong>Company</strong></td>
                                                            <td scope="col" style={{width:"5%"}}><strong>Students</strong></td>
                                                            <td scope="col" style={{width:"9%"}}><strong>Type</strong></td>
                                                            <td scope="col" style={{width:"9%"}}><strong>Status</strong></td>
                                                            <td scope="col" style={{textAlign: "right",width:"6%" }}><strong>Action</strong></td>
                                                        </tr>
                                                    </thead>
                                                    <tbody >
                                                        {classes && classes.map((c,i) => (
                                                            <tr>
                                                                <td><span className="badge badge-pink" style={{width:"25px"}}>{i+1}</span></td>
                                                                <td>{c.dayWeek}</td>
                                                                <td>{c.classDateString}</td>
                                                                <td>{c.classDatesCombined}</td>
                                                                <td>{c.classLength} mins</td>
                                                                <td>{c.teacher}</td>
                                                                <td align="right"><CurrencyFormater value={c.teacherRate} /></td>
                                                                <td>{(c.company) ? c.company : "No company"}</td>
                                                                <td><div style={{width:"55px", textAlign:"right"}}>{c.studentCount}</div></td>
                                                                <td>{c.courseType}</td>
                                                                <td>
                                                                    <ClassStatusBadge statusId={c.seriesStatusId}  onClick={() => showMarkingModal(c.classId) } />
                                                                </td>
                                                                
                                                                <td align="center">
                                                                    {c.seriesStatusId !== 6 &&
                                                                    <>
                                                                        <div  style={{float: "right" ,paddingTop:"6px", cursor: "pointer"}}><i className="ti-trash pink" style={{float: "right",fontSize: "20px"}} onClick={()=> showDeleteConfirm(c.classId) } ></i></div>
                                                                        <div  style={{float: "right", width:"6px"}}>&nbsp;</div>
                                                                        <div  style={{float: "right",paddingTop:"6px", cursor: "pointer"}}><i className="ti-pencil pink" style={{float: "right",fontSize: "20px"}} onClick={()=> classRowSelected(c.classId)} ></i></div>
                                                                    </>}
                                                                </td>
                                                            </tr>
                                                        ))}

                                                        {!classes || classes.length === 0 && (
                                                            <tr>
                                                                <td colSpan={12} align="center">No classes currently</td>
                                                            </tr>
                                                        ) 

                                                        }
                                                    
                                                        
                                                            
                                                    </tbody>
                                                </table>
                                            </div>
                                        }    


                                        </div>
                                    </div>
                                </div>
                            </div>




                        </div>
                    </div>
                </div>
            </div>
            </div>

            <Modal show={showEditClass} onHide={()=>setShowEditClass(false)} dialogClassName="modal-YYYpx" centered>
                {!showMoveToEnd && <Modal.Header closeButton>
                    <Modal.Title>Edit Classes</Modal.Title>
                </Modal.Header>}
                <Modal.Body>
                    
                    {!showMoveToEnd && <ClassesEditForm errorMessage={classGeneratorError} id={selectedClassId} updateClass={updateClass} setSeriesId={setSeriesId} />}

                    {showMoveToEnd && 
                        <>
                             <div style={{textAlign:"center", paddingBottom:"20px",paddingTop:"20px"}}><i className="ti-help-alt" style={{color:"#fe6271", fontSize:"72px",paddingTop:"15px"}}></i></div>

                            <div style={{textAlign:"center",fontSize:"27px", fontWeight:"bold", paddingBottom:"8px", margin:"0px"}} className="header-title m-t-0 m-b-30">You are moving this class to the end of the course</div>

                            <div style={{textAlign:"center",fontSize:"22px",paddingBottom:"22px"}} className="header-title m-t-0 m-b-30">
                                Are you sure?
                            </div>
                        </>
                    }

                </Modal.Body>
                <Modal.Footer>

                    <div className="mr-auto">
                        {!showMoveToEnd &&  showMoveToEndButton && <ActionButton onClick={() => setShowMoveToEnd(true)} text="MOVE TO END"/>}
                    </div>
                    {!showMoveToEnd && <ActionButton onClick={()=>SaveClassChanges()} text="UPDATE CLASSES"/>}
                    {showMoveToEnd && 
                        <div>
                            <ActionButton onClick={() => {setShowMoveToEnd(false);moveClassToEnd()} } text="OK" />&nbsp;
                            <CancelButton onClick={() => {setShowMoveToEnd(false)}}  />
                        </div>
                    }

                </Modal.Footer>
            </Modal>


            <Modal show={show} onHide={handleClose} dialogClassName="modal-1200px" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Class Generator</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ClassesForm id={currentCourse.courseId} errorMessage={classGeneratorError} currentCourse={currentCourse} updateGenerateOptions={updateGenerateOptions}  generatorValidationErrors={generatorValidationErrors} classes={classes} />
                </Modal.Body>
                <Modal.Footer>
                    <ActionButton onClick={createClasses} text="GENERATE CLASSES"/>
                </Modal.Footer>
            </Modal>


            <Modal show={showAddStudents} onHide={toogleAddStudentsModal} dialogClassName="modal-1400px">
                <Modal.Header closeButton>
                    <Modal.Title>Add Students</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <AddStudents id={currentCourse.courseId} updateStudents={updateStudents} />

                </Modal.Body>
                <Modal.Footer>
                    <ActionButton text="SAVE" onClick={() => saveStudentChanges()}  />
                    <CancelButton onClick={() => toogleAddStudentsModal()} />
                </Modal.Footer>
            </Modal>


            <Modal show={showClassDeleteConfirm} onHide={() => setShowClassDeleteConfirm(false)} dialogClassName="modal-MMMpx" centered>
                
                <Modal.Body>
                    <div style={{textAlign:"center", paddingBottom:"20px",paddingTop:"20px"}}><i className="ti-help-alt" style={{color:"#fe6271", fontSize:"72px",paddingTop:"15px"}}></i></div>

                    <div style={{textAlign:"center",fontSize:"27px", fontWeight:"bold", paddingBottom:"8px", margin:"0px"}} className="header-title m-t-0 m-b-30">Delete classes</div>

                    <div style={{textAlign:"center",fontSize:"22px",paddingBottom:"8px"}} className="header-title m-t-0 m-b-30"><>Do you wish to remove just this class or the whole class series?</></div>
                
                    <div className='row'>
                        <div className=" mr-auto" style={{paddingLeft:"8px"}}><ActionButton onClick={()=> {deleteSeries(currentSelectedClassId); setShowClassDeleteConfirm(false); }} text="DELETE SERIES" /></div>
                        <div style={{paddingRight:"8px"}}><ActionButton onClick={() => { deleteClass(currentSelectedClassId); setShowClassDeleteConfirm(false); }} text="DELETE CLASS" /></div>
                    </div>
                </Modal.Body>
                
            </Modal>


            <Modal show={showStudentDeleteConfirm} onHide={() => setShowStudentDeleteConfirm(false)} dialogClassName="modal-MMMpx" centered>                                
                <Modal.Body>
                    <div style={{textAlign:"center", paddingBottom:"20px",paddingTop:"20px"}}><i className="fas fa-exclamation red" style={{fontSize:"65px",paddingTop:"15px"}}></i></div>

                    <div style={{textAlign:"center",fontSize:"27px", fontWeight:"bold", paddingBottom:"8px", margin:"0px"}} className="header-title m-t-0 m-b-30">Are you sure?</div>

                    <div style={{textAlign:"center",fontSize:"22px",paddingBottom:"8px"}} className="header-title m-t-0 m-b-30">
                        You want to delete this student
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <ActionButton  text="YES" onClick={()=>{removeStudentAction()}} />
                    <CancelButton  text="CANCEL" onClick={()=>{setShowStudentDeleteConfirm(false)}} />
                </Modal.Footer>
            </Modal>

            <Modal show={showStatusUpdate} onHide={()=> setShowStatusUpdate(false)}  dialogClassName="modal-450px">
                <Modal.Header closeButton>
                <Modal.Title>Update Course Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group row">
                        <div style={{paddingLeft:"45px"}}>
                            <select className="form-control" id="courseStatusDropDown" onChange={(e) => handlecourseStatusDropDown(e)} style={{width:"345px"}}>
                                {currentCourse.markedCount === 0 && <option value={1} selected={currentCourse.courseStatusId === 1}>Draft</option>}
                                <option value={2} selected={currentCourse.courseStatusId === 2}>Active</option>
                                <option value={3} selected={currentCourse.courseStatusId === 3}>Completed</option>
                                <option value={4} selected={currentCourse.courseStatusId === 4}>Archived</option>
                            </select>
                        </div>
                    </div>
                    <div> 
                        {/*(currentStudentMinMax.studentCount < currentStudentMinMax.minNumberStudents) && <div style={{color:"red"}}>Too few students for this course - {currentStudentMinMax.studentCount} out of  {currentStudentMinMax.minNumberStudents} </div> */} <br/>
                        {/* (currentStudentMinMax.studentCount > currentStudentMinMax.maxNumberStudents) && <div style={{color:"red"}}>Too many students for this course - {currentStudentMinMax.studentCount} out of  {currentStudentMinMax.minNumberStudents} </div> */} <br/>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <ActionButton onClick={()=> updateCourseStatus()} text="UPDATE STATUS" />
                    <CancelButton onClick={()=> setShowStatusUpdate(false)} />

                </Modal.Footer>
            </Modal>

            <MarkClassesModal refreshMarkedClasses={refreshMarkedClasses} hide={() => setShowMarkClassesModal(false)} classId={currentSelectedClassId} show={showMarkClassesModal} students={new Array<Student>()} updateGridClassStatus={classMarkedUpdate} mode="M" />

            <Modal show={showStudentsInClassModal} onHide={()=> setShowStudentsInClassModal(!showStudentsInClassModal)} dialogClassName="modal-750px">
                <Modal.Header closeButton>
                    <Modal.Title>Students in class</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        
                <table className="table">
                    <tr>
                        <td><strong>First name</strong></td>
                        <td><strong>Last name</strong></td>
                        <td><strong>Email</strong></td>
                    </tr>

                    {studentsInClass.map(s => (
                        <tr>
                            <td>{s.firstName}</td>
                            <td>{s.lastName}</td>
                            <td>{s.email}</td>
                            <td>{getStatusSpanFromId(s.statusId)}</td>
                        </tr>
                    ))}

                </table>

                </Modal.Body>
                <Modal.Footer>
                    <ActionButton onClick={createClasses} text="GENERATE CLASSES"/>
                </Modal.Footer>
            </Modal>


            <Modal show={showStudentAttendenceReport} onHide={()=>setShowStudentAttendenceReport(false)} dialogClassName="modal-1400px" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Attendance Report</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{height:"650px"}}> 
                    <div style={{fontSize:"20px"}}><strong>Student attendance</strong></div>
                    <StudentAttendence courseId={props.id} rowMaxNumber={3000} showScroll={true} />
                    <div style={{height:"30px"}}></div>
                    <div style={{fontSize:"20px"}}><strong>Total student class attendance</strong></div>
                    <StudentAttendenceAgri courseId={props.id} />
                </Modal.Body>
                <Modal.Footer>
                    <ActionButton onClick={() => PrintStudentAttendence()} text="PRINT"/>
                    <CancelButton onClick={() => setShowStudentAttendenceReport(false)} text={'CLOSE'} />
                </Modal.Footer>
            </Modal>

            <Modal show={showStudentOveride} onHide={()=>setShowStudentOveride(false)} dialogClassName="modal-750px" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Manage enrollment</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{height:"650px"}}> 
                    <StudentOveride student={currentStudent} courseId={currentCourse.courseId} UpdateStudentOveride={UpdateStudentOveride} />
                </Modal.Body>
                <Modal.Footer>
                    <ActionButton onClick={() => SaveStudentOveride()} text="SAVE"/>
                    <CancelButton onClick={() => setShowStudentOveride(false)} text={'CLOSE'} />
                </Modal.Footer>
            </Modal>
            
        </>
    )
}

export default ClassesGridMOCK;