import React, {useState, useEffect} from 'react';
import { hours } from './Dates';

let hoursData:string[] = [
    '00','01','02','03','04','05','06','07','08','09','10','11','12',
    '13','14','15','16','17','18','19','20','21','22','23'
]; 

let minsData:string[] = [
    '00','05','10','15','20','25','30','35','40','45','50','55'
]; 

interface IProps{
    fullDate?:string;
    timeUpdated?:Function;
    isError?:Boolean;
    label?:string;
    errorMessage?:string;
    startTime?:string;
    width?:number
}

const TimeSelector = (props:IProps) => {
    const [hour, setHour]   = useState("00");
    const [min, setMin]     = useState("00");

    const setTimeFromFullDate = () => {
        let fullTime:string = "";
        if(props.fullDate && props.fullDate!="") {
            
            fullTime = props.fullDate.split(' - ')[0];
            
            setHour(fullTime.split(':')[0]);
            setMin(fullTime.split(':')[1]);
        }
        if(props.startTime && props.startTime != ""){
            fullTime = props.startTime.split(' - ')[0];

            setHour(fullTime.split(':')[0]);
            setMin(fullTime.split(':')[1]);
        }

    }

    const hoursChanged =(e:any) => {
        setHour(e.target.value);
        combineTimeAndRaiseEvent(e.target.value, min);
    }

    const minsChanged =(e:any) => {
        setMin(e.target.value);
        combineTimeAndRaiseEvent(hour, e.target.value);
    }

    const combineTimeAndRaiseEvent = (hr,mn) => {
        if(props.timeUpdated){
            props.timeUpdated(hr + ":" + mn);
        }
    }


    useEffect(() => {
        setTimeFromFullDate();
    }, [props.fullDate]);


    return (
        <>





        <div className="form-group row">
            {props.label &&
            <div style={{width:"125px",paddingTop:"6px",textAlign:"right", paddingRight:"30px"}}>
                <label >{props.label}</label>
            </div>}

            <div style={{paddingTop:"0px"}}>
                <div className="row">
                    <div className="col" style={{paddingRight:"0px"}}>
                        <select onChange={hoursChanged} className={`form-control`} style={{width:`${props.width}px`}}>
                            {
                            hoursData.map(h=> (
                                <option selected={hour===h}>{h}</option>
                            ))
                            }
                        </select>
                    </div>

                    <div className="col">
                        <select onChange={minsChanged} className={`form-control`} style={{width:`${props.width}px`}}>
                            {
                            minsData.map(m=> (
                                <option selected={min === m}>{m}</option>
                            ))
                            }
                        </select>
                    </div>
                </div>

                {(props.isError===true) && <div style={{color:"#e96154"}}>{(props.errorMessage) && props.errorMessage}</div>}
            </div>
        </div>






            
        </>);
    ;
}

export default TimeSelector;