import React from 'react'

interface IProps {
    value:number
}

const formatTime = (value:number):string => {

    const hours = Math.floor(value / 60);          
    const minutes = value % 60;

    return hours.toString() + 'h ' + minutes.toString() + 'm';


    /*if(value.toString().indexOf('.') !== -1){
        return value.toFixed(2);
    } else {
        return value.toString();
    }*/
}

const TimeFormatter = (props:IProps) => {
  return (
    <span>{formatTime(props.value)}</span>
  )
}

export default TimeFormatter


