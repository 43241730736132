export default class ChangePasswordRequestVM {
    constructor() {
        
    }

    public UserName: string = ""; 
    public OldPassword:string = ""; 
    public NewPassword: string = ""; 
    public ConfirmPassword: string = ""; 
}

