import React, { useState, useEffect} from 'react';
import InputTextBoxDuel from '../common/InputTextBoxDuel';
import InputDropDown from '../common/InputDropDown';
import InputTextArea from '../common/InputTextArea';
import InputTextAreaDuel from '../common/InputTextAreaDual';

import axios from 'axios';
import Student from '../../models/Student';
import Company from '../../models/Company';
import Course from '../../models/Course';
import InputDatePickerDual from '../common/InputDatePickerDual';
import GDPRVM from '../../models/GDPRVM';

import useSnackbar from 'react-snackbar-toast';
import TickCross from '../common/TickCross';

import ActionButton from '../buttons/ActionButton';
import CancelButton from '../buttons/CancelButton';

import FormService from '../../services/formService';
import CourseStatusBadge from '../badges/CourseStatusBadge';

import SubjectBadge from '../badges/SubjectBadge';

import { withRouter } from "react-router-dom";
import swal from 'sweetalert';


interface IProps {
    id:number,
    history:any
}

const StudentProfileMOCK = (props: IProps) => {
    const {addToast} = useSnackbar();

    const studentUpsertToast = (messg, option) => {
        addToast(messg, option);  
    };

    const [formMode, setFormMode] = useState("E");
    const [currentGender, setCurrentGender] = useState("M");
    const [currentStudent, setCurrentStudent] = useState(new Student());
    const [validationError, setValidationError] = useState(new Array<string>());

    const [gdprConsent, setGdprConsent] = useState(new Array<GDPRVM>());
    

    const [comps, setComps] = useState(new Array<Company>());
    const [coursesAttended, setCoursesAttended] = useState(new Array<Course>());

    const profilePicMale = new Image();
    const profilePicFemale = new Image();
    const profilePicUnknown = new Image();

    profilePicMale.src = "/assets/images/profile-person-male.png";
    profilePicFemale.src = "/assets/images/profile-person-female.png";
    profilePicUnknown.src = "/assets/images/profile-person-unknown.png";

    async function fetchData() {
        let response = await axios.get<Student>(`/api/students/getbyid?id=${props.id}`);
        let student = response.data;

        setCurrentStudent(student);

        var gdprResponse = await axios.get<Array<GDPRVM>>(`/api/users/GetGDPRConsentByLogin?login=${student.email}`);
        setGdprConsent(gdprResponse.data);

        var coursesResponse = await axios.get<Array<Course>>(`/api/courses/GetCoursesByStudentId?studentId=${props.id}`);
        setCoursesAttended(coursesResponse.data);

        FormService.setAreFormsDirty(false);

    }

    const loadCompanies = async() => {
        let companies = await axios.get<Array<Company>>(`/api/companies/getall`);

        let compOption = new Array<any>();
        companies.data.map(c => {
            compOption.push({ Text: c.name, Value: c.companyId })
        });

        setComps(compOption);
    }

    useEffect(() => {
        if (props.id) {
            fetchData();
        } else {
            setCurrentStudent(new Student());
            //toogleFormMode();
            
        }
        loadCompanies();
    }, []);

    const updateStudent = (e:any) => {
        setCurrentStudent({ ...currentStudent, [e.target.name]: e.target.value });

        FormService.setAreFormsDirty(true);
    }

    
    const companyOnChangeHandler = (e: any) => {
        const newComp = e.target.options[e.target.selectedIndex].text;
        
        setCurrentStudent({ ...currentStudent, companyId: parseInt(e.target.value), company: newComp});

        FormService.setAreFormsDirty(true);
    }

    const emailIsValid = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }
    

    const saveStudent = async () => {
        let isFormValid: boolean = true;
        let invalid = new Array<string>();


        if (!currentStudent.firstName || currentStudent.firstName === "") {
            invalid.push("firstName");
            isFormValid = false;
        }
        if (!currentStudent.lastName || currentStudent.lastName === "") {
            invalid.push("lastName");
            isFormValid = false;
        }
        if (!currentStudent.email || currentStudent.email === "" || !emailIsValid(currentStudent.email)) {
            invalid.push("email");
            isFormValid = false;
        }
        if (!currentStudent.companyId || currentStudent.companyId === 0) {
            invalid.push("companyId");
            isFormValid = false;
        }


      

        currentStudent.dateOfBirth = new Date();

        

        if (isFormValid) {
            let success:boolean = false;

            var response = await axios.post<Student>('/api/students/upsert', currentStudent)
            .then((response) => {
                setCurrentStudent(response.data);
                success = true;
            })
            .catch(function (error) {
                studentUpsertToast(error.response.data, {className: "customToast",type: "error" });
            });
            
            if(success){
                //Reset any errors on page
                invalid = new Array<string>();
                setValidationError(invalid);
                
                FormService.setAreFormsDirty(false);

                

                swal("Student successfully updated","Well done!", "success", {
                    timer: 3000,
                    buttons:{}
                });
            }
        } else {
           setValidationError(invalid);
        }
    }

    const isFieldValid = (fieldName: string): boolean => {
        let error = validationError.find(s => s == fieldName);
        if (error) {
            return true;
        } else {
            return false;
        }
    }

    const dateOfBirthChangeHandler = (date:any) => {
        let newDate = (date.getDate()).toString() + "/" + ((date.getMonth() + 1).toString()+ "/" + date.getFullYear().toString())

        setCurrentStudent({ ...currentStudent, dateOfBirthString: newDate });

        FormService.setAreFormsDirty(true);
    }

    const selectRow = (courseId:number) => {
        props.history.push(`/CoursesForm/${courseId}`);
    }

    return (
        <> 
        <div className="row">
            <div className="col-6">
                <div className="card" >
                    <div>
                        <div className="page-title-box" style={{paddingBottom:"50px",paddingTop:"10px",paddingLeft:"15px",paddingRight:"15px"}}>
                            <div className="page-title-btn btn-group float-right"><img src="/assets/images/Healthpathavatar.png" style={{paddingRight:"30px",paddingTop:"5px",paddingBottom:"10px"}} /></div>
                            <h4 className="header-title" style={{paddingTop:"8px",paddingLeft:"30px"}}>Student's profile</h4>
                        </div>

                        <div className="col" style={{paddingLeft:"85px"}}> 
                            <InputTextBoxDuel label="First Name" name="firstName" value={currentStudent.firstName} mode={formMode} width={415} isError={isFieldValid("firstName")} onChange={(e) => updateStudent(e)} />
                            <InputTextBoxDuel label="Last Name" name="lastName" value={currentStudent.lastName} mode={formMode} isError={isFieldValid("lastName")} onChange={(e) => updateStudent(e)}   width={415} />

                            <InputTextAreaDuel label="Address" name="address1" errorMessage="Please enter an address 1"  isError={isFieldValid("address1")} value={currentStudent.address1} mode={formMode}  onChange={(e) => updateStudent(e)} width={415}  />
                            <InputTextBoxDuel label="Town" name="town" value={currentStudent.town} onChange={(e) => updateStudent(e)} mode={formMode} width={415} isError={isFieldValid("town")} />    
                            <InputTextBoxDuel label="County" name="county" value={currentStudent.county} onChange={(e) => updateStudent(e)} mode={formMode} width={415} isError={isFieldValid("county")} />    
                            <InputTextBoxDuel label="Post Code" name="postCode" value={currentStudent.postCode} onChange={(e) => updateStudent(e)} mode={formMode} width={415} isError={isFieldValid("postCode")}  />    


                            <InputTextBoxDuel label="Email" name="email" value={currentStudent.email} onChange={(e) => updateStudent(e)} mode={formMode} width={415} isError={isFieldValid("email")} />
                            <InputTextBoxDuel label="Mobile" name="mobile" value={currentStudent.mobile} onChange={(e) => updateStudent(e)} mode={formMode} width={415} isError={isFieldValid("mobile")} />
                            <InputTextBoxDuel label="Landline" name="landline" value={currentStudent.landline} onChange={(e) => updateStudent(e)} mode={formMode} width={415} isError={isFieldValid("landline")}  /> 
                            <InputDatePickerDual label="Date of birth" value={currentStudent.dateOfBirthString && currentStudent.dateOfBirthString} onChange={(date)=> dateOfBirthChangeHandler(date)}  mode={formMode}/>

                            <InputTextBoxDuel label="Nationality" name="nationality" mode={formMode} value={currentStudent.nationality} onChange={(e) => updateStudent(e)} width={415} isError={isFieldValid("nationality")} />


                            {(formMode === "E") ? <InputDropDown pleaseSelectMessage=" -- Please select -- " label="Company" data={comps} width={415} value={currentStudent.companyId} showPlease={true} changeFunc={companyOnChangeHandler} isError={ isFieldValid("companyId")}/>
                            : <InputTextBoxDuel label="Company" value={currentStudent.company} mode={formMode} width={415}  />
                            }


                            <div style={{height:"38px"}}></div>

                            <>
                                <div className="form-group text-right m-b-0">

                                    <ActionButton onClick={()=>saveStudent()} text="SAVE" /><span>&nbsp;</span>

                                    <CancelButton onClick={()=> fetchData()}/>
                                   
                                </div>
                                <div>&nbsp;</div>
                            </>
                        </div>
                    </div>
                </div>                   
            </div>
            <div className="col-6">
                <div className="card"  >
                    <div style={{height:"523px"}}>
                        <div className="row">
                            <div className="col">
                                <div style={{width: "155px;"}}></div>
                                <div>
                                    <div className="page-title-box" style={{paddingBottom:"15px",paddingTop:"10px",paddingLeft:"15px",paddingRight:"15px"}}>
                                        <h4 className="header-title" style={{paddingTop:"15px",paddingLeft:"30px"}}>Courses attended</h4>
                                    </div>
                                        
                                    <div className="input-group">
                                        <div style={{paddingLeft:"20px",paddingRight:"20px"}} className="table-responsive" >
                                            <div id="NewScroller" className="table-responsive" style={{height: "425px", paddingTop: "10px;"}}>
                                                <table className="table table table-hover m-0 ">
                                                    <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Course</th>
                                                        <th>Subject</th>
                                                        <th>Level</th>
                                                        <th>Start Date</th>
                                                        <th>Status</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    
                                                    {coursesAttended.map((c,i) => (
                                                        <tr  >
                                                            <td>{i+1}</td>
                                                            <td style={{cursor:"pointer"}} onClick={() => selectRow(c.courseId)}>{c.title}</td>
                                                            <td>
                                                                <SubjectBadge color={c.subjectColor} text={c.subject} />
                                                            </td>
                                                            <td>{c.courseLevel}</td>
                                                            <td>{c.startDateString}</td>
                                                            <td>
                                                                <CourseStatusBadge statusId={c.courseStatusId} allowClick={false} onClick={() => { }} />
                                                            </td>
                                                        </tr>
                                                    ))
                                                    }


                                                   
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <div className="card"  >
                            <div style={{height:"215px"}}>
                                <div className="row" style={{paddingBottom:"10px"}}>
                                    <div className="col">
                                        <div  style={{paddingLeft:"10px",paddingTop:"25px"}}><h4 className="header-title">GDPR settings</h4></div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        {!gdprConsent || gdprConsent.length === 0 &&
                                            <div style={{paddingLeft:"10px"}}>GDPR settings updated by user</div>
                                        } 
                                        {gdprConsent.map(c => (
                                            <div className="row" style={{paddingBottom:"6px",paddingLeft:"12px"}}>
                                                <div className="col-10">{c.name}</div>
                                                <div className="col-2"><TickCross checked={c.consent}/></div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="card">
                                <div style={{height:"215px"}}>
                                    <div className="row" style={{paddingBottom:"10px"}}>
                                        <div className="col">
                                            <div  style={{paddingLeft:"10px",paddingTop:"25px"}}><h4 className="header-title">Online access</h4></div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="row" style={{paddingBottom:"6px",paddingLeft:"12px"}}>
                                                <div className="col-10">Online access granted</div>
                                                <div className="col-2"><TickCross checked={true}/></div>
                                            </div>
                                            <div className="row" style={{paddingBottom:"6px",paddingLeft:"12px"}}>
                                                <div className="col-10">Logins this month</div>
                                                <div className="col-2"></div>
                                            </div>
                                            <div className="row" style={{paddingBottom:"6px",paddingLeft:"12px"}}>
                                                <div className="col-7">Last logged in</div>
                                                <div className="col-5"></div>
                                            </div>


                                            <div className="page-title-box" style={{paddingBottom:"25px",paddingTop:"15px", paddingRight:"40px"}}>
                                                <div className="page-title-btn btn-group float-right">
                                                    <ActionButton onClick={()=> alert("Not implemented")} text="REMOVE LOGIN" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default withRouter(StudentProfileMOCK);