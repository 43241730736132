import React, { useEffect, useState } from 'react';
import axios from 'axios';
import InputTextBox from '../common/InputTextBox';
import InputTextArea from '../common/InputTextArea';
import InputDropDown from '../common/InputDropDown';

import InputDatePicker from '../common/InputDatePicker';
import InputDatePickerDuel from '../common/InputDatePickerDual';
import InputRadioList from '../common/InputRadioList';
import InputTextBoxDuel from '../common/InputTextBoxDuel';
import InputTextAreaDuel from '../common/InputTextAreaDual';
import InputRadioListDual from '../common/InputRadioListDual';

import Subject from '../../models/Subject';
import CourseType from '../../models/CourseType';
import Company from '../../models/Company';
import CourseLevel from '../../models/CourseLevel';
import Course from '../../models/Course';

import checkValidDateFormat from '../../services/dateService';

import InputCheckBoxDuel from '../common/InputCheckBoxDual';

import WaveBox from '../common/WaveBox'; 



import { Row, Col } from 'reactstrap';

interface IProps {
    id?: number,
    updateCourseDetails:Function,
    activeTab:string,
    setActiveTab:Function,
    refresh:number
    
}

let currentActiveTab:number = 1;

const CoursesDetailsForm = (props:IProps) => {
    const [showCompanies, setShowCompanies] = useState(true);
    const [subjects, setSubjects] = useState(new Array<Subject>());
    const [courseTypes, setCourseTypes] = useState(new Array<CourseType>());
    const [companies, setCompanies] = useState(new Array<Company>());
    const [courseLevels, setCourseLevels] = useState(new Array<CourseLevel>());
    const [currentCourse, setCurrentCourse] = useState(new Course());
    const [validationError, setValidationError] = useState(new Array<string>());
    const [editMode, setEditMode] = useState("D");

    useEffect(() => {
        const loadStaticData = async () => {
            let subOptions          = new Array<any>();
            let courseTypeOptions   = new Array<any>();
            let companyOptions      = new Array<any>();
            let courseLevelOptions  = new Array<any>();

            let response = await axios.get<Array<Subject>>(`/api/subjects/getall`);
            response.data.map(c => subOptions.push({ Text: c.name, Value: c.subjectId }));

            let responseCourseType = await axios.get<Array<CourseType>>(`/api/courseTypes/getall`);
            responseCourseType.data.map(c => courseTypeOptions.push({ Text: c.name, Value: c.courseTypeId }));

            let responseCompanies = await axios.get<Array<Company>>(`/api/companies/getall`);
            responseCompanies.data.map(c => companyOptions.push({ Text: c.name, Value: c.companyId }));

            let responseCourseLevel = await axios.get<Array<CourseLevel>>(`/api/courses/GetAllLevels`);
            responseCourseLevel.data.map(c => courseLevelOptions.push({ Text: c.name, Value: c.courseLevelId }));

            setSubjects(subOptions);
            setCourseTypes(courseTypeOptions);
            setCompanies(companyOptions);
            setCourseLevels(courseLevelOptions);

            if (props.id) {
                setEditMode('D');
                let response = await axios.get<Course>(`/api/courses/GetById?id=${props.id}`);
                await props.updateCourseDetails(response.data);

                let f = formValid(response.data);

                let loadedCourse = response.data;

                loadedCourse.isValid = f;

                await setCurrentCourse(loadedCourse);
                setShowCompanies(!loadedCourse.private);
            } else {
                setShowCompanies(true);
                setEditMode('E');
            }
        }
        loadStaticData();

        
    }, []);

    useEffect(() => {
        if(props.refresh!==0) {
            refresh();
        } 
      }, [props.refresh]);

    const refresh = () => {
        saveCourse();
    }

    const unknown = [
        { Text: "Company", Value: 1 },
        { Text: "Private", Value: 2 },
    ]

    const updateCourse = (e: any) => {

        const formIsValid: boolean = formValid(currentCourse)

        let updatedCourse = { ...currentCourse, [e.target.name]: e.target.value, isValid:formIsValid };
        setCurrentCourse(updatedCourse);

        props.updateCourseDetails(updatedCourse);
    }
    const updateCourseNumeric = (e: any) => {
        const formIsValid: boolean = formValid(currentCourse);
        ;
        if (!isNaN(e.target.value)) {
            let updatedCourse = { ...currentCourse, [e.target.name]: parseInt(e.target.value),isValid:formIsValid };

            setCurrentCourse(updatedCourse);
            props.updateCourseDetails(updatedCourse);
        }
    }

    const companyChangeHandler = (e: any) => {
        const formIsValid: boolean = formValid(currentCourse);
        const text = e.target.options[e.target.selectedIndex].text;

        const updatedCourse = { ...currentCourse, companyId: parseInt(e.target.value), isValid: formIsValid, company: text };

        setCurrentCourse(updatedCourse);

        props.updateCourseDetails(updatedCourse);
    }

    const courseTypesChangeHandler = (e: any) => {
        const formIsValid: boolean = formValid(currentCourse);

        const updatedCourse = { ...currentCourse, typeId: parseInt(e.target.value), isValid: formIsValid }

        setCurrentCourse(updatedCourse);

        props.updateCourseDetails(updatedCourse);
    }

    const isFieldValid = (fieldName: string): boolean => {
        let error = validationError.find(s => s == fieldName);
        if (error) {
            return true;
        } else {
            return false;
        }
    }

    //TODO: Why do we have two seperate validation routines
    const formValid =  (course:Course) => {
        let isFormValid: boolean = true;
        let invalid = new Array<string>();

        if (!checkValidDateFormat(course.startDateString)) {
            //invalid.push("startDateString");
            isFormValid = false;
        }

        if (!checkValidDateFormat(course.endDateString)) {
           // invalid.push("endDateString");
            isFormValid = false;
        }

        if (!course.title || course.title === "") {
            //invalid.push("title");
            isFormValid = false;
        }
        if (!course.description || course.description === "") {
            //invalid.push("description");
            isFormValid = false;
        }

        if (course.typeId === 0) {
            //invalid.push("typeId");
            isFormValid = false;
        }

        if (course.subjectId === 0) {
            //invalid.push("subjectId");
            isFormValid = false;
        }

        if (course.courseLevelId === 0) {
            //invalid.push("courseLevelId");
            isFormValid = false;
        }
        
        if (course.companyId=== 0 && !course.private) {
            //invalid.push("companyId");
            isFormValid = false;
        }
        
        return isFormValid;
    }

    const saveCourse = async () => {
        let isFormValid: boolean = true;
        let invalid = new Array<string>();

        if (!checkValidDateFormat(currentCourse.startDateString)) {
            invalid.push("startDateString");
            isFormValid = false;
        }

        if (!checkValidDateFormat(currentCourse.endDateString)) {
            invalid.push("endDateString");
            isFormValid = false;
        }

        if (!currentCourse.title || currentCourse.title === "") {
            invalid.push("title");
            isFormValid = false;
        }
        if (!currentCourse.description || currentCourse.description === "") {
            invalid.push("description");
            isFormValid = false;
        }


        if (currentCourse.typeId === 0) {
            invalid.push("typeId");
            isFormValid = false;
        }

        if (currentCourse.subjectId === 0) {
            invalid.push("subjectId");
            isFormValid = false;
        }

        if (currentCourse.courseLevelId === 0) {
            invalid.push("courseLevelId");
            isFormValid = false;
        }
        
        
        if (currentCourse.companyId=== 0 && !currentCourse.private) {
            invalid.push("companyId");
            isFormValid = false;
        }

        

        if (isFormValid) {
            //await axios.post('/api/courses/upsert', currentCourse);
            //alert("Posted");
            setValidationError(new Array<string>());
            const updatedCourse = { ...currentCourse,  isValid: true }
            props.updateCourseDetails(currentCourse)

            setCurrentCourse(updatedCourse);

        } else {
            setValidationError(invalid);
        }
    }

    const subjectChangeHandler = (e: any) => {
        const text = e.target.options[e.target.selectedIndex].text;

        let updatedCourse = { ...currentCourse, subjectId: parseInt(e.target.value), subject: text};

        setCurrentCourse(updatedCourse);
        props.updateCourseDetails(updatedCourse);
    }

    const levelChangeHandler = (e: any) => {
        const text = e.target.options[e.target.selectedIndex].text;

        let updatedCourse = { ...currentCourse, courseLevelId: parseInt(e.target.value), courseLevel: text };

        setCurrentCourse(updatedCourse);
        props.updateCourseDetails(updatedCourse);
    }

    const startDateChangeHandler = (date: any) => {
        let newDate = (date.getDate()).toString() + "/" + ((date.getMonth() + 1).toString() + "/" + date.getFullYear().toString())
        let updatedCourse = { ...currentCourse, startDateString: newDate };

        setCurrentCourse(updatedCourse);
        props.updateCourseDetails(updatedCourse);
    }

    const endDateChangeHandler = (date: any) => {
        let newDate = (date.getDate()).toString() + "/" + ((date.getMonth() + 1).toString() + "/" + date.getFullYear().toString())
        let updatedCourse = { ...currentCourse, endDateString: newDate };

        setCurrentCourse(updatedCourse);
        props.updateCourseDetails(updatedCourse);
    }

    const privateCompanyChange = (val: boolean) => {
        let updatedCourse = { ...currentCourse, private: val };

        setCurrentCourse(updatedCourse);
        setShowCompanies(!val);

        props.updateCourseDetails(updatedCourse);
    }

    const toogleFormMode = () =>{
        setEditMode((editMode==="E" ? "M" : "E"));
    }

    const updateCourseSponsored = (e:any) => {

        let updatedCourse = { ...currentCourse, sponsored: e.target.checked };

        setCurrentCourse(updatedCourse);
        props.updateCourseDetails(updatedCourse);
    }

    const updateCourseVirtual = (e:any) => {

        let updatedCourse = { ...currentCourse, virtual: e.target.checked };

        setCurrentCourse(updatedCourse);
        props.updateCourseDetails(updatedCourse);
    }
    

    return (<>
        

            <Row>
                <Col sm="12">
                    <div className="col-sm card" style={{ padding: "15px" }}>
                        <div className="row">
                           <div className="col">1</div>
                           <div className="col">2</div>
                           <div className="col">3</div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <InputTextBoxDuel label="Course name" mode={editMode} width={500} name="title" value={currentCourse.title} onChange={(e) => updateCourse(e)} isError={isFieldValid("title")} errorMessage="Please enter a compnay name" />

                                {(editMode !== "E") && <div style={{paddingTop:"7px"}}></div>}
                                <InputTextBoxDuel label="Description" errorMessage="Please enter a description" mode={editMode} name="description" value={currentCourse.description} onChange={(e) => updateCourse(e)} isError={isFieldValid("description")}   width={500} />


                                {(editMode !== "E") && <div style={{paddingTop:"7px"}}></div>}
                                {(editMode === "E") ? <InputDropDown label="Subject" errorMessage="Please select a subject" data={subjects} value={currentCourse.subjectId} changeFunc={subjectChangeHandler} showPlease={true} isError={isFieldValid("subjectId")} width={500} />
                                : <InputTextBoxDuel label="Course Type" value={currentCourse.subject} mode={editMode} width={325} />}

                                {(editMode !== "E") && <div style={{paddingTop:"7px"}}></div>}
                                {(editMode === "E") ? <InputDropDown label="Level" errorMessage="Please select a level" data={courseLevels} value={currentCourse.courseLevelId} changeFunc={levelChangeHandler} showPlease={true} isError={isFieldValid("courseLevelId")} width={500} />
                                : <InputTextBoxDuel label="Level" value={currentCourse.courseLevel} mode={editMode} width={325} />}


                                {(editMode !== "E") && <div style={{paddingTop:"7px"}}></div>}
                                {(editMode === "E") ? <InputDropDown label="Course type" errorMessage="Please select a type" name="type" data={courseTypes} showPlease={true} value={currentCourse.typeId} changeFunc={courseTypesChangeHandler} isError={isFieldValid("typeId")}  width={500} />
                                :   <InputTextBoxDuel  label="Course type" value={currentCourse.courseType} mode={editMode} width={325}  />}


                                {(editMode !== "E") && <div style={{paddingTop:"7px"}}></div>}
                                {(editMode === "E") ? showCompanies &&  <InputDropDown label="Company" errorMessage="Please select a company" data={companies} value={currentCourse.companyId} showPlease={true} changeFunc={companyChangeHandler}  width={500} />
                                : showCompanies && <InputTextBoxDuel label="Company"   value={currentCourse.company} mode={editMode} width={325}  />}

                                {showCompanies && <InputCheckBoxDuel label="Sponsored" mode={editMode} width={25} checked={currentCourse && currentCourse.sponsored} onChange={(e) => updateCourseSponsored(e)} />}

                                <InputCheckBoxDuel label="Virtual" mode={editMode} width={25} checked={currentCourse && currentCourse.virtual} onChange={(e) => updateCourseVirtual(e)} />

                            </div>
                            <div className="col"> 
                                <InputDatePickerDuel label="Start Date" value={currentCourse.startDateString && currentCourse.startDateString} mode={editMode} isError={isFieldValid("startDateString")} onChange={(date) => startDateChangeHandler(date)} errorMessage="Please enter a Start Date" width={500} />
                                
                                {(editMode !== "E") && <div style={{paddingTop:"7px"}}></div>}
                                <InputDatePickerDuel label="End Date" value={currentCourse.endDateString && currentCourse.endDateString} mode={editMode} isError={isFieldValid("endDateString")}  onChange={(date) => endDateChangeHandler(date)} errorMessage="Please enter an End Date"/>

                                {(editMode !== "E") && <div style={{paddingTop:"7px"}}></div>}
                                <InputTextBoxDuel label="Price per student" mode={editMode}  name="price" value={currentCourse.price.toString()} isError={isFieldValid("price")} onChange={(e) => updateCourseNumeric(e)} errorMessage="Please enter a price" width={500} />

                                {(editMode !== "E") && <div style={{paddingTop:"7px"}}></div>}
                                <InputTextBoxDuel label="Max students" mode={editMode} name="maxNumberStudents" value={currentCourse.maxNumberStudents.toString()} isError={isFieldValid("maxNumberStudents")} onChange={(e) => updateCourseNumeric(e)} width={500} errorMessage="Please enter min students"  />
                                {(editMode !== "E") && <div style={{paddingTop:"7px"}}></div>}
                                <InputTextBoxDuel label="Min students" mode={editMode} name="minNumberStudents" value={currentCourse.minNumberStudents.toString()} isError={isFieldValid("minNumberStudents")} onChange={(e) => updateCourseNumeric(e)} width={500} errorMessage="Please enter max students"/>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <div className="row">
                        <div className="col-12">
                            <div style={{float: "right", paddingRight:"5px",paddingTop:"10px"}}><i className="ti-pencil pink" style={{float: "right",fontSize: "20px"}} onClick={()=>toogleFormMode()}></i></div>
                            
                        </div>    
            </div> 
           
        </>
    );
}

export default CoursesDetailsForm;