export default class ClassGenVM {
    constructor() {
        
    }

    public classId:number 
    public courseId:number 
    public teacherId:number
    public companyId:number
    public teacherRateId:number
    public dayWeekId:number
    public scheduleId:number
    public roomId:number
    public courseIterationTypeId:number
    public classLength:number
    public startDateString:string
    public endDate:string
    public numberOfClasses:number
    public time:string

    public classDateString: string
    public classDatesCombined: string

    public seriesId: number

    public templateClassId:number
}

