import React, { useState, useEffect, Component } from "react";
import { Accordion,Card,Button,Modal } from 'react-bootstrap';
import SettlementReportVM from '../../models/SettlementReportVM'; 
import TeacherPaymentVM from '../../models/TeacherPaymentVM';
import Class from '../../models/Class';

import WaveBox from "../../components/common/WaveBox";

import ActionButton from '../../components/buttons/ActionButton';
import CancelButton from '../../components/buttons/CancelButton';
import Confirmation from  '../../modals/Confirmation';

import TeachersPaymentTable from './TeacherPaymentTable';
import PaymentPdfTemplate from '../common/PaymentPdfTemplate'

import DatePicker from "react-datepicker";
import moment from 'moment';
import axios from 'axios';
import { jsPDF } from "jspdf";


interface IProps {
    id:number;
}

const TeachersPayments = (props:IProps) => {
    const [settlementReports, setSettlementReports] = useState(new Array<SettlementReportVM>());
    const [paymentRecords, setPaymentRecords] = useState(new Array<TeacherPaymentVM>());

    const [outstandingPaymentRecords, setOutstandingPaymentRecords] = useState(new Array<TeacherPaymentVM>());
    const [periodEndDateString, setPeriodEndDateString] =  useState(moment().format("DD/MM/YYYY").toString());

    const [viewToShow, setViewToShow] = useState("P");
    const [showRaisePaymentModal, setShowRaisePaymentModal] = useState(false);
    const [currentPaymentHeaderId, setCurrentPaymentHeaderId]= useState(0);

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [currentClasses, setCurrentClasses] = useState(new Array<Class>());

    const [grandTotal, setGrandTotal] = useState(0);

    const [currentPayementHeader, setCurrentPayementHeader] = useState(new SettlementReportVM());


    const getStartMonth = () => {
        let currentYear = new Date().getFullYear();
        let currentMonth = new Date().getMonth() + 1;

        return currentMonth.toString() + "-" +  currentYear.toString();
    }

    const startDate = getStartMonth();
    
    

    useEffect(() => {
        loadData();
        loadOutstandingPaymentRecords(periodEndDateString);
    }, []);


    const convertClassesFrom = (teacherPayments:Array<TeacherPaymentVM>):Array<Class> => {
        let classesToConvert:Array<Class> = new Array<Class>();

        teacherPayments.map(tp=>{
            tp.paymentItems.map(pi=>{
                let classToA:Class = new Class();
                classToA.classLength = pi.classLength;
                classToA.course = pi.title;
                classToA.teacherRate = pi.ratePerHour
                classToA.coursePaymentTotal = pi.classPaymentTotal;
                classToA.classDateString = pi.classSeperateDateString;
                classToA.classDatesCombined = pi.classSeperateTimeString;
                classToA.subject = pi.subject;

                classesToConvert.push(classToA);
            })
        });

        return classesToConvert;

    }


    
    const loadData = async() => {
        let response = await axios.get<Array<SettlementReportVM>>(`/api/reports/GetSettlementReports?teacherId=${props.id}`);
        setSettlementReports(response.data);
        
        if(response.data && response.data.length>0){
            let paymentHeaderId = response.data[0].paymentHeaderId;
            
            setCurrentPayementHeader(response.data[0]);

            loadPaymentRecords(paymentHeaderId);
            setCurrentPaymentHeaderId(paymentHeaderId);
        }
    }

    const roundup = (num,dec) => {

        return Math.round((num + Number.EPSILON) * 100) / 100;


        /*dec= dec || 0;
        var  s=String(num);
        if(num%1)s= s.replace(/5$/, '6');
        return Number((+s).toFixed(dec));
        */
     }

    

    const totalCoursePayment = (teacherPayments:Array<TeacherPaymentVM>) => {

        teacherPayments.map(tp=>{
            let coursePaymentTotal:number = 0;

            
            tp.paymentItems.map(pi=>{

                if(pi.courseId === 1049){
                    const i = 1;
                }
    
                let courseItems = tp.paymentItems.filter(p => p.courseId == pi.courseId);
                let sumCourseTotal:number = 0; 

                courseItems.map(i => {
                    sumCourseTotal += (i.classLength / 60) * i.ratePerHour;
                    pi.coursePaymentTotal = roundup(sumCourseTotal,2);
                })
            });
        });

        return teacherPayments;
    }

    const loadPaymentRecords = async(e:number) => {
        let response = await axios.get<Array<TeacherPaymentVM>>(`/api/reports/GetTeacherPaymentReportAuto?teacherIds=${props.id}&paymentHeaderId=${e}`);
        setPaymentRecords(response.data);
        setCurrentPaymentHeaderId(e);

        let teacherPayments = response.data;
        teacherPayments =  totalCoursePayment(teacherPayments);

        let classesToAdd:Array<Class> = convertClassesFrom(response.data);
        
        setCurrentClasses(classesToAdd);
    }

    const loadOutstandingPaymentRecords = async(dateToShow:string) => {
        let response = await axios.get<Array<TeacherPaymentVM>>(`/api/reports/GetTeacherPaymentReportAuto?teacherIds=${props.id}&periodEndDate=${dateToShow}`);

        let teacherOutstanding = response.data;
        teacherOutstanding =  totalCoursePayment(teacherOutstanding);


        setOutstandingPaymentRecords(teacherOutstanding);
    }

    
    const RaisePaymentReport = async() => {
        await axios.get<Array<TeacherPaymentVM>>(`/api/reports/RaisePaymentReport?dateToShow=${startDate}&teacherIds=${props.id}&periodEndDate=${periodEndDateString}`);
        
        let todayDate = moment().format("DD/MM/YYYY").toString();
        loadOutstandingPaymentRecords(todayDate);

        toogleRaisePaymentModal();

        setPeriodEndDateString(todayDate);

        loadData();
    }


    const paymentTypeChange = (e) => {
        setViewToShow((e) === "SETTLEMENT REPORTS" ? "P" : "O");
    }

    
    const periodDateChangeHandler = async(date:any) => {
        let newDate = (date.getDate()).toString() + "/" + ((date.getMonth() + 1).toString()+ "/" + date.getFullYear().toString())

        moment.locale('en-gb');                  

        setPeriodEndDateString(newDate);

        loadOutstandingPaymentRecords(newDate)

    }

    const deletePaymentRecord = async(paymentHeaderId:number) => {
        await axios.get(`/api/reports/DeletePayments?paymentHeaderId=${paymentHeaderId}&teacherId=${props.id}`);
    }

    const toogleRaisePaymentModal = () => {
        setShowRaisePaymentModal(!showRaisePaymentModal);
    }

    const hideConfirmationModal = () => {
        setShowConfirmation(!showConfirmation);
    }

    const deletePaymentConfirm_Click = async() => {
        await deletePaymentRecord(currentPaymentHeaderId);
        loadData();
        setPaymentRecords([]);
        loadOutstandingPaymentRecords(periodEndDateString);
        setShowConfirmation(false);

    }

    const deletePaymentClicked = (paymentHeaderId:number) => {
        setShowConfirmation(true);
        setCurrentPaymentHeaderId(paymentHeaderId);
    }

    const openPrintWindow = () => {
        window.open(`/reports/printpaymentreport?isTeacher=true&id=${currentPaymentHeaderId}&teacherId=${props.id}&grandTotal=${grandTotal}&periodEndDate=${currentPayementHeader.periodEndDateString}`, "_blank", "menubar=no,location=no,height=1200,width=1200");
    }

    


    return (
        <>
            <WaveBox allowEmpty={false} items ={['SETTLEMENT REPORTS','CLASSES TO PROCESS']} size={'lg'} onClick={(e) => paymentTypeChange(e)} selected="SETTLEMENT REPORTS"/>
                <br/><br/><br/>

                {viewToShow === "P" && <div>
                    <div className="row">
                        <div className="col-9">
                            <TeachersPaymentTable payments={paymentRecords} getPaymentGrandTotal={(e) => {setGrandTotal(e)}} />
                        </div>
                        
                        <div className="col-3">
                            {settlementReports && settlementReports.length > 0 &&
                                <table className="table">
                                    <tr>
                                        <td><strong>Settlement report</strong></td>
                                        <td><strong>Payment</strong></td>
                                        <td align="right"><strong>Actions</strong></td>
                                    </tr>
                                    {
                                        settlementReports.map(p => (
                                            <tr style={{cursor:"pointer", backgroundColor:(p.paymentHeaderId== currentPaymentHeaderId) ? "#efefef" : "#FFFFFF"}} >
                                                <td style={{width:"1325px"}} onClick={() => loadPaymentRecords(p.paymentHeaderId)}>{p.periodEndDateString}</td>
                                                <td>£{p.payment.toFixed(2)}</td>
                                                <td style={{width:"400px"}}>
                                                    <>
                                                        <div  style={{float: "right", paddingTop:"1px",  cursor: "pointer"}} onClick={()=> openPrintWindow() } ><i style={{fontSize:"18px"}} className="far fa-file-pdf pink"></i></div>
                                                        <div  style={{float: "right",paddingRight:"8px", paddingTop:"0px", cursor: "pointer"}} onClick={()=> deletePaymentClicked(p.paymentHeaderId) } ><i className="ti-trash pink" style={{float: "right",fontSize: "20px"}}></i></div>
                                                    </>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </table>
                            }
                        </div>
                    </div>

                    {!settlementReports || settlementReports.length === 0 && <div style={{textAlign:"center", paddingTop:"125px"}}>This teacher does not have any settlement reports</div>}

                    <br/>
                    <div>
                        
                    </div>
                </div>
                }

                {viewToShow==="O" && <div>
                    <>
                        <h4  className="header-title m-t-0 m-b-30">Period end date </h4>
                        <DatePicker autoComplete="off" className={`form-control-datepicker-250` }  dateFormat="dd/MM/yyyy" style={{width:"35px"}} value={periodEndDateString}  name="startDateString" onChange={(e:any)=> periodDateChangeHandler(e)} autocomplete="nope"  />
                        {outstandingPaymentRecords && outstandingPaymentRecords.length > 0 &&
                            <div style={{float:"right", paddingRight:"5px", paddingTop:"15px"}}><ActionButton text="RAISE SETTLEMENT REPORT" onClick={() => toogleRaisePaymentModal()} /></div>
                        }
                        <div style={{paddingBottom:"10px"}}></div>
                    </>

                    {!outstandingPaymentRecords || outstandingPaymentRecords.length === 0 &&
                        <div style={{textAlign:"center", paddingTop:"55px"}}>
                            This teacher does not have any classes to process
                        </div>
                    }


                    <TeachersPaymentTable payments={outstandingPaymentRecords} getPaymentGrandTotal={(e) => {}}  />    
                    
                   

                   
                </div>
            }



            <Modal show={showRaisePaymentModal}  dialogClassName="modal-750px"  onHide={()=> toogleRaisePaymentModal()}>
                <Modal.Header closeButton>
                    <Modal.Title>Raise payment </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            Are you sure you wish to raise this payment record?
                        </div>

                    </div>
                   

                </Modal.Body>
                <Modal.Footer>
                    <ActionButton onClick={() => RaisePaymentReport()} text="RAISE SETTLEMENT REPORT" />

                    <CancelButton onClick={() => toogleRaisePaymentModal()}  />
                </Modal.Footer>
            </Modal>


            <Confirmation title={'Delete confirmation'} confirmText="DELETE" bodyText={'Are you sure you want to delete this settlement report?'} showModal={showConfirmation} hideModal={hideConfirmationModal} confirmButton_Click={deletePaymentConfirm_Click} />
            
            <PaymentPdfTemplate isTeacherReport={true} classes={currentClasses} paymentTotal={grandTotal} periodEndDateString={currentPayementHeader.periodEndDateString} />
        </>
    )
}

export default TeachersPayments;