import React, { useState, useEffect, Component, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import axios from "axios";
import Teacher from '../../models/Teacher';
import useSnackbar from 'react-snackbar-toast';
import {confirm} from 'react-bootstrap-confirmation';

import TickCross from '../common/TickCross';
import Pagination from 'react-bootstrap/Pagination'
import ActionButton from '../buttons/ActionButton';

import SubjectBadge from '../badges/SubjectBadge';

import Pill from '../common/Pill';

interface IState {
    records:any
}

interface IProps {
    history:any
}

const TeachersGrid = (props: IProps) => {
    const {addToast} = useSnackbar();
    const [teachers, setTeachers] = useState(new Array<Teacher>());
    const [teachersAll, setTeachersAll] = useState(new Array<Teacher>());
    const [currentPage, setCurrentPage] = useState(1);
    const [numberOfPages, setNumberOfPages] = useState(1);

    const [searchField, setSearchField] = useState("");

    const [searchStatus, setSearchStatus] = useState(1);

    const paginationConfig = {
        recordsPerPage:100
    }

    const open = option => {
        addToast("Toast Message", option);
    };
    
    const loadTeachers = async (searchText:string="", status=1) => {
        let teachers = new Array<Teacher>();


        let response = await axios.get<Array<Teacher>>(`/api/teachers/getall?searchText=${searchText}&status=${status}`);


        for(var i=0; i < paginationConfig.recordsPerPage; i++) {
            if(response.data[i]) {
                teachers.push(response.data[i]);
            }
        }

        let numberOfPages = Math.ceil(response.data.length / paginationConfig.recordsPerPage);

        setTeachers(teachers);
        setTeachersAll(response.data);
        setNumberOfPages(numberOfPages);
    }

    useEffect(() => {
        loadTeachers();
    }, []);
 
    const editRecord = (record) => {
        console.log("Edit Record", record);
    }
 
    const deleteRecord =(record) => {
        console.log("Delete Record", record);
        
    }

    const deleteTeacher = async(teacherId:number) => {
        const result = await confirm('Are you sure you wish to remove this teacher?');
        if(result){
            axios.get(`/api/teachers/deleteteacher?id=${teacherId}`);
            let teacherEdit:Array<Teacher> =  [...teachers] ;

            teacherEdit = teacherEdit.filter(t=>t.teacherId!==teacherId);
            setTeachers(teacherEdit);
        }
    }

   

    const convertSubjectsToPills = (currentCourses: string) => {
        let courseArray = currentCourses.split(',');

        let pills = courseArray.map(f => (
            <>
                <SubjectBadge color={f.split('|')[1]} text={f.split('|')[0]} />&nbsp;
            </>
        ));
        return pills;
    }

    const renderPaginationItem = () => {
        let output = new Array<any>();
        for(var i=1; i < numberOfPages + 1; i++) {
            if(i != currentPage){
                output.push(<Pagination.Item key={i} data-page={i}> {i} </Pagination.Item>)
            } else {
                output.push(<Pagination.Item key={i} data-page={i} active> {i} </Pagination.Item>)
            }
        }
        return output;
    }

    

    const handlePagClick = (e) => {
        const clickValue = e.target.offsetParent.getAttribute("data-page")
        ? e.target.offsetParent.getAttribute("data-page")
        : e.target.getAttribute("data-page");

        repaginate(parseInt(clickValue));

        setCurrentPage(parseInt(clickValue));
    }

    const repaginate = (pageNub:number) => {
        let teachers = new Array<Teacher>();

        let offset = ((pageNub-1) * paginationConfig.recordsPerPage);

        for(var i= offset; i < offset + paginationConfig.recordsPerPage; i++) {
            if(teachersAll[i]){
                teachers.push(teachersAll[i]);
            }
        }

        setTeachers(teachers);
    }

    const searchField_OnChange = async (e:any) => {
        setSearchField(e.target.value);

        loadTeachers(e.target.value, searchStatus);
    }

    const statusOnChange = (e:any) => {
        setSearchStatus(parseInt(e.target.value));
        loadTeachers(searchField, e.target.value);
    }
 
    return (
        <>
            <div>
                <div>
                        <div className="card"  >
                        <div className="card-body">
                            <div>
                                <div className="row mb-0 ">
                                    <div className="col-9" style={{paddingRight:"10px"}} ></div>


                                    <div className="col-2" style={{paddingRight:"10px"}} >
                                        <div  style={{paddingBottom:"10px",paddingLeft:"10px"}}>
                                        <div style={{fontWeight: 600}}>Teacher name </div>
                                            <input className="form-control"  value={searchField} onChange={searchField_OnChange} />
                                        </div>
                                    </div> 

                                    <div className="col-1" style={{paddingRight:"20px"}} >
                                        <div  style={{paddingBottom:"10px"}}>
                                            <div style={{fontWeight: 600}}>Status</div>
                                            <select className="form-control" value={searchStatus} onChange={(e) => statusOnChange(e)} >
                                                <option value={-1}>All</option>
                                                <option value={1}>Active</option>
                                                <option value={0}>Inactive</option>
                                            </select>
                                        </div>
                                    </div>  
                                </div> 
                            </div> 
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-body">
                            
                        <div className="page-title-box" >
                            <div className="page-title-btn btn-group float-right">
                                <ActionButton onClick={()=>props.history.push('/TeachersForm')} text="ADD TEACHER" />
                            </div>
                            <h4 className="page-title">Teachers - {teachers.length} found</h4>
                        </div>
                            
                        <table className="table table table-hover m-0 " style={{backgroundColor:"white"}}>
                            <thead>
                                <tr>
                                    <th style={{width:"75px"}}></th>
                                    <th>First name</th>
                                    <th>Last name</th>
                                    <th>Mobile</th>
                                    <th>Email</th>
                                    <th>Subjects taught</th>
                                    <th>Status</th>
                                    <th style={{width:"130px"}}>Online access</th>
                                </tr>
                            </thead>
                            <tbody>
                                {teachers && teachers.map((t,i) => (
                                    <tr onClick={()=> props.history.push(`/teachersForm/${t.teacherId}`)} >
                                        <td valign="top"><Pill value={i+1} color="pink" /></td>
                                        <td>{t.firstName}</td>
                                        <td>{t.lastName}</td>
                                        <td>{t.mobile}</td>
                                        <td>{t.email}</td>
                                        <td style={{width:"320px"}}>{convertSubjectsToPills(t.subjectsString) }</td>
                                        <td>{(!t.active) ? <span className="badge badge-primary bg-grey">Inactive</span> : <span className="badge badge-primary bg-green">Active</span>}</td>
                                        <td style={{textAlign: "center"}}>
                                            <TickCross checked={t.hasLogin}/>
                                        </td>
                                    </tr>
                                ))}

                                {!teachers || teachers.length ===0 && <tr><td colSpan={7} align="center">No teachers currently</td></tr>}
                                    
                            </tbody>
                        </table>
                    </div>
                </div>
                {currentPage !== numberOfPages && teachers && teachers.length > 0 &&
                    <Pagination onClick={handlePagClick} style={{float:"right", paddingTop:"8px"}}>
                        <Pagination.First data-page={1} />
                        <Pagination.Prev data-page={(currentPage !== 1 ) ?  currentPage - 1 :  1 }   />
                            {renderPaginationItem().map(pag=>(
                                pag
                            ))}
                        <Pagination.Next  data-page={(currentPage < numberOfPages ) ?  currentPage + 1 :  numberOfPages} />
                        <Pagination.Last data-page={numberOfPages}  />
                    </Pagination>
                }
            </div>


            
        </>
    )
}
 
export default withRouter(TeachersGrid);