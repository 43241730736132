import React, {useState, useEffect} from 'react';

import Form1FindStudent from '../components/SignUp/Form1FindStudent';
import Form2Register from '../components/SignUp/Form2Register';
import Form3Confirmation from '../components/SignUp/Form3Confirmation';
import Student from '../models/Student';

interface IProps {
    history:History;
}
const SignUp = (props:IProps) => {
    const [stage, setStage] = useState(1);
    const [currentStudent, setCurrentStudent] = useState(new Student());

    const changeWizardStage = (nextStage:number) => {
        setStage(nextStage);
    }

    const updateCurrentStudent = (student:Student) => {
        setCurrentStudent(student);
    }

    return(<>
        {(stage===1) && <Form1FindStudent changeWizardStage={changeWizardStage} updateCurrentStudent={updateCurrentStudent} />}
        {(stage===2) && <Form2Register changeWizardStage={changeWizardStage} currentStudent={currentStudent}  />}
        {(stage===3) && <Form3Confirmation changeWizardStage={changeWizardStage} history={props.history} />}
    </>)
}

export default SignUp;