import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { AlertLink } from 'react-bootstrap/Alert';
import Address from '../../models/Address';
import Confirmation from '../../modals/Confirmation';


interface IProps {
    addressClick:Function;
    companyId:number;
}

const AddressGrid = (props:IProps) => {

    const [addresses, setAddresses] = useState(new Array<Address>());
    const [showDelConfirm, setShowDelConfirm] = useState(false);

    const [currentAddress, setCurrentAddress] = useState(new Address());

    const loadData = async() => {
        const response = await axios.get<Array<Address>>(`/api/companies/GetAllByCompanyId?companyId=${props.companyId}&onlySecondary=true`);
        setAddresses(response.data);
    }

    const deleteSecondaryAddress = async(address:Address) => {
        setShowDelConfirm(true);
        setCurrentAddress(address);
    }

    const confirmButton_Click = async() =>{
        await axios.get(`/api/companies/DeleteCompanyAddress?addressId=${currentAddress.addressId}`);
        loadData();
        setShowDelConfirm(false);
    }

    useEffect(() => {
        loadData();
    }, []);
    
    return (
        <>
            <table className="table table table-hover m-0 ">
                {addresses && addresses.length > 0 && 
                <tr>
                    <td><strong>Address</strong></td>
                    <td><strong>Post code</strong></td>
                    <td></td>
                </tr>}

                {addresses.map(a => (
                    <tr >
                        <td style={{cursor:"pointer"}} onClick={() => props.addressClick(a.addressId)}>{a.address}</td>
                        <td style={{cursor:"pointer"}} onClick={() => props.addressClick(a.addressId)}>{a.postCode}</td>
                        <td>
                            {!a.inUse && 
                                <div  style={{float: "right" ,paddingTop:"3px",paddingRight:"6px", cursor: "pointer"}}><i className="ti-trash pink" style={{float: "right",fontSize: "20px"}} onClick={()=> deleteSecondaryAddress(a) } ></i></div>
                            }
                        </td>
                    </tr>
                ))}

                {!addresses || addresses.length === 0  && <tr>
                        <td colSpan={4} align="center" >No secondary addresses currently</td>
                    </tr>
                }

            </table>

            <Confirmation showModal={showDelConfirm} hideModal={() => setShowDelConfirm(false)} confirmButton_Click={confirmButton_Click}  title="Confirm deletion" bodyText="Are you sure you wish to delete this address?"  />
        </>
    );
}

export default AddressGrid;