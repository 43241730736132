export default class SettlementReportVM {
    constructor() {
      
    }

    public paymentHeaderId:number;
    public dateCreated:Date;
    public dateCreatedString:string;
    public payment:number;

    public periodEndDate:Date;
    public periodEndDateString:string;

}