export default class Student {
    constructor() {

    }

    public studentId: number = 0;
    public clientId: number = 0;
    public firstName: string = "";
    public lastName: string = "";
    public email: string = "";
    public genderId: number = 3;
    public gender: string = "";
    public dateOfBirth: Date ;
    public dateOfBirthString: string = "";
    public companyId: number = 0;
    public statusId: number = 0;
    public address1: string = "";
    public address2: string = "";
    public town: string = "";
    public postCode: string = "";
    public mobile: string = "";
    public landline: string = "";
    public company: string = "";
    public county: string = "";
    public country: string = "";
    public notes: string = "";
    public status: number = 0;
    public currentCourses: string = "";
    public hasLogin: boolean = false;
    public userId: number;
    public nationality: string = "";

    public consentReceiveEmails: boolean = false;
    public consentForProcessingPersonalData: boolean = false;
    public consentForProcessingMarketingData: boolean = false;
    public consentForProcessingSalesData: boolean = false;

    public hasMarkedOrPaid: boolean = false;


}

