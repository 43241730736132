import React, {useState} from 'react';
import BottomNavigation from 'reactjs-bottom-navigation';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

import 'reactjs-bottom-navigation/dist/index.css';


interface IProps {
    history:any;
    currentTab:number;
}

const BottomNav = (props:IProps) => {
    
    const [currentTab, setCurrentTab] = useState(0);
    const bottomNavItems = [
        {
          title: 'Home Page',
          icon: <i className="ti-home" style={{fontSize:"22px"}}></i>,
          activeIcon: <i className="ti-home" style={{fontSize:"22px"}}></i>,
          onClick: () => props.history.push('/')
        },
        {
          title: 'Current Courses',  
          icon: <i className="ti-calendar" style={{fontSize:"22px"}}></i>,
          activeIcon: <i className="ti-calendar" style={{fontSize:"22px"}}></i>,
          onClick: () => props.history.push('/students/classesmobile')
        },
        {
          title: 'Past Courses',  
          icon: <i className="ti-calendar" style={{fontSize:"22px"}}></i>,
          activeIcon: <i className="ti-calendar" style={{fontSize:"22px"}}></i>,
          onClick: () => props.history.push('/students/coursesmobile')
        },
        
    ]
    return(
        <>{isMobile && 
        <BottomNavigation
            items={bottomNavItems}
            defaultSelected={props.currentTab}
            //onItemClick={(item) => setCurrentTab(item.id)}
        />}
        </>
    );
}

export default BottomNav;