import React from 'react';

interface IProps {
    statusId:number;
    onClick:Function
}



const isStatusClickable = (statudId:number) => {
    return true;
}


const getStatusName = (statusId:number) => {
    switch(statusId) {

        case 0:
            return <span className={`badge badge-inverse`}>No Students</span>;

        case 1:
          return <span className={`badge badge-class-status-scheduled`} >Scheduled</span>;
        case 2:
            return <span className={`badge badge-class-status-requires-marking`}>Requires marking</span>;
        case 3:
            return <span className={`badge badge-class-status-marked`}>Marked </span>;
        case 4:
            return <span className={`badge badge-class-status-Archived`}>Archived</span>;
        case 5:
            return <span>Suspended</span>;
            default:
        case 6:
            return <span className={`badge badge-class-status-paid`}>Paid</span>;

          return <span></span>;
      }
}

const ClassStatusBadge = (props:IProps) => {
    if(isStatusClickable(props.statusId)){
        return <><a style={{cursor: "pointer"}} onClick={() => props.onClick()}>{getStatusName(props.statusId)} </a></>
    } else {
        return <>{getStatusName(props.statusId)}</>
    }
    
}

export default ClassStatusBadge;
