

import React, {useEffect, useState} from 'react';

import BottomNav from '../../teachers/BottomNav';
import Class from '../../models/Class';
import UserService from '../../services/userService';
import axios from 'axios';

import MarkClassesModal from '../../modals/MarkClassesModal';

interface IProps {
    history:any
}

const MarkClassesMobile = (props:IProps) => {
    const [classes, setClasses] = useState(new Array<Class>());
    let userService = new UserService();

    const loadData = async() => {
        const results = await axios.get<Array<Class>>(`api/classes/GetByFilter?timePeriod=W&subjectId=&teacherId=${userService.getUser().roles[0].entityId}&levelId=-1&statusId=-1`); 
        setClasses(results.data);
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
           
           im here to mark mobile classes
            <BottomNav history={props.history} currentTab={2} />
        </>
    )
}

export default MarkClassesMobile;