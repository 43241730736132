


export default class PaymentReport {
    constructor() {

    }

    public paymentHeaderId:number
    public title: string
    public monthYear:string
    public revision:number
    public periodEndDate:Date;
    public periodEndDateString:string;
    public amountTotal:number;
}

        