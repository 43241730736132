import React, {useState,useEffect} from 'react';
import InputTextBox from '../common/InputTextBox';
import InputTextBoxDuel from '../common/InputTextBoxDuel';
import TimeSelector from '../common/TimeSelector';

import InputDropDown from '../common/InputDropDown';
import InputDatePickerDual from '../common/InputDatePickerDual';
import Teacher from '../../models/Teacher';
import DayWeek from '../../models/DayWeek';
import Room from '../../models/Room';
import TeachersRate from '../../models/TeachersRate';

import DropDownOption from '../../models/DropDownOption';
import ClassGenVM from '../../models/ClassGenVM';
import ClassSeries from '../../models/ClassSeries';

import axios from 'axios';




//const days = [{}'Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'];

const days = [
    {Value:1, Text:"Monday"},
    {Value:2, Text:"Tuesday"},
    {Value:3, Text:"Wednesday"},
    {Value:4, Text:"Thursday"},
    {Value:5, Text:"Friday"},
    {Value:6, Text:"Saturday"},
    {Value:7, Text:"Sunday"}
]

const times = [
    {Text:"09:00", Value:0},
    {Text:"09:30", Value:1},
    {Text:"10:00", Value:2},
    {Text:"10:30", Value:3},
    {Text:"11:00", Value:4},
    {Text:"11:30", Value:5},
    {Text:"12:00", Value:6},
    {Text:"12:30", Value:7},
    {Text:"13:00", Value:8},
    {Text:"13:30", Value:9},
    {Text:"14:00", Value:10},
    {Text:"14:30", Value:11},
    {Text:"15:00", Value:12},
    {Text:"15:30", Value:13},
    {Text:"16:00", Value:14},
    {Text:"16:30", Value:15},
    {Text:"17:00", Value:16},
    {Text:"17:30", Value:17},
    {Text:"18:00", Value:18},
    {Text:"18:30", Value:19},
    {Text:"19:30", Value:20},
    {Text:"20:00", Value:21},
    {Text:"20:30", Value:22},
    {Text:"21:00", Value:23},
    {Text:"21:30", Value:24},
    {Text:"22:00", Value:25},
    {Text:"22:30", Value:26},
    {Text:"23:00", Value:27},
    {Text:"00:00", Value:28},
]

const schedules = [
    {Text:"Just this class", Value:1},
    {Text:"This series", Value:2},
    {Text:"All classes", Value:3},
]

interface IProps {
    id:number,
    updateClass: Function,
    setSeriesId: Function,
    errorMessage:string
}

const ClassesEditForm = (props:IProps) => {
    const [showDate, setShowDate] = useState(true);
    const [teachers, setTeachers] = useState(new Array<DropDownOption>());
    const [dayWeeks, setDayWeeks] = useState(new Array<DropDownOption>());
    const [rooms, setRooms]       = useState(new Array<DropDownOption>());

    const [classGen, setClassGen] = useState(new ClassGenVM());
    const [timeSelected, setTimeSelected] = useState(0);
    const [classSeries, setClassSeries] = useState(new Array<DropDownOption>());
    const [teacherRates, setTeacherRates] = useState(new Array<DropDownOption>());

    const [currentClass, setCurrentClass] = useState(new ClassGenVM());




    useEffect(() => {
        let teacherOptions = new Array<DropDownOption>();
        let scheduleOptions = new Array<DropDownOption>();
        let dayWeekOptions = new Array<DropDownOption>();
        let roomOptions = new Array<DropDownOption>();
        let seriesOptions = new Array<DropDownOption>();
        let teacherRateOptions = new Array<DropDownOption>();

        const loadData = async () => {

            let responseClass = await axios.get<ClassGenVM>(`/api/classes/getbyid?id=${props.id}`);
            const data = responseClass.data;

            data.time = data.classDatesCombined;

            props.updateClass(data);



            let responseTeachers = await axios.get<Array<Teacher>>(`/api/teachers/getall?status=1`);
            responseTeachers.data.map(t => teacherOptions.push({ Text: t.firstName + " " + t.lastName, Value: t.teacherId }));

            let responseDayWeeks = await axios.get<Array<DayWeek>>(`/api/staticdata/getalldayweeks`);
            responseDayWeeks.data.map(t => dayWeekOptions.push({ Text: t.name, Value: t.dayWeekId }));

            let responseRooms = await axios.get<Array<Room>>(`/api/rooms/getbycompanyid?id=${data.companyId}`);
            responseRooms.data.map(t => roomOptions.push({ Text: t.name, Value: t.roomId }));

            let responseSeries = await axios.get<Array<ClassSeries>>(`/api/classes/GetSeriesByCourseId?id=${data.courseId}`);
            seriesOptions.push({ Text: 'This class', Value: -1})
            seriesOptions.push({ Text: 'This + following classes', Value: data.seriesId})
           

            let responseTeachersRates = await axios.get<Array<TeachersRate>>(`/api/teachers/GetTeachersRateByTeacherId?id=${data.teacherId}`);
            responseTeachersRates.data.map(t => teacherRateOptions.push({ Text: '£' + t.rate.toString() + ' per hr', Value: t.teacherRateId }));

            setTeachers(teacherOptions);
            setDayWeeks(dayWeekOptions);
            setClassSeries(seriesOptions);
            setRooms(roomOptions);
            setTeacherRates(teacherRateOptions);


            data.time = setTime(data);

            setCurrentClass(data);


            await setClassGen(data);
        }

        loadData();
    }, []);

    const setTime = (data: ClassGenVM) => {
        const timeCombined = data.classDatesCombined;
        const startTime = timeCombined.split(' - ')[0];

        return startTime;
    }

    const changeHandler = (e:any) => {
        let updatedOptions = { ...classGen, [e.target.name]: parseInt(e.target.value) };
        setClassGen (updatedOptions);

        props.updateClass(updatedOptions);
    }

    const teacherChangeHandler = async (e: any) => {
        let teacherId = parseInt(e.target.value);
        let updatedOptions = { ...classGen, [e.target.name]: teacherId };
        let teacherRateOptions = new Array<DropDownOption>();
        setClassGen(updatedOptions);

        let responseTeachersRates = await axios.get<Array<TeachersRate>>(`/api/teachers/GetTeachersRateByTeacherId?id=${teacherId}`);
        responseTeachersRates.data.map(t => teacherRateOptions.push({ Text: '£' + t.rate.toString() + ' per hr', Value: t.teacherRateId }));
        setTeacherRates(teacherRateOptions);


        props.updateClass(updatedOptions);
    }
    

    const changeHandlerTime = (e: any) => {
        const text = e.target.options[e.target.selectedIndex].text;
        const timeFound = times.find(t => t.Text === text);
        const timeText = (timeFound ? timeFound.Text : 0);

        let updatedOptions = { ...classGen, [e.target.name]: timeText };
        setClassGen(updatedOptions);
        props.updateClass(updatedOptions);
    }

    const newChangeHandlerTime = (newTime:string) => {
        let updatedOptions = { ...classGen, ['time']: newTime };
        setClassGen(updatedOptions);
        props.updateClass(updatedOptions);
    }

    const changeApplyto = (e:any) => {
        if(e.target.value == -1){
            setShowDate(true)
            props.setSeriesId(-1);
        } else {
            setShowDate(false)
            props.setSeriesId(classGen.seriesId);
        }
    }

    const startDateChangeHandler = (date: any) => {
        let newDate = (date.getDate()).toString() + "/" + ((date.getMonth() + 1).toString() + "/" + date.getFullYear().toString())
        let updatedClass = { ...classGen, classDateString: newDate };

        setClassGen(updatedClass);
        props.updateClass(updatedClass);
    }

    return (
        <> 
            <div className="col-12" style={{padding:"15px"}}>
                <div style={{height:"145px"}} >
                    <div className="row">
                        <div className="col">
                            <div className="row" style={{paddingLeft:"15px"}}>
                                <div className="col"> 
                                    <InputDropDown label="Teacher" data={teachers} name="teacherId" value={classGen.teacherId} showPlease={true} width={215} changeFunc={(e) => teacherChangeHandler(e) }  />
                                    <InputDropDown label="Teacher's rate" data={teacherRates} name="teacherRateId" value={classGen.teacherRateId} showPlease={true} width={150} changeFunc={(e) => changeHandler(e)}  />

                                    <TimeSelector label="Start time" fullDate={currentClass.classDatesCombined} timeUpdated={newChangeHandlerTime}  />
                                </div>
                                <div className="col">
                                    {/*<InputDropDown label="Room" data={rooms} name="roomId" value={classGen.roomId} showPlease={true}  width={415}  changeFunc={(e) => changeHandler(e)} /> */}
                                    <InputTextBoxDuel mode="E" label="Class mins" width={125} name="classLength" value={classGen.classLength ? classGen.classLength.toString() : ""} onChange={(e) => changeHandler(e)}/>
                                    <InputDropDown  label="Apply to" data={classSeries} showPlease={false} width={195} changeFunc={(e)=>changeApplyto(e)}  />

                                    <InputDatePickerDual mode="E" value={classGen.classDateString} label="Class Date" onChange={(date) => startDateChangeHandler(date)} width={15}  />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{color:"red"}}><div style={{textAlign:"center"}}>{props.errorMessage}</div></div>
            </div> 
        </>
    )
}

export default ClassesEditForm;