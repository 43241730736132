import React, { useContext,useState } from 'react';
import Student from '../../models/Student';

import axios from 'axios';

interface IProps {
    changeWizardStage:Function;
    updateCurrentStudent:Function;
}

const Form1FindStudent = (props:IProps) => {
    const [email, setEmail] = useState("");
    const [errorVis, setErrorVis] = useState("");
    

    const lookUpUser = () => {
        //props.changeWizardStage(2)
    }

    const formSubmitted = async(e:any) => {
        e.preventDefault();
        const response = await axios.get<Student>(`/api/signup/GetStudentByEmail?email=${email}`);

        const studentFound = response.data;
        if(studentFound) {
            if(studentFound.hasLogin) {
                setErrorVis("You are already registered, please log in");
            } else {
                props.updateCurrentStudent(studentFound);
                setErrorVis("");
                props.changeWizardStage(2);
            }
        } else {
            setErrorVis("We could not find you, please contact us");
        }
    }

    const updateEmail = (e:any) => {
        setEmail(e.target.value);
    }
  
    return(
        <>
            <div className="">
                <form onSubmit={(e:any)=>formSubmitted(e)}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-8 col-lg-6 col-xl-5">
                                <div className="text-center pb-1">
                                    <a href="index.html">
                                        <span><img src="assets/images/logo.png" alt="" height="18" /></span>
                                    </a>
                                </div>
                                <div className="card mt-4">
                                    <div className="card-body p-4">

                                        <div className="form-group mb-3">
                                            <div style={{fontSize:"18px"}}>Please enter your email</div>
                                        </div>

                                        <div className="form-group mb-3">
                                            <input className={`form-control `}  type="text" placeholder="email" value={email} onChange={(e) => updateEmail(e)} />
                                        </div>

                                        <div className="form-group mb-4">
                                            <button className="btn btn-primary-pink btn-bordred btn-block waves-effect waves-light text-uppercase" onClick={() => lookUpUser()} >NEXT</button>
                                        </div>
                                        <div className="form-group mb-3">
                                            <div>{errorVis}</div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default Form1FindStudent;