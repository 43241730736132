import React, {Component} from 'react';
import 'react-image-picker/dist/index.css'
import axios from 'axios';

import Avatar from '../../models/Avatar';

interface IProps {
  avatarChanged:Function
  currentAvatarId: number
}

interface IState {
    avatar:Avatar,
    imageList:Array<Avatar>,
    currentAvatarId: number
}

class TeacherAvatarSelector extends Component<IProps,IState> {
    constructor(props) {
      super(props)
      this.state = {
        avatar: new Avatar(),
        imageList:new Array<Avatar>(),
        currentAvatarId:this.props.currentAvatarId
      }
      this.onPick = this.onPick.bind(this);

      this.loadAvatars();

      this.setState({avatar:this.state.imageList[1]});

    }
  
    onPick(image:any) {
      let newAvatar = new Avatar();

      newAvatar.avatarId = image.value;
      newAvatar.imageName = image.src;

      this.props.avatarChanged(newAvatar);
      this.setState({avatar:newAvatar});

    }

    loadAvatars = async() => {
      let response = await axios.get<Array<Avatar>>('api/avatar/getall');

      this.setState({imageList:response.data});
    }

    setCurrentAvatar = (avatar:Avatar) => {

      this.setState({currentAvatarId:avatar.avatarId});

      let newAvatar = new Avatar();

      newAvatar.avatarId = avatar.avatarId;
      newAvatar.imageName = avatar.imageName;

      this.props.avatarChanged(newAvatar);

      this.setState({avatar:newAvatar});
    }
  
    render() {
      return (
        <div>
          <div className="image_picker" style={{paddingLeft:"35px"}}>
            
            {this.state.imageList.map(image => (
                <div style={{paddingTop:"8px"}} className={`responsive ${image.avatarId === this.state.currentAvatarId ? "selected" : ""}`} onClick={() => this.setCurrentAvatar(image)}>
                  <img src={`/assets/images/${image.imageName.replace('{size}','lg')}`} className="thumbnail" style={{width: 150, height: 150, objectFit: "cover"}}  />
                  <div className="checked"><div className="icon"></div></div>
                </div>
              ))
            }
          </div>
        </div>
      )
    }
  }
export default  TeacherAvatarSelector;