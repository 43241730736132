import React, {useEffect, useState} from 'react'
import Axios from 'axios';
import ClassAttendenceBadge from '../badges/ClassAttendenceBadge';

class AttendanceContainer {
  public attendanceLists:Array<AttendanceList>;
}

class AttendanceList {
  constructor() {

  }
  public attendanceRow: Array<AttendanceRow>;
}

class AttendanceRow {
  constructor() {

  }

  public studentName: number = 0;
  public attendanceItems: Array<AttendanceItem>;
}

class AttendanceItem {
  public classDate:string;
  public statusId: number; 
}


interface IProps {
  courseId:number;
  rowMaxNumber:number;
  showScroll:boolean
}


const StudentAttendence = (props:IProps) => {

    const rowMaxNumber:number = props.rowMaxNumber;

    const [attendanceArray, setAttendanceArray] = useState(new Array<Array<string>>());

    const convertDataRowToArray = (data, rowId):Array<string> => {
      let result = new Array<string>();  
      let row =  JSON.stringify(data);
      let rowString = row.replace("{","").replace("}","");

      result = rowString.split(','); 

      return result;

    }
  
    const hydrateAttendenceModel = (data) => {

      let attendanceContainer = new AttendanceContainer();
      let attendanceList = new AttendanceList();
      const datesPerRow:number = 5;
      const result = new Array<string[]>();

      for(let i=0;i<data.length;i++){
        let row = convertDataRowToArray(data[i], i); 
        result.push(row);
      }

      /*data.forEach((i,d) => {
        let result = convertDataRowToArray(d, i); 
        alert(result[i]);
        
      });*/

      //let attendanceRow = new AttendanceRow();
      //attendanceRow.studentName = 

      return result;
    }    
  
    const loadData = async() => {
        var result = await Axios.get(`/api/reports/GetStudentCourseAttendence?courseId=${props.courseId}`);
        const attendanceArrayResult = hydrateAttendenceModel(result.data);

        setAttendanceArray(attendanceArrayResult);
    }


    const showCell = (rowMaxNumber:number, counter:number, currentTable:number) => {

      //counter = 1 to 10 for each row
      //currentRow = 1 to 3 for each table
      //rowMaxNumber = const number of cells in a row

      const startNumber = ((currentTable -1) * rowMaxNumber) +1;


      if(counter >= startNumber && counter < (startNumber + rowMaxNumber)){
        return true;
      } else {
        return false;
      }
      return true
    }

    const showDate = (rowMaxNumber:number, counter:number, currentTable:number,a:Array<string>) => {
      let dateToReturn:string = "";
      let timeToReturn:string = "";
      let dateToGetIndex:number = 0;
      if(currentTable === 1){
        dateToGetIndex = counter ;
      }

      if(currentTable === 2){
        dateToGetIndex = (counter + rowMaxNumber) ;
      }

      if(currentTable >= 3){
        dateToGetIndex = (counter + (rowMaxNumber * (currentTable-1))) ;
      }

      if(counter !== 0 && counter <= rowMaxNumber){
        dateToReturn = a[dateToGetIndex];
        let pro = dateToReturn;
        if(pro && pro.indexOf(':') !== -1){
          dateToReturn = (pro.split(' ')[0] + ' ' + pro.split(' ')[1] + ' ' + pro.split(' ')[2]).replace('"','');
          timeToReturn = ((pro.split(' ')[3]).split(':')[0] + ':' + (pro.split(' ')[3]).split(':')[1] +':' + (pro.split(' ')[3]).split(':')[2]).replace('"','').replace('-',' - ');
        }
      }
      if ((counter <=rowMaxNumber) && counter !== 0 && (dateToReturn!="" && timeToReturn!="")){
        return <td style={{width:"140px"}} align='center'><div><strong>{dateToReturn}<br/>{timeToReturn}</strong></div></td>
      }
      if((counter <=rowMaxNumber) && counter === 0){
        return <td style={{width:"245px"}} align='left'><div><strong>{dateToReturn}<br/>  {timeToReturn}</strong></div></td>
      }
    }



    const buildAttendenceTable = (data) => {
      const counter:number = 1;
      
      return <div>
        {data.map((m,counter) => (
          <>
          {
            attendanceArray.map((a,j) => (
              (j === 0 ) &&
              <tr style={{paddingTop:"8px"}}>
                {a.map((r,i) => (
                    showDate(rowMaxNumber, i, m, a)
                ))}
              </tr>
            ))
          }

          {
            attendanceArray.map(a => (
              <tr style={{height:"30px"}}>
                {a.map((r,i) => (
                  <>
                    {
                    //If i=0 then its the student otherwise check to see if we can show the cell
                    (i===0 || showCell(rowMaxNumber, i, m)) && <td align={(i!==0) ? `center` : `left`} > 
                    {i!==0 && isNaN(parseInt(r.split(':')[2])) &&  <div style={{paddingTop:"5px"}}><i style={{fontSize:"22px",color:"#45B0E2",fontWeight:"bolder"}}className="mdi mdi-close red fa-lg"></i></div>}
                      {(i!==0 && showCell(rowMaxNumber, i, m)) && (parseInt(r.split(':')[3]) === 2 || parseInt(r.split(':')[3]) === 5) &&  <ClassAttendenceBadge statusId={parseInt(r.split(':')[3])} onClick={()=>{}} />}
                      {(i===0) ? JSON.parse('{' + r + '}').Student : ''}
                    </td>
                    }
                  </>
                ))}
              </tr>
            ))
          }
          <tr>
            {/* different border depending on if last table */}            
            {(data.length === counter+1) && <td style={{borderLeftColor:"white",borderRightColor:"white",borderBottomColor:"white"}}>&nbsp;</td>}
            {(data.length !==  counter+1) && <td style={{borderLeftColor:"white",borderRightColor:"white"}}>&nbsp;</td>}
          </tr>
        </>
        ))
          
      }
      </div>
    }

    const getArrayToLoop = ():Array<number> => {
      let returnArray = new Array<number>();
      let i:number=0;

      if(attendanceArray[0]){
        //minus one as the first cell contains the student names
        i = Math.ceil((attendanceArray[0].length -1) / rowMaxNumber);
      }

      for(let j=0; j < i; j++){
        returnArray.push(j+1);
      }

      return returnArray;
    }

    const attendenceTableBuilder = () => {
      const numberOfTables = getArrayToLoop();

      return buildAttendenceTable(numberOfTables);
    }

    useEffect(() => {
        loadData();
    }, []);

  return (
    <div>
      <div id="NewScroller"  style={(props.showScroll)?{overflowX:"auto", width:"98%"} : {}}>
        
        {attendanceArray.length > 0 &&
          <table className='attendenceTable' style={{paddingBottom:"12px", width:`${attendanceArray[0] && attendanceArray[0].length * 150}px`}}>
            {attendenceTableBuilder()}
          </table>
        }
        {attendanceArray.length === 0 && 
          <div>No records found</div>
        }

      </div>
    </div>
  )
}

export default StudentAttendence