import React, { useState, useEffect, Component } from "react";
import axios from "axios";
import { Button,Modal } from 'react-bootstrap';
import InputDatePickerDual from "../common/InputDatePickerDual";
import TeachersRates from "./TeachersRates";
import InputTextBoxDuel from '../common/InputTextBoxDuel';
import InputTextAreaDuel from '../common/InputTextAreaDual';
import Teacher from '../../models/Teacher';
import Subject from '../../models/Subject';
import GDPRVM from '../../models/GDPRVM';
import TeacherRate from '../../models/TeachersRate';
import useSnackbar from 'react-snackbar-toast';
import ReactSelectOption from '../../models/ReactSelectOption';
import TickCross from '../common/TickCross';
import TeacherAvatarSelector from './TeacherAvatarSelector';
import Avatar from '../../models/Avatar';
import ActionButton from '../buttons/ActionButton';
import CancelButton from '../buttons/CancelButton';
import FormService from '../../services/formService';
import Nationality from '../../models/Nationality';
import TeachersClasses from '../../components/teachers/teacherSubjectSelector';
import SubjectBadge from "../badges/SubjectBadge";

import ActiveBadgeStatus from '../../components/common/ActiveBadgeStatus';
import postal from 'postal';

import swal from 'sweetalert';

interface IProps {
 id:number
}

const TeachersForm =  (props: IProps) =>  {
  const {addToast} = useSnackbar();

  const open = (message, option:any) => {
    addToast(message, option);  
  };

  const newTeacher = new Teacher();
  newTeacher.genderId = 1;

  const [currentTeacher, setCurrentTeacher] = useState(newTeacher);
  const [currentTeacherRate, setCurrentTeacherRate] = useState(new TeacherRate());
  const [validationErrors, setValidationErrors] = useState(new Array<string>());
  const [editMode, setEditMode] = useState("E");
  const [subjectsTempStore, setSubjectsTempStore] = useState(new Array<Subject>());
  const [subjectsOptions, setSubjectsOptions] = useState(new Array<ReactSelectOption>());
  const [subjects, setSubjects] = useState(new Array<Subject>());
  const [rateDescError, setRateDescError] = useState(false);
  const [rateError, setRateError] = useState(false);
  const [show, setShow] = useState(false);
  const [currentSubjects, setCurrentSubjects] = useState(new Array<ReactSelectOption>());
  const [gdprConsent, setGdprConsent] = useState(new Array<GDPRVM>());
  const [showStatusUpdate, setShowStatusUpdate] = useState(false);
  const [currentSelectedStatus, setCurrentSelectedStatus] = useState(0);
  const [showSelectAvatar, setShowSelectAvatar] =  useState(false);
  const [currentAvatar, setCurrentAvatar] =  useState(new Avatar());
  const [Nationalites, setNationalities] = useState(new Array<Nationality>());
  const [showSelectSubjects, setShowSelectSubjects] = useState(false);
  const [showLoginDetails, setShowLoginDetails] = useState(false);
  const [tempPassword, setTempPassword] = useState("");
  const [showRemoveAccessConf, SetShowRemoveAccessConf] = useState(false);
  const sidebarChannel = postal.channel("SideBar");

  const loadTeacher = async(teacherId:number) => {
    const response = await axios.get<Teacher>(`/api/teachers/getteacherbyid?id=${teacherId}`);
    let loadededTeacher = response.data;

    let subjectIds:Array<number> = [];

    loadededTeacher.subjects.map(s => {
      subjectIds.push(s.subjectId)
    });

    setCurrentTeacher(loadededTeacher);

    getSubjectDefaults(loadededTeacher.subjects);
  }

  const loadSubjects = async() => {
    const response = await axios.get<Array<Subject>>(`/api/subjects/getall`);
    setSubjects(response.data);

    let subjectArray = new Array<ReactSelectOption>();
    response.data.forEach(s => {
      let dropDownOption = new ReactSelectOption();
      dropDownOption.value = s.subjectId;
      dropDownOption.label = s.name;
      dropDownOption.other = s.color;

      subjectArray.push(dropDownOption);
    });

    setSubjectsOptions(subjectArray);
  }


  const loadGPRD = async() => {
    var gdprResponse = await axios.get<Array<GDPRVM>>(`/api/users/GetGDPRConsentByLogin?login=rolandjpiggott@gmail.com`);
        setGdprConsent(gdprResponse.data);
  }

  const loadNationalities =  async () => {
    const response = await axios.get<Array<Nationality>>("/api/StaticData/GetAllNationalities");
    setNationalities(response.data);
  }

  useEffect(() => {
    if(props.id && props.id !== 0){
      loadTeacher(props.id);
    } else {
      setEditMode("E");
    }

    loadSubjects();
    loadGPRD();
    loadNationalities();
   
    window.scrollTo(0, 0);

    sidebarChannel.publish("changeUrl", "teachers");
  }, []);

  const handleClose = () => {
    setShow(!show);
  }

  const updateTeacher = (e:any) => {
    FormService.setAreFormsDirty(true);
    setCurrentTeacher({ ...currentTeacher, [e.target.name]: e.target.value });
  }

  const IsNumeric=(num) => {
    return (num > 0 || num === 0 || num === '0' || num < 0) && num !== true && isFinite(num);
  }

  const updateTeachersRate = (e:any) => {
      if (e.target.name === "rate") {

        if(IsNumeric(e.target.value)){
          setCurrentTeacherRate({ ...currentTeacherRate, [e.target.name]: e.target.value });
        }  
      } else {
          setCurrentTeacherRate({ ...currentTeacherRate, [e.target.name]: e.target.value });
      }

  }

 

  const dateOfBirthChangeHandler = (date:any) => {
    let newDate:string; 

    if(date){
      newDate = (date.getDate()).toString() + "/" + ((date.getMonth() + 1).toString()+ "/" + date.getFullYear().toString());
    } else {
      newDate = "";
    }

    setCurrentTeacher({ ...currentTeacher, dateOfBirthString: newDate });

    FormService.setAreFormsDirty(true);
  }

  const showRatesGrid = (e:any) => {
    e.preventDefault();
    setShow(true);
  }

  const addRate = () => {
    let teacher = currentTeacher;
    let newRate = new TeacherRate();

    if(!currentTeacherRate.description ||  currentTeacherRate.description===""){
      setRateDescError(true);
      return false;
    } 

    if(currentTeacherRate.rate && currentTeacherRate.rate < 0){
      setRateError(true);
      return false;
    }

    newRate.rate = parseFloat(currentTeacherRate.rate.toString());
    newRate.description = currentTeacherRate.description;
    newRate.active = true;
    
    teacher.rates.push(newRate);

    setCurrentTeacher(teacher);
    setCurrentTeacherRate(new TeacherRate());

    setShow(false);

    //Remove rates validation error
    let validationError = validationErrors.filter(v => v !== "rates"); 

    setValidationErrors(validationError);

    postTeacher();
    FormService.setAreFormsDirty(true);
  }

  const emailIsValid = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  const postTeacher = async (teacher?:Teacher) => {


    if(!teacher){
      teacher = currentTeacher;
    }

    let errors = new Array<string>();

    if(!teacher.firstName ||  teacher.firstName===""){
      errors.push("firstName");
    }
    if(!teacher.lastName ||  teacher.lastName===""){
      errors.push("lastName");
    }
    if(!teacher.email ||  teacher.email==="" || !emailIsValid(teacher.email)){
      errors.push("email");
    }
    /*
    if(!currentTeacher.address1 ||  currentTeacher.address1===""){
      errors.push("address1");
    }
    if(!currentTeacher.address2 ||  currentTeacher.address2===""){
      errors.push("address2");
    }
    if(!currentTeacher.town ||  currentTeacher.town===""){
      errors.push("town");
    }
    if(!currentTeacher.postCode ||  currentTeacher.postCode===""){
      errors.push("postCode");
    }*/

    if(teacher.rates && teacher.rates.length <= 0){
      errors.push("rates");
    }

    teacher.dateOfBirth = new Date();

    //updateSubjects();
    if(errors.length === 0) {

      let success:boolean = false;

      let teacherReturned = await axios.post<Teacher>('/api/teachers/upsert', teacher).then((response) => {
        loadTeacher(response.data.teacherId);
        success = true;
      }).catch(function (error) {
        open(error.response.data, {className: "customToast",type: "error" });
      });

      if(success){
        setValidationErrors(new Array<string>());

        FormService.setAreFormsDirty(false);

        
  
        swal("Teacher successfully saved","Well done!", "success", {
          timer: 3000,
          buttons:{}
        });
      }

    } else {
      setValidationErrors(errors);
    }
  } 

  const isFieldValid = (fieldName: string): boolean => {
    let error = validationErrors.find(s => s == fieldName);
      if (error) {
          return true;
      } else {
          return false;
      }
  }

  const deleteRate = async(rateId:number, index:number) => {
      const response = await axios.get(`/api/teachers/CanRateBeRemoved?id=${rateId}`);
      const canRateBeUsed = (parseInt(response.data) === 0);

      if(!canRateBeUsed){
        open("Rate has been used and can't be deleted", {className: "customToast",type: "error" });
        return false;
      }

      let teacher = { ...currentTeacher };


     //alert(index);

      let rates = new Array<TeacherRate>();
      if(rateId === 0) {
       teacher.rates.map((t,i) => {
          if(i !== index){
            rates.push(t);
          }
        });
      } else {
        rates = teacher.rates.filter(r => r.teacherRateId !== rateId);
      }


      teacher.rates = rates;

      await setCurrentTeacher(teacher);
      postTeacher(teacher);

  }

 
  
  const style = {
    control: base => ({
      ...base,
      border: '1px solid #c0c0c0',
      boxShadow: 'none',
      '&:hover': {
          border: '1px solid #c0c0c0',
      },
      width:"350px",
    })
  };

  const subjectsHandleChange = (selectedOption) => {
    let selSubs = new Array<number>();
    if(selectedOption){
      selectedOption.map(s => {
        selSubs.push(s.value);
      });
    }
    
    setCurrentSubjects(selectedOption);
  }

  const getSubjectDefaults = (subjects:Array<Subject>): Array<ReactSelectOption> => {
    let rs = Array<ReactSelectOption>();
    let rs2 = Array<ReactSelectOption>();


    if(subjects) {
      subjects.map(c => {
        let option1 = new ReactSelectOption();
        option1.value = c.subjectId;
        option1.label = c.name;
        option1.other = c.color;
        rs2.push(option1);
      })
    }

    const rs3 = [
      { value: 'chocolate', label: 'Chocolate' },
      { value: 'strawberry', label: 'Strawberry' },
      { value: 'vanilla', label: 'Vanilla' }
    ];

    

    let option1 = new ReactSelectOption();
    option1.value = 2;
    option1.label = <img src="/assets/images/Healthpathavatar.png" style={{paddingLeft:"10px",paddingTop:"5px",paddingBottom:"10px"}} /> + " jimSpanish";
    rs.push(option1);
    
    let option = new ReactSelectOption();
    option.value = 1;
    option.label = "English";
    rs.push(option);


    setCurrentSubjects(rs2);

    return rs2;
  }

  const convertSubjectsToPills = (currentCourses: string) => {
    let courseArray = currentCourses.split(',');

    let pills = courseArray.map(f => (
        <>
          <SubjectBadge color={f.split('|')[1]} text={f.split('|')[0]} />&nbsp;
        </>
    ));
    return pills;
  }

  const getSubjectText = ():any => {
    var result:string = "";
    currentTeacher.subjects.map(c => {
      result += c.name + "|" + c.color + ",";
    });
    if(currentTeacher.subjects && currentTeacher.subjects.length > 0)
      return convertSubjectsToPills(result);
    else
      return convertSubjectsToPills("None|grey");
  }


  const openRatesForm = () => {
    setShow(true);
    setCurrentTeacherRate(new TeacherRate());
  }

  const handleTeacherStatusDropDown = (e:any) => {
      setCurrentSelectedStatus(parseInt(e.target.value));
  }

  const updateCourseStatus = async () => {
    let active: boolean = (currentSelectedStatus === 1) ? true : false;

    var response = await axios.get(`/api/teachers/UpdateTeacherStatus?teacherId=${currentTeacher.teacherId}&statudId=${active}`);

    setShowStatusUpdate(false);
    setCurrentTeacher({ ...currentTeacher, active: active });

    FormService.setAreFormsDirty(true);

  } 

  const updateTeacherAvatar = () => {
    let teacherToUpdate = currentTeacher;
    teacherToUpdate.avatarId = currentAvatar.avatarId;
    teacherToUpdate.avatarImage = currentAvatar.imageName;


    setCurrentTeacher(teacherToUpdate);

    setShowSelectAvatar(false);
  }

  const nationalityChangeHandler = (e: any) => {
    const text = e.target.options[e.target.selectedIndex].text;

    let updatedTeacher = { ...currentTeacher, nationalityId: parseInt(e.target.value), courseType: text };

    setCurrentTeacher(updatedTeacher);
    FormService.setAreFormsDirty(true);

  }

  const subjectSelectChangeHandler = (subjects:Array<Subject>) => {
    setSubjectsTempStore(subjects);
  }; 

  const updateTeacherSubjects = () => {
    let updatedTeacher = { ...currentTeacher, subjects:subjectsTempStore };

    setCurrentTeacher(updatedTeacher);

    FormService.setAreFormsDirty(true);

    setShowSelectSubjects(false);
  }

  const toogleAccess = async() => {
    if(!currentTeacher.hasLogin){
      let response = await axios.get(`/api/users/RegisterUser?id=${currentTeacher.teacherId}&type=2`);
      setTempPassword(response.data);

      let updatedTeacher = { ...currentTeacher, hasLogin:true };
      setCurrentTeacher(updatedTeacher);

      setShowLoginDetails(true);

    } else {
      let response = await axios.get(`/api/users/DeleteUser?id=${currentTeacher.teacherId}&type=2`);
      setTempPassword(response.data);

      let updatedTeacher = { ...currentTeacher, hasLogin:false };
      setCurrentTeacher(updatedTeacher);

      swal("Teacher access has been removed","Well done!", "success", {
        timer: 3000,
        buttons:{}
      });

      SetShowRemoveAccessConf(false);

    }
  }

  const handleFocus = (event) => event.target.select();

  return (
      <>
          <div className="row">
            <div className="col-xl" style={{minWidth:"650px"}}  >
                <div className="card">
                    <div>
                        <div className="page-title-box" style={{paddingBottom:"50px",paddingTop:"10px",paddingLeft:"15px",paddingRight:"15px"}}>
                            {currentTeacher.avatarImage ? <div className="page-title-btn btn-group float-right"><img src={`/assets/images/${currentTeacher.avatarImage.replace('{size}','md')}`} onClick={()=>setShowSelectAvatar(!showSelectAvatar)} style={{paddingRight:"25px",paddingTop:"5px",paddingBottom:"10px"}} /></div>
                            : <div className="page-title-btn btn-group float-right"><img src={"/assets/images/Healthpath-avatar.png"} onClick={()=>setShowSelectAvatar(!showSelectAvatar)} style={{paddingRight:"25px",paddingTop:"5px",paddingBottom:"10px"}} /></div> 
                          }


                            <h4 className="header-title" style={{paddingTop:"12px",paddingBottom:"10px", paddingLeft:"30px"}}>Teacher's profile</h4>
                        </div>

                        <div className="col" style={{paddingLeft:"85px"}}> 
                            <InputTextBoxDuel label="First name" name="firstName" errorMessage="" isError={isFieldValid("firstName")}  value={currentTeacher.firstName} mode={editMode}  onChange={(e) => updateTeacher(e)} width={415} />
                            <InputTextBoxDuel label="Last name" name="lastName" errorMessage="" isError={isFieldValid("lastName")} value={currentTeacher.lastName} mode={editMode}  onChange={(e) => updateTeacher(e)} width={415}  />
                            <InputTextAreaDuel label="Address" name="address1" errorMessage=""  isError={isFieldValid("address1")} value={currentTeacher.address1} mode={editMode}  onChange={(e) => updateTeacher(e)} width={415}  />
                            <InputTextBoxDuel label="Town" name="town" errorMessage="Please enter a town" isError={isFieldValid("town")} value={currentTeacher.town} mode={editMode} onChange={(e) => updateTeacher(e)} width={415}  />
                            <InputTextBoxDuel label="City" name="city" errorMessage="Please enter an city" value={currentTeacher.city}  mode={editMode}  onChange={(e) => updateTeacher(e)} width={415}  />
                            <InputTextBoxDuel label="County" name="County" errorMessage="Please enter an county"   mode={editMode}   width={415}  />
                            <InputTextBoxDuel label="Post code"  errorMessage="Please enter a post code" name="postCode" isError={isFieldValid("postCode")} value={currentTeacher.postCode} mode={editMode}  onChange={(e) => updateTeacher(e)} width={415}  />
                            <InputTextBoxDuel label="Mobile" errorMessage="Please enter a mobile" name="mobile" value={currentTeacher.mobile} mode={editMode}  onChange={(e) => updateTeacher(e)} width={415}  />
                            <InputTextBoxDuel label="Landline" errorMessage="Please enter a mobile" name="landline" value={currentTeacher.landline} mode={editMode}  onChange={(e) => updateTeacher(e)} width={415}  />
                            <InputTextBoxDuel label="Email" name="email" errorMessage="" isError={isFieldValid("email")} mode={editMode} value={currentTeacher.email} onChange={(e) => updateTeacher(e)} width={415}  />
                            <InputDatePickerDual label="Date of birth" mode={editMode} width={650} isError={isFieldValid("dateOfBirthString")} value={currentTeacher.dateOfBirthString && currentTeacher.dateOfBirthString} onChange={(date)=> dateOfBirthChangeHandler(date)}  />

                            <div className="form-group row">
                                <div style={{width:"125px",paddingTop: "6px", paddingRight:"30px",textAlign:"right",}}>
                                    <label>Nationality</label>
                                </div>
                                <div style={{paddingTop:"0px" }}>
                                    <select value={currentTeacher.nationalityId}  style={{width:"415px"}} className={`form-control ${isFieldValid("typeId") ? "parsley-error" : ""}`} onChange={(e:any) => nationalityChangeHandler(e)}>
                                        <option style={{color:"#A9A9A9"}}> -- Please select -- </option>
                                        {Nationalites.map(n => (
                                            <option value={n.nationalityId}>{n.name}</option>
                                        ))}
                                    </select> 
                                </div>
                            </div>

                            <div className="form-group row">
                                <div style={{width:"125px",paddingTop:(editMode==="E") ? "6px" : "0px",textAlign:"right", paddingRight:"30px"}}>
                                    <label>Subjects</label>
                                </div>
                                <div style={{paddingTop:"6px"}}><a style={{cursor:"pointer"}} onClick={()=> setShowSelectSubjects(!showSelectSubjects)}>{getSubjectText()}</a></div>
                            </div>

                            <div className="form-group row">
                              <div style={{width:"125px",textAlign:"right", paddingRight:"30px"}}>
                                <label>Status</label>
                              </div>
                              <div >
                                <ActiveBadgeStatus status={currentTeacher.active} onClick={() => setShowStatusUpdate(true)} />
                              </div>
                            </div>
                            <br/>
                            <>
                                <div className="form-group text-right m-b-0">
                                    <ActionButton onClick={()=>postTeacher()} text="SAVE"  /><span>&nbsp;</span> 
                                    <CancelButton onClick={()=>{}} />
                                </div>
                                <div>&nbsp;</div>
                            </>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-xl" style={{minWidth:"400px"}}>
                <div className="card"  >
                    <div style={{height:"599px"}}>
                        
                        <div className="row">
                            <div className="col-xl-12">
                            <div style={{width: "155px;"}}>
                            </div>
                            <div>
                                
                                <div >
                                  <div style={{paddingLeft:"20px",paddingRight:"20px"}}>
                                      <TeachersRates mode={editMode} rates={currentTeacher.rates} deleteRate={deleteRate} addRate={showRatesGrid} openRatesForm={openRatesForm} />
                                      {isFieldValid("rates") && <div style={{color:"red"}}>Please add at least one rate</div>}
                                  </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <div className="card"  >
                      <div style={{height:"222px"}}>
                            <div className="row" style={{paddingBottom:"10px"}}>
                                <div className="col">
                                    <div  style={{paddingLeft:"20px",paddingTop:"25px"}}><h4 className="header-title">GDPR settings</h4></div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    {!gdprConsent || gdprConsent.length === 0 &&
                                        <div style={{paddingLeft:"20px"}}>GDPR settings updated by user</div>
                                    } 
                                    {gdprConsent.map(c => (
                                        <div className="row" style={{paddingBottom:"6px",paddingLeft:"20px",paddingRight:"12px"}}>
                                            <div className="col-10">{c.name}</div>
                                            <div className="col-2"><TickCross checked={c.consent}/></div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="card"  >
                        <div style={{height:"222px"}}>
                              <div className="row" style={{paddingBottom:"10px"}}>
                                  <div className="col">
                                    <div  style={{paddingLeft:"20px",paddingTop:"25px"}}><h4 className="header-title">Online access</h4></div>
                                  </div>
                              </div>
                              <div className="row">
                                  <div className="col">
                                      <div className="row" style={{paddingBottom:"6px",paddingLeft:"20px",paddingRight:"9px"}}>
                                          <div className="col-10">Online access granted</div>
                                          <div className="col-2"><TickCross checked={currentTeacher.hasLogin}/></div>
                                      </div>
                                      <div className="row" style={{paddingBottom:"6px",paddingLeft:"20px",paddingRight:"9px"}}>
                                          <div className="col-10">Logins this month</div>
                                          <div className="col-2"></div>
                                      </div>
                                      <div className="row" style={{paddingBottom:"6px",paddingLeft:"20px",paddingRight:"7px"}}>
                                          <div className="col-8">Last logged in</div>
                                          <div className="col-4"></div>
                                      </div>


                                    <div className="page-title-box" style={{paddingBottom:"25px",paddingTop:"14px", paddingRight:"50px"}}>
                                      
                                    <div className="page-title-btn btn-group float-right">

                                    
                                    <ActionButton onClick={()=> {(currentTeacher.hasLogin) ?  SetShowRemoveAccessConf(true) : toogleAccess()}}  text={(currentTeacher.hasLogin) ? "REMOVE ACCESS" : "GRANT ACCESS"} />
                                  </div>
                                  

                                  </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
            </div>
        </div>

        <Modal show={show} onHide={handleClose} dialogClassName="modal-450px" centered>
            <Modal.Header closeButton>
                <Modal.Title>Rates</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col">

                  <InputTextBoxDuel label="Description" name="description" mode="E" onChange={(e) => updateTeachersRate(e)}  value={currentTeacherRate.description} isError={rateDescError}    />
                  <InputTextBoxDuel focusOnClick={true} label="Rate" name="rate" mode="E"  onChange={(e) => updateTeachersRate(e)} value={currentTeacherRate.rate.toString()} isError={rateError} />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
                <ActionButton text="ADD" onClick={()=>addRate()} />
                <CancelButton text="CANCEL" onClick={()=>handleClose()} />
            </Modal.Footer>
        </Modal>


        <Modal show={showStatusUpdate} onHide={()=> setShowStatusUpdate(false)} dialogClassName="modal-450px">
            <Modal.Header closeButton>
            <Modal.Title>Update Teacher Status</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group row" >
                  <div style={{paddingLeft:"45px"}}>
                      <select className="form-control" id="courseStatusDropDown" onChange={(e) => handleTeacherStatusDropDown(e)} style={{width:"345px"}} >
                          <option value={0} selected={currentTeacher.active === false}>Inactive</option>
                          <option value={1} selected={currentTeacher.active === true}>Active</option>
                      </select>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <ActionButton text="SAVE" onClick={() => updateCourseStatus()} />
                <CancelButton  onClick={() => setShowStatusUpdate(false)} />
            </Modal.Footer>
        </Modal>


        <Modal show={showSelectAvatar} onHide={()=> setShowSelectAvatar(false)} dialogClassName="modal-750px">
            <Modal.Header closeButton>
            <Modal.Title>Select Avatar</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TeacherAvatarSelector avatarChanged={(avatar:Avatar) => setCurrentAvatar(avatar)} currentAvatarId={currentTeacher.avatarId} />
            </Modal.Body>
            <Modal.Footer>
                <ActionButton onClick={() => updateTeacherAvatar()} text="SAVE" /><CancelButton onClick={() => setShowSelectAvatar(false)}  />
            </Modal.Footer>
        </Modal>

        <Modal show={showSelectSubjects} onHide={()=> setShowSelectSubjects(false)} dialogClassName="modal-450px">
            <Modal.Header closeButton>
              
            <Modal.Title>Select subjects</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <TeachersClasses teacherSubjects={currentTeacher.subjects} onSubjectChange={subjectSelectChangeHandler} />
            </Modal.Body>
            <Modal.Footer>
                <ActionButton onClick={() => updateTeacherSubjects()} text="SAVE" /><CancelButton onClick={() => setShowSelectSubjects(false)}  />
            </Modal.Footer>
        </Modal>



        <Modal show={showLoginDetails} onHide={()=> setShowLoginDetails(false)} dialogClassName="modal-MMMpx" centered>
            <Modal.Body>
              <div style={{textAlign:"center", paddingBottom:"20px",paddingTop:"20px"}}><i className="ti-unlock green" style={{fontSize:"65px",paddingTop:"15px"}}></i></div>
              <div style={{textAlign:"center",fontSize:"27px", fontWeight:"bold", paddingBottom:"8px", margin:"0px"}} className="header-title m-t-0 m-b-30">Access has been granted for <br/>{currentTeacher.firstName} {currentTeacher.lastName}!</div>
              <div style={{textAlign:"center",fontSize:"22px",paddingBottom:"8px"}} className="header-title m-t-0 m-b-30">
                Password - {tempPassword}
                <div style={{paddingLeft:"5px", paddingTop:"10px"}}><ActionButton text="COPY TO CLIPBOARD" onClick={()=>{ navigator.clipboard.writeText(tempPassword)}} /></div>
              </div>

            </Modal.Body>
        </Modal>


        <Modal show={showRemoveAccessConf} onHide={() => SetShowRemoveAccessConf(false)} dialogClassName="modal-MMMpx" centered>                                
                <Modal.Body>
                    <div style={{textAlign:"center", paddingBottom:"20px",paddingTop:"20px"}}><i className="ti-help-alt" style={{color:"#fe6271", fontSize:"72px",paddingTop:"15px"}}></i></div>

                    <div style={{textAlign:"center",fontSize:"27px", fontWeight:"bold", paddingBottom:"8px", margin:"0px"}} className="header-title m-t-0 m-b-30">Are you sure?</div>

                    <div style={{textAlign:"center",fontSize:"22px",paddingBottom:"8px"}} className="header-title m-t-0 m-b-30">
                        You want to remove online access for this teacher
                    </div>

                    <div style={{textAlign:"right"}}>
                      <ActionButton  text="YES" onClick={()=>{toogleAccess()}}  />&nbsp;
                      <CancelButton  text="CANCEL" onClick={()=>{SetShowRemoveAccessConf(false)}} />
                    </div>

                </Modal.Body>
            </Modal>
      </>
  );
}
export default TeachersForm;