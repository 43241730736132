import React, { useState, useEffect} from 'react';
import InputDropDown from '../common/InputDropDown';
import InputTextBoxDuel from '../common/InputTextBoxDuel';
import InputTextArea from '../common/InputTextArea';
import Company from '../../models/Company';
import Room from '../../models/Room';

import axios from 'axios';
import useSnackbar from 'react-snackbar-toast';

import ActionButton from '../buttons/ActionButton';
import CancelButton from '../buttons/CancelButton';

import swal from 'sweetalert';

interface IProps {
  id:number
}

const RoomsForm = (props:IProps) => {
    const {addToast} = useSnackbar();

    const open = option => {
      addToast("Room successfully saved", option);  
    };

    const [currentRoom, setCurrentRoom] = useState(new Room());
    const [comps, setComps] = useState(new Array<Company>());
    const [validationError, setValidationError] = useState(new Array<string>());


    const updateRoom = (e:any) => {
      setCurrentRoom({ ...currentRoom, [e.target.name]: e.target.value });
    }

    const updateCapacity = (e:any) => {
      setCurrentRoom({ ...currentRoom, "capacity": parseInt(e.target.value) });
    }

    const isFieldValid = (fieldName: string): boolean => {
      let error = validationError.find(s => s == fieldName);
      if (error) {
          return true;
      } else {
          return false;
      }
    }

    const postRoom = async() => {

      let isFormValid: boolean = true;
      let invalid = new Array<string>();

      if (!currentRoom.name || currentRoom.name === "") {
          invalid.push("name");
          isFormValid = false;
      }

      if (!currentRoom.companyId || currentRoom.companyId === 0) {
        invalid.push("companyId");
        isFormValid = false;
     }


      if (isFormValid) {
        await axios.post<Room>(`/api/rooms/upsert`, currentRoom);

        swal("Room successfully saved","Well done!", "success", {
          timer: 3000,
          buttons:{}
        });
      } else {
        setValidationError(invalid);
      }
    }
    
    const loadCompanies = async() => {
      let companies = await axios.get<Array<Company>>(`/api/companies/getall`);
    
      let compOption = new Array<any>();
      companies.data.map(c => {
          compOption.push({ Text: c.name, Value: c.companyId })
      });
    
      setComps(compOption);
    }

    const loadRoom = async(roomId:number) => {
      let response = await axios.get<Room>(`/api/rooms/getbyid?id=${roomId}`);

      setCurrentRoom(response.data);
    }

    const companyOnChangeHandler = (e: any) => {
      const newComp = e.target.options[e.target.selectedIndex].text;
      
      setCurrentRoom({ ...currentRoom, companyId: parseInt(e.target.value), company: newComp});
    }

    useEffect(() => {
      if(props.id){
        loadRoom(props.id);
      }
      loadCompanies();
    }, []);


    
    return (
        <>
          <div >
              <div className="form-group">
                  <InputTextBoxDuel label="Room Name" width={275} name="name" mode="E" value={currentRoom.name} isError={isFieldValid("name")} errorMessage="Please enter a room name"  onChange={(e) => updateRoom(e)} />
              </div>
              <div className="form-group">
                  <InputTextBoxDuel label="Capacity" width={275}  mode="E" value={(currentRoom && currentRoom.capacity) ? currentRoom.capacity.toString() : "10"} onChange={(e) => updateCapacity(e)} />
              </div>
              <div className="form-group">
                <InputDropDown data={comps} label="Company" changeFunc={companyOnChangeHandler} isError={isFieldValid("companyId")} errorMessage="Please select a company"  value={currentRoom && currentRoom.companyId} width={275} showPlease={true} />
              </div>
              <div className="form-group">
                <InputTextArea labelWidth={125}  label="Description" name="description" value={currentRoom.description} onChange={(e) => updateRoom(e)} width={650} height={250} />
              </div>

              <div className="form-group text-right m-b-0" style={{paddingBottom:"5px", paddingRight:"15px"}}>


                <ActionButton onClick={() => postRoom()} text="SAVE" /><span>&nbsp;</span>

                <CancelButton onClick={()=>alert("hello")} />


              </div>
           
          </div>
      </>
    )
}

export default RoomsForm;