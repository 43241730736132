import React, { PureComponent, useEffect } from 'react';
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

interface IState {
  data:any
}

interface IProps {
  data:any
}

export default class BarChartExample extends PureComponent<IProps,IState> {
  static jsfiddleUrl = 'https://jsfiddle.net/alidingling/30763kr7/';

  constructor(props){
    super(props);
    this.state = {
      data:[]
    };
  
  }


  loadData = async()=>{
    //const response = await axios.get<Array<StudentMonthlyCountVM>>(`/api/reports/GetStudentMonthlyCount`);

    let data = Array<any>();
    let i = 0;
    this.props.data && this.props.data.map(d => {
      if(i>4) data.push({name:d.monthShortName,studentCount1:d.studentCount1,studentCount2:d.studentCount2})
      i++;
    });


    this.setState({data});
    
  }

  componentWillMount(){
   this.loadData();
  }

  render() {
    return (
      <BarChart
        width={475}
        height={300}
        data={this.props.data}
        margin={{
          top: 5, right: 30, left: 0, bottom: 5,
        }}
        allowDecimals={false}
        
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name"  />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="studentCount1" fill="#45b0e2" name="Last Year" />
        <Bar dataKey="studentCount2" fill="#fe6271"  name="This Year" />
      </BarChart>
    );
  }
}
