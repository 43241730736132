import React, { useState, useEffect} from 'react'
import { Button,Modal } from 'react-bootstrap';
import {confirm} from 'react-bootstrap-confirmation';
import axios from 'axios';

import WaveBox from '../common/WaveBox'; 
import ReactDatatable from 'react-datatable-ext';
import Student from '../../models/Student';

import Course from '../../models/Course';
import ActionButton from '../buttons/ActionButton';
import CancelButton from '../buttons/CancelButton';


const config = {
    page_size: 10,
    length_menu: [ 10, 20, 50 ],
    button: {
       
    },
    show_length_menu:false,
    striped:false,
    highlightOnHover:true,
    show_filter:false
    
}

interface IProps {
    updateStudents: Function;
    id:number,
    postCourse:Function;
    incrementRefresh:Function,
    currentCourse:Course
}

const StudentsGrid = (props:IProps) => {
    const [show, setShow] = useState(false);
    const [interestedStudents, setInterestedStudents] = useState<Array<Student>>(new Array<Student>());
    const [enrolledStudents, setEnrolledStudents] = useState<Array<Student>>(new Array<Student>());
    const [waitingStudents, setWaitingStudents] = useState<Array<Student>>(new Array<Student>());

    const [studentDb, setStudentDb] = useState(new Array<Student>());
    const [filteredStudentDb, setFilteredStudentDb] = useState(new Array<Student>());
    const [selectedStudent, setSelectedStudent] = useState(new Array<Student>());
    const [filteredStudent, setfilteredStudent] = useState(new Array<Student>());

    const [studentsSearched, setStudentsSearched] = useState("");

    const [currentFilter, setCurrentFilter] = useState("");
    

    useEffect(() => {
        const loadStudents = async () => {
            let courseId = "";
            if (props.id) {
                courseId = props.id.toString();
            }

            var response = await axios.get<Array<Student>>(`/api/students/getall?CourseId=${courseId}`);

            setStudentDb(response.data);
            setFilteredStudentDb(response.data);

            if (props.id) {
                let response = await axios.get<Array<Student>>(`/api/students/GetStudentsByCourseId?id=${props.id}`);
                setfilteredStudent(response.data);
                loadLocalData(response.data);
            }
        }
        loadStudents();
    }, []);
    
    const loadLocalData = (data:Array<Student>) => {
        setSelectedStudent(data);

        let enrolled    = data.filter(d => d.statusId==1);
        let enquired    = data.filter(d => d.statusId==2);
        let waiting     = data.filter(d => d.statusId==3);


        setEnrolledStudents(enrolled);
        setInterestedStudents(enquired);
        setWaitingStudents(waiting);


        props.updateStudents(enrolled);
    }

    const handleClose = () => {
        setShow(!show);
    }

    const WaveBoxHandler = (record:Student, item) => {
        let studentsEmail: string = "";

        studentsEmail = record.email;

        let foundStudent = interestedStudents.find(s => s.email == studentsEmail);
        if (foundStudent && foundStudent != null) {
            let students = interestedStudents.filter(s => s.email != studentsEmail);
            setInterestedStudents(students);
        }

        foundStudent = enrolledStudents.find(s => s.email == studentsEmail);
        if (foundStudent && foundStudent != null) {
            let students = enrolledStudents.filter(s => s.email != studentsEmail);
            setEnrolledStudents(students);
        }

        foundStudent = waitingStudents.find(s => s.email == studentsEmail);
        if (foundStudent && foundStudent != null) {
            let students = waitingStudents.filter(s => s.email != studentsEmail);
            setWaitingStudents(students);
        }

        if (item == "Enrolled") {
            record.statusId = 1;
            let foundStudent = enrolledStudents.find(s => s.email == studentsEmail);

            if (foundStudent && foundStudent != null) {
                let students = enrolledStudents.filter(s => s.email != studentsEmail);
                setEnrolledStudents(students);
            } else {
                setEnrolledStudents([...enrolledStudents, record]);
            }
        } 

        if (item == "Enquired") {
            record.statusId = 2;
            let foundStudent = interestedStudents.find(s => s.email == studentsEmail);

            if (foundStudent && foundStudent!=null){
                let students = interestedStudents.filter(s => s.email != studentsEmail);
                setInterestedStudents(students);
            } else {
                setInterestedStudents([...interestedStudents, record]);
            }
        }
        
        if (item == "Waiting") {
            record.statusId = 3;
            let foundStudent = waitingStudents.find(s => s.email == studentsEmail);

            if (foundStudent && foundStudent != null) {
                let students = waitingStudents.filter(s => s.email != studentsEmail);
                setWaitingStudents(students);
            } else {
                setWaitingStudents([...waitingStudents, record]);
            }
        }
        if(item == ""){
            record.statusId = 0;
        }
    }

    const convertSubjectsToPills = (currentCourses:string) => {
        let courseArray = currentCourses.split(',');

        let pills = courseArray.map(f => (
            <><span className={`badge badge-${f.split('|')[1]}`}>{f.split('|')[0]}</span>&nbsp;</>
        ));
        return pills;
    }

    

    const getStatusFromId = (statusId: number) => {
        if (statusId === 1) {
            return "Enrolled";
        } else if (statusId === 2){
            return "Enquired";
        } else if (statusId === 3){
            return "Waiting";
        }
    }

    const getStatusSpanFromId = (statusId: number) => {
        if (statusId === 1) {
            return <span className="badge badge-success">Enrolled</span>;
        } else if (statusId === 2){
            return <span className="badge badge-primary">Enquired</span>;
        } else if (statusId === 3){
            return <span className="badge badge-info">Waiting</span>;
        }
    }

    const addStudents = () => {
        let data = [...waitingStudents, ...interestedStudents,...enrolledStudents ];

        setSelectedStudent(data);
        setfilteredStudent(data);

        props.updateStudents(data);

        //props.postCourse();

        setShow(false);

        props.incrementRefresh();
    }

    const filterSelectedStudents = (e: any) => {
        let students = selectedStudent;

        if (e == "Enrolled") {
            setfilteredStudent(students.filter(s => s.statusId == 1));
        } else if (e == "Enquired") {
            setfilteredStudent(students.filter(s => s.statusId == 2));
        } else if (e == "Waiting") {
            setfilteredStudent(students.filter(s => s.statusId == 3));
        } else if (e == "") {
            setfilteredStudent(students);
        }
        setCurrentFilter(e);

    }

    const filterStudents = () => {
        let  filteredStudents: Array<Student>;

        if (studentsSearched == "") {
            filteredStudents = studentDb;
        } else {
            filteredStudents = studentDb.filter(s => s.firstName.toLowerCase().indexOf(studentsSearched.toLowerCase()) !=-1)
        }
        setFilteredStudentDb(filteredStudents);
    }

    const deleteStudentFromCourse = async (studentId:number) => {
        
        const result = await confirm('Are you sure you wish to remove this student?');
        if(result){
            await axios.get(`/api/courses/DeleteStudentFromCourse?courseId=${props.id}&studentId=${studentId}`);

            let response = await axios.get<Array<Student>>(`/api/students/GetStudentsByCourseId?id=${props.id}`);
            setfilteredStudent(filteredStudent.filter(s => s.studentId != studentId));
            props.incrementRefresh();
        }
    }

    return (
        <> 
            <div className="card">
                <div className="row">
                    <div className="col">
                        <div className="page-title-box" style={{paddingBottom:"25px", paddingTop:"15px"}}>
                            <div className="page-title-btn btn-group float-right">
                                <button type="button"  className="btn btn-primary-pink dropdown-toggle page-title-drop waves-effect waves-light" data-toggle="dropdown" aria-expanded="false" onClick={()=>setShow(true)}>Add <i className="ti-plus ml-1"></i></button>
                            </div>
                            <h4 className="page-title"></h4>
                        </div>
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-8">
                        <div style={{paddingLeft:"15px"}}><h4>{props.currentCourse.title}</h4></div>
                        
                    </div>
                    <div className="col-3"></div>

                    <div className="col-1">
                        <div style={{ paddingTop: "8px" }}><WaveBox items={['Enrolled', 'Enquired', 'Waiting']} allowEmpty={true} onClick={(e)=> filterSelectedStudents(e)} /></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                    <br/>
                    <div className="col-sm card" style={{padding:"15px"}}>
                    <table className="table  mb-0 table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">First Name</th>
                                    <th scope="col">Last Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Gender</th>
                                    <th scope="col">Date of birth</th>
                                    <th scope="col">Company</th>
                                    <th scope="col">Status</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                    {filteredStudent && filteredStudent.map(c =>(
                                    <tr >
                                        <td  style={{padding:"8px"}}>{c.firstName}</td>
                                        <td>{c.lastName}</td>
                                        <td>{c.email}</td>
                                        <td>{c.gender}</td>
                                        <td>{c.dateOfBirthString}</td>
                                        <td>{c.company}</td>
                                        <td>{getStatusSpanFromId(c.statusId)}</td>
                                        <td>
                                            <div  style={{float: "right", paddingRight:"5px",paddingTop:"6px", cursor: "pointer"}}><i className="ti-trash pink" style={{float: "right",fontSize: "20px"}} onClick={() => deleteStudentFromCourse(c.studentId)}></i></div>
                                        </td>
                                    </tr>
                                    ))}
                                    {!filteredStudent || filteredStudent.length === 0 && (
                                        <tr>
                                            <td colSpan={8} align="center">No students have been added</td>
                                        </tr>    
                                    )}
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>
            </div>
             
             <br/>

             <Modal show={show} onHide={handleClose} dialogClassName="modal-750px">
                <Modal.Header closeButton>
                    <Modal.Title>Add Students</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                <div className="row">
                    <div className="col">
                        <div className="page-title-box" style={{paddingBottom:"25px"}}>
                            <div className="page-title-btn btn-group float-right">
                                <div className="input-group">
                                        <input type="search" id="example-input1-group2" name="example-input1-group2" className="form-control" value={studentsSearched} onChange={(e) => setStudentsSearched(e.target.value)} placeholder="Search"  style={{width:"300px"}} />
                                    <span className="input-group-append">
                                        <button 
                                            type="button" 
                                            className="btn btn-primary-pink waves-effect waves-light"
                                            onClick={()=> filterStudents()}
                                        >
                                        <div style={{height:"5px"}}></div>
                                        <i className="ti-search" style={{marginTop:"25px"}}></i>
                                        </button>
                                    </span>
                                </div>
                            </div>
                            <div>
                               <table>
                                    <tr>
                                        <td style={{width:"100px"}}><strong>Enrolled</strong></td>
                                        <td>
                                        {enrolledStudents && enrolledStudents.map(s => (
                                            <><span className="badge badge-primary-pink" style={{ padding: "5px" }}>{s.firstName + " " + s.lastName}</span>&nbsp;</>
                                        ))}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{width:"100px"}}><strong>Enquired</strong></td>
                                        <td>
                                        {interestedStudents && interestedStudents.map(s => (
                                            <><span className="badge badge-primary-pink" style={{ padding: "5px" }}>{s.firstName + " " + s.lastName}</span>&nbsp;</>
                                        ))}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{width:"100px"}}><strong>Waiting</strong></td>
                                        <td>
                                        {waitingStudents && waitingStudents.map(s => (
                                            <><span className="badge badge-primary-pink" style={{ padding: "5px" }}>{s.firstName + " " + s.lastName}</span>&nbsp;</>
                                        ))}
                                        </td>
                                    </tr>
                                   
                               </table>
                            </div>
                        </div>
                    </div>
                </div>

                


              

                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary-pink" style={{ width: "125px" }} onClick={()=>addStudents()}>Save</button>
                    <ActionButton text="save" onClick={()=>addStudents()} />
                    <CancelButton onClick={()=>handleClose()} /> 
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default StudentsGrid