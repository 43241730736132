import React, {useEffect, useState} from 'react';
import 'react-image-picker/dist/index.css'
import Subject from '../../models/Subject';
import CheckBoxRound from '../common/CheckBoxRound';

import axios from 'axios';


interface IProps {
    teacherSubjects:Array<Subject>;
    onSubjectChange:Function;
}

class SubjectVM {
    constructor() {

    }

    public name:string;
    public checked:boolean;
    public subjectId:number;
}



   
const TeacherSubjectSelector = (props:IProps) => {
    const [subjects, setSubjects] = useState(new Array<SubjectVM>());

    useEffect(() => {
        getSubjects();
    }, []);

    const getSubjects = async () => {
        const response = await axios.get<Array<SubjectVM>>('/api/subjects/getall'); 
        const subjects = response.data;

        props.teacherSubjects.map(ts => {
            const s = subjects.find(s => s.subjectId == ts.subjectId)
            if(s){
                s.checked = true;
            }
        });
        
        setSubjects(subjects);
    }


    const updateSubjectChecked = (subjectSelected:SubjectVM) => {
        let subjectsCopy = [...subjects];
        let foundIndex = subjectsCopy.findIndex(i => i.subjectId === subjectSelected.subjectId);
        let rate:SubjectVM = subjectsCopy[foundIndex];

        rate.checked = !rate.checked;
        subjectsCopy[foundIndex] = rate;

        setSubjects(subjectsCopy);

        props.onSubjectChange(subjectsCopy.filter(s => s.checked === true));
        
    }

    return (
        <div>
            <table className="table  table-hover m-0 "  style={{backgroundColor:"white", width:"100%"}}>
                

                {subjects.map(s => (
                    <tr>
                        <td style={{width:"350px"}}>{s.name}</td>

                        <td style={{padding:"2px"}} align="center" >  
                            <CheckBoxRound checked={s.checked} onChange={() => updateSubjectChecked(s)} />
                        </td>
                    </tr>
                ))}
            </table>
        </div>
    );
}

export default TeacherSubjectSelector;

