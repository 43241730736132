import React, {useState} from 'react';

import axios from 'axios';


class PasswordReset {
    public PasswordResetId: number = 10;
    public UserId: number = 10;
    public Code: string= "";
    public Email: string= "";
    public Password: string = "";
}

interface IProps {
    history:any;
}

const ForgotPassword = (props:IProps) => {
    const [email, setEmail] = useState("");
    const [code, setCode]   = useState("");
    const [codeError, setCodeError]   = useState(false);

    const [passwordError, setPasswordError]   = useState(false);
    const [emailError, setEmailError]   = useState(false);

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [currentStage, setCurrentStage] = useState(1);

    const [errorText, setErrorText] = useState("");

    const [emailErrorText, setEmailErrorText] = useState("");
    
    

    const updateEmail = (e:any) => {
        setEmail(e.target.value);
    }
    const updateCode = (e:any) => {
        setCode(e.target.value);
    }
    const updatePassword = (e:any) => {
        setPassword(e.target.value);
    }
    const updateConfirmPassword = (e:any) => {
        setConfirmPassword(e.target.value);
    }
    

    const formSubmitted = async(e:any) => {
        e.preventDefault();

        if(email === ""){
            setEmailErrorText("Please enter an email address");
            setEmailError(true);
            return;
        }

       
    
        const passwordReset = new PasswordReset();
        passwordReset.Email = email
        await axios.post(`/api/users/PasswordResetRequest`, passwordReset);
        setCurrentStage(2);
        setEmailError(false);
    }

    const UpdatePasswordRequest = async() => {
        if(password !== confirmPassword){
            setErrorText("Passwords do not match");
            setPasswordError(true);
            return;
        }
        if(password.length <8){
            setErrorText("Password needs to be 8 characters or more");
            setPasswordError(true);
            return;
        }
        
        let passwordReset = new PasswordReset();
        passwordReset.Email = email;
        passwordReset.Code = code;
        passwordReset.Password = password;
        const response = await axios.post(`/api/users/ResetPassword`, passwordReset);
        if(response.data === 1){
            setCurrentStage(3);
            setCodeError(false);
        }
        if(response.data === 2){
            setErrorText("Code is incorrect");
            setCodeError(true);
        }
        setPasswordError(false);
    }
    
    return(
        <>
       
            {currentStage === 1 && <div className="">
                <form onSubmit={(e:any) => formSubmitted(e)}>
                    
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-8 col-lg-6 col-xl-5">
                                <div className="text-center pb-1">
                                    <a href="index.html">
                                        <span><img src="assets/images/logo.png" alt="" height="18" /></span>
                                    </a>
                                </div>
                                <div className="card mt-4">
                                    <div className="card-body p-4">
                                        <div className="form-group mb-3">
                                            <div style={{fontSize:"18px"}}>Please enter your email</div>
                                        </div>

                                        <div className="form-group mb-3">
                                            <input className={`form-control ${emailError ? "parsley-error" : ""}  `}  type="text" placeholder="email" value={email} onChange={(e) => updateEmail(e)} />
                                        </div>

                                        <div className="form-group mb-4">
                                            <button className="btn btn-primary-pink btn-bordred btn-block waves-effect waves-light text-uppercase">NEXT</button>
                                        </div>

                                        <div className="form-group mb-4">
                                            {emailErrorText}
                                        </div>
                                        

                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </form>
            </div>}

            {currentStage===2 && 
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-6 col-xl-5">
                            <div className="text-center pb-1">
                                <a href="index.html">
                                    <span><img src="assets/images/logo.png" alt="" height="18" /></span>
                                </a>
                            </div>
                            <div className="card mt-4">
                                <div className="card-body p-4">

                                    <div className="form-group mb-3">
                                        <div style={{fontSize:"18px"}}>Please enter the code</div>
                                    </div>

                                    <div className="form-group mb-3">
                                        <input className={`form-control ${codeError ? "parsley-error" : ""} `}  type="text" placeholder="code" value={code} onChange={(e) => updateCode(e)} />
                                    </div>


                                    <div className="form-group mb-3">
                                        <input className={`form-control ${passwordError ? "parsley-error" : ""} `}  type="text" placeholder="password" value={password} onChange={(e) => updatePassword(e)} />
                                    </div>

                                    <div className="form-group mb-3">
                                        <input className={`form-control ${passwordError ? "parsley-error" : ""} `}  type="text" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => updateConfirmPassword(e)} />
                                    </div>


                                    <div className="form-group mb-4">
                                        <button className="btn btn-primary-pink btn-bordred btn-block waves-effect waves-light text-uppercase" onClick={()=>UpdatePasswordRequest()}  >NEXT</button>
                                    </div>
                                    <div className="form-group mb-3">
                                        {errorText}
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            }


            {currentStage===3 && 
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-6 col-xl-5">
                            <div className="text-center pb-1">
                                <a href="index.html">
                                    <span><img src="assets/images/logo.png" alt="" height="18" /></span>
                                </a>
                            </div>
                            <div className="card mt-4">
                                <div className="card-body p-4">

                                    <div className="form-group mb-3">
                                        <div style={{fontSize:"18px"}}>Your password has been updated</div>
                                    </div>
                                    <div className="form-group mb-3">
                                        <div style={{fontSize:"18px", cursor:"pointer"}} onClick={()=>{props.history.push('/login')}}>Login</div>
                                    </div>

                                    
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            }
        </>
    );
}

export default ForgotPassword;