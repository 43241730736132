import React from 'react'

interface IProps {
    label:string,
    placeHolder?:string,
    width?:number,
    mode?:string,
    checked?: boolean,
    onChange?: Function,
    name?: string,
    isError?: boolean,
    errorMessage?: string,
    isPassword?: boolean
}

const InputCheckBoxDuel = (props:IProps) => {
    return (
        <div className="form-group row">
            <div style={{width:"155px",paddingTop:(props.mode==="E") ? "6px" : "0px"}}>
                <label >{props.label}:</label>
            </div>
            <div>
                {(props.mode === "E") ? <><input type="checkbox" className={`form-control ${props.isError ? "parsley-error" : ""}`} name={props.name} checked={props.checked} style={{ width: props.width }} onClick={(e: any) => props.onChange && props.onChange(e)}/> {(props.isError===true) && <div style={{color:"#e96154"}}>{(props.errorMessage) && props.errorMessage}</div>} </> : <div>{(props.checked) ? "Yes" : "No"}</div>}
            </div>
        </div>
    )
}

export default InputCheckBoxDuel;