import React, {useState, useEffect} from 'react';
import InputDropDown from '../common/InputDropDown';
import InputDatePicker from '../common/InputDatePicker';
import axios from "axios";
import Course from "../../models/Course";

import Student from '../../models/Student';
import WaveBox from "../../components/common/WaveBox";
import ClassStatus from '../../components/common/ClassStatus';

import MarkClassesModal from '../../modals/MarkClassesModal';
import CheckBoxRound from '../common/CheckBoxRound';

import CourseStatusBadge from '../badges/CourseStatusBadge';

import SubjectBadge from '../badges/SubjectBadge';


import Pill from '../../components/common/Pill';

import { Accordion,Card,Button,Modal } from 'react-bootstrap';



const companies = [
    {value:1, Text:"Shell"},
    {value:2, Text:"BP"},
    {value:3, Text:"Total"},
]


const rooms = [
    {value:1, Text:"Pedler Room"},
    {value:2, Text:"New York"},
    {value:3, Text:"London"},
]

const rates = [
    {value:1, Text:"Day to day"},
    {value:2, Text:"Evenings"},
    {value:3, Text:"Weekend"},
]

interface IProps {
    id:number;
    history:any;
}

const TeachersClasses = (props:IProps) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [courses, setCourses] = useState(new Array<Course>());
    const [currentOpenTab, setCurrentOpenTab] = useState(0);
    const [showStudents, setShowStudents] = useState(false);
    const [currentCourseStudents, setCurrentCourseStudents] = useState(new Array<Student>());
    const [currentSelectedClass, setCurrentSelectedClass] = useState(0);

    const [searchCriteria, setSearchCriteria] = useState("");
    const [viewStatus, setViewStatus] = useState("");

    const [showOnlyMarking, setShowOnlyMarking] = useState(false);

    const classesTimeChange = async(text:string) => {
        loadTeacherClasses(text, showOnlyMarking, searchCriteria);
        setViewStatus(text);
    }

    useEffect(() => {
        classesTimeChange("CURRENT");
    }, []);


    const loadTeacherClasses = async(viewStatus:string, onlyMarking:boolean, searchText) => {
        const response = await axios.get<Array<Course>>(`/api/courses/GetCoursesByTeacherId?teacherId=${props.id}&viewStatus=${viewStatus}&requiresMarking=${onlyMarking}&searchCriteria=${searchText}`);
        setCourses(response.data);
    }

    const showOnlyMarking_onChange = () => {
        setShowOnlyMarking(!showOnlyMarking);
        loadTeacherClasses(viewStatus, !showOnlyMarking, searchCriteria);
    }

    const searchCriteria_onChange = (text:string) => {
        loadTeacherClasses(viewStatus, showOnlyMarking, text);
        setSearchCriteria(text);
    }

    const updateCurrentOpenTab = (tabId:number) => {
        if(tabId === currentOpenTab){
            tabId = 0;
        }
        setCurrentOpenTab(tabId)
    }

    const getSeriesStatusId = (statusId: number) => {
        if (statusId === 1) {
            return "Pending";
        } else if (statusId === 2) {
            return "Requires marking";
        } else if (statusId === 3) {
            return "Marked";
        }
    } 

  
    

    const updateGridClassStatus = (classId:number, marked:boolean) => {
        let newStatus = 0;

        if(marked){
            newStatus = 3;
        } else {
            newStatus = 2;
        }
 
        let data = [...courses];

        data && data.map((c,i) => {
            c.classes.map((cl,i) => {
                if(cl.classId === classId) {
                    cl.seriesStatusId = newStatus;
                }
            })
        });

        setCourses(data);
    }

    const selectRow = (courseId:number) => {
        props.history.push(`/CoursesForm/${courseId}`)
    }


    return(
        <div>
            <div className="page-title-box" style={{paddingBottom:"25px"}}>
                
                <div className="row">
                    <div className="col-6">
                        <div>
                            <input id="" className="form-control" style={{width:"250px"}} onChange={(e:any) => searchCriteria_onChange(e.target.value) } />                            

                            <table  style={{backgroundColor:"white"}} >
                                <tbody>
                                    <tr>
                                        <td style={{padding:"2px"}} align="center" > 
                                            {<CheckBoxRound checked={showOnlyMarking} onChange={() => showOnlyMarking_onChange()} />}
                                        </td>
                                        <td>Only require marking</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="page-title-btn btn-group float-right">
                            <WaveBox allowEmpty={true} items ={['CURRENT', 'PAST', 'FUTURE']} size={'sl'} onClick={(e) => classesTimeChange(e)} selected="CURRENT"/>
                        </div>
                    </div>
                </div>
            </div>


            <table className="table table table-hover m-0 ">
                                                <thead>
                                                <tr>
                                                    <th style={{width:"65px"}}></th>
                                                    <th>Course</th>
                                                    <th style={{width:"160px"}}>Subject</th>
                                                    <th style={{width:"160px"}}>Level</th>
                                                    <th style={{width:"160px"}}>Start Date</th>
                                                    <th style={{width:"160px"}}>Status</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                   
                                                {courses.map((c,i) => (
                                                    <tr  >
                                                        <td><Pill value={i+1} color="pink" /></td>
                                                        <td style={{cursor:"pointer"}} onClick={() => selectRow(c.courseId)}>{c.title}</td>
                                                        <td>
                                                            <SubjectBadge text={c.subject} color={c.subjectColor} />
                                                        </td>
                                                        <td>{c.courseLevel}</td>
                                                        <td>{c.startDateString}</td>
                                                        <td>
                                                            <CourseStatusBadge statusId={c.courseStatusId} allowClick={false} onClick={() => { }} />
                                                        </td>
                                                    </tr>
                                                ))
                                                }
                                                
                                                </tbody>
                                            </table>


            
           


            {(!courses || courses.length===0) && <div style={{textAlign: "center" }}>Teacher does not have any courses currently</div>}

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                <Modal.Title>Edit Class</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col">
                            <InputDatePicker label="Date of Birth" value="" />
                            <InputDropDown label="Room" data={rooms} width={235} showPlease={true}/>
                            <InputDropDown label="Rate" data={rates} width={235}  showPlease={true}/>
                            <InputDropDown label="Company" data={companies} width={235} showPlease={true}/>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleClose}>
                    Save Changes
                </Button>
                </Modal.Footer>
            </Modal>

            <MarkClassesModal hide={() => setShowStudents(false)} classId={currentSelectedClass} show={showStudents} students={currentCourseStudents} updateGridClassStatus={updateGridClassStatus} />
          
        </div>
    )
}

export default TeachersClasses;