import React, {useState, useEffect} from 'react';
import axios from 'axios';

import moment from 'moment';

import InputTextBoxDual from '../common/InputTextBoxDuel';
import InputDropDown from '../common/InputDropDown';
import InputDatePicker from '../common/InputDatePicker';
import InputDatePickerDual from '../common/InputDatePickerDual';
import TimeSelector from '../common/TimeSelector';

import Teacher from '../../models/Teacher';
import DayWeek from '../../models/DayWeek';
import Schedule from '../../models/Schedule';
import DropDownOption from '../../models/DropDownOption';
import ClassGenVM from '../../models/ClassGenVM';
import TeachersRate from '../../models/TeachersRate';
import Room from '../../models/Room';
import Course from '../../models/Course';
import Class from '../../models/Class';



const times = [
    {Text:"05:30", Value:"05:30"},
    {Text:"05:00", Value:"05:00"},
    {Text:"06:00", Value:"06:00"},
    {Text:"06:30", Value:"06:30"},
    {Text:"07:00", Value:"07:00"},
    {Text:"07:30", Value:"07:30"},
    {Text:"08:00", Value:"08:00"},
    {Text:"08:30", Value:"08:30"},
    {Text:"09:00", Value:"09:00"},
    {Text:"09:30", Value:"09:30"},
    {Text:"10:00", Value:"10:00"},
    {Text:"10:30", Value:"11:30"},
    {Text:"11:00", Value:"11:00"},
    {Text:"11:30", Value:"11:30"},
    {Text:"12:00", Value:"12:00"},
    {Text:"12:30", Value:"12:30"},
    {Text:"13:00", Value:"13:00"},
    {Text:"13:30", Value:"13:30"},
    {Text:"14:00", Value:"14:00"},
    {Text:"14:30", Value:"14:30"},
    {Text:"15:00", Value:"15:00"},
    {Text:"15:30", Value:"15:30"},
    {Text:"16:00", Value:"16:00"},
    {Text:"16:30", Value:"16:30"},
    {Text:"17:00", Value:"17:00"},
    {Text:"17:30", Value:"17:30"},
    {Text:"18:00", Value:"18:00"},
    {Text:"18:30", Value:"18:30"},
    {Text:"19:00", Value:"19:00"},
    {Text:"19:30", Value:"19:30"},
    {Text:"20:00", Value:"20:00"},
    {Text:"20:30", Value:"20:30"},
    {Text:"21:00", Value:"21:00"},
    {Text:"21:30", Value:"21:30"},
    {Text:"22:00", Value:"22:00"},
    {Text:"22:30", Value:"22:30"},
    {Text:"23:00", Value:"23:00"},
    {Text:"23:30", Value:"23:30"},
    {Text:"00:00", Value:"00:00"},
]


interface IProps {
    id:number, 
    currentCourse:Course,
    updateGenerateOptions: Function,
    generatorValidationErrors:Array<string>,
    classes:Array<Class>,
    errorMessage:string
}

const ClassesForm = (props:IProps) => {
    const [showEndDate] = useState(false);
    const [teachers, setTeachers] = useState(new Array<DropDownOption>());
    const [schedules, setSchedules] = useState(new Array<DropDownOption>());
    const [dayWeeks, setDayWeeks] = useState(new Array<DropDownOption>());
    const [classGen, setClassGen] = useState(new ClassGenVM());
    const [teacherRates, setTeacherRates] = useState(new Array<DropDownOption>());
    const [rooms, setRooms] = useState(new Array<DropDownOption>());

    

    useEffect(() => {
        let teacherOptions = new Array<DropDownOption>();
        let scheduleOptions = new Array<DropDownOption>();
        let dayWeekOptions = new Array<DropDownOption>();
        let teacherRateOptions = new Array<DropDownOption>();
        let roomOptions = new Array<DropDownOption>();

        const loadData = async () => {
            let responseTeachers = await axios.get<Array<Teacher>>(`/api/teachers/getall?status=1`);
            responseTeachers.data.map(t => teacherOptions.push({ Text: t.firstName + " " + t.lastName, Value: t.teacherId }));

            let responseSchedules = await axios.get<Array<Schedule>>(`/api/staticdata/getallschedules`);
            responseSchedules.data.map(t => scheduleOptions.push({ Text: t.name, Value: t.scheduleId }));

            let responseDayWeeks = await axios.get<Array<DayWeek>>(`/api/staticdata/getalldayweeks`);
            responseDayWeeks.data.map(t => dayWeekOptions.push({ Text: t.name, Value: t.dayWeekId }));

            let responseRooms = await axios.get<Array<Room>>(`/api/rooms/getbycourseid?id=${props.id}`);
            responseRooms.data.map(t => roomOptions.push({ Text: t.name, Value: t.roomId }));

            //Set defaults
            let classGenVMDefaults = new ClassGenVM();
            classGenVMDefaults.startDateString  = props.currentCourse.startDateString;
            classGenVMDefaults.numberOfClasses  = props.currentCourse.duration;
            classGenVMDefaults.classLength      = props.currentCourse.classLengthTemplate;
            classGenVMDefaults.time = "00:00";

            //Load values from previous series
            if(props.classes && props.classes.length > 1) {
                let classFromLastSeries = props.classes[props.classes.length-1];
                if(classFromLastSeries) {
                    classGenVMDefaults.teacherId = classFromLastSeries.teacherId;
                    classGenVMDefaults.teacherRateId = classFromLastSeries.teacherRateId;
                    classGenVMDefaults.scheduleId = classFromLastSeries.scheduleId;
                    classGenVMDefaults.classDatesCombined   = classFromLastSeries.classDatesCombined;
                    updateTeacherRates(classGenVMDefaults.teacherId)
                }
            }

            let dt = moment(props.currentCourse.startDateString, "DD-MM-YYYY")
            let defaultDayId:number = 0; 

            switch(dt.format('dddd')) {
                case "Monday":
                    defaultDayId = 1;
                  break;
                case "Tuesday":
                    defaultDayId = 2;
                  break;
                case "Wednesday":
                    defaultDayId = 3;
                    break;
                case "Thursday":
                    defaultDayId = 4;
                    break;
                case "Friday":
                    defaultDayId = 5;
                    break;
                case "Saturday":
                    defaultDayId = 6;
                    break;
                case "Sunday":
                    defaultDayId = 7;
                    break;
            }

            classGenVMDefaults.dayWeekId = defaultDayId;

            if(props.currentCourse.parentId !== 0 && (!props.currentCourse.classes || props.currentCourse.classes.length === 0)){
                const response = await axios.get<Array<Class>>(`/api/classes/GetByCourseId?id=${props.currentCourse.parentId}`);
    
                classGenVMDefaults.teacherId            = response.data[0].teacherId;
                classGenVMDefaults.teacherRateId        = response.data[0].teacherRateId;
                updateTeacherRates(classGenVMDefaults.teacherId);

                classGenVMDefaults.scheduleId           = response.data[0].scheduleId;
                classGenVMDefaults.classDatesCombined   = response.data[0].classDatesCombined;
                classGenVMDefaults.time                 = response.data[0].classTime;

                //alert(JSON.stringify(response.data[0]));
            }

            setClassGen(classGenVMDefaults);
            setTeachers(teacherOptions);
            setSchedules(scheduleOptions);
            setDayWeeks(dayWeekOptions);
            setRooms(roomOptions);

            props.updateGenerateOptions(classGenVMDefaults);
        }
        loadData();

        setDefaultsIfNewlyCloned();
    }, []);

  
    const setDefaultsIfNewlyCloned = async() => {
        let classGenVMDefaults = new ClassGenVM();
        

       
    }

   
    const changeHandler = (e:any) => {
        let updatedOptions = { ...classGen, [e.target.name]: parseInt(e.target.value) };
        setClassGen (updatedOptions);

        props.updateGenerateOptions(updatedOptions);
    }

    const teacherChangeHandler = async(e:any) => {
        const teacherId:number = parseInt(e.target.value);

        let updatedOptions = { ...classGen, [e.target.name]: teacherId };
        setClassGen (updatedOptions);

      

        props.updateGenerateOptions(updatedOptions);

        updateTeacherRates(teacherId);
    }

    const updateTeacherRates = async(teacherId:number) => {
        let teacherRateOptions = new Array<DropDownOption>();
        let responseTeachersRates = await axios.get<Array<TeachersRate>>(`/api/teachers/GetTeachersRateByTeacherId?id=${teacherId}&onlyActive=true`);
        responseTeachersRates.data.map(t => teacherRateOptions.push({ Text: t.description +  ' - £' +  t.rate.toString() + ' per hr', Value: t.teacherRateId }));
        setTeacherRates(teacherRateOptions);
    }

    const changeHandlerString = (e:any) => {
        let updatedOptions = { ...classGen, [e.target.name]: e.target.value };
        setClassGen(updatedOptions);

        props.updateGenerateOptions(updatedOptions);
    }

    const startDateChangeHandler = (date: any) => {
        let newDate = (date.getDate()).toString() + "/" + ((date.getMonth() + 1).toString() + "/" + date.getFullYear().toString())
        let updatedOptions = { ...classGen, startDateString: newDate };

        setClassGen (updatedOptions);
        props.updateGenerateOptions(updatedOptions);
    }


    const isFieldValid = (fieldName: string): boolean => {
        let error = props.generatorValidationErrors.find(s => s == fieldName);
        if (error) {
            return true;
        } else {
            return false;
        }
    }

    const newChangeHandlerTime = (newTime:string) => {
        let updatedOptions = { ...classGen, ['time']: newTime };
        setClassGen(updatedOptions);
        props.updateGenerateOptions(updatedOptions);
    }

  
    return (
        <>
            <div className="col-12" style={{padding:"15px"}}>
                <div className="card" >
                    <div className="row" style={{paddingBottom:"10px"}}>
                        <div className="col">
                            <div className="card-title pink" style={{paddingLeft:"10px",paddingTop:"10px"}}>Class Details </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="row" style={{paddingLeft:"15px"}}>
                                <div className="col"> 
                                    <InputDropDown label="Teacher" data={teachers} name="teacherId" value={classGen.teacherId} isError={isFieldValid("teacherId")} showPlease={true} width={415} changeFunc={(e:any) => teacherChangeHandler(e)}  />
                                    <InputDropDown label="Teacher's rate" name="teacherRateId" value={classGen.teacherRateId} isError={isFieldValid("teacherRateId")} data={teacherRates} showPlease={true} width={415} changeFunc={(e:any) => changeHandler(e)}  />
                                    
                                    <TimeSelector label="Start time" fullDate={classGen.classDatesCombined}   timeUpdated={newChangeHandlerTime} width={145} />
                                    
                                    <InputDropDown label="Schedule" name="scheduleId" value={classGen.scheduleId} isError={isFieldValid("scheduleId")} data={schedules} showPlease={true} width={415} changeFunc={(e:any) => changeHandler(e)}  />
                                    <div>{(props.currentCourse.private===false) && <InputDropDown label="Room" name="roomid" isError={isFieldValid("roomId")} data={rooms} showPlease={true} width={415} changeFunc={(e:any) => changeHandler(e)}  />}</div>
                                    
                                </div>
                                <div className="col">
                                    <InputDatePickerDual mode="E" value={classGen.startDateString && classGen.startDateString} label="Start date" onChange={(date) => startDateChangeHandler(date)} width={75}  />
                                    {showEndDate && <InputDatePicker label="End Date" value="" />}
                                    {!showEndDate && <InputTextBoxDual  mode="E" name="numberOfClasses" value={(classGen.numberOfClasses) ? classGen.numberOfClasses.toString() : "0"} onChange={(e:any)=>changeHandler(e)} isError={isFieldValid("numberOfClasses")} label="No of classes" width={415}/>}
                                    <InputTextBoxDual mode="E" name="classLength" onChange={(e:any)=>changeHandler(e)} value={(classGen.classLength) ? classGen.classLength.toString() : "0"} isError={isFieldValid("classLength")} label="Class length" width={415}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{color:"red", fontSize:"20px", textAlign:"center"}}>{props.errorMessage}</div>
            </div> 
        </>
    )
}

export default ClassesForm;