import React from 'react'

interface IProps {
    label:string,
    placeHolder?:string,
    width?:number,
    mode?:string,
    value?: string,
    onChange?: Function,
    name?: string,
    isError?: boolean,
    errorMessage?:string
}

const InputTextAreaDuel = (props:IProps) => {
    return (
        <div className="form-group row">
            <div style={{width:"125px",paddingTop:(props.mode==="E") ? "6px" : "0px",textAlign:"right", paddingRight:"30px"}}>
                <label >{props.label}</label>
            </div>
            <div>
                {(props.mode === "E") ? <><textarea  className={`form-control ${props.isError ? "parsley-error" : ""}`}   name={props.name} value={props.value} style={{ width: props.width }} onChange={(e: any) => props.onChange && props.onChange(e)} ></textarea> {(props.isError===true) && <div style={{color:"#e96154"}}>{(props.errorMessage) && props.errorMessage}</div>} </>: <div>{props.value}</div>}
            </div>
        </div>
    )
}

export default InputTextAreaDuel;