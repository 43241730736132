import React, { useState, useEffect, Component } from "react";
import TeacherPaymentVM from '../../models/TeacherPaymentVM';

import PaymentTable from '../reports/PaymentTable';

interface IProps {
    payments:Array<TeacherPaymentVM>;
    getPaymentGrandTotal:Function;
}

const TeachersPaymentTable = (props:IProps) => {
    return (
        <>
            <PaymentTable teacherPayments={props.payments} getPaymentGrandTotal={props.getPaymentGrandTotal} getPaymentTotalHours={()=>{}} hideNoRecordsMessage={true} />
        </>
    )
}

export default TeachersPaymentTable;