import React, { useState, useEffect} from 'react';

interface IProps {
    items:Array<string>
    size?:string,
    allowEmpty?:boolean,
    onClick?: Function,
    selected?:string,
    floatLeft?:boolean
}

const mediumStyle={width:"95px",fontSize:".9rem"}
const smallStyle={width:"85px",fontSize:".7rem"}
const smallLongStyle={width:"86px",fontSize:".7rem"}
const mobiletyle={width:"115px",fontSize:".7rem"}

const largeStyle={width:"185px",fontSize:".7rem"}

const WaveBox = (props:IProps) => {
    const [currentItem, setCurrentItem] = useState(props.allowEmpty ? "" : props.items[0]);


    const getStyle = (size:string) => {
        switch (size){
            case "s":
                return smallStyle;
            case "sl":
                return smallLongStyle;
            case "m":
                return mediumStyle;
            case "mm":
                return mobiletyle;
            case "lg":
                return largeStyle;
            }
    }
    

    useEffect(() => {
        if (props.selected) {
            setCurrentItem(props.selected);
        }
    }, []);
    useEffect(() => {
        if (props.selected) {
            setCurrentItem(props.selected);
        } else {
            setCurrentItem("");
        }
    }, [props.selected]);


    const changeSelectedItem = (i:string) => {
        let newVal = i;
        if (i != currentItem ) {
            newVal = i;
        } else if (i == currentItem && props.allowEmpty) {
            newVal = "";
        }
        setCurrentItem(newVal);
        props.onClick && props.onClick(newVal)
    }

    return (
        <div className="page-title-box" >
            <div className={`btn-group ${(!props.floatLeft || !props.floatLeft) && 'float-right'}`} > 
                <div className="btn-group">
                    {props.items.map(i => (
                        <button type="button" style={props.size ? getStyle(props.size) : getStyle('props.size')} className={`btn ${(i==currentItem ? "btn-primary-pink" : "btn-white")} `} onClick={() => changeSelectedItem(i)}>{i}</button>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default WaveBox;