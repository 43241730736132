import React, { useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import UserService from "../services/userService";
import FormService from "../services/formService";

import { withRouter } from 'react-router-dom';

import { Modal } from 'react-bootstrap';

import ActionButton from '../components/buttons/ActionButton';
import CancelButton from '../components/buttons/CancelButton';

import ConfirmationModal from '../components/common/ConfirmationModal';

import postal from 'postal';
import { isWindows } from 'react-device-detect';


interface IProps {
    history:any;
}

const logOut = () => {
    let userService = new UserService();

    userService.clearUser();
    document['location'].href = "/";  
}

const SideBar = (props:IProps) => {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [currentSection, setCurrentSection] = useState("");

    const [redirctUrl, setRedirctUrl] = useState("");

    const userService = new UserService();

    const sidebarChannel = postal.channel("SideBar");

    const redirectToUrl = (url:string) => {
        props.history.push(url);
        setShowConfirmation(false);
    }


   


    const changeUrl = async (url:string) => {
        if(FormService.getAreFormsDirty()){
            setRedirctUrl(url);
            setShowConfirmation(true);
        } else {
            props.history.push(url);
            setCurrentSection(url.replace("/",""));
        }
    }

    const changeHighlight = async (url:string) => {
        setCurrentSection(url.replace("/",""));
    }

    const hookUpPostalListeners = () => {
        sidebarChannel.subscribe("changeUrl", (newUrl:any) => {
            changeHighlight(`/${newUrl}`);
        });

    }

    const pulseClasses = () => {
        sidebarChannel.publish("pulseClasses", "updated");
    }
    const pulseCourses = () => {
        sidebarChannel.publish("pulseCourses", "updated");
    }

    useEffect(() => {
        hookUpPostalListeners();
    }, []);



    return (
        <>
            <div className="left-side-menu">
               <div className="slimscroll-menu">
                    <div id="sidebar-menu">
                        <ul className="metismenu" id="side-menu">
                            <li className="menu-title">Navigation</li>


                            {<li>
                                <a onClick={() => changeUrl("/")} className={`waves-effect ${currentSection==="" && "active"}` }>
                                    <i className="mdi mdi-speedometer" style={{fontSize:"21px"}}></i>
                                    <span> {(userService.hasRole("CompanyAdmin")) ? 'Dashboard' : "Home"} </span>
                                </a>
                            </li>}


                            {userService.hasRole("CompanyAdmin") &&
                                <li>
                                    <a onClick={() => {changeUrl("/courses?statusId=2");pulseCourses()}} className={`waves-effect ${(currentSection==="courses" || currentSection==="courses?statusId=2")  && "active"}` }>
                                        <i className="ti-briefcase" style={{fontSize:"16px"}} ></i>
                                        <span> Courses</span>
                                    </a>
                                </li>   
                            }

                            
                            {userService.hasRole("CompanyAdmin") &&
                                <li> 
                                    <a onClick={() => {changeUrl("/classes?statusId=1");pulseClasses(); }} className={`waves-effect ${(currentSection==="classes" || currentSection==="classes?statusId=1")  && "active"}`}>
                                        <i className="mdi mdi-teach" style={{fontSize:"20px"}} ></i>
                                        <span> Classes </span>
                                    </a>
                                </li>
                            }

                            {userService.hasRole("CompanyAdmin") &&
                                <li>
                                    <a onClick={() => changeUrl("/students")} className={`waves-effect ${currentSection==="students" && "active"}`}>
                                        <i className="mdi mdi-account" style={{fontSize:"20px"}} ></i>
                                        <span> Students </span>
                                    </a>
                                </li>
                            }


                            {userService.hasRole("Teacher") &&
                                 <li>
                                    <a onClick={() => changeUrl("/teachers/coursesmobile")} className={`waves-effect ${currentSection==="teachers/coursesmobile" && "active"}` }>
                                        <i className="ti-blackboard"></i>
                                        <span> Courses </span>
                                    </a>
                                </li>
                            }

                            {userService.hasRole("Teacher") &&
                                <li>
                                    <a onClick={() => {changeUrl("/teachers/classesmobile")}} className={`waves-effect ${currentSection==="teachers/classesmobile" && "active"}`}>
                                        <i className="ti-calendar"></i>
                                        <span> Classes </span>
                                    </a>
                                </li>
                            }

                            {userService.hasRole("Teacher") &&
                                <li>
                                    <a onClick={() => changeUrl("/teachers/studentsmobile")} className={`waves-effect ${currentSection==="teachers/studentsmobile" && "active"}`}>
                                        <i className="ti-user"></i>
                                        <span> Students </span>
                                    </a>
                                </li>
                            }

                            {/*userService.hasRole("Teacher") &&
                                <li>
                                    <a onClick={() => changeUrl("/teachers/paymentsmobile")} className={`waves-effect ${currentSection==="classes" && "active"}`}>
                                        <i className="ti-user"></i>
                                        <span> Payments </span>
                                    </a>
                                </li>
                            */}

                            {userService.hasRole("Student") &&
                                <li>
                                    <a onClick={() => {changeUrl("/students/classesmobile")}} className={`waves-effect ${currentSection==="teachers/classesmobile" && "active"}`}>
                                        <i className="ti-calendar"></i>
                                        <span> Classes </span>
                                    </a>
                                </li>
                            }
                            {userService.hasRole("Student") &&
                                 <li>
                                    <a onClick={() => changeUrl("/students/coursesmobile")} className={`waves-effect ${currentSection==="teachers/coursesmobile" && "active"}` }>
                                        <i className="ti-blackboard"></i>
                                        <span> Courses </span>
                                    </a>
                                </li>
                            }



                            {userService.hasRole("CompanyAdmin") &&
                                <>
                                    <li>
                                        <a onClick={() => changeUrl("/teachers")} className={`waves-effect ${currentSection==="teachers" && "active"}`}>
                                            <i className="mdi mdi-school-outline" style={{fontSize:"22px"}} ></i>
                                            <span> Teachers </span>
                                        </a>
                                    </li>
                                
                                    <li>
                                        <a onClick={() => changeUrl("/companies")} className={`waves-effect ${currentSection==="companies" && "active"}`}>
                                            <i className="ti ti-world" style={{fontSize:"16px"}} ></i>
                                            <span> Companies </span>
                                        </a>
                                    </li>

                                    <li>
                                        <a onClick={() => changeUrl("/rooms")}  className={`waves-effect ${currentSection==="rooms" && "active"}`}>
                                            <i className="mdi mdi-google-classroom" ></i>
                                            <span> Rooms </span>
                                        </a>
                                    </li>

                                    <li>
                                        <a onClick={() => changeUrl("/reports")} className={`waves-effect ${currentSection==="reports" && "active"}`}>
                                            <i className="mdi mdi-bank-outline" style={{fontSize:"20px"}}></i>
                                            <span> Payments </span>
                                        </a>
                                    </li>

                                    <li>
                                        <a onClick={() => changeUrl("/notes")} className={`waves-effect ${currentSection==="notes" && "active"}`}>
                                            {/*<i className="mdi mdi-file-document-edit-outline"></i>*/}
                                            <i className="ti ti-pencil-alt" style={{fontSize:"17px"}}></i>
                                            <span> Notes </span>
                                        </a>
                                    </li>
                                </>
                            }
                        </ul>
                    </div>
                </div>    
            </div>

            <ConfirmationModal showModal={showConfirmation} onConfirmed={() => redirectToUrl(redirctUrl)} onHide={() => setShowConfirmation(false)} />
        </>
    );
}

export default withRouter(SideBar);