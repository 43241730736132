import React, {useState, useEffect} from 'react'
import TeachersRate from '../../models/TeachersRate';
import {confirm} from 'react-bootstrap-confirmation';
import ActionButton from '../buttons/ActionButton';
import CheckBoxRound from '../common/CheckBoxRound';

import FormService from '../../services/formService';

import Confirmation from  '../../modals/Confirmation';

interface IProps {
    rates:Array<TeachersRate>
    deleteRate:Function,
    mode:string,
    addRate:Function,
    openRatesForm: Function
}

const TeachersRates = (props:IProps, teacherRateId:number) => {
    const [currentTeacherRate, setCurrentTeacherRate] = useState(new Array<TeachersRate>());
    const [showConfirmation, setShowConfirmation] = useState(false);

    const [currentTeacherRateId, setCurrentTeacherRateId] = useState(0);
    const [currentTeacherRateIndex, setCurrentTeacherRateIndex] = useState(0);

    

    const hideModal =() =>{
        setShowConfirmation(false);
    }

    const confirmButton_Click = () => {
        props.deleteRate(currentTeacherRateId, currentTeacherRateIndex);
        hideModal()
    }

    const fireDeleteRate = async(teacherRateId:number, index:number) => {
        //alert("delete")
        setCurrentTeacherRateId(teacherRateId);
        setCurrentTeacherRateIndex(index);
        setShowConfirmation(true);
    }

    const toogleActive = (r:TeachersRate) => {
        let currentTeacherRateCopy = [...currentTeacherRate];
        let foundIndex = currentTeacherRateCopy.findIndex(i => i.teacherRateId === r.teacherRateId);
        let rate:TeachersRate = currentTeacherRateCopy[foundIndex];

        rate.active = !r.active;
        currentTeacherRateCopy[foundIndex] = rate;

        FormService.setAreFormsDirty(true);

        setCurrentTeacherRate(currentTeacherRateCopy);
    }

    useEffect(() => {
        const cloneSheepsES6 = [...props.rates];
        setCurrentTeacherRate(cloneSheepsES6);
    }, [JSON.stringify(props.rates)]);

    

    return (<>
        <div className="form-group row">
                <div className="tags-default col-xl-12"> 
                    <div className="page-title-box" style={{paddingBottom:"25px",paddingTop:"15px"}}>
                        <div className="page-title-btn btn-group float-right">
                            <ActionButton onClick={()=> props.openRatesForm()} text="ADD RATE" />
                        </div>
                        <h4 className="header-title" style={{paddingTop:"8px"}}>Teacher's rates</h4>
                    </div>

                    <table className="table  table-hover m-0 "  style={{backgroundColor:"white", width:"100%"}} >
                        <thead>
                            <tr>
                                <th scope="col" style={{width:"*"}}>Rate name</th>
                                <th scope="col" align="center" style={{width:"118px"}}>Rate per hour</th>
                                <th scope="col" align="center" style={{width:"70px"}}>Active</th>
                                <th align="center" style={{width:"15px"}}>

                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentTeacherRate && currentTeacherRate.map((r,i) => (
                                <tr >
                                    <td style={{padding:"2px"}}> <div style={{paddingTop:"5px", paddingLeft:"12px"}}>{r.description}</div></td>
                                    <td style={{padding:"2px"}} align="right"><div style={{paddingTop:"5px", paddingRight:"12px"}}>{r.rate.toFixed(2)}</div></td>
                                    <td style={{padding:"2px"}} align="center" > 
                                        {(props.mode === "E") ? 
                                            <CheckBoxRound checked={(r.active) ? true : false} onChange={() => toogleActive(r)} />
                                            : (r.active) ? <i style={{fontSize:"22px"}}className="zmdi zmdi-check green fa-lg"  ></i> : <i style={{fontSize:"22px"}}className="zmdi zmdi-close red fa-lg"></i> 
                                        }
                                    </td>
                                    <td style={{padding:"2px"}}>
                                        {(!r.inUse) && 
                                            <i className="ti-trash pink" style={{ float: "right", fontSize: "20px", paddingTop:"5px", cursor:"pointer" }} onClick={(e) => fireDeleteRate(r.teacherRateId, i)}></i>
                                        }
                                    </td>
                                </tr>
                            ))}
                            {!props.rates || props.rates.length === 0 && (<tr><td colSpan={5} align="center">No rates defined</td></tr>)}


                        </tbody>
                    </table>
                </div>
        </div>
        <Confirmation title={'Delete confirmation'} confirmText="DELETE" bodyText={'Are you sure you want to delete this rate?'} showModal={showConfirmation} hideModal={hideModal} confirmButton_Click={confirmButton_Click} />
    </>
    )
}

export default TeachersRates;