import React, { useState, useEffect, Component } from "react";
import Document from '../../models/Document';
import axios from 'axios';
import UploadedDocument from '../../models/UploadedDocument';

interface IProps {
    id:number;
}

const DocumentList = (props:IProps) => {
    const [documents, setDocuments] = useState(new Array<Document>());
    const [files, setFiles] = useState(new Array<UploadedDocument>());

    const fetchDocuments = async() => {
        let response = await axios.get<Array<UploadedDocument>>(`/api/files/GetFilesByStudentId?studentId=${props.id}`);
        setFiles(response.data);
    }

    useEffect(() => {
        fetchDocuments();
    }, []);

    const getTypeIcon = (type:string) => {
        if(!type){
            return <div></div>;
        }

        switch(type.toUpperCase()) {
            case "DOC":
                return <i className="fa fa-file-word-o blue"></i>
            case "PDF":
                return <i className="fa fa-file-pdf-o pink"></i>
            default:
              return <div></div>
          }
    }

    const convertSubjectsToPills = (currentCourses:string) => {
        let courseArray = currentCourses.split(',');

        let pills = courseArray.map(f => (
            <><span className={`badge badge-${f.split('|')[1]}`}>{f.split('|')[0]}</span>&nbsp;</>
        ));
        return pills;
    }

    return (
        <>
           <table className="table table table-hover m-0 table-bordered " style={{backgroundColor:"white"}}>
                <thead>
                    <tr>
                        <th style={{width:"65px"}} align="center">Type</th>
                        <th style={{width:"55%"}} >Title</th>
                        <th className="text-right" style={{width:"95px"}}>File size</th>
                        <th style={{width:"165px"}}>Shared with</th>
                        <th style={{width:"135px"}} className="text-right">Times accessed</th>
                        <th style={{width:"195px"}}>Upload date</th>
                    </tr>
                </thead>

                <tbody>
                    {files.map(d => (
                        <tr>
                            <td  align="center">{getTypeIcon(d.extention)}</td>
                            <td>{d.name}</td>
                            <td className="text-right">{d.size}</td>
                            <td >{convertSubjectsToPills(d.fileAccessSubject)}</td>
                            <td className="text-right">{d.timesAccessed}</td>
                            <td>{d.uploadedDateString}</td>
                        </tr>
                    ))}

                    {!files || files.length === 0 && 
                        <tr>
                            <td colSpan={6}>No files currently</td>
                        </tr>
                    }
                </tbody>

            </table>

            
        </>
    )
}

export default DocumentList;