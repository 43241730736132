export default class Nationality {
    constructor() {

    }

    public unmarkedClassTotal: number = 0;
    public draftCourseTotal: number = 0;
    public lessThanThreeClassesRemaining: number = 0;
    public classCountErrorTotal: number = 0;
    public expiredTotal: number = 0;
    
}

