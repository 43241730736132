export default class DayWeek {
    constructor() {

    }

    public noteId: number = 0;
    public UserId:number;
    public entityId: number;
    public entityTypeId: number;
    public dateStamp:Date;
    public dateString:string;
    public title: string = "";
    public text: string = "";
    public userFullName: string = "";
}

