import React, {useEffect, useState, useRef} from 'react';
import axios from 'axios';
import TeacherPaymentVM from '../../models/TeacherPaymentVM';
import InputDropDown from '../common/InputDropDown';

import { Button,Modal } from 'react-bootstrap';

import PaymentReport from '../../models/PaymentReport';
import PaymentTable from './PaymentTable';
import Teacher from '../../models/Teacher';

import ActionButton from '../buttons/ActionButton';
import CheckBoxRound from '../common/CheckBoxRound';

import CurrencyFormater from '../common/CurrencyFormater';

import DatePicker, {registerLocale} from "react-datepicker";
import moment from 'moment';

import Confirmation from '../../modals/Confirmation';

import postal from 'postal';

import eu from "date-fns/locale/en-GB";

registerLocale('eu', eu);


class TeacherVM {
    constructor() {
        this.checked = true;
    }

    public firstName:string;
    public lastName:string;
    public checked:boolean;
    public teacherId:number;
}

interface IProps {
    incrementRefresh:Function;
    tabClickedTimes:number;
}


const SettlementReport = (props:IProps) => {

    const startRef = useRef<DatePicker>();

    const getStartMonth = () => {
        let currentYear = new Date().getFullYear();
        let currentMonth = new Date().getMonth() + 1;

        return currentMonth.toString() + "-" +  currentYear.toString();
    }

    const startDate = getStartMonth();

    const [teacherPayments, setTeacherPayments] = useState(new Array<TeacherPaymentVM>());
    const [paymentReports, setPaymentReports] = useState(new Array<PaymentReport>());
    const [currentDate, setCurrentDate] = useState(startDate);
    const [showPaymentModel, setShowPaymentModel] = useState(false);
    const [raisedPayments, setRaisedPayments] = useState(new Array<TeacherPaymentVM>());
    const [teachers, setTeachers] = useState(new Array<TeacherVM>());
    const [teacherString, setTeacherString] = useState("");
    const [periodEndDateString, setPeriodEndDateString] =  useState(moment().format("DD/MM/YYYY").toString());
    const [allTeachers, setAllTeachers] = useState(true);

    const [paymentGrandTotal, setPaymentGrandTotal] = useState(0);
    const [paymentTotalHours, setPaymentTotalHours] = useState(0);

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [compactTable, setCompactTable] = useState(false);

    const loadData = async(loadTeachers:boolean,teachersIds:string="") => {
        //let teachersIds:string = "";
        if(loadTeachers){
            let responseTeacher = await axios.get<Array<TeacherVM>>(`/api/teachers/Getall?status=1`);

            let setTeachersChecked = responseTeacher.data;
    
            setTeachersChecked.forEach(t => {
                teachersIds += t.teacherId + ",";
                t.checked=true;
            })
            if(teachersIds.length>0){
                teachersIds=teachersIds.slice(0, -1)
            }
            
            setTeacherString(teachersIds);
            setTeachers(setTeachersChecked);
        }

        let response = await axios.get<Array<TeacherPaymentVM>>(`/api/reports/GetTeacherPaymentReportAuto?teacherIds=${teachersIds}&periodEndDate=${periodEndDateString}`);
        setTeacherPayments(totalCoursePayment(response.data));
    }

    const dateOptions = [
        {Text:"Oct 2020",Value:"10-2020"},
        {Text:"Nov 2020",Value:"11-2020"},
        {Text:"Dec 2020",Value:"12-2020"},
        {Text:"Jan 2021",Value:"1-2021"},
        {Text:"Feb 2021",Value:"2-2021"},
        {Text:"Mar 2021",Value:"3-2021"},
        {Text:"Apr 2021",Value:"4-2021"},
        {Text:"May 2021",Value:"5-2021"}
    ]

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
    ];


    const getCurrrentSelectedDateText = () => {
        let currentMonth:number = parseInt(currentDate.split('-')[0]) -1;
        let monthName:string = monthNames[currentMonth];

        return monthName + " " + currentDate.split('-')[1];
    }

    const changeReportDate = async(e:any) => {
        loadData(e.target.value);
        const dateToShow = e.target.value;

        await setCurrentDate(dateToShow);
        loadPaymentReports(dateToShow);
    }

    const RaisePaymentReport = async() => {

        let rs = await axios.get<Array<TeacherPaymentVM>>(`/api/reports/RaisePaymentReport?dateToShow=${currentDate}&teacherIds=${teacherString}&periodEndDate=${periodEndDateString}`);
        
        await new Promise(resolve => setTimeout(resolve, 1500));

        loadPaymentReports(moment().format("DD/MM/YYYY").toString());

        setPeriodEndDateString(moment().format("DD/MM/YYYY").toString());

        loadTeacherPaymentsFromDate(moment().format("DD/MM/YYYY").toString());


        loadData(false);

        props.incrementRefresh();
    }

    const loadPaymentReports = async(dateToShow:string) => {
        const response = await axios.get<Array<PaymentReport>>(`api/reports/GetPaymentHeaders?dateToShow=${dateToShow}`);
        setPaymentReports(response.data);
    }

    const showPayment = async(paymentHeaderId:number) => {
        const response = await axios.get<Array<TeacherPaymentVM>>(`api/reports/GetRaisedPaymentReport?paymentHeaderId=${paymentHeaderId}`);
        setRaisedPayments(response.data);
        setShowPaymentModel(true);
    }

    const teacher_onChange = async(teacherId:number) => {
        let update = [...teachers];

        let teacher = update.find(t => t.teacherId===teacherId);
        if(teacher) {
            teacher.checked = !teacher.checked;
            setTeachers(update);
        }

        let teachersIds:string = "";
        update.forEach(t => {
            if(t.checked){
                teachersIds += t.teacherId + ",";
                t.checked=true;
            }
        })
        if(teachersIds.length>0){
            teachersIds=teachersIds.slice(0, -1)
        }

        let response = await axios.get<Array<TeacherPaymentVM>>(`/api/reports/GetTeacherPaymentReportAuto?teacherIds=${teachersIds}&periodEndDate=${periodEndDateString}`);
        setTeacherPayments(totalCoursePayment(response.data));
        setTeacherString(teachersIds);
    }

    const roundup = (num,dec) => {

        return Math.round((num + Number.EPSILON) * 100) / 100;


        /*dec= dec || 0;
        var  s=String(num);
        if(num%1)s= s.replace(/5$/, '6');
        return Number((+s).toFixed(dec));
        */
     }

    const totalCoursePayment = (teacherPayments:Array<TeacherPaymentVM>) => {

        teacherPayments.map(tp=>{
            let coursePaymentTotal:number = 0;
            
            tp.paymentItems.map(pi=>{
                let courseItems = tp.paymentItems.filter(p => p.courseId == pi.courseId);
                let sumCourseTotal:number = 0; 

                courseItems.map(i => {
                    sumCourseTotal += (i.classLength / 60) * i.ratePerHour;
                    pi.coursePaymentTotal = roundup(sumCourseTotal,2);
                })
            });
        });

        return teacherPayments;
    }


    const periodDateChangeHandler = async(date:any) => {
        let newDate = (date.getDate()).toString() + "/" + ((date.getMonth() + 1).toString()+ "/" + date.getFullYear().toString())

        moment.locale('en-gb');                  

        setPeriodEndDateString(newDate);

        let response = await axios.get<Array<TeacherPaymentVM>>(`/api/reports/GetTeacherPaymentReportAuto?teacherIds=${teacherString}&periodEndDate=${newDate}`);
        setTeacherPayments(totalCoursePayment(response.data));
    }

    const loadTeacherPaymentsFromDate = async(date:string) => {
        let response = await axios.get<Array<TeacherPaymentVM>>(`/api/reports/GetTeacherPaymentReportAuto?teacherIds=${teacherString}&periodEndDate=${date}`);
        setTeacherPayments(totalCoursePayment(response.data));

    }

    const teacher_toogleAll = async () => {
        setAllTeachers(!allTeachers);
        let teachersIds:string = "";
        
        let teachersUpdate = [...teachers];

        setPaymentGrandTotal(0);

        teachersUpdate.map(t => {
            t.checked = !allTeachers;
            if(!allTeachers){
                teachersIds += t.teacherId + ",";
            }
        });
        
        if(teachersIds.length>0){
            teachersIds=teachersIds.slice(0, -1)
        }

        await setTeachers(teachersUpdate);
        loadData(false,teachersIds);
    }

    const addUpGrandTotal = () => {
        //alert("Here to top up");
    }

    const reloadPayments = async() => {
        loadData(true);

        await new Promise(r => setTimeout(r, 5000));
    }


    useEffect(() => {
        const sidebarChannel = postal.channel("SettlementReport");
        let sc = sidebarChannel.subscribe("SettlementReport", (newUrl:any) => {
            reloadPayments();
        });

        loadData(true);
        loadPaymentReports(currentDate);
        addUpGrandTotal();
        
        return () => {
            sc.unsubscribe();
        }

    }, []);

    
    return (
        <>
        <div >
            <div className="row">
                <div className="col-9">
                    <div className="card">
                        <div className="card-body">
                            <div className="dropdown float-right" style={{height:"650px"}}>
                                <a href="#" className="dropdown-toggle card-drop" data-toggle="dropdown"
                                    aria-expanded="false">
                                    <i className="zmdi zmdi-more-vert"></i>
                                </a>
                                <ul className="dropdown-menu dropdown-menu-right">
                                    <li><a href="#" className="dropdown-item">Action</a></li>
                                    <li><a href="#" className="dropdown-item">Another action</a></li>
                                    <li><a href="#" className="dropdown-item">Something else here</a></li>
                                    <li className="dropdown-divider"></li>
                                    <li><a href="#" className="dropdown-item">Separated link</a></li>
                                </ul>
                            </div>

                            <h4 style={{paddingBottom:"8px"}} >
                                <div className="page-title-btn btn-group float-right"  style={{padding:"2px", textAlign:"center"}} >
                                    <div style={{paddingTop:"10px", paddingRight:"5px", fontSize:"14px", fontWeight:5}}>Only payment list</div>
                                        <CheckBoxRound checked={compactTable} onChange={()=>{setCompactTable(!compactTable)}} />
                                    </div>
                                <div style={{fontSize:"22px",paddingBottom:"8px"}} className="header-title m-t-0 m-b-30">CLASSES TO PROCESS</div>
                                <div className="header-title m-t-0 m-b-30" style={{fontSize:"17px"}}>TOTAL HOURS {(paymentTotalHours/60).toFixed(2)}</div>
                                <div className="header-title m-t-0 m-b-30" style={{fontSize:"17px"}}>TOTAL AMOUNT <CurrencyFormater value={paymentGrandTotal} /></div>
                            </h4>

                            <div style={{paddingTop:"15px"}}></div>
                            <PaymentTable teacherPayments={teacherPayments} getPaymentTotalHours={(totalHours:number)=>{setPaymentTotalHours(totalHours)}} getPaymentGrandTotal={(grandTotal:number) => {setPaymentGrandTotal(grandTotal)}} compactTable={compactTable} />
                        </div>
                    </div>
                </div>
                <div className="col-3">
                    
                    <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="dropdown float-right">
                                            <a href="#" className="dropdown-toggle card-drop" data-toggle="dropdown"
                                                aria-expanded="false">
                                                <i className="zmdi zmdi-more-vert"></i>
                                            </a>
                                            <ul className="dropdown-menu dropdown-menu-right">
                                                <li><a href="#" className="dropdown-item">Action</a></li>
                                                <li><a href="#" className="dropdown-item">Another action</a></li>
                                                <li><a href="#" className="dropdown-item">Something else here</a></li>
                                                <li className="dropdown-divider"></li>
                                                <li><a href="#" className="dropdown-item">Separated link</a></li>
                                            </ul>
                                        </div>


                                        <h4 style={{ paddingBottom: "8px" }} className="header-title m-t-0 m-b-30">Period end date </h4>
                                        
                                        <div className="row">
                                            <div className="col-10">
                                                <div>
                                                <div className="input-group" style={{paddingTop:"0px" }}>
                                                    <>
                                                        <DatePicker ref={startRef} calendarStartDay={1} locale="eu" autoComplete="off" className={`form-control-datepicker-payments` }  dateFormat="dd/MM/yyyy" style={{width:"35px"}} value={periodEndDateString}  name="startDateString" onChange={(e:any)=> periodDateChangeHandler(e)} autocomplete="nope"  />
                                                        <div className="input-group-append" onClick={() => {startRef.current.setOpen(true)}}>
                                                            <span className="input-group-text  b-0 text-white btn-primary-pink"><i className="ti-calendar"></i></span>
                                                        </div>
                                                    </>

                                                    
                                                </div>
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                
                                            </div>
                                            <div style={{paddingLeft:"12px", paddingTop:"15px"}}><ActionButton width={215} color={'PURPLE'} onClick={() => setShowConfirmationModal(true)} text="RAISE SETTLEMENT REPORT" /></div>    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="dropdown float-right">
                                            <a href="#" className="dropdown-toggle card-drop" data-toggle="dropdown"
                                                aria-expanded="false">
                                                <i className="zmdi zmdi-more-vert"></i>
                                            </a>
                                            <ul className="dropdown-menu dropdown-menu-right">
                                                <li><a href="#" className="dropdown-item">Action</a></li>
                                                <li><a href="#" className="dropdown-item">Another action</a></li>
                                                <li><a href="#" className="dropdown-item">Something else here</a></li>
                                                <li className="dropdown-divider"></li>
                                                <li><a href="#" className="dropdown-item">Separated link</a></li>
                                            </ul>
                                        </div>

                                        <h4 style={{paddingBottom:"8px"}} className="header-title m-t-0 m-b-30">Teachers</h4>


                                        <table className="table">
                                            <tr>
                                                <td>All</td>
                                                <td style={{padding:"2px"}} align="center"><CheckBoxRound checked={allTeachers} onChange={() => teacher_toogleAll()} /> </td>
                                            </tr>
                                            {teachers.map(t => (
                                            <tr>
                                                <td>{t.firstName} {t.lastName}</td>
                                                <td style={{padding:"2px"}} align="center"><CheckBoxRound checked={t.checked} onChange={() => teacher_onChange(t.teacherId)} /> </td>
                                            </tr>
                                            ))
                                            }
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal dialogClassName="modal-1000px" show={showPaymentModel} onHide={()=>setShowPaymentModel(false)}  size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Raised Reports</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="verticalScroll"><PaymentTable teacherPayments={raisedPayments} getPaymentGrandTotal={(paymentTotal:number)=>{}} getPaymentTotalHours={()=>{}} /></div>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>

            <Confirmation showModal={showConfirmationModal} hideModal={() => setShowConfirmationModal(false)} confirmButton_Click={() => {RaisePaymentReport();setShowConfirmationModal(false)}} title="Raise report confirmation" bodyText="Are you sure you wish to raise this settlement report?"  />
        </>
    )
}

export default SettlementReport;