import React, {useState,useEffect} from 'react';

interface IProps {
    checked:boolean
}

const TickCross = (props:IProps) => {

    return (
        <div> {(props.checked) ? <i style={{fontSize:"22px",color:"#45B0E2",fontWeight:"bolder"}}className="mdi mdi-check fa-lg"  ></i> : <i style={{fontSize:"22px",color:"#fe6271",fontWeight:"bolder"}}className="mdi mdi-close red fa-lg"></i>} </div>
    ) 

}

export default TickCross;