export default class ChangeUserDetailsRequestVM {
    constructor() {

    }

    public UserName: string 
    public FirstName: string 
    public LastName: string 
    public Email: string 
}

