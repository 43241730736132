import Nationality from '../models/Nationality';

export default class FormService  {
    public static setAreFormsDirty = (val:boolean) => {
        window['areFormsDirty'] = val;
    }

    public static getAreFormsDirty = () => {
        return window['areFormsDirty'];
    }

    public static setNationalityList(nationalities:Array<Nationality> ){
        window['nationalites'] = nationalities;
    }

    public static getNationalityList():Array<Nationality>{
        return window['nationalites'];
    }
}