import React, {useEffect, useState} from 'react'
import {Calendar,momentLocalizer } from 'react-big-calendar'
import events from './Events'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import CalandarItem from '../../models/CalandarItem';

import axios from "axios";

const localizer = momentLocalizer(moment)

interface IProps {
  id?:number,
  type?:string,
  history:any
}

const BasicCalandar = (props:IProps) => {
  const [events, setEvents] = useState(new Array<CalandarItem>());
  useEffect(() => {
    const loadClasses = async () => {
        let id:number = (props.id ? props.id : 0);
        let type:string = (props.type ? props.type : "T");

        let url:string="";
        if(type === "T") {
          url=`/api/calandar/TeacherCalandar?id=${id}`;
        } else if(type === "R") {
          url=`/api/calandar/RoomCalandar?id=${id}`;
        } else {
          url=`/api/calandar/StudentCalandar?id=${id}`;
        }
        const response = await axios.get<Array<CalandarItem>>(url);

        let evnt = response.data; 
        evnt.forEach(e => {
            e.start = new Date(e.start);
            e.end = new Date(e.end);
        });

        setEvents(response.data);
    }; 
    loadClasses();
}, []);
  
  return (
    <div>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        onSelectEvent={event =>  props.history.push(`/CoursesForm/${event.courseId}`)}
      />
    </div>

  )
}




export default BasicCalandar;