import React from 'react'

interface IProps {
    label:string,
    placeHolder?:string,
    width?:number,
    mode?:string,
    value?: string,
    onChange?: Function,
    name?: string,
    isError?: boolean,
    errorMessage?: string,
    isPassword?: boolean,
    labelWidth?:number,
    focusOnClick?:boolean
}

const handleFocus = (event) => event.target.select();

const InputTextArea2 = (props:IProps) => {
    return (
        <div className="form-group row">
            <div style={{width:(props.labelWidth) ? props.labelWidth.toString() + "px" : "125px",paddingTop:"6px",textAlign:"right", paddingRight:"30px"}}>
                <label >{props.label}</label>
            </div>
            <div style={{paddingTop:(props.mode==="E") ? "0px" : "5px"}}>
                {(props.mode === "E") ? <><textarea onClick={(e:any) => { if(props.focusOnClick && props.focusOnClick === true) handleFocus(e) }}   className={`form-control ${props.isError ? "parsley-error" : ""}`} name={props.name} value={props.value} style={{ width: props.width }} onChange={(e: any) => props.onChange && props.onChange(e)}/> {(props.isError===true) && <div style={{color:"#e96154"}}>{(props.errorMessage) && props.errorMessage}</div>} </> : <div>{props.value}</div>}
            </div>
        </div>
    )
}

export default InputTextArea2;